export class Promotion {
  id: string;
  title: string;
  description: string;
  order: number;
  is_active: boolean;
  payload: any;
  type: string;
  price_details: any;
  store_key_id: string;

  constructor(
    id: string,
    title: string,
    description: string,
    order: number,
    payload: any,
    type: string,
    price_details: any,
    is_active: boolean,
    store_key_id: string
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.order = order;
    this.payload = payload;
    this.type = type;
    this.price_details = price_details;
    this.is_active = is_active;
    this.store_key_id = store_key_id;
  }

  static fromDto(promotion: any) {
    const benefits_text = promotion.payload.benefits_text as string[];
    promotion.payload.benefits_text = benefits_text.map((item) => {
      return { benefit_text: item };
    });
    const array = new Array<any>();
    if (promotion.price_details) {
      Object.entries<object>(promotion.price_details).forEach(
        ([key, value]) => {
          array.push({
            key,
            ...value
          });
        }
      );
      promotion.price_details = array;
    }
    return new Promotion(
      promotion.id,
      promotion.title,
      promotion.description,
      promotion.order,
      promotion.payload,
      promotion.type,
      promotion.price_details,
      promotion.is_active,
      promotion.store_key_id
    );
  }
}
