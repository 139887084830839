import { ConfirmProps } from 'react-admin';

import { CheckCircle, InfoOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

export type ConfirmCancelProps = Omit<ConfirmProps, 'title' | 'content'> & {
  recordName: string;
};
export function ConfirmCancel(props: ConfirmCancelProps) {
  return (
    <Dialog disableScrollLock={true} open={props.isOpen ?? false}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to cancel this {props.recordName}.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          disabled={props.loading ?? false}
          sx={(theme) => ({ color: theme.palette.grey[500] })}
        >
          <InfoOutlined /> Abort
        </Button>
        <Button
          onClick={props.onConfirm}
          disabled={props.loading ?? false}
          sx={(theme) => ({ color: theme.palette.warning.main })}
        >
          <CheckCircle /> Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
