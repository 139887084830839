import { LONG_LOCALE_STRING_CONFIG } from './constants';

export function calcDateToValidationFormat(days = 0, month = 0, years = 0) {
  const newDate = new Date();
  newDate.setDate(newDate.getDate() + days);
  newDate.setMonth(newDate.getMonth() + month);
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate.toISOString().split('T')[0];
}

export function formatStringToDate(date: string) {
  return new Date(date).toLocaleString('en-GB', LONG_LOCALE_STRING_CONFIG);
}
