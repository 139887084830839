import {
  Create,
  CreateProps,
  useCreate,
  useEditContext,
  useNotify
} from 'react-admin';

import { ConfirmCreate } from 'shared/components/dialogs/ConfirmCreate.component';

import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { UnchangedDialog } from 'shared/components/dialogs/UnchangedDialog.component';
import { ResourceName } from 'shared/enums';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';

import { AdminsForm } from '../components/AdminsForm.component';
import { AppAdmin } from '../models/Admin.model';
import { AdminsMutationPayload } from '../models/admins-mutation-payload.model';

export function AdminsCreate(props: CreateProps) {
  return (
    <Create {...props} title='Admins'>
      <>
        <AdminsCreateForm />
      </>
    </Create>
  );
}
function AdminsCreateForm() {
  const notify = useNotify();
  const [create, updateState] = useCreate();
  const createContext = useEditContext<AppAdmin>();

  const {
    shouldShowConfirmSubmit,
    shouldShowUnchanged,
    shouldShowUnsaved,
    goBack,
    handleSave,
    handleCancelEdit,
    handleCancelSubmit,
    handleConfirmSubmit,
    handleUnchangedContinue,
    handleUnchangedQuit,
    handleUnsavedCancel,
    handleUnsavedConfirm
  } = useMutationVM<AdminsMutationPayload, AppAdmin>({
    PayloadClass: AdminsMutationPayload,
    record: createContext.record,
    resource: ResourceName.ADMINS,
    mutator: (resource, id, data, prev, options) => {
      create(resource, data, options);
    },
    onMutateFailure: handleUpdateFailure,
    onMutateSuccess: handleUpdateSuccess
  });
  function handleUpdateSuccess() {
    notify('Admin permissions updated successfully.', { type: 'success' });
    goBack();
  }
  function handleUpdateFailure(e: Error) {
    notify(
      e.message || 'Something went wrong. Please try again in a few seconds.',
      { type: 'error' }
    );
  }
  return (
    <>
      <AdminsForm onCancel={handleCancelEdit} onSave={handleSave} />
      <ConfirmCreate
        loading={createContext.loading || updateState.loading}
        isOpen={shouldShowConfirmSubmit}
        onConfirm={handleConfirmSubmit}
        onClose={handleCancelSubmit}
        recordName=' an Admin'
      />
      <ConfirmUnsaved
        loading={createContext.loading}
        isOpen={shouldShowUnsaved}
        onClose={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
      />
      <UnchangedDialog
        name='Admin'
        itemPageName='Customer'
        isOpen={shouldShowUnchanged}
        onContinue={handleUnchangedContinue}
        onQuit={handleUnchangedQuit}
      />
    </>
  );
}
