import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
  required
} from 'react-admin';

import { ResourceName } from 'shared/enums/resource-name.enum';
import { RaListOrder } from 'shared/services/backoffice-data-provider/enums/ra-list-order.enum';

import { AdminsFormSource } from '../../../resources/admins/components/AdminsForm.component';

export type userIDProps = Omit<ReferenceInputProps, 'reference' | 'source'>;

export function UserIdInputFromEmail(props: userIDProps) {
  const appUsersIdFilterToQuery = (filter: string) => {
    const getSearch = filter.length > 0 ? { search: filter } : {};
    return {
      ...getSearch
    };
  };
  return (
    <ReferenceInput
      {...props}
      source={AdminsFormSource.user_id}
      reference={ResourceName.USERS}
      sort={{
        field: 'email',
        order: RaListOrder.ASC
      }}
      filterToQuery={appUsersIdFilterToQuery}
      validate={required()}
    >
      <AutocompleteInput optionText='email' resettable />
    </ReferenceInput>
  );
}
