import { ComponentType } from 'react';
import {
  ResourceComponentProps,
  ResourceComponentPropsWithId,
  ResourceMatch,
  ResourceOptions,
  ResourceProps
} from 'react-admin';

import { ResourceIntent } from 'shared/enums';

export class RaModule implements ResourceProps {
  name: string;
  list?: ComponentType<ResourceComponentProps>;
  create?: ComponentType<ResourceComponentProps>;
  edit?: ComponentType<ResourceComponentPropsWithId>;
  show?: ComponentType<ResourceComponentPropsWithId>;
  icon?: ComponentType<unknown>;
  intent?: ResourceIntent;
  match?: ResourceMatch;
  options?: ResourceOptions;

  constructor(
    name: string,
    options?: ResourceOptions,
    list?: ComponentType<ResourceComponentProps>,
    create?: ComponentType<ResourceComponentProps>,
    edit?: ComponentType<ResourceComponentPropsWithId>,
    show?: ComponentType<ResourceComponentPropsWithId>,
    icon?: ComponentType<unknown>,
    intent: ResourceIntent = ResourceIntent.ROUTE,
    match?: ResourceMatch
  ) {
    this.name = name;
    this.options = options;
    this.list = list;
    this.create = create;
    this.edit = edit;
    this.show = show;
    this.icon = icon;
    this.intent = intent;
    this.match = match;
  }
  static fromResourceProps(config: ResourceProps) {
    return new RaModule(
      config.name,
      config.options || {},
      config.list,
      config.create,
      config.edit,
      config.show,
      config.icon,
      config.intent as ResourceIntent,
      config.match
    );
  }
}
