import { DataProvider } from 'react-admin';

import { ActivityDataProvider } from 'resources/activities/ActivityDataProvider.service';

import { AdminDataProvider } from 'resources/admins/AdminDataProvider.service';
import { CampaignDataProvider } from 'resources/campaigns/CampaignDataProvider.service';

import { BatchesDataProvider } from 'resources/code_batches/BatchesDataProvider';
import { CollectionDataProvider } from 'resources/collections/CollectionDataProvider.service';
import { ContentPackagesDataProvider } from 'resources/content_packages/ContentPackagesDataProvider.service';
import { CustomerDataProvider } from 'resources/customers/CustomerDataProvider.service';
import { LeaderboardDataProvider } from 'resources/leaderboard/LeaderboardDataProvider.service';
import { MembershipDisplayDataProvider } from 'resources/membership_display/MembershipDisplayDataProvider.service';
import { StoreKeysDataProvider } from 'resources/mobile_store_keys/StoreKeysDataProvider.service';
import { PromotionsDataProvider } from 'resources/promotions/PromotionsDataProvider.service';
import { ReferralDataProvider } from 'resources/referrals/ReferralDataProvider.service';
import { SegmentDataProvider } from 'resources/segments/SegmentDataProvider.service';
import { TestDataProvider } from 'resources/tests/TestDataProvider.service';

import { UsersDataProvider } from 'resources/users/data-provider/UsersDataProvider.service';

import { UserPlansDataProvider } from 'resources/users_plans/UserPlansDataProvider';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { AuthService } from 'shared/services/backoffice-data-provider';

import { DefaultDataProvider } from 'shared/services/backoffice-data-provider/default-data-provider.service';
import { Constructor } from 'shared/utils/types/constructor';

import { LogsDataProvider } from './logs-data-provider/logs-data-provider.service';
import { CodesDataProvider } from '../../resources/codes/CodesDataProvider.service';

export class DataProviderProxy {
  constructor(private authService: AuthService) {}

  private defaultProvider = new DefaultDataProvider(this.authService);
  private userPlansProvider!: UserPlansDataProvider;
  private codesProvider!: CodesDataProvider;
  private codeBatchesProvider!: BatchesDataProvider;
  private usersProvider!: UsersDataProvider;
  private adminsProvider!: AdminDataProvider;
  private loggsDataProvider!: LogsDataProvider;
  private customerDataProvider!: CustomerDataProvider;
  private activitiesDataProvide!: ActivityDataProvider;
  private testDataProvide!: TestDataProvider;
  private collectionsDataProvide!: CollectionDataProvider;
  private leaderboardssDataProvide!: LeaderboardDataProvider;
  private membershipDisplayDataProvider!: MembershipDisplayDataProvider;
  private contentPackagesDataProvider!: ContentPackagesDataProvider;
  private promotionsDataProvider!: PromotionsDataProvider;
  private storeKeysDataProvider!: StoreKeysDataProvider;
  private campaignDataProvider!: CampaignDataProvider;
  private referralDataProvider!: ReferralDataProvider;
  private segmentDataProvider!: SegmentDataProvider;

  get() {
    return new Proxy<DataProvider>(this.defaultProvider, {
      get: (target, name: string) => (resource: string, params: object) => {
        let provider: DataProvider;

        switch (resource) {
          case ResourceName.ONLINE_CUSTOMERS:
          case ResourceName.OFFLINE_CUSTOMERS: {
            provider = this.getProviderInstance(
              this.customerDataProvider,
              CustomerDataProvider
            );
            break;
          }
          case ResourceName.ADMINS: {
            provider = this.getProviderInstance(
              this.adminsProvider,
              AdminDataProvider
            );
            break;
          }
          case ResourceName.CODES: {
            provider = this.getProviderInstance(
              this.codesProvider,
              CodesDataProvider
            );
            break;
          }
          case ResourceName.BATCHES: {
            provider = this.getProviderInstance(
              this.codeBatchesProvider,
              BatchesDataProvider
            );
            break;
          }
          case ResourceName.USERS_PLANS: {
            provider = this.getProviderInstance(
              this.userPlansProvider,
              UserPlansDataProvider
            );
            break;
          }
          case ResourceName.USERS: {
            provider = this.getProviderInstance(
              this.usersProvider,
              UsersDataProvider
            );
            break;
          }
          case ResourceName.LOGS: {
            provider = this.getProviderInstance(
              this.loggsDataProvider,
              LogsDataProvider
            );
            break;
          }
          case ResourceName.ACTIVITIES:
          case ResourceName.USER_ACTIVITIES: {
            provider = this.getProviderInstance(
              this.activitiesDataProvide,
              ActivityDataProvider
            );
            break;
          }
          case ResourceName.TESTS: {
            provider = this.getProviderInstance(
              this.testDataProvide,
              TestDataProvider
            );
            break;
          }
          case ResourceName.COLLECTIONS: {
            provider = this.getProviderInstance(
              this.collectionsDataProvide,
              CollectionDataProvider
            );
            break;
          }
          case ResourceName.LEADERBOARD: {
            provider = this.getProviderInstance(
              this.leaderboardssDataProvide,
              LeaderboardDataProvider
            );
            break;
          }
          case ResourceName.MEMBERSHIP_DISPLAY: {
            provider = this.getProviderInstance(
              this.membershipDisplayDataProvider,
              MembershipDisplayDataProvider
            );
            break;
          }
          case ResourceName.CONTENT_PACKAGES: {
            provider = this.getProviderInstance(
              this.contentPackagesDataProvider,
              ContentPackagesDataProvider
            );
            break;
          }
          case ResourceName.PROMOTIONS: {
            provider = this.getProviderInstance(
              this.promotionsDataProvider,
              PromotionsDataProvider
            );
            break;
          }
          case ResourceName.STORE_KEYS: {
            provider = this.getProviderInstance(
              this.storeKeysDataProvider,
              StoreKeysDataProvider
            );
            break;
          }
          case ResourceName.CAMPAIGNS: {
            provider = this.getProviderInstance(
              this.campaignDataProvider,
              CampaignDataProvider
            );
            break;
          }
          case ResourceName.REFERRALS: {
            provider = this.getProviderInstance(
              this.referralDataProvider,
              ReferralDataProvider
            );
            break;
          }
          case ResourceName.SEGMENTS: {
            provider = this.getProviderInstance(
              this.segmentDataProvider,
              SegmentDataProvider
            );
            break;
          }
          default:
            provider = target;
        }

        return provider[name].bind(provider)(resource, params);
      }
    });
  }
  private getProviderInstance<T>(
    prop: T | undefined,
    Instance: Constructor<T, [authService: AuthService]>
  ) {
    let provider = prop;
    if (provider == null) {
      provider = new Instance(this.authService);
    }
    return provider;
  }
}
