import React, { useState } from 'react';
import {
  fetchEnd,
  fetchStart,
  Record as RaRecord,
  useAuthProvider
} from 'react-admin';
import { useDispatch } from 'react-redux';

import axios, { AxiosError, AxiosResponse } from 'axios';

import {
  BPError,
  BPErrorResponse
} from 'shared/services/backoffice-data-provider';
import { BPApiResponse } from 'shared/services/backoffice-data-provider/models/server-responses/bp-api-response.model';
import { getApiRoot } from 'shared/utils/helperFunctions/dotenv.utils';
import { createSearchParams } from 'shared/utils/navigation';

export function useAxiosCall<Data = RaRecord, Params = object>(props: {
  resource: string;
  params?: Params;
  init?: RequestInit;
  autoFetch?: boolean;
}) {
  const client = axios.create();
  const { resource, init, autoFetch = true, params = {} } = props;
  const authProvider = useAuthProvider();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Data>();
  const [error, setError] = useState<BPError | undefined>();

  const handleFetch = React.useCallback(
    async (_params?: Params) => {
      setLoading(true);
      dispatch(fetchStart());
      const token = await authProvider.getToken();
      const method = init?.method ?? 'GET';
      let url = `${getApiRoot()}/${resource}`;
      let data = _params ?? params ?? undefined;
      if (method === 'GET') {
        url = `${url}?${createSearchParams(_params ?? params ?? {})}`;
        data = undefined;
      }
      return client
        .request({
          url,
          method,
          data,
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(async (response: AxiosResponse) => {
          const { payload } = response.data as BPApiResponse<Data>;
          setData(payload as Data);
          dispatch(fetchEnd());
          setLoading(false);
          return payload as Data;
        })
        .catch((e: AxiosError<BPErrorResponse>) => {
          const error: BPError | undefined = e.response?.data.error;
          setError(error);
          dispatch(fetchEnd());
          setLoading(false);
          throw error;
        });
    },
    [dispatch, authProvider, client, resource, params, init?.method]
  );

  React.useEffect(() => {
    if (autoFetch) handleFetch();
  }, [handleFetch, autoFetch]);
  return React.useMemo(
    () => ({ loading, data, error, fetch: handleFetch }),
    [loading, data, error, handleFetch]
  );
}
