import { Privilege } from 'shared/utils/constants/blazepod';

const { NONE } = Privilege;

export function isPublisher(permission: Privilege = NONE) {
  return permission === Privilege.PUBLISHER;
}
export function isEditor(permission: Privilege = NONE) {
  return permission === Privilege.EDITOR || isPublisher(permission);
}
export function isViewer(permission: Privilege = NONE) {
  return permission === Privilege.VIEWER || isEditor(permission);
}
