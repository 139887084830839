import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import {
  Auth,
  AuthProvider as Auth0Provider,
  getAuth,
  GoogleAuthProvider
} from 'firebase/auth';
import { FirebaseStorage, getStorage } from 'firebase/storage';

export let Storage: FirebaseStorage;

export class FirebaseWrapper {
  authProvider: Auth0Provider;
  auth: Auth;
  storage: FirebaseStorage;
  app: FirebaseApp;

  constructor(firebaseConfig: FirebaseOptions) {
    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth(this.app);
    this.authProvider = new GoogleAuthProvider();
    this.storage = getStorage(this.app);
    Storage = this.storage;
  }
}
