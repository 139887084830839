import { useMemo } from 'react';
import { useNotify, useRecordContext } from 'react-admin';

import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import { Button, CircularProgress, Tooltip } from '@mui/material';

import {
  HasOnError,
  HasOnSuccess
} from 'shared/components/interfaces/MutationComponentProps';
import { ResourceName } from 'shared/enums';

import { useAxiosCall } from 'shared/hooks/useAxiosCall';

import { BPError } from 'shared/services/backoffice-data-provider';
import { getTitleFromCamelCase } from 'shared/utils/helperFunctions/strings';

import { AppUser } from '../models/AppUser.model';

export type resetSeenItems = Partial<HasOnError & HasOnSuccess> & {
  property_name: string;
};

export function ResetSeenItemsButton(props: resetSeenItems) {
  const notify = useNotify();
  const record = useRecordContext<AppUser>();
  const title = getTitleFromCamelCase(props.property_name).toLocaleLowerCase();
  const fetchResetSeenItems = useAxiosCall<boolean>({
    resource: `${ResourceName.USERS}/reset_seen_items`,
    autoFetch: false
  });
  const handleMutateSuccess = () => {
    notify(`Reset ${title} success.`, { type: 'success' });
  };
  const handleMutateFailure = () => (e: BPError) => {
    notify(e?.message ?? 'Something went wrong', { type: 'error' });
  };
  const buttonLabel = useMemo(() => {
    if (record == null) {
      return 'Loading User...';
    }
    return `Reset ${title}`;
  }, [record, title]);

  const handleClick = () => {
    fetchResetSeenItems
      .fetch({
        property_name: props.property_name,
        document_number: record.id
      })
      .then(handleMutateSuccess)
      .catch(handleMutateFailure);
  };

  const isDisabled = useMemo(
    () => record == null || fetchResetSeenItems.loading,
    [fetchResetSeenItems.loading, record]
  );

  if (record == null) {
    return <CircularProgress size='sm' />;
  }
  return (
    <Tooltip
      arrow
      placement='left'
      title={buttonLabel}
      describeChild
      enterDelay={500}
    >
      <div>
        <Button
          onClick={handleClick}
          disabled={isDisabled}
          size='small'
          variant='contained'
        >
          <AppShortcutIcon />
          {buttonLabel}
        </Button>
      </div>
    </Tooltip>
  );
}
