import { CreateProps, useRedirect } from 'react-admin';

import { CustomersCreate } from 'resources/customers/components/CustomersCreate.component';

import { ResourceName } from 'shared/enums/resource-name.enum';

export function OfflineCustomersCreate(props: CreateProps) {
  const redirect = useRedirect();
  const navigateToList = () => redirect(`/${ResourceName.OFFLINE_CUSTOMERS}`);
  return (
    <CustomersCreate
      onCancel={navigateToList}
      handleSuccess={navigateToList}
      imperativeResource={ResourceName.CUSTOMERS}
      {...props}
    />
  );
}
