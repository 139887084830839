import { useMemo } from 'react';
import {
  BooleanField,
  Datagrid,
  Loading,
  NumberField,
  Record,
  ReferenceField,
  TextField,
  useListContext
} from 'react-admin';

import { EditNewTagButton } from 'resources/contents/EditNewTagButton.component';
import { EditTrendingTagButton } from 'resources/contents/EditTrendingTagButton.component';
import { PublishUnpublishedButton } from 'resources/contents/PublishUnpublishedButton.component';

import { FieldObject } from 'resources/types';
import { BaseDeleteButton } from 'shared/components/buttons/BaseDeleteButton.component';
import { ContentItemEditButton } from 'shared/components/buttons/ContentItemEditButton.component';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { ResourceName } from 'shared/enums';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { HasOmitItems } from 'shared/interfaces/react-admin';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';

import { shortenString } from 'shared/utils/helperFunctions/shortenString';

import { Collection } from '../models/Collection.model';

export const CollectionTable = (
  props: Partial<HasOmitItems<keyof Collection>>
) => {
  const listContext = useListContext<Collection>();
  const { permissions } = useAppPermissions();
  const fields = useMemo(() => {
    const allFields: Array<FieldObject<keyof Collection>> = [
      {
        source: 'display_name',
        element: (
          <LinkableField
            source={'display_name'}
            label='Name'
            render={(record?: Record) =>
              shortenString(record?.display_name ?? '', 50)
            }
            emptyText='-'
          />
        )
      },
      {
        source: 'visibility',
        element: (
          <TextField
            sortable={false}
            source='visibility'
            label='App visibility'
            emptyText='-'
          />
        )
      },
      {
        source: 'is_popular',
        element: (
          <BooleanField
            sortable={false}
            source='is_popular'
            label='Is Popular'
            emptyText='-'
          />
        )
      },
      {
        source: 'contents_count',
        element: (
          <NumberField
            sortable={false}
            source={`contents_count`}
            label='Content Count'
            emptyText='-'
          />
        )
      },
      {
        source: 'my_draft',
        element: (
          <ReferenceField
            sortable={false}
            source='my_draft'
            label={'Draft Name'}
            link={'show'}
            emptyText='-'
            reference={ResourceName.COLLECTIONS}
          >
            <TextField source='display_name' />
          </ReferenceField>
        )
      }
    ];

    const output: JSX.Element[] = [];

    for (const field of allFields) {
      if (props.omitItems ? !props.omitItems.includes(field.source) : true) {
        output.push(field.element);
      }
    }
    return output;
  }, [props.omitItems]);

  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <Datagrid>
          {fields}
          {isPublisher(permissions?.content) && <PublishUnpublishedButton />}
          {isPublisher(permissions?.content) && <EditNewTagButton />}
          {isPublisher(permissions?.content) && <EditTrendingTagButton />}
          {isPublisher(permissions?.content) && (
            <ContentItemEditButton
              label=''
              resource={ResourceName.COLLECTIONS}
            />
          )}
          {isPublisher(permissions?.content) && (
            <BaseDeleteButton
              label={false}
              tooltip='Delete collection'
              messageContent={'Confirm to delete this collection'}
              messageTitle={'Delete collection'}
            />
          )}
        </Datagrid>
      )}
    </>
  );
};
