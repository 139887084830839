import { useMemo } from 'react';
import { ConfirmProps, useRecordContext } from 'react-admin';

import { CheckCircle, InfoOutlined } from '@mui/icons-material';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List
} from '@mui/material';

import { ContentItem } from 'resources/activities/models/ContentItem.model';
import { ListItemWithIcon } from 'shared/components/common/ListItemWithIcon';

export function PublishWebsiteConfirmDialog(
  props: Omit<ConfirmProps, 'content'>
) {
  const record = useRecordContext<ContentItem>();

  const hasTags = useMemo(() => {
    if (
      record.goals.length < 1 ||
      record.accessories.length < 1 ||
      record.fields.length < 1
    ) {
      return false;
    }
    return true;
  }, [record.accessories.length, record.fields.length, record.goals.length]);

  const hasDescription = useMemo(() => {
    if (record.description.length <= 3) return false;
    return true;
  }, [record.description.length]);

  const hasPicture = useMemo(() => {
    if (!record.cover_box) return false;
    return true;
  }, [record.cover_box]);

  const hasVideo = useMemo(() => {
    return record.has_video;
  }, [record.has_video]);

  const shouldDisabled = useMemo(() => {
    return !(hasTags && hasDescription && hasPicture && hasVideo);
  }, [hasDescription, hasPicture, hasTags, hasVideo]);

  return (
    <Dialog disableScrollLock={true} open={props.isOpen ?? false}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Verify all above is completed and confirm to 
          publish ${record.type.toLowerCase()} to website`}
        </DialogContentText>
        <Grid>
          <Grid>
            <List dense={true}>
              <ListItemWithIcon
                approve={hasDescription}
                label='Description'
              ></ListItemWithIcon>
              <ListItemWithIcon
                approve={hasPicture}
                label='Picture'
              ></ListItemWithIcon>
              <ListItemWithIcon
                approve={hasVideo}
                label='Video'
              ></ListItemWithIcon>
              <ListItemWithIcon
                approve={hasTags}
                label='Tags'
              ></ListItemWithIcon>
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          disabled={props.loading ?? false}
          sx={(theme) => ({ color: theme.palette.grey[500] })}
        >
          <InfoOutlined /> {'Abort'}
        </Button>
        <Button
          onClick={props.onConfirm}
          disabled={shouldDisabled}
          sx={(theme) => ({ color: theme.palette.primary.main })}
        >
          <CheckCircle /> {'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
