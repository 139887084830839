import { useCallback, useState } from 'react';
import {
  Edit,
  EditProps,
  useEditContext,
  useNotify,
  useRedirect,
  useUpdate
} from 'react-admin';
import { useHistory, useParams } from 'react-router-dom';

import {
  CustomerCreatedPayload,
  CustomerMutationPayload
} from 'resources/customers/models/customer-mutation-payload.model';
import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { ConfirmUpdate } from 'shared/components/dialogs/ConfirmUpdate.component';
import { UnchangedDialog } from 'shared/components/dialogs/UnchangedDialog.component';
import { ResourceName } from 'shared/enums/resource-name.enum';

import { validateChanged } from 'shared/utils/forms/validateChanged';

import { CustomerForm } from './CustomerForm.component';

export type CustomerEditProps = EditProps;

export function CustomersEdit(props: CustomerEditProps) {
  return (
    <Edit
      {...props}
      title='Offline Customers'
      resource={ResourceName.CUSTOMERS}
    >
      <CustomerEditForm />
    </Edit>
  );
}

function CustomerEditForm() {
  const [formValues, setFormValues] = useState<CustomerMutationPayload>(
    new CustomerMutationPayload()
  );
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>(false);
  const [shouldShowUnchanged, setShouldShowUnchanged] =
    useState<boolean>(false);
  const [shouldShowUnsaved, setShouldShowUnsaved] = useState<boolean>(false);
  const notify = useNotify();
  const redirect = useRedirect();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const editContext = useEditContext();

  const [update, updateState] = useUpdate();

  const navigateToList = () => {
    redirect(`/${ResourceName.OFFLINE_CUSTOMERS}`);
  };
  const navigateToShow = useCallback(
    () => redirect(`/${ResourceName.OFFLINE_CUSTOMERS}/${params.id}/show`),
    [params.id, redirect]
  );
  const goBack = () => {
    history.goBack();
  };
  const handleSave = (values: Partial<CustomerCreatedPayload>) => {
    const payload = new CustomerMutationPayload(
      values.email,
      values.first_name,
      values.last_name,
      values.alias
    );
    const changed = validateChanged(payload, editContext.record || {});

    if (changed) {
      setFormValues(payload);
      setShouldShowConfirm(true);
      return;
    }
    setShouldShowUnchanged(true);
  };

  const handleUnchangedContinue = () => {
    setShouldShowUnchanged(false);
  };

  const handleCancelEdit = () => {
    setShouldShowUnsaved(true);
  };
  const handleCancelUnsaved = () => {
    setShouldShowUnsaved(false);
  };
  const handleConfirmUnsaved = () => {
    navigateToList();
    setShouldShowUnsaved(false);
  };

  const handleUpdateSuccess = useCallback(() => {
    notify('Customer data updated successfully.', { type: 'success' });
    navigateToShow();
  }, [navigateToShow, notify]);
  const handleUpdateFailure = useCallback(
    (e: Error) => {
      notify(
        e.message || 'Something went wrong. Please try again in a few seconds.',
        { type: 'error' }
      );
    },
    [notify]
  );

  const handleConfirmUpdate = useCallback(
    (formData: CustomerMutationPayload) => {
      update(
        `${ResourceName.CUSTOMERS}`,
        params.id,
        formData,
        {},
        {
          onSuccess() {
            handleUpdateSuccess();
          },
          onFailure(e) {
            handleUpdateFailure(e);
          }
        }
      );
    },
    [handleUpdateFailure, handleUpdateSuccess, params.id, update]
  );

  const handleCancelUpdate = () => {
    setShouldShowConfirm(false);
  };

  return (
    <>
      <CustomerForm
        onSave={handleSave}
        onCancel={handleCancelEdit}
        loading={updateState.loading || editContext.loading}
        fieldsToHide={['type']}
      />
      <ConfirmUpdate
        recordName='Offline Customer'
        loading={updateState.loading}
        isOpen={shouldShowConfirm}
        onClose={handleCancelUpdate}
        onConfirm={() => handleConfirmUpdate(formValues)}
      />
      <ConfirmUnsaved
        isOpen={shouldShowUnsaved}
        onClose={handleCancelUnsaved}
        onConfirm={handleConfirmUnsaved}
      />
      <UnchangedDialog
        name='Offline Customer'
        isOpen={shouldShowUnchanged}
        onContinue={handleUnchangedContinue}
        onQuit={goBack}
      />
    </>
  );
}
