import { usePermissions } from 'react-admin';

import { BackOfficePrivileges } from 'shared/services/firebase-auth-provider/BackOfficePrivileges.model';

/**
 * @returns a <BackOfficePrivileges> typed usePermissions call.
 *
 * @description consider using this type safe version of usePermissions instead of typing it every time you use it.
 */
export const useAppPermissions = () => usePermissions<BackOfficePrivileges>();
