import { SectionName } from 'shared/enums/section-name.enum';
import { Privilege } from 'shared/utils/constants/blazepod';
import { DATE } from 'shared/utils/dates';

export class BackOfficePrivileges implements Record<SectionName, Privilege> {
  readonly entities: Privilege;
  readonly content: Privilege;
  readonly communication: Privilege;
  readonly monetization: Privilege;
  readonly config: Privilege;
  readonly isAdmin: boolean;
  readonly validUntil: number;
  constructor({
    entities = Privilege.NONE,
    content = Privilege.NONE,
    communication = Privilege.NONE,
    monetization = Privilege.NONE,
    config = Privilege.NONE,
    isAdmin = false,
    validUntil = Date.now() + DATE.IN_MILLISECONDS.HOUR / 2
  }: Partial<BackOfficePrivileges> = {}) {
    this.entities = entities;
    this.content = content;
    this.communication = communication;
    this.monetization = monetization;
    this.config = config;
    this.isAdmin = isAdmin;
    this.validUntil = validUntil;
  }
}
