import { useMemo } from 'react';

import { Datagrid, EditButton } from 'react-admin';

import { Theme, useMediaQuery } from '@mui/material';

import { Customer } from 'resources/customers/models/Customer.model';
import { UserFunctionField } from 'resources/users/components/user-fields';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';
import { shortenString } from 'shared/utils/helperFunctions/shortenString';

import {
  CustomerDateField,
  CustomerFunctionField,
  CustomerNumberField,
  CustomerTextField
} from './fields/customer-fields';
import { CustomerReferenceField } from './fields/CustomerReferenceField.component';

interface CustomersTableProps {
  omitFields?: OmittableFields[];
  editable?: boolean;
}
export function CustomersTable(props: CustomersTableProps) {
  const { permissions } = useAppPermissions();

  const lg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));

  const fieldsToOmit = useMemo(
    () =>
      props.omitFields
        ? Object.fromEntries(props.omitFields.map((field) => [field, field]))
        : {},
    [props.omitFields]
  ) as Record<OmittableFields, OmittableFields>;

  const shouldRenderCodesSummary = useMemo(() => {
    return lg && !fieldsToOmit.codes_summary;
  }, [lg, fieldsToOmit]);
  return (
    <Datagrid>
      {!fieldsToOmit.alias && (
        <LinkableField
          source='alias'
          label='Customer Name (alias)'
          emptyText='-'
          render={(record) => shortenString(record?.alias ?? '')}
        />
      )}
      {!fieldsToOmit.full_name &&
        (fieldsToOmit.alias ? (
          <LinkableField
            emptyText='-'
            source='full_name'
            label='Full Name'
            render={renderFullName}
          />
        ) : (
          <CustomerFunctionField
            emptyText='-'
            source='full_name'
            label='Full Name'
            render={renderFullName}
          />
        ))}

      {!fieldsToOmit.email && (
        <CustomerTextField emptyText='-' source='email' />
      )}
      {!fieldsToOmit.origin && (
        <CustomerTextField emptyText='-' source='origin' />
      )}
      {!fieldsToOmit.type && <CustomerTextField emptyText='-' source='type' />}
      {!fieldsToOmit.creator && (
        <CustomerReferenceField
          source='creator_id'
          reference={ResourceName.USERS}
          emptyText='-'
          link='show'
        >
          <UserFunctionField render={renderFullName} emptyText='-' />
        </CustomerReferenceField>
      )}
      {!fieldsToOmit.creation_date && (
        <CustomerDateField
          locales='en-GB'
          emptyText='-'
          source={'creation_date'}
        />
      )}
      {!fieldsToOmit.codes_summary && (
        <CustomerNumberField
          emptyText='0'
          source={`codes_summary.total`}
          label='# Total Codes'
        />
      )}
      {shouldRenderCodesSummary && (
        <CustomerNumberField
          emptyText='0'
          source={`codes_summary.activated`}
          label='# Activated Codes'
        />
      )}
      {shouldRenderCodesSummary && (
        <CustomerNumberField
          emptyText='0'
          source={`codes_summary.pending`}
          label='# Pending Codes'
        />
      )}
      {shouldRenderCodesSummary && (
        <CustomerNumberField
          emptyText='0'
          source={`codes_summary.canceled`}
          label='# Canceled Codes'
        />
      )}
      {shouldRenderCodesSummary && (
        <CustomerNumberField
          emptyText='0'
          source={`codes_summary.expired`}
          label='# Expired Codes'
        />
      )}
      {props.editable && isPublisher(permissions?.entities) && (
        <EditButton label='' />
      )}
    </Datagrid>
  );
}
export type OmittableFields =
  | Exclude<keyof Customer, 'first_name' | 'last_name'>
  | 'full_name';
