import { ValueOfObject } from 'shared/utils/types';

export const Privilege = Object.freeze({
  VIEWER: 'viewer',
  EDITOR: 'editor',
  PUBLISHER: 'publisher',
  NONE: 'none'
} as const);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Privilege = ValueOfObject<typeof Privilege>;
