import {
  List,
  ListProps,
  Loading,
  SearchInput,
  useListContext
} from 'react-admin';

import { Divider } from '@mui/material';
import {
  CustomersSortableFields,
  CustomersSortableFieldsEnum
} from 'resources/customers/customers-sortable-fields.enum';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { Pagination } from 'shared/components/pagination/Pagination.component';
import { RaListOrder } from 'shared/services/backoffice-data-provider/enums/ra-list-order.enum';
import { CustomerType } from 'shared/utils/constants/blazepod';
import { createSearchParams } from 'shared/utils/navigation';

import { Summary } from './CustomersListSummary.component';
import { CustomersTable, OmittableFields } from './CustomersTable.component';
import { Customer } from '../models/Customer.model';

interface CustomerListProps extends ListProps {
  omitFields?: OmittableFields[];
  typesToSum: CustomerType[];
  editable?: boolean;
  sortBy?: CustomersSortableFields;
  extendedfilter?: JSX.Element[];
}

export function CustomersList(props: CustomerListProps) {
  const listContext = useListContext<Customer>();
  const { typesToSum: sumFilter, omitFields, ...restProps } = props;

  const orderFilters = [
    <SearchInput source='search' alwaysOn />,
    ...(props.extendedfilter ?? [])
  ];
  return (
    <List
      {...restProps}
      filterDefaultValues={{ customer_state: 'All' }}
      sort={{
        field: props.sortBy ?? CustomersSortableFieldsEnum.full_name,
        order: RaListOrder.ASC
      }}
      filters={orderFilters}
      perPage={100}
      pagination={<Pagination />}
      exporter={false}
    >
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <TabbedDatagrid
          omitFields={omitFields}
          sumFilter={sumFilter}
          editable={props.editable}
        />
      )}
    </List>
  );
}

function TabbedDatagrid(props: {
  omitFields: CustomerListProps['omitFields'];
  sumFilter: CustomerListProps['typesToSum'];
  editable: CustomerListProps['editable'];
}) {
  const listContext = useListContext();

  return (
    <>
      <Summary
        summaryUrl={`summary?${createSearchParams({
          customer_type: props.sumFilter
        })}`}
      />
      <Divider />

      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <CustomersTable
          omitFields={props.omitFields}
          editable={props.editable}
        />
      )}
    </>
  );
}
