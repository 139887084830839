import { List, ListProps, SearchInput } from 'react-admin';

import { AppResponsive } from 'shared/components/common/AppResponsive.component';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { SectionName } from 'shared/enums/section-name.enum';
import { RaListOrder } from 'shared/services/backoffice-data-provider';

import { LeaderboardTable } from '../components/LeaderboardTable.component';

export function LeaderboardsList(props: ListProps) {
  return (
    <List
      {...props}
      perPage={25}
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'edit', 'list']}
          sectionName={SectionName.Content}
        />
      }
      sort={{ field: 'label', order: RaListOrder.ASC }}
      filters={[<SearchInput source='search' alwaysOn />]}
      exporter={false}
    >
      <AppResponsive
        xs={
          <LeaderboardTable
            omitItems={['start_date', 'end_date', 'subtitle']}
          />
        }
        sm={
          <LeaderboardTable
            omitItems={['start_date', 'end_date', 'subtitle']}
          />
        }
        md={
          <LeaderboardTable
            omitItems={['start_date', 'end_date', 'subtitle']}
          />
        }
        lg={
          <LeaderboardTable
            omitItems={['start_date', 'end_date', 'subtitle']}
          />
        }
        xl={
          <LeaderboardTable
            omitItems={['start_date', 'end_date', 'subtitle']}
          />
        }
      />
    </List>
  );
}
