import { useMemo } from 'react';

import {
  DateInput,
  NumberInput,
  regex,
  required,
  SimpleForm,
  TextInput
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { uniqueId } from 'lodash';

import { HasCustomerId } from 'resources/customers/interfaces';
import { FieldObject } from 'resources/types';
import { LabelWrapper } from 'shared/components/fields/LabelWrapper.component';
import { UserIdInputFromEmail } from 'shared/components/inputs/UserIdInputFromEmail.component';

import { SaveCancelFormToolbar } from 'shared/components/toolbars/SaveCancelFormToolbar.component';

import { getTitleCase } from 'shared/utils/helperFunctions/strings';
import { rem, vh } from 'shared/utils/styles/css-unit';

import { LeaderboardMutationPayload } from '../models/leaderboard-mutation-payload.model';
import { LeaderboardTitle } from '../pages/LeaderboardShow.component';

export enum LeaderboardFormSource {
  id = 'id',
  title = 'title',
  subtitle = 'subtitle',
  user_id = 'user_id',
  activity_id = 'activity_id',
  size = 'number_of_items',
  start_date = 'start_date',
  end_date = 'end_date',
  right_logo = 'right_logo'
}

export interface LeaderboardFormProps extends Partial<HasCustomerId> {
  onSave(values: Partial<LeaderboardMutationPayload>): void;
  onCancel(): void;
  fieldsToOmit?: LeaderboardFormSource[];
}

export function LeaderboardForm(props: LeaderboardFormProps) {
  const { onCancel, onSave, fieldsToOmit } = props;

  const shouldShowField = useMemo(
    () => (field: LeaderboardFormSource) => {
      return fieldsToOmit ? !fieldsToOmit.includes(field) : true;
    },
    [fieldsToOmit]
  );

  const handleSave = useMemo(
    () => (values: Partial<LeaderboardMutationPayload>) => {
      onSave(values);
    },
    [onSave]
  );
  const leaderboardCreationFields = useMemo(() => {
    const fields: FieldObject<LeaderboardFormSource>[] = [
      {
        source: LeaderboardFormSource.id,
        element: (
          <TextInput
            label={'Label'}
            key={uniqueId()}
            source={'label'}
            validate={[
              required(),
              regex(
                /^[a-zA-Z0-9,_]*$/,
                'Empty or special characters are not allowed'
              )
            ]}
          />
        )
      }
    ];
    const filteredFields = fields.filter((field) =>
      shouldShowField(field.source)
    );
    if (filteredFields.length === 0) {
      return [];
    }
    return filteredFields.map((field) => field.element);
  }, [shouldShowField]);

  return (
    <>
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <LeaderboardTitle />
        </Typography>
        <SimpleForm
          submitOnEnter={false}
          save={handleSave}
          toolbar={<SaveCancelFormToolbar onCancel={onCancel} />}
        >
          <LabelWrapper component='h4'>
            {leaderboardCreationFields.length > 0
              ? 'Add Leaderboard'
              : 'Edit Leaderboard'}{' '}
          </LabelWrapper>
          {leaderboardCreationFields}
          <UserIdInputFromEmail></UserIdInputFromEmail>
          <TextInput
            label={getTitleCase(LeaderboardFormSource.title)}
            key={uniqueId()}
            validate={[required()]}
            source={LeaderboardFormSource.title}
          />
          <TextInput
            label={getTitleCase(LeaderboardFormSource.subtitle)}
            key={uniqueId()}
            source={LeaderboardFormSource.subtitle}
          />
          <NumberInput
            label={'Number of items'}
            key={uniqueId()}
            validate={[required()]}
            source={LeaderboardFormSource.size}
          ></NumberInput>
          <TextInput
            label={'Activity id'}
            key={uniqueId()}
            validate={[required()]}
            source={LeaderboardFormSource.activity_id}
          />
          <TextInput
            label={'Right logo url'}
            key={uniqueId()}
            source={LeaderboardFormSource.right_logo}
          />
          <DateInput
            label={'Start date'}
            validate={[required()]}
            source={LeaderboardFormSource.start_date}
            isRequired={false}
          />
          <DateInput
            label={'End date'}
            source={LeaderboardFormSource.end_date}
            isRequired={false}
          />
        </SimpleForm>
      </Box>
    </>
  );
}
