import {
  BooleanField,
  BooleanFieldProps,
  DateField,
  DateFieldProps,
  FieldProps,
  FunctionField,
  FunctionFieldProps,
  ReferenceField,
  ReferenceFieldProps,
  TextField,
  TextFieldProps
} from 'react-admin';

import { TypedField } from 'shared/interfaces/react-admin/typed-field';

import { MembershipCode } from '../models/MembershipCode.model';

type CodesFieldProps<P extends FieldProps> = P & {
  source: keyof MembershipCode;
};
type CodesField<P extends FieldProps> = TypedField<CodesFieldProps<P>>;
export const CodesTextField = TextField as CodesField<TextFieldProps>;

export const CodesDateField = DateField as CodesField<DateFieldProps>;

export const CodesBooleanField = BooleanField as CodesField<BooleanFieldProps>;

export interface CodesFunctionFieldProps
  extends Omit<CodesFieldProps<FunctionFieldProps>, 'render'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: (record?: MembershipCode) => any;
}

export const CodesFunctionField =
  FunctionField as CodesField<CodesFunctionFieldProps>;

export const CodesReferenceField =
  ReferenceField as CodesField<ReferenceFieldProps>;
