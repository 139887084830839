import { List, SearchInput, TextInput } from 'react-admin';

import { CodesTable } from 'resources/codes/components/CodesTable.component';
import {
  CodesFilterableField,
  CodesFilterableFieldsEnum
} from 'resources/codes/enums/codes-filterable-fields.enum';
import { CodesSortableFieldsEnum } from 'resources/codes/enums/codes-sortable-fields.enum';
import { Pagination } from 'shared/components/pagination/Pagination.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { RaListOrder } from 'shared/services/backoffice-data-provider/enums/ra-list-order.enum';

import { HasCustomerId } from '../interfaces';

interface CustomerCodesProps extends HasCustomerId {
  filters?: CodesFilterableField[];
}
export function CustomerCodes(props: CustomerCodesProps) {
  const { customer_id, filters } = props;
  function getFilters() {
    const shouldRenderFilter = (filter: CodesFilterableField) =>
      filters && filters.includes(filter);
    const filtersToRender = [];

    if (shouldRenderFilter(CodesFilterableFieldsEnum.search)) {
      filtersToRender.push(
        <SearchInput source={CodesFilterableFieldsEnum.search} alwaysOn />
      );
    }

    if (shouldRenderFilter(CodesFilterableFieldsEnum.creator_id)) {
      filtersToRender.push(
        <TextInput
          label='Creator ID'
          source={CodesFilterableFieldsEnum.creator_id}
          alwaysOn
        />
      );
    }
    return filtersToRender;
  }

  return (
    <List
      resource={ResourceName.CODES}
      basePath={'/' + ResourceName.CODES}
      filters={getFilters()}
      filterDefaultValues={{ customer_id }}
      title=' '
      sort={{
        field: CodesSortableFieldsEnum.code,
        order: RaListOrder.ASC
      }}
      actions={<></>}
      exporter={false}
      pagination={<Pagination />}
    >
      <CodesTable omitItems={['customer_id']} />
    </List>
  );
}
