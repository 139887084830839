import {
  BackOfficeDataProvider,
  BackOfficeDtoConverter,
  DataProviderMethod,
  defaultUrlRenderer,
  DtoConverter,
  UrlArray
} from 'shared/services/backoffice-data-provider';

export class BatchesDataProvider extends BackOfficeDataProvider {
  protected dtoConverter: DtoConverter = new BackOfficeDtoConverter();

  protected updateMethod: 'put' | 'patch' = 'patch';
  protected urlRenderer(
    url: string | UrlArray,
    method?: DataProviderMethod | undefined
  ): string {
    return defaultUrlRenderer(url, method);
  }
}
