import {
  Error,
  Loading,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  useShowContext
} from 'react-admin';

import { Box, Grid, Typography } from '@mui/material';
import { Customer } from 'resources/customers/models/Customer.model';
import { LabelWrapper } from 'shared/components/fields/LabelWrapper.component';
import { LargeTextWrapper } from 'shared/components/fields/LargeTextWrapper.component';
import { SmallTextWrapper } from 'shared/components/fields/SmallTextWrapper.component';
import { ResourceName } from 'shared/enums';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';
import { getTitleCase } from 'shared/utils/helperFunctions/strings';
import { rem, vh } from 'shared/utils/styles/css-unit';
import { v4 } from 'uuid';

import { CustomerTitle } from './CustomerTitle.component';
import {
  CustomerEmailField,
  CustomerFunctionField,
  CustomerTextField
} from './fields/customer-fields';
import { HasCodesComponent, HasCodesFilters } from '../interfaces';

export type CustomersShowProps = ShowProps &
  HasCodesComponent &
  HasCodesFilters;

export function CustomersShow(props: CustomersShowProps) {
  const { codesFilters, CodesComponent, ...rest } = props;
  return (
    <>
      <Show {...rest} resource={ResourceName.CUSTOMERS}>
        <CustomerCard
          codesFilters={codesFilters}
          CodesComponent={CodesComponent}
        />
      </Show>
    </>
  );
}

function CustomerCard({ CodesComponent }: HasCodesFilters & HasCodesComponent) {
  const showContext = useShowContext<Customer>();
  const { record: customer, loading, error } = showContext;

  if (loading || !customer) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <Box sx={{ minHeight: vh(30) }}>
      <Typography
        sx={{ p: rem(2), fontSize: rem(2) }}
        variant='h2'
        component='h2'
      >
        <CustomerTitle />
      </Typography>
      <TabbedShowLayout>
        <Tab label='details'>
          {customer.alias ? (
            <CustomerTextField label='Customer Name (alias)' source='alias' />
          ) : (
            <CustomerFunctionField render={renderFullName} source='full_name' />
          )}
          <CustomerTextField source='id' />
          <CustomerTextField source='type' />
          <CustomerTextField source='origin' />
          <CustomerTextField source='creation_date' />
          <CustomerFunctionField render={renderFullName} source='full_name' />
          {customer.email && <CustomerEmailField source='email' />}
        </Tab>
        <Tab label='codes'>
          {!customer.codes_summary.total ? (
            <Typography>No Codes</Typography>
          ) : (
            <>
              <LargeTextWrapper
                align='center'
                component='h3'
                marginBottom={rem(2)}
              >
                Codes Summary
              </LargeTextWrapper>
              <Grid container justifyContent='space-between'>
                {Object.entries(customer.codes_summary).map(([key, sum]) => {
                  return (
                    <Grid
                      key={`${sum}-${v4()}`}
                      item
                      sm={6}
                      md={4}
                      lg={2}
                      sx={{
                        pt: 0,
                        pl: 0,
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <LabelWrapper
                        component='div'
                        variant='caption'
                        align='center'
                      >
                        {getTitleCase(key)}
                      </LabelWrapper>
                      <SmallTextWrapper align='center' component='p'>
                        {Number.parseInt(sum).toLocaleString()}
                      </SmallTextWrapper>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
          {CodesComponent && <CodesComponent customer_id={customer.id} />}
        </Tab>
      </TabbedShowLayout>
    </Box>
  );
}
