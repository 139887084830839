import { List, ListProps, SearchInput } from 'react-admin';

import { AppResponsive } from 'shared/components/common/AppResponsive.component';
import { Pagination } from 'shared/components/pagination/Pagination.component';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { SectionName } from 'shared/enums/section-name.enum';
import { RaListOrder } from 'shared/services/backoffice-data-provider';

import { CollectionsTabbedDatagrid } from '../components/CollectionsTabbedDatagrid.component';

import { CollectionTable } from '../components/CollectionTable.component';

export function CollectionsList(props: ListProps) {
  return (
    <List
      {...props}
      perPage={100}
      sort={{ field: 'display_name', order: RaListOrder.ASC }}
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'edit', 'list']}
          sectionName={SectionName.Content}
        />
      }
      pagination={<Pagination />}
      filters={[<SearchInput source='search' alwaysOn />]}
      exporter={false}
    >
      <AppResponsive
        xs={<CollectionTable omitItems={[]} />}
        sm={<CollectionTable omitItems={[]} />}
        md={<CollectionTable omitItems={[]} />}
        lg={<CollectionsTabbedDatagrid />}
        xl={<CollectionsTabbedDatagrid />}
      />
    </List>
  );
}
