import CampaignIcon from '@mui/icons-material/Campaign';

import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { CampaignCreate } from './pages/CampaignCreate.component';
import { CampaignEdit } from './pages/CampaignEdit.component';
import { CampaignList } from './pages/CampaignList.component';
import { CampaignShow } from './pages/CampaignShow.component';

export const CampaignsModule = RaModule.fromResourceProps({
  name: ResourceName.CAMPAIGNS,
  intent: ResourceIntent.ROUTE,
  options: { label: 'Campaigns' },
  create: CampaignCreate,
  list: CampaignList,
  show: CampaignShow,
  edit: CampaignEdit,
  icon: CampaignIcon
});
