import {
  BackOfficeDataProvider,
  defaultUrlRenderer
} from 'shared/services/backoffice-data-provider';

import { ReferralDtoConverter } from './ReferralDtoConverter';

export class ReferralDataProvider extends BackOfficeDataProvider {
  protected dtoConverter = new ReferralDtoConverter();
  protected urlRenderer = defaultUrlRenderer;
}
