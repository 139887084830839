import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { AdminsCreate } from './pages/AdminsCreate.component';

import { AdminsEdit } from './pages/AdminsEdit.component';

import { AdminsList } from './pages/AdminsList.component';
import { AdminsShow } from './pages/AdminsShow.component';

export const AdminsModule = RaModule.fromResourceProps({
  name: ResourceName.ADMINS,
  options: { label: 'Admins' },
  list: AdminsList,
  show: AdminsShow,
  edit: AdminsEdit,
  create: AdminsCreate,
  intent: ResourceIntent.ROUTE,
  icon: AdminPanelSettingsIcon
});
