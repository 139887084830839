import { Show, ShowProps, useRecordContext } from 'react-admin';

import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';

import { SectionName } from 'shared/enums/section-name.enum';

import { CodeCancelButton } from '../components/CodeCancelButton.component';
import { CodeEditButton } from '../components/CodeEditButton.component';

import { CodesShowLayout } from '../components/CodesShowLayout.component';
import { MembershipCode } from '../models/MembershipCode.model';

export type CodesShowProps = ShowProps;

export function CodesShow(props: CodesShowProps) {
  return (
    <Show
      {...props}
      title='Codes'
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'create', 'edit']}
          extraActions={[
            { type: 'edit', element: <CodeEditButton label='Edit' /> },
            {
              type: 'cancel',
              element: <CodeCancelButton label='Cancel Code' />
            }
          ]}
          sectionName={SectionName.Entities}
        />
      }
    >
      <CodesShowLayout />
    </Show>
  );
}

export function CodesTitle() {
  const code = useRecordContext<MembershipCode>();
  const label =
    Object.keys(code).length === 0 ? 'Create Codes' : `Code - ${code?.code}`;
  return <>{label}</>;
}
