import {
  BackOfficeDataProvider,
  defaultUrlRenderer
} from 'shared/services/backoffice-data-provider';

import { MembershipDisplayDtoConverter } from './MembershipDisplayDtoConverter';

export class MembershipDisplayDataProvider extends BackOfficeDataProvider {
  protected dtoConverter = new MembershipDisplayDtoConverter();
  protected urlRenderer = defaultUrlRenderer;
}
