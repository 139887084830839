import { Admin, InitialState, Resource } from 'react-admin';
import { QueryClient, QueryClientProvider } from 'react-query';

import { createTheme, ThemeProvider } from '@mui/material';
import { modules } from 'resources';
import { Dashboard } from 'resources/Dashboard.component';
import LogInPage from 'resources/log-in/LogIn.component';
import { Layout } from 'shared/components/layout/Layout.component';
import { DataProviderProxy } from 'shared/services/DataProviderProxy.service';
import { FirebaseAuthProvider } from 'shared/services/firebase-auth-provider/FirebaseAuthProvider.service';
import { FirebaseWrapper } from 'shared/services/firebase-wrapper/FirebaseWrapper';
import { getCurrentFireConfig } from 'shared/services/firebase-wrapper/utils';

export function Root() {
  const firebaseWrapper = new FirebaseWrapper(getCurrentFireConfig());
  const queryClient = new QueryClient();
  const theme = createTheme();
  const authProvider = new FirebaseAuthProvider(firebaseWrapper);
  const dataProviderProxy = new DataProviderProxy(authProvider);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Admin
          dataProvider={dataProviderProxy.get()}
          authProvider={authProvider}
          loginPage={LogInPage}
          layout={Layout}
          dashboard={Dashboard}
          initialState={(state: InitialState) => ({
            ...state,
            admin: { ui: { sidebarOpen: true } }
          })}
        >
          {modules.map((module) => (
            <Resource {...module} key={module.name} />
          ))}
        </Admin>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
