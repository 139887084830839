import { useMemo } from 'react';
import { useFormState } from 'react-final-form';

import { PlanDurationFormGroup } from 'resources/codes/components/codes-form/PlanDurationFormGroup.component';
import { MembershipPlansEnum } from 'shared/utils/constants/blazepod';

export function PlanDurationInputGroup() {
  const { values } = useFormState();

  const shouldRenderGroup: boolean = useMemo(
    () =>
      !!values?.membership_plan &&
      values.membership_plan === MembershipPlansEnum.PRO,
    [values.membership_plan]
  );

  return shouldRenderGroup ? <PlanDurationFormGroup /> : <></>;
}
