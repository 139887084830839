import {
  ArrayField,
  Datagrid,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { SectionName } from 'shared/enums/section-name.enum';
import { vh, rem } from 'shared/utils/styles/css-unit';

import { MembershipDisplayModel } from '../models/MembershipDisplay.model';

export function MembershipDisplayShow(props: ShowProps) {
  return (
    <Show
      {...props}
      title='Offers'
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'create']}
          sectionName={SectionName.Monetization}
        />
      }
    >
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <MembershipDisplayTitle />
        </Typography>
        <TabbedShowLayout>
          <Tab label='Details'>
            <TextField source='name' label='Name' emptyText='-' />
            <TextField source='id' label='Id' emptyText='-' />
            <TextField source='source_plan' label='From Plan' emptyText='New' />
            <TextField source='target_plan' label='To Plan' emptyText='-' />
            <TextField
              source='payload.headline'
              label='Headline'
              emptyText='-'
            />
            <TextField
              source='payload.plans_title'
              label='Plans Title'
              emptyText='-'
            />
            <ArrayField
              source='payload.benefits_text'
              label='Benefits'
              emptyText='-'
            >
              <Datagrid>
                <TextField source='benefit_text' label={''} />
              </Datagrid>
            </ArrayField>
            <TextField source='payload.button' label='CTA' emptyText='-' />
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
}

export function MembershipDisplayTitle() {
  const display = useRecordContext<MembershipDisplayModel>();
  return (
    <>
      {/* {`Switch plan from ${getTitleCase(display.source_plan ?? 'new')} to ${getTitleCase(display.target_plan)}`} */}
      {display?.name}
    </>
  );
}
