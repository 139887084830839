import { useMemo } from 'react';
import { Datagrid, Loading, TextField, useListContext } from 'react-admin';

import { FieldObject } from 'resources/types';
import { BaseEditButton } from 'shared/components/buttons/BaseEditButton.component';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { HasOmitItems } from 'shared/interfaces/react-admin';

import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';

import { MembershipDisplayModel } from '../models/MembershipDisplay.model';

export const MembershipDisplayTable = (
  props: Partial<HasOmitItems<keyof MembershipDisplayModel>>
) => {
  const listContext = useListContext<MembershipDisplayModel>();
  const { permissions } = useAppPermissions();
  const fields = useMemo(() => {
    const allFields: Array<FieldObject<keyof MembershipDisplayModel>> = [
      {
        source: 'id',
        element: (
          <LinkableField
            source='id'
            label='Id'
            emptyText='-'
            sortable={false}
          />
        )
      },
      {
        source: 'name',
        element: (
          <TextField
            source='name'
            label='Name'
            emptyText='-'
            sortable={false}
          />
        )
      },
      {
        source: 'source_plan',
        element: (
          <TextField
            source='source_plan'
            label='From Plan'
            emptyText='New'
            sortable={false}
          />
        )
      },
      {
        source: 'target_plan',
        element: (
          <TextField
            source='target_plan'
            label='To Plan'
            emptyText='-'
            sortable={false}
          />
        )
      },
      {
        source: 'payload',
        element: (
          <TextField
            source='payload.headline'
            label='Headline'
            emptyText='-'
            sortable={false}
          />
        )
      },
      {
        source: 'payload',
        element: (
          <TextField
            source='payload.plans_title'
            label='Plans Title'
            emptyText='-'
            sortable={false}
          />
        )
      },
      {
        source: 'payload',
        element: (
          <TextField
            source='payload.button'
            label='CTA'
            emptyText='-'
            sortable={false}
          />
        )
      }
    ];

    const output: JSX.Element[] = [];

    for (const field of allFields) {
      if (props.omitItems ? !props.omitItems.includes(field.source) : true) {
        output.push(field.element);
      }
    }
    return output;
  }, [props.omitItems]);

  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <Datagrid>
          {fields}
          {isPublisher(permissions?.monetization) && (
            <BaseEditButton label={false} tooltip='Edit offer' />
          )}
        </Datagrid>
      )}
    </>
  );
};
