import { ValueOfObject } from 'shared/utils/types';

export const CodesSortableFieldsEnum = Object.freeze({
  customer: 'customer.full_name',
  code: 'code',
  expired_date: 'expired_date',
  activation_date: 'activation_date',
  code_status: 'code_status',
  app_user: 'app_user.email'
} as const);

export type CodesSortableField = ValueOfObject<typeof CodesSortableFieldsEnum>;
