import LockIcon from '@mui/icons-material/Lock';
import { RaModule } from 'resources/RaModule';
import { ResourceName } from 'shared/enums/resource-name.enum';

import { CodesCreate } from './pages/CodesCreate.component';
import { CodesEdit } from './pages/CodesEdit.component';
import { CodesList } from './pages/CodesList.component';
import { CodesShow } from './pages/CodesShow.component';

export const CodesModule = RaModule.fromResourceProps({
  name: ResourceName.CODES,
  options: { label: 'Codes' },
  icon: LockIcon,
  list: CodesList,
  create: CodesCreate,
  edit: CodesEdit,
  show: CodesShow
});
