import {
  BooleanInput,
  maxValue,
  NumberInput,
  required,
  useRecordContext
} from 'react-admin';
import { useFormState } from 'react-final-form';

import { PlanDuration } from 'resources/plans/plan-duration.model';
import { LabelWrapper } from 'shared/components/fields/LabelWrapper.component';

import { CodesFormSource } from './CodesFormSource.enum';

export function PlanDurationFormGroup() {
  const record = useRecordContext();
  const formState = useFormState<{ plan_duration?: PlanDuration }>();
  const { values } = formState;
  const isForeverTrue = () => values.plan_duration?.is_forever || false;
  const numberInputProps = () => ({
    min: 0,
    defaultValue: 0,
    disabled: isForeverTrue()
  });
  const getIsForeverValidators = () => {
    const validate = [];
    const { plan_duration } = values;
    if (!plan_duration?.days && !plan_duration?.months) {
      validate.push(required(), validateIsForever);
    }
    return { validate };
  };

  return (
    <>
      <LabelWrapper component='label'>Plan Duration</LabelWrapper>
      <br />
      <NumberInput
        initialValue={record?.init_data?.plan_duration?.days}
        source={`${CodesFormSource.plan_duration}.days`}
        label='Days'
        {...numberInputProps()}
        validate={[maxValue(364), required(), validateDays]}
      />
      <br />
      <NumberInput
        initialValue={record?.init_data?.plan_duration?.months}
        source={`${CodesFormSource.plan_duration}.months`}
        label='Months'
        {...numberInputProps()}
        validate={[required(), validateMonths]}
      />
      <br />
      <BooleanInput
        initialValue={record?.init_data?.plan_duration?.is_forever}
        source={`${CodesFormSource.plan_duration}.is_forever`}
        defaultValue={false}
        label='Is Forever'
        {...getIsForeverValidators()}
      />
    </>
  );
}

function validateIsForever(
  value: boolean,
  values: Record<'plan_duration', PlanDuration>
) {
  if (!value) {
    if (!values.plan_duration.days && !values.plan_duration.months) {
      return 'Must be forever if days and months are 0.';
    }
  }
  return undefined;
}
function validateDays(
  value: number,
  values: Record<'plan_duration', PlanDuration>
) {
  if (!values) return 'Required';
  if (!values.plan_duration?.is_forever) {
    if (value === undefined) return 'Required';
    if (value <= 0 && values.plan_duration.months <= 0) {
      return "Must be larger than 0 when months' value is 0.";
    }
  }
  return undefined;
}
function validateMonths(
  value: number,
  values: Record<'plan_duration', PlanDuration>
) {
  if (!values) return 'Required';
  if (!values.plan_duration?.is_forever) {
    if (value === undefined) return 'Required';
    if (value <= 0 && values.plan_duration.days <= 0) {
      return "Must be larger than 0 when months' value is 0.";
    }
  }
  return undefined;
}
