import { useMemo } from 'react';
import {
  Datagrid,
  FunctionField,
  Loading,
  ReferenceField,
  TextField,
  useListContext
} from 'react-admin';

import { uniqueId } from 'lodash';
import { FieldObject } from 'resources/types';
import { BaseDeleteButton } from 'shared/components/buttons/BaseDeleteButton.component';
import { BaseEditButton } from 'shared/components/buttons/BaseEditButton.component';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { ResourceName } from 'shared/enums';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { HasOmitItems } from 'shared/interfaces/react-admin';

import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';

import { AppAdmin } from '../models/Admin.model';

export interface AdminTableProps extends Partial<HasOmitItems<keyof AppAdmin>> {
  editable?: boolean;
}

export const AdminTable = (props: AdminTableProps) => {
  const listContext = useListContext<AppAdmin>();
  const { permissions } = useAppPermissions();
  const fields = useMemo(() => {
    const allFields: Array<FieldObject<keyof AppAdmin>> = [
      {
        source: 'user_id',
        element: (
          <LinkableField
            source='user_id'
            label='App user id'
            emptyText='-'
            key={uniqueId()}
          />
        )
      },
      {
        source: 'user_id',
        element: (
          <ReferenceField
            reference={ResourceName.USERS}
            label='User email'
            source='user_id'
            link={'show'}
            key={uniqueId()}
            sortBy='email'
          >
            <TextField source='email' />
          </ReferenceField>
        )
      },
      {
        source: 'user_id',
        element: (
          <ReferenceField
            reference={ResourceName.USERS}
            label='Full Name'
            source='user_id'
            link={false}
            key={uniqueId()}
            sortBy='full_name'
          >
            <FunctionField render={renderFullName} />
          </ReferenceField>
        )
      },
      {
        source: 'content',
        element: (
          <TextField
            source='content'
            label='Content Permission'
            emptyText='-'
            key={uniqueId()}
            sortable={false}
          />
        )
      },
      {
        source: 'entities',
        element: (
          <TextField
            source='entities'
            label='Entities Permission'
            emptyText='-'
            key={uniqueId()}
            sortable={false}
          />
        )
      },
      {
        source: 'communication',
        element: (
          <TextField
            source='communication'
            label='Communication Permission'
            emptyText='-'
            key={uniqueId()}
            sortable={false}
          />
        )
      },
      {
        source: 'monetization',
        element: (
          <TextField
            source='monetization'
            label='Monetization Permission'
            emptyText='-'
            key={uniqueId()}
            sortable={false}
          />
        )
      },
      {
        source: 'config',
        element: (
          <TextField
            source='config'
            label='Config Permission'
            emptyText='-'
            key={uniqueId()}
            sortable={false}
          />
        )
      }
    ];

    const output: JSX.Element[] = [];

    for (const field of allFields) {
      if (props.omitItems ? !props.omitItems.includes(field.source) : true) {
        output.push(field.element);
      }
    }
    return output;
  }, [props.omitItems]);

  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <Datagrid>
          {fields}
          {isPublisher(permissions?.config) && (
            <BaseEditButton label={false} tooltip='Edit Admin' />
          )}
          {isPublisher(permissions?.config) && (
            <BaseDeleteButton
              label={false}
              tooltip='Delete admin'
              messageContent={'Confirm to delete this Admin'}
              messageTitle={'Delete Admin'}
            />
          )}
        </Datagrid>
      )}
    </>
  );
};
