import {
  ArrayField,
  BooleanField,
  Datagrid,
  ReferenceField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext
} from 'react-admin';

import { Box, Stack, Typography } from '@mui/material';

import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { ResourceName } from 'shared/enums';
import { SectionName } from 'shared/enums/section-name.enum';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';
import { vh, rem } from 'shared/utils/styles/css-unit';

import { AddToPackageButton } from '../components/AddToPackageButton.component';
import { RemoveFromPackageButton } from '../components/RemoveFromPackageButton.component';
import { ContentPackages } from '../content-packages.dto';

export function PackagesShow(props: ShowProps) {
  const { permissions } = useAppPermissions();
  return (
    <Show
      {...props}
      title='Content Packages'
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'create', 'edit']}
          extraActions={[]}
          sectionName={SectionName.Content}
        />
      }
    >
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <ContentPackagesTitle />
        </Typography>
        <TabbedShowLayout>
          <Tab label='Details'>
            <TextField source='id' label='Id' emptyText='-' />
            <TextField source='name' label='Name' emptyText='-' />
            <TextField source='description' label='Description' emptyText='-' />
          </Tab>
          <Tab label='Contents'>
            {isPublisher(permissions?.content) && (
              <>
                <br />
                <Stack
                  sx={{ alignItems: 'center' }}
                  direction='row'
                  spacing={4}
                >
                  <AddToPackageButton></AddToPackageButton>
                </Stack>
                <br />
              </>
            )}
            <ArrayField source='contents' sortable={false} label={'Contents'}>
              <Datagrid>
                <ReferenceField
                  source={'content_id'}
                  label={'Activity Name'}
                  link={'show'}
                  sortBy={'display_name'}
                  reference={ResourceName.ACTIVITIES}
                >
                  <TextField source='display_name' />
                </ReferenceField>
                <BooleanField
                  source='allow'
                  sortable={false}
                  label='Is open'
                ></BooleanField>
                <BooleanField
                  source='override'
                  sortable={false}
                  label='By override'
                ></BooleanField>
                {isPublisher(permissions?.content) && (
                  <RemoveFromPackageButton />
                )}
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
}
export function ContentPackagesTitle() {
  const content = useRecordContext<ContentPackages>();
  return <>{content.name}</>;
}
