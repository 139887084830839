import { useMemo, useState } from 'react';
import {
  DateInput,
  DateInputProps,
  maxValue,
  required,
  Validator
} from 'react-admin';
import { useForm } from 'react-final-form';

import { HasId } from 'resources/types';

import { calcDateToValidationFormat } from 'shared/utils/dates/dates-helper';

import { CodesFormSource } from './CodesFormSource.enum';

export function ExpiredDateInput(props: DateInputProps & Partial<HasId>) {
  const min = calcDateToValidationFormat(1);
  const max = calcDateToValidationFormat(0, 0, 2);
  const form = useForm();
  const [isNoExpiration, setIsNoExpiration] = useState<boolean>(
    props.id != null && form.getFieldState(CodesFormSource.expired_date) == null
  );

  const handleNeverExpiresClick = useMemo(
    () => () => {
      form.change(CodesFormSource.expired_date, null);
      setIsNoExpiration((value) => !value);
    },
    [form]
  );

  const validator: Validator = useMemo(() => {
    if (isNoExpiration) {
      return (value, values) => undefined;
    }
    return required();
  }, [isNoExpiration]);

  return (
    <DateInput
      inputProps={{ min }}
      validate={[validator, maxValue(max)]}
      {...props}
    />
    // <> {
    //   isPublisher(permissions?.entities) &&
    //   <Button variant="contained" onClick={handleNeverExpiresClick}>
    //     {isNoExpiration ? 'Set Date' : 'Set as Never Expires'}
    //   </Button>
    // }
    // <br />
    // {!isNoExpiration && (
    //   <DateInput
    //     inputProps={{ min }}
    //     validate={[validator, maxValue(max)]}
    //     {...props}
    //   />
    // )}
    // </>
  );
}
