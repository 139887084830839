import { useMemo } from 'react';
import {
  Datagrid,
  NumberField,
  ReferenceField,
  TextField,
  DateField,
  useListContext,
  Loading
} from 'react-admin';

import { uniqueId } from 'lodash';
import { FieldObject } from 'resources/types';
import { BaseDeleteButton } from 'shared/components/buttons/BaseDeleteButton.component';
import { BaseEditButton } from 'shared/components/buttons/BaseEditButton.component';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import UrlFieldNewWindow from 'shared/components/fields/UrlFieldNewWindow.component';
import { ResourceName } from 'shared/enums';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { HasOmitItems } from 'shared/interfaces/react-admin';

import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';

import { LeaderboardModel } from '../models/Leaderboard.model';

export const LeaderboardTable = (
  props: Partial<HasOmitItems<keyof LeaderboardModel>>
) => {
  const listContext = useListContext<LeaderboardModel>();
  const { permissions } = useAppPermissions();
  const fields = useMemo(() => {
    const allFields: Array<FieldObject<keyof LeaderboardModel>> = [
      {
        source: 'id',
        element: <LinkableField source='id' label='Label' emptyText='-' />
      },
      {
        source: 'title',
        element: (
          <TextField
            sortable={false}
            source='title'
            label='Title'
            emptyText='-'
          />
        )
      },
      {
        source: 'subtitle',
        element: (
          <TextField
            sortable={false}
            source='subtitle'
            label='Subtitle'
            emptyText='-'
          />
        )
      },
      {
        source: 'user_id',
        element: (
          <ReferenceField
            sortable={false}
            reference={ResourceName.USERS}
            label='User Email'
            source='user_id'
            link={'show'}
            key={uniqueId()}
            sortBy='full_name'
          >
            <TextField source='email' />
          </ReferenceField>
        )
      },
      {
        source: 'activity_id',
        element: (
          <TextField
            sortable={false}
            source='activity_id'
            label='Activity ID'
            emptyText='-'
          />
        )
      },
      {
        source: 'number_of_items',
        element: (
          <NumberField
            sortable={false}
            source='number_of_items'
            label='Size'
            emptyText='-'
          />
        )
      },
      {
        source: 'start_date',
        element: (
          <DateField
            sortable={false}
            source='start_date'
            label='Start Date'
            emptyText='-'
          />
        )
      },
      {
        source: 'end_date',
        element: (
          <DateField
            sortable={false}
            source='end_date'
            label='End Date'
            emptyText='-'
          />
        )
      },
      {
        source: 'right_logo',
        element: (
          <UrlFieldNewWindow
            sortable={false}
            shortString={30}
            source='right_logo'
            label='Right Logo'
            emptyText='-'
          />
        )
      },
      {
        source: 'leaderboard_url',
        element: (
          <UrlFieldNewWindow
            sortable={false}
            shortString={30}
            source='leaderboard_url'
            label='Leaderboard Link'
            emptyText='-'
          />
        )
      }
    ];

    const output: JSX.Element[] = [];

    for (const field of allFields) {
      if (props.omitItems ? !props.omitItems.includes(field.source) : true) {
        output.push(field.element);
      }
    }
    return output;
  }, [props.omitItems]);

  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <Datagrid>
          {fields}
          {isPublisher(permissions?.content) && (
            <BaseEditButton label={false} tooltip='Edit Leaderboard' />
          )}
          {isPublisher(permissions?.content) && (
            <BaseDeleteButton
              label={false}
              tooltip='Delete Leaderboard'
              messageContent={'Confirm to delete this Leaderboard'}
              messageTitle={'Delete Leaderboard'}
            />
          )}
        </Datagrid>
      )}
    </>
  );
};
