import { List, ListProps, SearchInput } from 'react-admin';

import { AppResponsive } from 'shared/components/common/AppResponsive.component';
import { Pagination } from 'shared/components/pagination/Pagination.component';
import { RaListOrder } from 'shared/services/backoffice-data-provider';

import { TestsTabbedDatagrid } from '../components/TestsTabbedDatagrid.component';

import { TestsTable } from '../components/TestsTable.component';

export function TestsList(props: ListProps) {
  return (
    <List
      {...props}
      perPage={100}
      sort={{ field: 'display_name', order: RaListOrder.ASC }}
      pagination={<Pagination />}
      filters={[<SearchInput source='search' alwaysOn />]}
      exporter={false}
    >
      <AppResponsive
        xs={<TestsTable omitItems={[]} />}
        sm={<TestsTable omitItems={[]} />}
        md={<TestsTable omitItems={[]} />}
        lg={<TestsTabbedDatagrid />}
        xl={<TestsTabbedDatagrid />}
      />
    </List>
  );
}
