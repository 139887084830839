import { downloadCSV, ExportButton, Exporter } from 'react-admin';

import { MembershipCode } from 'resources/codes/models/MembershipCode.model';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { csvMaker } from 'shared/utils/helperFunctions/csvMaker';

export function ExportCodesButton(props: { created?: string }) {
  const handleDownload: Exporter = async (
    data,
    _fetchRelatedRecords,
    dataProvider
  ) => {
    const _customers = await _fetchRelatedRecords(
      data,
      'customer_id',
      ResourceName.CUSTOMERS
    );
    const _users = await _fetchRelatedRecords(
      data,
      'app_user_id',
      ResourceName.USERS
    );
    const codesForExport: Array<MembershipCode> = data.map(
      ({ customer_id, app_user_id, ...rest }: MembershipCode) => {
        return {
          ...rest,
          app_user: app_user_id != null ? _users[app_user_id].email : null,
          customer: customer_id != null ? _customers[customer_id].alias : null
        };
      }
    );

    const headers = [
      'code',
      'id',
      'customer',
      'app_user',
      'creation_date',
      'status',
      'activation_date',
      'plan_duration'
    ];

    const csv = csvMaker(codesForExport, headers);
    downloadCSV(csv, `code_batch-${props.created}`);
  };

  return <ExportButton exporter={handleDownload} />;
}
