import { SvgIcon, SvgIconProps } from '@mui/material';

function LargeLogo(
  props: Omit<SvgIconProps, 'width' | 'height' | 'viewBox' | 'xmlns'>
) {
  return (
    <SvgIcon
      width='306'
      height='454'
      viewBox='0 0 306 454'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.6507 443.971H27.8246C29.7788 443.971 31.2988 443.681 32.4086 443.126C33.5184 442.547 34.3387 441.775 34.9177 440.762C35.4968 439.748 35.8345 438.542 35.9552 437.167C36.0758 435.792 36.1482 434.272 36.1482 432.631C36.1482 430.918 36.0517 429.446 35.8587 428.192C35.6657 426.937 35.2796 425.876 34.6765 425.031C34.0733 424.187 33.253 423.535 32.1673 423.101C31.1058 422.667 29.6823 422.426 27.9211 422.426H23.6507L23.6507 443.971ZM23.6507 413.885H27.9934C31.2023 413.885 33.3736 413.089 34.4835 411.472C35.5933 409.856 36.1482 407.516 36.1482 404.427C36.1482 401.412 35.545 399.144 34.3146 397.624C33.06 396.08 30.8404 395.332 27.6316 395.332H23.6507L23.6507 413.885ZM14.0001 386.26H28.1141C31.2022 386.26 33.832 386.743 36.0034 387.684C38.1748 388.625 39.9601 389.903 41.3595 391.471C42.7347 393.064 43.748 394.873 44.3511 396.924C44.9543 398.975 45.2438 401.098 45.2438 403.318V405.778C45.2438 407.612 45.099 409.156 44.8095 410.411C44.52 411.665 44.1099 412.775 43.5308 413.716C42.9518 414.633 42.2521 415.477 41.456 416.201C40.6357 416.925 39.6947 417.6 38.6091 418.228C40.8769 419.313 42.5658 420.906 43.6273 423.005C44.713 425.128 45.2438 427.951 45.2438 431.473V435.261C45.2438 441.196 43.8204 445.732 40.9493 448.892C38.0783 452.053 33.4943 453.621 27.1731 453.621H14.0001V386.26Z'
        fill='#2C84DC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.4086 385.995H60.0958V443.937H79.3006V453.626H50.4086V385.995Z'
        fill='#2C84DC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M103.251 405.658H103.058L98.3293 430.074H107.98L103.251 405.658ZM99.1737 386.26H107.208L122.263 453.621H112.612L109.765 439.145H96.6163L93.7694 453.621H84.1188L99.1737 386.26Z'
        fill='#2C84DC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M128.824 444.933L146.939 395.301H129.762V386.256H157.403V393.882L138.928 444.379H157.403V453.425H128.824V444.933Z'
        fill='#2C84DC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M163.768 386.256H192.448V395.301H173.392V415.029H189.994V424.075H173.392V443.802H192.448V453.425H163.768V386.256Z'
        fill='#2C84DC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M208.5 418.128H213.118C215.066 418.128 216.581 417.839 217.639 417.286C218.722 416.709 219.491 415.939 219.996 414.977C220.501 413.991 220.79 412.812 220.886 411.441C220.982 410.07 221.03 408.507 221.03 406.824C221.03 405.26 220.982 403.769 220.886 402.35C220.79 400.931 220.501 399.705 220.044 398.67C219.563 397.636 218.842 396.818 217.832 396.217C216.846 395.592 215.403 395.303 213.503 395.303H208.5L208.5 418.128ZM198.88 386.26H213.31C215.956 386.26 218.265 386.597 220.285 387.294C222.281 387.992 224.109 389.218 225.769 390.974C227.404 392.73 228.534 394.798 229.16 397.155C229.785 399.512 230.098 402.711 230.098 406.727C230.098 409.686 229.929 412.211 229.593 414.327C229.256 416.444 228.51 418.392 227.38 420.22C226.057 422.433 224.301 424.14 222.089 425.367C219.876 426.593 216.99 427.219 213.406 427.219H208.5V453.434H198.88V386.26H198.88Z'
        fill='#2C84DC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M246.258 437.208C246.258 439.511 246.899 441.196 248.158 442.288C249.44 443.381 250.935 443.927 252.668 443.927C254.401 443.927 255.897 443.381 257.179 442.288C258.461 441.196 259.079 439.511 259.079 437.208V402.189C259.079 399.887 258.437 398.201 257.179 397.109C255.921 396.041 254.425 395.494 252.692 395.494C250.959 395.494 249.463 396.041 248.181 397.133C246.899 398.225 246.282 399.91 246.282 402.213V437.208H246.258ZM236.762 402.19C236.762 399.531 237.213 397.156 238.163 395.115C239.088 393.073 240.323 391.364 241.842 390.034C243.362 388.705 245.071 387.684 246.971 386.995C248.846 386.33 250.769 385.998 252.692 385.998C254.615 385.998 256.515 386.33 258.414 387.019C260.313 387.707 262.022 388.705 263.542 390.058C265.061 391.387 266.296 393.097 267.222 395.138C268.148 397.18 268.623 399.554 268.623 402.213V437.232C268.623 439.962 268.148 442.336 267.222 444.354C266.296 446.372 265.061 448.058 263.542 449.387C262.022 450.717 260.313 451.714 258.414 452.402C256.515 453.091 254.615 453.423 252.692 453.423C250.769 453.423 248.87 453.067 246.971 452.402C245.071 451.714 243.362 450.717 241.842 449.387C240.323 448.058 239.088 446.372 238.163 444.354C237.237 442.336 236.762 439.962 236.762 437.232V402.19Z'
        fill='#2C84DC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M284.453 444.367H288.878C291.572 444.367 293.496 443.694 294.626 442.347C295.757 441 296.334 438.883 296.334 435.973V403.601C296.334 400.955 295.805 398.911 294.722 397.468C293.664 396.025 291.716 395.303 288.878 395.303H284.453L284.453 444.367ZM274.832 386.26H289.071C294.53 386.26 298.715 387.799 301.601 390.83C304.487 393.884 305.93 398.141 305.93 403.625V435.035C305.93 441.337 304.415 445.954 301.36 448.961C298.306 451.943 293.929 453.434 288.205 453.434H274.808V386.26L274.832 386.26Z'
        fill='#2C84DC'
      />
      <mask
        id='mask0_6_3'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='292'
        height='354'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M155.602 130.221C129.079 130.221 104.176 140.006 85.4783 157.775C66.7641 175.544 56.4578 199.211 56.4578 224.417C56.4578 249.622 66.7641 273.289 85.4783 291.058C104.177 308.828 129.08 318.614 155.602 318.614C182.123 318.614 207.026 308.828 225.725 291.058C244.428 273.289 254.729 249.622 254.729 224.417C254.729 199.211 244.428 175.544 225.725 157.775C207.027 140.006 182.124 130.221 155.602 130.221ZM155.587 354C119.113 354 84.8588 340.534 59.1337 316.083C38.1225 296.106 24.8139 270.767 20.6468 242.803C16.6178 215.766 21.7622 187.681 35.1325 163.72C35.1026 163.749 35.0791 163.773 35.0543 163.8L34.9802 163.875C43.3934 150.986 34.023 140.674 32.0588 138.737L18.4451 125.8C12.232 119.886 12.232 110.263 18.4451 104.35C21.4602 101.489 25.4685 99.9135 29.7317 99.9135C33.995 99.9135 38.0033 101.489 41.0184 104.35L47.0241 110.067L53.0408 115.785L57.4333 119.958C60.0177 122.4 63.5449 123.745 67.3655 123.745C71.6777 123.745 75.9673 122.01 79.1346 118.985C82.1603 116.11 83.933 112.376 84.1267 108.471C84.3204 104.568 82.9113 100.978 80.1592 98.3628L67.788 86.5964L30.1731 50.862L4.66783 26.6141C1.65784 23.7533 0.00012207 19.9467 0.00012207 15.8937C0.00012207 11.8406 1.65784 8.03358 4.66783 5.17414C7.68304 2.31377 11.6914 0.738037 15.9545 0.738037C20.2176 0.738037 24.2259 2.31377 27.2411 5.17414L92.7048 67.3826L120.172 93.443C123.32 96.4445 127.497 98.0973 131.936 98.0973C136.212 98.0973 140.202 96.5434 143.17 93.7225C149.413 87.7896 149.281 77.9851 142.876 71.8679L133.655 63.1252L98.4163 29.6179C92.2035 23.7041 92.2035 14.0816 98.4163 8.16681C101.416 5.30644 105.415 3.73118 109.678 3.73118C113.942 3.73118 117.947 5.30645 120.957 8.16681L255.383 135.919C255.605 136.13 255.793 136.37 255.974 136.601C256.11 136.775 256.241 136.942 256.386 137.101C280.038 161.725 292.649 193.73 291.898 227.217C291.145 260.809 276.992 292.368 252.048 316.083C226.317 340.534 192.06 354 155.587 354H155.587Z'
          fill='#1DB7E9'
        />
      </mask>
      <g mask='url(#mask0_6_3)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M31.2915 -272.973C220.835 -272.973 374.046 -126.929 374.046 53.7471V71.4078C374.046 252.083 220.835 398.126 31.2915 398.126C-158.25 398.126 -311.462 252.083 -311.462 71.4078V53.7471C-311.462 -126.929 -158.25 -272.973 31.2915 -272.973Z'
          fill='url(#paint0_linear_6_3)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_6_3'
          x1='672.754'
          y1='152.665'
          x2='138.178'
          y2='-576.305'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0FC9E7' />
          <stop offset='0.21' stopColor='#2E7EDB' />
          <stop offset='0.56' stopColor='#4D33CF' />
          <stop offset='0.75' stopColor='#2E28A0' />
          <stop offset='1' stopColor='#0F1C70' />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}

// const LargeLogo = createSvgIcon(
//   <>
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M23.6507 443.971H27.8246C29.7788 443.971 31.2988 443.681 32.4086 443.126C33.5184 442.547 34.3387 441.775 34.9177 440.762C35.4968 439.748 35.8345 438.542 35.9552 437.167C36.0758 435.792 36.1482 434.272 36.1482 432.631C36.1482 430.918 36.0517 429.446 35.8587 428.192C35.6657 426.937 35.2796 425.876 34.6765 425.031C34.0733 424.187 33.253 423.535 32.1673 423.101C31.1058 422.667 29.6823 422.426 27.9211 422.426H23.6507L23.6507 443.971ZM23.6507 413.885H27.9934C31.2023 413.885 33.3736 413.089 34.4835 411.472C35.5933 409.856 36.1482 407.516 36.1482 404.427C36.1482 401.412 35.545 399.144 34.3146 397.624C33.06 396.08 30.8404 395.332 27.6316 395.332H23.6507L23.6507 413.885ZM14.0001 386.26H28.1141C31.2022 386.26 33.832 386.743 36.0034 387.684C38.1748 388.625 39.9601 389.903 41.3595 391.471C42.7347 393.064 43.748 394.873 44.3511 396.924C44.9543 398.975 45.2438 401.098 45.2438 403.318V405.778C45.2438 407.612 45.099 409.156 44.8095 410.411C44.52 411.665 44.1099 412.775 43.5308 413.716C42.9518 414.633 42.2521 415.477 41.456 416.201C40.6357 416.925 39.6947 417.6 38.6091 418.228C40.8769 419.313 42.5658 420.906 43.6273 423.005C44.713 425.128 45.2438 427.951 45.2438 431.473V435.261C45.2438 441.196 43.8204 445.732 40.9493 448.892C38.0783 452.053 33.4943 453.621 27.1731 453.621H14.0001V386.26Z"
//       fill="#2C84DC"
//     />
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M50.4086 385.995H60.0958V443.937H79.3006V453.626H50.4086V385.995Z"
//       fill="#2C84DC"
//     />
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M103.251 405.658H103.058L98.3293 430.074H107.98L103.251 405.658ZM99.1737 386.26H107.208L122.263 453.621H112.612L109.765 439.145H96.6163L93.7694 453.621H84.1188L99.1737 386.26Z"
//       fill="#2C84DC"
//     />
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M128.824 444.933L146.939 395.301H129.762V386.256H157.403V393.882L138.928 444.379H157.403V453.425H128.824V444.933Z"
//       fill="#2C84DC"
//     />
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M163.768 386.256H192.448V395.301H173.392V415.029H189.994V424.075H173.392V443.802H192.448V453.425H163.768V386.256Z"
//       fill="#2C84DC"
//     />
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M208.5 418.128H213.118C215.066 418.128 216.581 417.839 217.639 417.286C218.722 416.709 219.491 415.939 219.996 414.977C220.501 413.991 220.79 412.812 220.886 411.441C220.982 410.07 221.03 408.507 221.03 406.824C221.03 405.26 220.982 403.769 220.886 402.35C220.79 400.931 220.501 399.705 220.044 398.67C219.563 397.636 218.842 396.818 217.832 396.217C216.846 395.592 215.403 395.303 213.503 395.303H208.5L208.5 418.128ZM198.88 386.26H213.31C215.956 386.26 218.265 386.597 220.285 387.294C222.281 387.992 224.109 389.218 225.769 390.974C227.404 392.73 228.534 394.798 229.16 397.155C229.785 399.512 230.098 402.711 230.098 406.727C230.098 409.686 229.929 412.211 229.593 414.327C229.256 416.444 228.51 418.392 227.38 420.22C226.057 422.433 224.301 424.14 222.089 425.367C219.876 426.593 216.99 427.219 213.406 427.219H208.5V453.434H198.88V386.26H198.88Z"
//       fill="#2C84DC"
//     />
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M246.258 437.208C246.258 439.511 246.899 441.196 248.158 442.288C249.44 443.381 250.935 443.927 252.668 443.927C254.401 443.927 255.897 443.381 257.179 442.288C258.461 441.196 259.079 439.511 259.079 437.208V402.189C259.079 399.887 258.437 398.201 257.179 397.109C255.921 396.041 254.425 395.494 252.692 395.494C250.959 395.494 249.463 396.041 248.181 397.133C246.899 398.225 246.282 399.91 246.282 402.213V437.208H246.258ZM236.762 402.19C236.762 399.531 237.213 397.156 238.163 395.115C239.088 393.073 240.323 391.364 241.842 390.034C243.362 388.705 245.071 387.684 246.971 386.995C248.846 386.33 250.769 385.998 252.692 385.998C254.615 385.998 256.515 386.33 258.414 387.019C260.313 387.707 262.022 388.705 263.542 390.058C265.061 391.387 266.296 393.097 267.222 395.138C268.148 397.18 268.623 399.554 268.623 402.213V437.232C268.623 439.962 268.148 442.336 267.222 444.354C266.296 446.372 265.061 448.058 263.542 449.387C262.022 450.717 260.313 451.714 258.414 452.402C256.515 453.091 254.615 453.423 252.692 453.423C250.769 453.423 248.87 453.067 246.971 452.402C245.071 451.714 243.362 450.717 241.842 449.387C240.323 448.058 239.088 446.372 238.163 444.354C237.237 442.336 236.762 439.962 236.762 437.232V402.19Z"
//       fill="#2C84DC"
//     />
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M284.453 444.367H288.878C291.572 444.367 293.496 443.694 294.626 442.347C295.757 441 296.334 438.883 296.334 435.973V403.601C296.334 400.955 295.805 398.911 294.722 397.468C293.664 396.025 291.716 395.303 288.878 395.303H284.453L284.453 444.367ZM274.832 386.26H289.071C294.53 386.26 298.715 387.799 301.601 390.83C304.487 393.884 305.93 398.141 305.93 403.625V435.035C305.93 441.337 304.415 445.954 301.36 448.961C298.306 451.943 293.929 453.434 288.205 453.434H274.808V386.26L274.832 386.26Z"
//       fill="#2C84DC"
//     />
//     <mask
//       id="mask0_6_3"
//       style={{ maskType: "alpha" }}
//       maskUnits="userSpaceOnUse"
//       x="0"
//       y="0"
//       width="292"
//       height="354"
//     >
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M155.602 130.221C129.079 130.221 104.176 140.006 85.4783 157.775C66.7641 175.544 56.4578 199.211 56.4578 224.417C56.4578 249.622 66.7641 273.289 85.4783 291.058C104.177 308.828 129.08 318.614 155.602 318.614C182.123 318.614 207.026 308.828 225.725 291.058C244.428 273.289 254.729 249.622 254.729 224.417C254.729 199.211 244.428 175.544 225.725 157.775C207.027 140.006 182.124 130.221 155.602 130.221ZM155.587 354C119.113 354 84.8588 340.534 59.1337 316.083C38.1225 296.106 24.8139 270.767 20.6468 242.803C16.6178 215.766 21.7622 187.681 35.1325 163.72C35.1026 163.749 35.0791 163.773 35.0543 163.8L34.9802 163.875C43.3934 150.986 34.023 140.674 32.0588 138.737L18.4451 125.8C12.232 119.886 12.232 110.263 18.4451 104.35C21.4602 101.489 25.4685 99.9135 29.7317 99.9135C33.995 99.9135 38.0033 101.489 41.0184 104.35L47.0241 110.067L53.0408 115.785L57.4333 119.958C60.0177 122.4 63.5449 123.745 67.3655 123.745C71.6777 123.745 75.9673 122.01 79.1346 118.985C82.1603 116.11 83.933 112.376 84.1267 108.471C84.3204 104.568 82.9113 100.978 80.1592 98.3628L67.788 86.5964L30.1731 50.862L4.66783 26.6141C1.65784 23.7533 0.00012207 19.9467 0.00012207 15.8937C0.00012207 11.8406 1.65784 8.03358 4.66783 5.17414C7.68304 2.31377 11.6914 0.738037 15.9545 0.738037C20.2176 0.738037 24.2259 2.31377 27.2411 5.17414L92.7048 67.3826L120.172 93.443C123.32 96.4445 127.497 98.0973 131.936 98.0973C136.212 98.0973 140.202 96.5434 143.17 93.7225C149.413 87.7896 149.281 77.9851 142.876 71.8679L133.655 63.1252L98.4163 29.6179C92.2035 23.7041 92.2035 14.0816 98.4163 8.16681C101.416 5.30644 105.415 3.73118 109.678 3.73118C113.942 3.73118 117.947 5.30645 120.957 8.16681L255.383 135.919C255.605 136.13 255.793 136.37 255.974 136.601C256.11 136.775 256.241 136.942 256.386 137.101C280.038 161.725 292.649 193.73 291.898 227.217C291.145 260.809 276.992 292.368 252.048 316.083C226.317 340.534 192.06 354 155.587 354H155.587Z"
//         fill="#1DB7E9"
//       />
//     </mask>
//     <g mask="url(#mask0_6_3)">
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M31.2915 -272.973C220.835 -272.973 374.046 -126.929 374.046 53.7471V71.4078C374.046 252.083 220.835 398.126 31.2915 398.126C-158.25 398.126 -311.462 252.083 -311.462 71.4078V53.7471C-311.462 -126.929 -158.25 -272.973 31.2915 -272.973Z"
//         fill="url(#paint0_linear_6_3)"
//       />
//     </g>
//     <defs>
//       <linearGradient
//         id="paint0_linear_6_3"
//         x1="672.754"
//         y1="152.665"
//         x2="138.178"
//         y2="-576.305"
//         gradientUnits="userSpaceOnUse"
//       >
//         <stop stopColor="#0FC9E7" />
//         <stop offset="0.21" stopColor="#2E7EDB" />
//         <stop offset="0.56" stopColor="#4D33CF" />
//         <stop offset="0.75" stopColor="#2E28A0" />
//         <stop offset="1" stopColor="#0F1C70" />
//       </linearGradient>
//     </defs>
//   </>,
//   "BlazePodLarge"
// );

export default LargeLogo;
