import { ReferenceField, TextField } from 'react-admin';

import { ResourceName } from 'shared/enums/resource-name.enum';

export function CurrentPlanField() {
  return (
    <ReferenceField
      source='plan_id'
      reference={ResourceName.MEMBERSHIP_PLANS}
      link={false}
      emptyText='NONE'
    >
      <TextField source='short_name' emptyText='NONE' />
    </ReferenceField>
  );
}
