import { v4 } from 'uuid';

import { StatDto } from './Stat.dto';

export class Stat {
  id: string;
  filter_key: string;
  filter_value: string | null;
  count: number;
  display_name: string;

  constructor(
    id: string,
    filter_key: string,
    filter_value: string | null,
    count: number | null,
    display_name: string
  ) {
    this.id = id;
    this.filter_key = filter_key;
    this.filter_value = filter_value;
    this.count = count ?? 0;
    this.display_name = display_name;
  }

  static fromStat(stat: StatDto, id?: string) {
    const { count, display_name, filter_key, filter_value } = stat;
    return new Stat(id || v4(), filter_key, filter_value, count, display_name);
  }
}
