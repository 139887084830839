import { Filter } from 'shared/utils/types/filter';

export enum MembershipPlanProvider {
  None = 'NONE',
  Apple = 'APPLE',
  Google = 'GOOGLE',
  Recharge = 'RECHARGE',
  RechargeEu = 'RECHARGE-EU',
  BlazePod = 'BLAZEPOD',
  QuickPlay = 'QUICKPLAY',
  Amazon = 'AMAZON',
  Shopify = 'SHOPIFY',
  ShopifyEU = 'SHOPIFY-EU'
}

function convertEnumToFilters(omitProviders: Array<MembershipPlanProvider>) {
  const response = Object.entries(MembershipPlanProvider).reduce(
    (acc: Filter[], [name, val]) => {
      if (!omitProviders.includes(val)) {
        acc.push({ id: val, name });
      }
      return acc;
    },
    []
  );
  return response;
}

export function codesOriginProvider() {
  const omitProvider = new Array<MembershipPlanProvider>(
    MembershipPlanProvider.None,
    MembershipPlanProvider.Apple,
    MembershipPlanProvider.Google
  );
  return convertEnumToFilters(omitProvider);
}

export function onlinePlanProviders() {
  const omitProvider = new Array<MembershipPlanProvider>(
    MembershipPlanProvider.None,
    MembershipPlanProvider.Apple,
    MembershipPlanProvider.Google,
    MembershipPlanProvider.BlazePod,
    MembershipPlanProvider.Shopify,
    MembershipPlanProvider.ShopifyEU
  );
  return convertEnumToFilters(omitProvider);
}

export function offlinePlanProviders() {
  const omitProvider = new Array<MembershipPlanProvider>(
    MembershipPlanProvider.None,
    MembershipPlanProvider.Apple,
    MembershipPlanProvider.Google,
    MembershipPlanProvider.Recharge,
    MembershipPlanProvider.RechargeEu,
    MembershipPlanProvider.QuickPlay,
    MembershipPlanProvider.Amazon
  );
  return convertEnumToFilters(omitProvider);
}
