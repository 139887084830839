import {
  BackOfficeDataProvider,
  defaultUrlRenderer
} from 'shared/services/backoffice-data-provider';

import { TestsDtoConverter } from './TestsDtoConverter';

export class TestDataProvider extends BackOfficeDataProvider {
  protected dtoConverter = new TestsDtoConverter();
  protected urlRenderer = defaultUrlRenderer;
}
