import { AnchorHTMLAttributes, memo, FC } from 'react';

import { InjectedFieldProps, PublicFieldProps } from 'react-admin';

import { Typography, Link } from '@material-ui/core';
import get from 'lodash/get';

import { useRecordContext } from 'ra-core';
import { shortenString } from 'shared/utils/helperFunctions/shortenString';

export interface urlFieldNewWindowProps extends UrlFieldProps {
  shortString?: number;
}

const UrlFieldNewWindow: FC<urlFieldNewWindowProps> = memo((props) => {
  const { className, emptyText, source, shortString } = props;
  const record = useRecordContext(props);
  const value = get(record, source ?? '');

  if (value == null) {
    return (
      <Typography component='span' variant='body2' className={className}>
        {emptyText}
      </Typography>
    );
  }

  return (
    <Link
      className={className}
      //   href={value}
      onClick={() => window.open(value)}
      variant='body2'
    >
      {shortString ? shortenString(value, shortString) : value}
    </Link>
  );
});

UrlFieldNewWindow.defaultProps = {
  addLabel: true
};

UrlFieldNewWindow.displayName = 'UrlField';

export interface UrlFieldProps
  extends PublicFieldProps,
    InjectedFieldProps,
    AnchorHTMLAttributes<HTMLAnchorElement> {}

export default UrlFieldNewWindow;
