import { ElementType } from 'react';

import { Typography, TypographyProps } from '@mui/material';

export interface SmallTextWrapperProps extends TypographyProps {
  component?: ElementType;
}

export function SmallTextWrapper(props: SmallTextWrapperProps) {
  const {
    gutterBottom = true,
    align = 'center',
    variant = 'subtitle1',
    component = 'span',
    ...rest
  } = props;
  return (
    <Typography
      {...rest}
      component={component}
      gutterBottom={gutterBottom}
      align={align}
      variant={variant}
    />
  );
}
