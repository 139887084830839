export const IN_MILLISECONDS = Object.freeze({
  MINUTE: 60000,
  HOUR: 3600000,
  DAY: 86400000,
  WEEK: 604800000,
  MONTH: 2592000000,
  YEAR: 31557600000
} as const);

export const LONG_LOCALE_STRING_CONFIG = Object.freeze({
  weekday: 'long',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
} as const);

export const AVG_DAYS_IN = Object.freeze({
  year: 365.2422,
  month: 30.43685
} as const);
