import {
  Pagination as RaPagination,
  PaginationProps as RaPaginationProps
} from 'react-admin';
export type PaginationProps = RaPaginationProps;

export function Pagination(props: PaginationProps) {
  return (
    <RaPagination
      {...props}
      rowsPerPageOptions={props.rowsPerPageOptions || [10, 25, 50, 100]}
    />
  );
}
