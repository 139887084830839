import { useMemo } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  Loading,
  Record,
  ReferenceField,
  TextField,
  useListContext
} from 'react-admin';

import { FieldObject } from 'resources/types';
import { BaseDeleteButton } from 'shared/components/buttons/BaseDeleteButton.component';
import { BaseEditButton } from 'shared/components/buttons/BaseEditButton.component';
import { ChangeStatusButton } from 'shared/components/buttons/ChangeStatusButton.component';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { ResourceName } from 'shared/enums';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { HasOmitItems } from 'shared/interfaces/react-admin';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';
import { shortenString } from 'shared/utils/helperFunctions/shortenString';

import { Campaign } from '../models/campaign.model';
import { getCampaignStatusString } from '../pages/CampaignShow.component';

export const CampaignTable = (props: Partial<HasOmitItems<keyof Campaign>>) => {
  const listContext = useListContext<Campaign>();
  const { permissions } = useAppPermissions();
  const fields = useMemo(() => {
    const allFields: Array<FieldObject<keyof Campaign>> = [
      {
        source: 'name',
        element: <LinkableField source={'name'} label='Name' emptyText='-' />
      },
      {
        source: 'description',
        element: (
          <FunctionField
            label='Description'
            render={(record?: Record) =>
              shortenString(record?.description ?? '', 50)
            }
            emptyText='-'
          />
        )
      },
      {
        source: 'start_date',
        element: (
          <DateField
            source='start_date'
            locales='en-GB'
            label='Start date'
            emptyText='-'
          />
        )
      },
      {
        source: 'end_date',
        element: (
          <DateField
            sortable={false}
            source='end_date'
            locales='en-GB'
            label='End date'
            emptyText='-'
          />
        )
      },
      {
        source: 'segment_id',
        element: (
          <ReferenceField
            link='show'
            sortable={false}
            reference={ResourceName.SEGMENTS}
            source='segment_id'
            emptyText='-'
            label='Segment'
          >
            <TextField source='name' />
          </ReferenceField>
        )
      },
      {
        source: 'is_active',
        element: (
          <FunctionField
            label='Status'
            render={(record?: Record) => getCampaignStatusString(record)}
            emptyText='-'
          />
        )
      }
    ];

    const output: JSX.Element[] = [];

    for (const field of allFields) {
      if (props.omitItems ? !props.omitItems.includes(field.source) : true) {
        output.push(field.element);
      }
    }
    return output;
  }, [props.omitItems]);

  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <Datagrid>
          {fields}
          {isPublisher(permissions?.monetization) && (
            <ChangeStatusButton
              recordType={'campaign'}
              resource={ResourceName.CAMPAIGNS}
            />
          )}
          {isPublisher(permissions?.monetization) && (
            <BaseEditButton label={false} tooltip='Edit Campaign' />
          )}
          {isPublisher(permissions?.monetization) && (
            <BaseDeleteButton
              label={false}
              tooltip='Delete campaign'
              messageContent={'Confirm to delete this campaign'}
              messageTitle={'Delete campaign'}
            />
          )}
        </Datagrid>
      )}
    </>
  );
};
