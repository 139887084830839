import { PlanDuration } from 'resources/plans/plan-duration.model';

import { MembershipPlanView } from '../../enums/MembershipPlanView.enum';

export class UpdateMembershipPlanPayload {
  membership_plan: MembershipPlanView;
  plan_duration: PlanDuration;

  constructor(
    membership_plan: MembershipPlanView,
    plan_duration: PlanDuration
  ) {
    this.plan_duration = plan_duration;
    this.membership_plan = membership_plan;
  }
  static getPurged(payload: UpdateMembershipPlanPayload) {
    if (payload.membership_plan === MembershipPlanView.PRO) {
      return { ...payload };
    } else {
      return { membership_plan: payload.membership_plan };
    }
  }
}
