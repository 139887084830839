import { AppBar as RaAppBar } from 'react-admin';

import { styled, CSSObject } from '@mui/material/styles';
// import MuiAppBar from "@mui/material/AppBar";
import { createBPBlueGradient } from 'shared/utils/styles/mixins';

export const AppBar = styled(RaAppBar)(({ theme }) => ({
  'zIndex': theme.zIndex.drawer + 1,
  'background': createBPBlueGradient(-90),
  '&::before': beforeMixin()
}));

export function beforeMixin(): CSSObject {
  return {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: `linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.2) 100%
    )`
  };
}
