export class PlanDuration {
  public days: number;
  public months: number;
  public is_forever: boolean;

  constructor(days = 0, months = 0, is_forever = false) {
    this.days = days;
    this.months = months;
    this.is_forever = is_forever;
  }
}
