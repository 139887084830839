import {
  AutocompleteInput,
  Record,
  ReferenceInput,
  ReferenceInputProps,
  SortPayload
} from 'react-admin';

import { isFunction } from 'lodash';

import { ResourceName } from 'shared/enums/resource-name.enum';
import { RaListOrder } from 'shared/services/backoffice-data-provider/enums/ra-list-order.enum';

interface AutocompleteReferenceInputProps
  extends Omit<ReferenceInputProps, 'children'> {
  source: string;
  label?: string;
  reference: ResourceName;
  sort?: SortPayload;
  optionText: string | ((option: Record) => string);
  filterKey?: string;
  alwaysOn?: boolean;
}

export function AutocompleteReferenceInput(
  props: AutocompleteReferenceInputProps
) {
  const {
    label,
    source,
    reference,
    sort,
    optionText,
    filterKey = source,
    alwaysOn,
    ...rest
  } = props;
  const filterToQuery = (filter: string) => {
    return { [filterKey]: filter || '' };
  };
  return (
    <ReferenceInput
      {...rest}
      label={label}
      source={source as string}
      reference={reference}
      sort={sort || { field: filterKey, order: RaListOrder.ASC }}
      filterToQuery={filterToQuery}
      alwaysOn={alwaysOn}
      defaultValue={null}
    >
      <AutocompleteInput
        helperText={false}
        optionText={isFunction(optionText) ? optionText : optionText}
        optionValue='id'
        clearAlwaysVisible
        resettable
      />
    </ReferenceInput>
  );
}
