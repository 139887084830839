import PersonIcon from '@mui/icons-material/Person';
import { RaModule } from 'resources/RaModule';
import { ResourceName } from 'shared/enums/resource-name.enum';

import { UsersList } from './pages/UsersList.component';
import UsersShow from './pages/UsersShow.component';

export const UsersModule = RaModule.fromResourceProps({
  name: ResourceName.USERS,
  options: { label: 'Users' },
  list: UsersList,
  // edit: UsersEdit,
  show: UsersShow,
  icon: PersonIcon
});
