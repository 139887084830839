import { Typography } from '@mui/material';
import { PlanDuration } from 'resources/plans/plan-duration.model';
import { DATE } from 'shared/utils/dates';

import { MembershipCode } from '../models/MembershipCode.model';

export function renderPlanDurationJSX(record?: MembershipCode) {
  const emptyValue = (
    <Typography variant='body1' display='inline'>
      -
    </Typography>
  );
  if (record == null) {
    return emptyValue;
  }

  let planDuration = record.plan_duration;

  if (record.plan_duration == null) {
    const totalDays = Math.round(
      new Date(
        new Date(record.expired_date ?? new Date()).getTime() -
          new Date(record.creation_date).getTime()
      ).getTime() / DATE.IN_MILLISECONDS.DAY
    );

    planDuration =
      record.expired_date == null
        ? null
        : new PlanDuration(
          Math.round(totalDays % DATE.AVG_DAYS_IN.month),
          Math.floor(totalDays / DATE.AVG_DAYS_IN.month)
        );
  }

  const { years, days, months, is_forever } = renderPlanDuration(planDuration);

  const Years = () => (
    <>
      {years > 0 && (
        <Typography variant='body1' display='inline'>
          {years} years{months > 0 && ','}
        </Typography>
      )}
    </>
  );
  const Months = () => (
    <>
      {months > 0 && (
        <Typography variant='body1' display='inline'>
          {months} months{days > 0 && ','}
        </Typography>
      )}
    </>
  );

  const Days = () => (
    <>
      {days > 0 && (
        <Typography variant='body1' display='inline'>
          {days} days
        </Typography>
      )}
    </>
  );

  if (is_forever) {
    return (
      <Typography variant='body1' display='inline'>
        Forever
      </Typography>
    );
  }

  if (planDuration == null) {
    return emptyValue;
  }

  return (
    <>
      <Years /> <Months /> <Days />
    </>
  );
}

interface PlanDurationViewModel {
  years: number;
  months: number;
  days: number;
  is_forever?: boolean;
}
export function renderPlanDuration(
  plan_duration: PlanDuration | null | undefined
): PlanDurationViewModel {
  const emptyValue: PlanDurationViewModel = {
    days: 0,
    months: 0,
    years: 0,
    is_forever: true
  };
  if (plan_duration == null) {
    return { ...emptyValue, is_forever: false };
  }
  if (plan_duration.is_forever === true) {
    return emptyValue;
  }

  const days = plan_duration.days ?? 0;
  const months =
    plan_duration.months > 12
      ? plan_duration.months % 12
      : plan_duration.months;
  const years =
    plan_duration.months > 12 ? Math.floor(plan_duration.months / 12) : 0;

  return { days, months, years };
}
