import { ReactNode } from 'react';

import Fab, { FabProps } from '@mui/material/Fab';

import { createBPBlueGradient } from 'shared/utils/styles/mixins';

export interface BPFabProps extends FabProps {
  onClick(): void;
  width?: number | string;
  children?: ReactNode;
}

function BPFab({
  onClick,
  width = '50px',
  children = null,
  ...rest
}: BPFabProps) {
  return (
    <Fab
      color='primary'
      variant='extended'
      sx={{ width, background: createBPBlueGradient(0) }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Fab>
  );
}
export { BPFab };
