import * as React from 'react';

import {
  HttpError,
  Loading,
  Show,
  ShowProps,
  useGetOne,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh
} from 'react-admin';

import { useParams } from 'react-router-dom';

import { Box, Drawer, Typography } from '@mui/material';

import { ChangeMembershipPlanDialog } from 'resources/users/components/change-plan-form/ChangePlanForm.component';

import {
  CancelRenewalDialog,
  EditExpireDate
} from 'resources/users_plans/components';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { ResourceName } from 'shared/enums/resource-name.enum';

import { SectionName } from 'shared/enums/section-name.enum';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';
import { rem, vh } from 'shared/utils/styles/css-unit';

import { HasId } from '../../types';
import { UserTabbedView } from '../components/show/UserTabbedView.component';
import { PlanEditable } from '../interfaces/HasOnEditPlan';
import { AppUser } from '../models/AppUser.model';

export function UsersShow(props: ShowProps) {
  const [shouldOpenChangePlan, setShouldOpenChangePlan] =
    React.useState<boolean>(false);
  const [shouldOpenEditExpire, setShouldOpenEditExpire] =
    React.useState<boolean>(false);
  const [shouldOpenEditIsRenew, setShouldOpenEditIsRenew] =
    React.useState<boolean>(false);
  const params = useParams<HasId>();
  const userPlan = useGetOne(ResourceName.USERS_PLANS, params.id, {
    enabled: !!params.id
  });
  const refresh = useRefresh();
  const notify = useNotify();

  const authorization = usePermissions();

  const handleEditPlan = (subject: PlanEditable) => {
    switch (subject) {
      case 'membership_plan':
        setShouldOpenChangePlan(true);
        break;
      case 'expire_date':
        setShouldOpenEditExpire(true);
        break;
      case 'is_renew':
        setShouldOpenEditIsRenew(true);
        break;
      default:
        return;
    }
  };
  const handleCancelChangePlan = () => {
    setShouldOpenChangePlan(false);
  };
  const handleChangePlanSuccess = () => {
    setShouldOpenChangePlan(false);
    refresh();
  };
  const handleCancelEditExpire = () => {
    setShouldOpenEditExpire(false);
  };
  const handleEditExpireSuccess = () => {
    setShouldOpenEditExpire(false);
    refresh();
  };
  const handleEditExpireError = (e: HttpError) => {
    const notifyError = (message = 'Something went wrong') => {
      notify(message, { type: 'error' });
    };

    setShouldOpenEditExpire(false);

    notifyError(e.message);
  };
  const handleCancelEditIsRenew = () => {
    setShouldOpenEditIsRenew(false);
  };
  const handleEditIsRenewSuccess = () => {
    setShouldOpenEditIsRenew(false);
    refresh();
  };
  const handleEditIsRenewError = (e: HttpError) => {
    const notifyError = (message = 'Something went wrong') => {
      notify(message, { type: 'error' });
    };

    setShouldOpenEditIsRenew(false);

    notifyError(e.message);
  };

  if (authorization.loading) {
    return <Loading />;
  }

  return (
    <>
      <Show
        {...props}
        title='Users'
        actions={
          <AppGenericTopToolbar
            omitItems={['show', 'filter', 'create', 'edit']}
            sectionName={SectionName.Entities}
          />
        }
      >
        <Box sx={{ minHeight: vh(30) }}>
          <Typography
            sx={{ p: rem(2), fontSize: rem(2) }}
            variant='h2'
            component='h2'
          >
            <UserTitle />
          </Typography>
          <UserTabbedView onEditPlan={handleEditPlan} />
        </Box>
      </Show>
      <Drawer
        disableScrollLock={true}
        open={shouldOpenChangePlan}
        anchor='right'
      >
        <ChangeMembershipPlanDialog
          onUpdateSuccess={handleChangePlanSuccess}
          onCancel={handleCancelChangePlan}
        />
      </Drawer>
      {userPlan.data != null && (
        <>
          <Drawer
            disableScrollLock={true}
            open={shouldOpenEditExpire}
            anchor='right'
          >
            <EditExpireDate
              onSuccess={handleEditExpireSuccess}
              onCancel={handleCancelEditExpire}
              onError={handleEditExpireError}
            />
          </Drawer>
          <CancelRenewalDialog
            isOpen={shouldOpenEditIsRenew}
            onCancel={handleCancelEditIsRenew}
            onSuccess={handleEditIsRenewSuccess}
            onError={handleEditIsRenewError}
          />
        </>
      )}
    </>
  );
}

export function UserTitle() {
  const user = useRecordContext<AppUser>();
  return <>{renderFullName(user)}</>;
}

export default UsersShow;
