export class UserPlanEditDto {
  is_renew?: boolean;
  expiration_date?: string;

  constructor({
    is_renew,
    expiration_date
  }: {
    is_renew?: boolean;
    expiration_date?: string;
  } = {}) {
    this.is_renew = is_renew;
    this.expiration_date = expiration_date;
  }
}
