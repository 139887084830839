import { useMemo } from 'react';
import {
  BooleanField,
  Datagrid,
  ListContextProvider,
  Loading,
  useListContext
} from 'react-admin';

import { uniqueId } from 'lodash';
import { MembershipCode } from 'resources/codes/models/MembershipCode.model';
import { CustomerLinkField } from 'resources/customers/components/fields/CustomerLinkField.component';
import { FieldObject } from 'resources/types';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { HasOmitItems } from 'shared/interfaces/react-admin';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';

import { getTitleCase } from 'shared/utils/helperFunctions/strings';

import { CodeCancelButton } from './CodeCancelButton.component';
import { CodeEditButton } from './CodeEditButton.component';
import { CodeResendButton } from './CodeResendButton.component';
import {
  CodesDateField,
  CodesFunctionField,
  CodesReferenceField,
  CodesTextField
} from './codes-fields';
import { CodesSortableFieldsEnum } from '../enums/codes-sortable-fields.enum';
import { renderPlanDurationJSX } from '../utils/renderPlanDuration';

export type CodesTableProps = Partial<HasOmitItems<keyof MembershipCode>>;

export const CodesTable = (props: CodesTableProps) => {
  const listContext = useListContext<MembershipCode>();
  const { permissions } = useAppPermissions();

  const fields = useMemo(() => {
    const allFields: Array<FieldObject<keyof MembershipCode>> = [
      {
        source: 'code',
        element: (
          <LinkableField
            source='code'
            label='Code'
            emptyText='-'
            viewResource={ResourceName.CODES}
            render={(record) => record?.code}
            key={uniqueId()}
          />
        )
      },
      {
        source: 'status',
        element: (
          <CodesFunctionField
            source='status'
            sortable={false}
            render={(record?: MembershipCode) =>
              getTitleCase(record?.status?.toString() ?? '')
            }
            sortBy={CodesSortableFieldsEnum.code_status}
          ></CodesFunctionField>
        )
      },
      {
        source: 'provider',
        element: (
          <CodesTextField source='provider' emptyText='-' key={uniqueId()} />
        )
      },
      {
        source: 'creator_id',
        element: (
          <CodesReferenceField
            emptyText='-'
            reference={ResourceName.USERS}
            label='Creator'
            source='creator_id'
            link={false}
            key={uniqueId()}
            // sortBy={CodesSortableFieldsEnum.app_user}
            sortable={false}
          >
            <LinkableField render={renderFullName} />
          </CodesReferenceField>
        )
      },
      {
        source: 'customer_id',
        element: (
          <CodesReferenceField
            emptyText='-'
            reference={ResourceName.CUSTOMERS}
            source='customer_id'
            link={false}
            key={uniqueId()}
            sortBy={CodesSortableFieldsEnum.customer}
          >
            <CustomerLinkField />
          </CodesReferenceField>
        )
      },
      {
        source: 'app_user_id',
        element: (
          <CodesReferenceField
            emptyText='-'
            reference={ResourceName.USERS}
            source='app_user_id'
            link={false}
            key={uniqueId()}
            sortBy={CodesSortableFieldsEnum.app_user}
          >
            <LinkableField render={renderFullName} />
          </CodesReferenceField>
        )
      },
      {
        source: 'creation_date',
        element: (
          <CodesDateField
            locales='en-GB'
            source='creation_date'
            key={uniqueId()}
          />
        )
      },
      {
        source: 'expired_date',
        element: (
          <CodesDateField
            locales='en-GB'
            label='Expiration Date'
            source='expired_date'
            emptyText='Never'
            key={uniqueId()}
          />
        )
      },
      {
        source: 'plan_duration',
        element: (
          <CodesFunctionField
            label='Plan Duration'
            source='plan_duration'
            render={renderPlanDurationJSX}
            sortable={false}
            key={uniqueId()}
          />
        )
      },
      {
        source: 'activation_date',
        element: (
          <CodesDateField
            locales='en-GB'
            label='Activation Date'
            source='activation_date'
            emptyText='Not Activated'
            key={uniqueId()}
          />
        )
      },
      {
        source: 'packages',
        element: (
          <BooleanField
            source='hasPackages'
            sortable={false}
            label='Has Packages'
          >
            {' '}
          </BooleanField>
        )
      }
    ];

    const output: JSX.Element[] = [];

    for (const field of allFields) {
      if (props.omitItems ? !props.omitItems.includes(field.source) : true) {
        output.push(field.element);
      }
    }
    return output;
  }, []); // empty array because of rerender-bug and props are not ment to change during operation.

  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <ListContextProvider value={listContext}>
          <Datagrid>
            {fields}
            {isPublisher(permissions?.entities) && <CodeEditButton />}
            {isPublisher(permissions?.entities) && <CodeCancelButton />}
            {isPublisher(permissions?.entities) && <CodeResendButton />}
          </Datagrid>
        </ListContextProvider>
      )}
    </>
  );
};
