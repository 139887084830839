export class PackageItem {
  content_id?: string;
  allow: boolean | null;
  override: boolean | null;
  package_id?: string;

  constructor(
    content_id: string,
    allow: boolean,
    override: boolean,
    package_id: string
  ) {
    this.content_id = content_id;
    this.allow = allow;
    this.override = override;
    this.package_id = package_id;
  }

  static fromDto(content: any, package_id: string) {
    return new PackageItem(
      content.content_id,
      content.allow,
      content.override,
      package_id
    );
  }
}
