import { BPMedia } from 'resources/activities/models/ContentItem.model';

export class CampaignsMutationPayload {
  name: string;
  description: string;
  is_active: boolean;
  start_date: string;
  media: BPMedia[];
  end_date: string | undefined;
  display_text: string | undefined;
  display_frequency: string | undefined;

  constructor(
    name: string,
    description: string,
    is_active: boolean,
    start_date: string,
    end_date: string | undefined,
    display_text: string | undefined,
    display_frequency: string | undefined,
    media: BPMedia[]
  ) {
    this.name = name;
    this.description = description;
    this.is_active = is_active;
    this.start_date = start_date;
    this.end_date = end_date;
    this.display_text = display_text;
    this.display_frequency = display_frequency;
    this.media = media;
  }

  static factory(campaign: Partial<any> = {}) {
    return new CampaignsMutationPayload(
      campaign.name,
      campaign.description,
      campaign.is_active ?? false,
      new Date(campaign.start_date).toISOString(),
      campaign.end_date ? new Date(campaign.end_date).toISOString() : undefined,
      campaign.display_text,
      campaign.display_frequency,
      campaign.media
    );
  }
}
