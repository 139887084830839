const colors = [
  '#4a68db',
  '#3bb6e6',
  '#6C5FFF',
  '#1C7FFF',
  '#4E43CE',
  '#38E2EB',
  '#18B9F0',
  // '#00F5FF',
  // '#15E3EB',
  '#0A6D7E'
];

export function simplePieChartParams(
  series: number[] = new Array<number>(),
  labels: string[] = new Array<string>(),
  titleText = 'Default Title'
) {
  return {
    series,
    options: {
      chart: {
        type: 'pie'
      },
      labels,
      colors,
      dataLabels: {
        style: {
          colors: ['#000000']
        },
        dropShadow: {
          enabled: false
        }
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            return value.toLocaleString();
          }
        }
      },
      title: {
        text: titleText,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263238'
        }
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'right'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }
  };
}

export function StackedColumnsParams(
  categories: string[],
  series: { name: string; data: number[] }[],
  titleText: string
) {
  return {
    series,
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true
      },
      colors,
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        }
      },
      dataLabels: {
        style: {
          colors: ['#000000']
        },
        dropShadow: {
          enabled: false
        }
      },
      xaxis: {
        type: 'string',
        categories
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            return value.toLocaleString();
          }
        }
      },
      title: {
        text: titleText,
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263238'
        }
      }
    }
  };
}
