import { useTheme } from '@mui/material';
import { em } from 'shared/utils/styles/css-unit';

type CSSXYDirections = 'left' | 'right' | 'top' | 'bottom';
type CSSFlexAlignment =
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-even';
// type CapitalizedCSSXYDirections = Capitalize<CSSXYDirections>;

export interface DividerProps {
  height?: string;
  margin?: {
    [T in CSSXYDirections]?: string;
  };
  width?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  marginBottom?: string;
  justifySelf?: CSSFlexAlignment;
  alignSelf?: CSSFlexAlignment;

  vairant?: 'horizontal' | 'diagonal';
}

export function Divider(props: DividerProps) {
  const theme = useTheme();
  return (
    <div
      style={{
        marginLeft: props.marginLeft ?? props.margin?.left ?? 0,
        marginRight: props.marginRight ?? props.margin?.right ?? 0,
        marginBottom: props.marginBottom ?? props.margin?.bottom ?? 0,
        marginTop: props.marginTop ?? props.margin?.top ?? 0,
        height: props.height ?? em(1.7),
        [`border${
          props.vairant && props.vairant === 'diagonal' ? 'Left' : 'Bottom'
        }`]: `${props.width ?? '1px solid'} ${theme.palette.grey[400]}`,
        alignSelf: props.alignSelf ?? 'center',
        justifySelf: props.justifySelf ?? 'center'
      }}
    ></div>
  );
}
