import { useMemo, useState } from 'react';

import {
  HttpError,
  LinearProgress,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import { Box, Button, Tooltip } from '@mui/material';

import { ContentItem } from 'resources/activities/models/ContentItem.model';
import { ResourceName } from 'shared/enums';
import { ContentUpdateAction } from 'shared/enums/content-enums';
import { useAxiosCall } from 'shared/hooks/useAxiosCall';

import { px } from 'shared/utils/styles/css-unit';

import { EditCommunityStatusDialog } from './EditBenchmarkOverrideStatusDialog.component';

export function EditCommunityButton(props: { label?: boolean }) {
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const record = useRecordContext<ContentItem>();
  const refresh = useRefresh();
  const notify = useNotify();

  const isCommunity = useMemo<boolean>(() => {
    return record.community_valid;
  }, [record?.community_valid]);

  const isDisabled = useMemo<boolean>(() => {
    return !record.has_benchmark;
  }, [record?.has_benchmark]);

  const tooltip = useMemo<string>(() => {
    if (isDisabled) {
      return `Benchmark is disabled`;
    }
    return `Update community status`;
  }, [isDisabled]);

  const fetchOverride = useAxiosCall<boolean>({
    resource: `${ResourceName.BASIC_CONTENT}/${record.id}`,
    autoFetch: false,
    init: { method: 'PATCH' }
  });

  const handleConfirm = (benchmark_override: boolean) => {
    fetchOverride
      .fetch({ benchmark_override, action: ContentUpdateAction.Benchmark })
      .then(() => handleSuccess())
      .catch((err) => handleFail(err));
  };

  const handleSuccess = () => {
    setShouldShowConfirm(false);
    refresh();
    notify(`Override updated successfully`, { type: 'success' });
  };

  const handleFail = (e: HttpError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  if (record == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='left'
        title={tooltip}
        describeChild
        enterDelay={1000}
      >
        <div>
          <Button
            disabled={isDisabled}
            onClick={handleClick}
            sx={(theme) => ({
              px: px(5),
              py: px(4),
              color: theme.palette.primary.main,
              lineHeight: 1.5
            })}
          >
            <EmojiEventsIcon
              fontSize='small'
              color={isCommunity ? undefined : 'disabled'}
            />
            {props.label != null ? (
              <Box component='span' sx={{ pl: px(6) }}>
                {'New Tag'}
              </Box>
            ) : (
              ''
            )}
          </Button>
        </div>
      </Tooltip>
      <EditCommunityStatusDialog
        onClose={handleAbort}
        onSend={handleConfirm}
        loading={fetchOverride.loading}
        isOpen={shouldShowConfirm}
        defaultValue={record?.community_valid}
        content={`Confirm to change ${record.type.toLowerCase()} benchmark community status`}
        title={`Force community calculation`}
        label={'Community status'}
      />
    </>
  );
}
