import { useCallback, useEffect, useState } from 'react';
import { Create, CreateProps, useCreate, useNotify } from 'react-admin';

import { CustomerCreatedPayload } from 'resources/customers/models/customer-mutation-payload.model';
import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';

import { CustomerType } from 'shared/utils/constants/blazepod';
import { v4 } from 'uuid';

import { CustomerForm } from './CustomerForm.component';

export interface CustomersCreateProps extends CreateProps {
  imperativeResource: string;
  handleSuccess(): void;
  onCancel: () => void;
  aliasDefaultValue?: string;
}

export function CustomersCreate(props: CustomersCreateProps) {
  const notify = useNotify();
  const [create, { loading }] = useCreate(props.imperativeResource);
  const [UID] = useState(v4());
  const [shouldShowUnsaved, setShouldShowUnsaved] = useState(false);

  const handleLoading = useCallback(() => {
    if (loading) {
      notify('Creating New Customer ...', { type: 'info' });
    }
  }, [loading, notify]);

  const onSave = useCallback(
    (values: Partial<CustomerCreatedPayload>) => {
      create(
        `${props.imperativeResource}/${UID}`,
        new CustomerCreatedPayload(
          values.email as string,
          values.type as CustomerType,
          values.first_name,
          values.last_name,
          values.alias
        ),
        {
          onFailure(error) {
            notify(error.message, 'error');
          },
          onSuccess() {
            notify('Customer created.', { type: 'success' });
            props.handleSuccess();
          }
        }
      );
    },
    [UID, create, notify, props]
  );
  const handleUnsavedConfirm = () => {
    props.onCancel();
    setShouldShowUnsaved(false);
  };
  const handleUnsavedClose = () => {
    setShouldShowUnsaved(false);
  };
  const handleCancel = () => {
    setShouldShowUnsaved(true);
  };

  useEffect(() => {
    handleLoading();
  }, [handleLoading]);
  return (
    <>
      <Create {...props} title='Offline Customers'>
        <CustomerForm
          onSave={onSave}
          loading={loading}
          onCancel={handleCancel}
          defaultValues={{ alias: props.aliasDefaultValue }}
        />
      </Create>
      <ConfirmUnsaved
        isOpen={shouldShowUnsaved}
        onClose={handleUnsavedClose}
        onConfirm={handleUnsavedConfirm}
      />
    </>
  );
}
