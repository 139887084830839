import { useMemo } from 'react';
import {
  Datagrid,
  ReferenceManyField,
  RichTextField,
  useRecordContext
} from 'react-admin';

import { Theme, useMediaQuery } from '@mui/material';
import { Batch } from 'resources/code_batches/batch.model';
import { CodesResponsiveTable } from 'resources/codes/components/CodesResponsiveList.component';
import { CodesSortableFieldsEnum } from 'resources/codes/enums/codes-sortable-fields.enum';
import { HasCustomerId } from 'resources/customers/interfaces/has-customer-id';
import { Customer } from 'resources/customers/models/Customer.model';
import { UserFunctionField } from 'resources/users/components/user-fields';
import { Pagination } from 'shared/components/pagination/Pagination.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { RaListOrder } from 'shared/services/backoffice-data-provider';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';

import { formatStringToDate } from 'shared/utils/dates/dates-helper';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';

import {
  BatchesFunctionField,
  CodeBatchesNumberField,
  CodeBatchesReferenceField
} from './batches-fields';
import { EditBatchButton } from './EditBatchButton.component';
import { ExportCodesButton } from '../codes/components/ExportCodesButton.component';

type BatchesListProps = HasCustomerId;

export function BatchesList({ customer_id }: BatchesListProps) {
  const { permissions } = useAppPermissions();

  const record = useRecordContext<Customer>();

  const isScreenSm = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('sm')
  );

  return (
    <ReferenceManyField
      target='created'
      reference={ResourceName.BATCHES}
      filter={{ customer_id }}
    >
      <Datagrid expand={<BatchCodes />}>
        <BatchesFunctionField
          sortable={false}
          source='created'
          render={(batch) => (batch ? formatStringToDate(batch.created) : '-')}
        />
        {isScreenSm && (
          <CodeBatchesNumberField
            emptyText='0'
            source={`number_of_codes`}
          ></CodeBatchesNumberField>
        )}
        {isScreenSm && (
          <CodeBatchesReferenceField
            sortable={false}
            emptyText='-'
            source='creator_id'
            reference={ResourceName.USERS}
            link='show'
          >
            <UserFunctionField render={renderFullName} />
          </CodeBatchesReferenceField>
        )}
        {isScreenSm && <RichTextField source='description' />}
        {isPublisher(permissions?.entities) && (
          <EditBatchButton
            customer_origin={record.origin}
            label={isScreenSm ? 'Edit' : undefined}
          />
        )}
      </Datagrid>
    </ReferenceManyField>
  );
}

function BatchCodes(props: { record?: Batch; resource?: string }) {
  const batchCodesDetail = useMemo(() => {
    return (
      <>
        <ExportCodesButton created={props.record?.created} />
        <CodesResponsiveTable />
      </>
    );
  }, [props.record?.created]);
  return (
    <ReferenceManyField
      pagination={<Pagination />}
      perPage={10}
      reference={ResourceName.CODES}
      target='creation_date'
      sort={{
        field: CodesSortableFieldsEnum.code,
        order: RaListOrder.ASC
      }}
      filter={{ batch_id: props.record?.id }}
    >
      {batchCodesDetail}
    </ReferenceManyField>
  );
}
