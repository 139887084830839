import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';

import { SectionName } from './section-name.enum';
export const SectionIcons: Record<SectionName, JSX.Element> = {
  [SectionName.Entities]: <PersonIcon />,
  [SectionName.Config]: <SettingsIcon />,
  [SectionName.Communication]: <NotificationsActiveIcon />,
  [SectionName.Content]: <CollectionsBookmarkIcon />,
  [SectionName.Monetization]: <MonetizationOnIcon />
};
