import { useState } from 'react';

import {
  HttpError,
  LinearProgress,
  useDelete,
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext
} from 'react-admin';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Tooltip, useTheme } from '@mui/material';

import { px } from 'shared/utils/styles/css-unit';

import {
  ConfirmDialog,
  DialogButtonConfig
} from '../dialogs/BaseConfirmDialog.component';

export interface BaseButtonProps {
  label?: string | boolean;
  disabled?: boolean;
  successMessage?: string;
  tooltip: string;
  messageContent: string;
  messageTitle: string;
}

export function BaseDeleteButton(props: BaseButtonProps) {
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const theme = useTheme();
  const [doDelete, deleteStat] = useDelete();
  const resource = useResourceContext();

  const handleCancelSuccess = () => {
    setShouldShowConfirm(false);
    refresh();
    notify(props.successMessage ?? 'Deleted successfully', { type: 'success' });
  };

  const handleCancelFail = (e: HttpError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  const handleConfirm = () => {
    doDelete(resource, record.id, undefined, {
      onFailure: handleCancelFail,
      onSuccess: handleCancelSuccess
    });
  };

  if (record == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='left'
        title={props.tooltip}
        describeChild
        disableHoverListener={props.disabled}
        enterDelay={1000}
      >
        <div>
          <Button
            disabled={deleteStat.loading || props.disabled}
            onClick={handleClick}
            sx={(theme) => ({
              px: px(5),
              py: px(4),
              color: theme.palette.warning.main,
              lineHeight: 1.5
            })}
          >
            <DeleteIcon fontSize='small' />
            {props.label != null ? (
              <Box component='span' sx={{ pl: px(6) }}>
                {props.label === false ? '' : props.label ?? 'Delete'}
              </Box>
            ) : (
              ''
            )}
          </Button>
        </div>
      </Tooltip>
      <ConfirmDialog
        onClose={handleAbort}
        onConfirm={handleConfirm}
        loading={deleteStat.loading}
        isOpen={shouldShowConfirm}
        confirmButton={
          new DialogButtonConfig('Delete', theme.palette.warning.main)
        }
        closeButton={new DialogButtonConfig('Abort', theme.palette.grey[500])}
        content={props.messageContent}
        title={props.messageTitle}
      />
    </>
  );
}
