import {
  Record,
  FunctionField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
  ReferenceField,
  DateField,
  ArrayField,
  Datagrid,
  ImageField,
  RichTextField
} from 'react-admin';

import { Box, Typography } from '@mui/material';

import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { ResourceName } from 'shared/enums';
import { SectionName } from 'shared/enums/section-name.enum';
import { vh, rem } from 'shared/utils/styles/css-unit';

import { Campaign } from '../models/campaign.model';

export function CampaignShow(props: ShowProps) {
  return (
    <Show
      {...props}
      title='Campaigns'
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'create']}
          extraActions={[]}
          sectionName={SectionName.Monetization}
        />
      }
    >
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <CampaignTitle />
        </Typography>
        <TabbedShowLayout>
          <Tab label='Details'>
            <TextField source='id' label='Id' emptyText='-' />
            <TextField source='name' label='Name' emptyText='-' />
            <TextField source='description' label='Description' emptyText='-' />
            <ReferenceField
              reference={ResourceName.SEGMENTS}
              source='segment_id'
              emptyText='-'
              label='Segment'
            >
              <TextField source='name' />
            </ReferenceField>
          </Tab>
          <Tab label='Campaign'>
            <DateField
              source='start_date'
              locales='en-GB'
              label='Start date'
              emptyText='-'
            />
            <DateField
              source='end_date'
              locales='en-GB'
              label='End date'
              emptyText='-'
            />
            <FunctionField
              label='Status'
              render={(record?: Record) => getCampaignStatusString(record)}
              emptyText='-'
            />
            <FunctionField
              label='Popup appearance'
              render={(record?: Record) =>
                `Show ${record?.display_frequency?.display_amount} 
                times with gap of ${record?.display_frequency?.gap_days} days`
              }
              emptyText='-'
            />
          </Tab>
          <Tab label='Media'>
            <ArrayField source='media' label={''}>
              <Datagrid>
                <TextField source='type'></TextField>
                <ImageField source='url' label='Image' emptyText='-' />
                <RichTextField source='text'></RichTextField>
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label='Texts'>
            <TextField
              source='display_text.headline'
              label='Headline'
              emptyText='-'
            />
            <TextField
              source='display_text.price_action_text'
              label='Prices Action Text'
              emptyText='-'
            />
            <RichTextField
              source='display_text.text'
              label='Text'
              emptyText='-'
            ></RichTextField>
            <TextField source='display_text.button' label='CTA' emptyText='-' />
            <TextField
              source='display_text.below_button'
              label='Text below button'
              emptyText='-'
            />
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
}

export function getCampaignStatusString(record: Record | undefined): any {
  const today = new Date().valueOf();
  const start_date = record?.start_date
    ? new Date(record.start_date).valueOf()
    : null;
  const end_date = record?.end_date
    ? new Date(record.end_date).valueOf()
    : null;
  if (start_date && start_date > today) return 'Future campaign';
  if (end_date && end_date < today) return 'Expired campaign';
  return record?.is_active ? 'Active' : 'Not-Active';
}

export function CampaignTitle() {
  const context = useRecordContext<Campaign>();
  return <>{context.name}</>;
}
