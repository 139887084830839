import { FirebaseOptions } from 'firebase/app';

import { isNodeEnv } from 'shared/utils/helperFunctions/dotenv.utils';

import { FIREBASE } from '../../utils/constants';

export function getCurrentFireConfig(): FirebaseOptions {
  if (isNodeEnv('prod')) {
    return FIREBASE.CONFIG.production;
  }
  return FIREBASE.CONFIG.development;
}
