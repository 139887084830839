import { useMemo, useState } from 'react';
import {
  Button,
  DateInput,
  HttpError,
  required,
  SaveButton,
  SaveContext,
  SaveContextProvider,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
  Validator
} from 'react-admin';

import { Cancel, Edit as EditIcon } from '@mui/icons-material';
import { Button as MuiButton, Drawer, Tooltip } from '@mui/material';
import { Batch } from 'resources/code_batches/batch.model';
import { ContentPackagesFormGroup } from 'resources/codes/components/codes-form/ContentPackagesFormGroup.component';
import { PlanDurationFormGroup } from 'resources/codes/components/codes-form/PlanDurationFormGroup.component';
import { MembershipPlanProvider } from 'resources/customers/enums/MembershipPlanProvider.enum';
import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { ConfirmUpdate } from 'shared/components/dialogs/ConfirmUpdate.component';
import { HasOnCancel } from 'shared/components/interfaces/MutationComponentProps';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';

import { BatchEditPayloadDto } from './batch-edit-payload.dto';

export function EditBatchButton(props: {
  label?: string;
  customer_origin: string;
}) {
  const [shouldOpenEdit, setShouldOpenEdit] = useState<boolean>(false);

  const record = useRecordContext<Batch>();

  const notify = useNotify();
  const refresh = useRefresh();
  const saveContext = SaveContext;

  const [showPlanDuration, setShowPlanDuration] = useState<boolean>(
    record.id != null
  );
  const [showExpired, setShowExpired] = useState<boolean>(record.id != null);
  const [showContentPackage, setContentPackage] = useState<boolean>(
    record.id != null
  );

  const isBlazepod = useMemo(() => {
    return props?.customer_origin === MembershipPlanProvider.BlazePod;
  }, [props?.customer_origin]);

  const handleShowPlanDurationClick = useMemo(
    () => () => {
      setShowPlanDuration((value) => !value);
    },
    []
  );

  const handleShowExpirationClick = useMemo(
    () => () => {
      setShowExpired((value) => !value);
    },
    []
  );

  const handleShowContentPackageClick = useMemo(
    () => () => {
      setContentPackage((value) => !value);
    },
    []
  );

  const [update, updateState] = useUpdate();

  const {
    handleSave,
    handleCancelSubmit,
    handleConfirmSubmit,
    handleUnsavedCancel,
    handleUnsavedConfirm,
    shouldShowConfirmSubmit,
    shouldShowUnsaved,
    handleCancelEdit
  } = useMutationVM<BatchEditPayloadDto, Batch>({
    record,
    PayloadClass: BatchEditPayloadDto,
    mutator: update,
    onMutateSuccess: handleSuccess,
    onMutateFailure: handleFail,
    resource: ResourceName.BATCHES,
    onAbort() {
      setShouldOpenEdit(false);
    }
  });

  function handleClick() {
    setShouldOpenEdit(true);
  }
  function handleSubmit(values: BatchEditPayloadDto) {
    const payload = BatchEditPayloadDto.factory(values);
    if (showPlanDuration) payload.plan_duration = undefined;
    if (showExpired) payload.expired_date = undefined;
    if (showContentPackage) payload.packages = undefined;
    if (values.description === record.description) {
      payload.description = undefined;
    }
    handleSave(payload);
  }
  function handleFail(e: HttpError) {
    notify(e?.message ?? 'Something went wrong.', { type: 'error' });
  }
  function handleSuccess() {
    setShouldOpenEdit(false);
    notify('Batch changed successfully.', { type: 'success' });
    refresh();
  }

  const validator: Validator = useMemo(() => {
    if (showExpired) {
      return (value, values) => undefined;
    }
    return required();
  }, [showExpired]);
  return (
    <>
      <Tooltip title='Edit Description'>
        <div>
          <Button label={props.label} onClick={handleClick}>
            <EditIcon />
          </Button>
        </div>
      </Tooltip>
      <Drawer disableScrollLock={true} open={shouldOpenEdit}>
        <SaveContextProvider value={saveContext}>
          <SimpleForm
            save={handleSubmit}
            toolbar={<EditBatchToolbar onCancel={handleCancelEdit} />}
          >
            <TextInput source='description' />
            {isBlazepod && (
              <>
                <MuiButton
                  variant='contained'
                  onClick={handleShowPlanDurationClick}
                >
                  {showPlanDuration ? 'Edit plan duration' : 'Cancel edit'}
                </MuiButton>
                <br />
                {!showPlanDuration && <PlanDurationFormGroup />}
                <br />
                <MuiButton
                  variant='contained'
                  onClick={handleShowExpirationClick}
                >
                  {showExpired ? 'Edit expired date' : 'Cancel edit'}
                </MuiButton>
                <br />
                {!showExpired && (
                  <DateInput
                    initialValue={record?.init_data?.expired_date}
                    source={'expired_date'}
                    isRequired={false}
                    inputProps={{ min: new Date().toISOString().split('T')[0] }}
                    validate={validator}
                  />
                )}
                <br />
                <MuiButton
                  variant='contained'
                  onClick={handleShowContentPackageClick}
                >
                  {showContentPackage ? 'Edit content packages' : 'Cancel edit'}
                </MuiButton>
                {!showContentPackage && (
                  <ContentPackagesFormGroup></ContentPackagesFormGroup>
                )}
              </>
            )}
          </SimpleForm>
        </SaveContextProvider>
      </Drawer>
      <ConfirmUpdate
        onClose={handleCancelSubmit}
        onConfirm={handleConfirmSubmit}
        recordName='codes batch'
        isOpen={shouldShowConfirmSubmit}
        loading={updateState.loading}
      />
      <ConfirmUnsaved
        isOpen={shouldShowUnsaved}
        onClose={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
      />
    </>
  );
}
function EditBatchToolbar({ onCancel, ...props }: ToolbarProps & HasOnCancel) {
  return (
    <Toolbar {...props}>
      <SaveButton />
      <MuiButton
        onClick={onCancel}
        variant='contained'
        sx={{ pl: '1em', ml: '0.5em' }}
      >
        <Cancel fontSize='small' sx={{ mr: '0.5em' }} /> Cancel
      </MuiButton>
    </Toolbar>
  );
}
