import {
  ArrayInput,
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SimpleFormIterator
} from 'react-admin';

import { LabelWrapper } from 'shared/components/fields/LabelWrapper.component';
import { ResourceName } from 'shared/enums';

export function ContentPackagesFormGroup() {
  return (
    <>
      <LabelWrapper component='h4'>Content Packages</LabelWrapper>
      <br />
      <ArrayInput source='packages'>
        <SimpleFormIterator disableReordering>
          <ReferenceInput
            source={'package_id'}
            label={'Package Name'}
            reference={ResourceName.CONTENT_PACKAGES}
          >
            <AutocompleteInput optionText='name' resettable />
          </ReferenceInput>
          <DateInput
            inputProps={{ min: new Date().toISOString().split('T')[0] }}
            label={'Package expired at'}
            source={'expiration_date'}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
}
