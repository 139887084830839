import {
  CreateResult,
  DeleteManyResult,
  DeleteResult,
  GetListResult,
  GetManyReferenceResult,
  GetManyResult,
  GetOneResult,
  Record as RaRecord,
  UpdateManyResult,
  UpdateResult
} from 'react-admin';

import {
  BPApiListPayload,
  DtoConverter
} from 'shared/services/backoffice-data-provider';
import { v4 } from 'uuid';

import { UserPlanDto, UserPlan } from './models';

export class UserPlanDtoConverter implements DtoConverter {
  convertList<DomainObject extends RaRecord = RaRecord>(
    dto: BPApiListPayload
  ): GetListResult<DomainObject> {
    if (dto == null) {
      return { data: [], total: 0 };
    }
    if (Array.isArray(dto)) {
      const arrayPayload: DomainObject[] = dto.map(
        UserPlan.dtoConverter
      ) as unknown as DomainObject[];

      return {
        data: arrayPayload,
        total: dto.length
      };
    }
    const list = this._handleList<DomainObject>(dto);
    const { page } = dto;

    return { data: list, total: page?.total || list.length };
  }
  convertOne<DomainObject extends RaRecord = RaRecord>(
    dto: UserPlanDto
  ): GetOneResult<DomainObject> {
    return { data: UserPlan.dtoConverter(dto) as unknown as DomainObject };
  }

  convertMany<DomainObject extends RaRecord = RaRecord>(
    dto: BPApiListPayload
  ): GetManyResult<DomainObject> {
    let list: DomainObject[] = [];
    if (Array.isArray(dto)) {
      list = dto.map(UserPlan.dtoConverter) as unknown as DomainObject[];
    } else {
      list = this._handleList<DomainObject>(dto);
    }

    return { data: list };
  }
  convertManyReference<DomainObject extends RaRecord = RaRecord>(
    dto: BPApiListPayload
  ): GetManyReferenceResult<DomainObject> {
    if (dto == null) {
      return { data: [], total: 0 };
    }
    if (Array.isArray(dto)) {
      const arrayPayload: DomainObject[] = dto.map(
        UserPlan.dtoConverter
      ) as unknown as DomainObject[];

      return {
        data: arrayPayload,
        total: dto.length
      };
    }
    const list = this._handleList(dto) as DomainObject[];
    const { page } = dto as unknown as BPApiListPayload;
    return { data: list, total: page?.total || list.length };
  }
  convertUpdate<DomainObject extends RaRecord = RaRecord>(
    dto: UserPlanDto
  ): UpdateResult<DomainObject> {
    return { data: UserPlan.dtoConverter(dto) as unknown as DomainObject };
  }
  convertUpdateMany(dto: any): UpdateManyResult {
    if (Array.isArray(dto)) {
      return { data: dto };
    }
    const data = dto['ids'];
    if (data != null) {
      return { data };
    }
    return { data: dto };
  }
  convertCreate<DomainObject extends RaRecord = RaRecord>(
    dto: any
  ): CreateResult<DomainObject> {
    if (dto == null) {
      return { data: { success: true, id: v4() } as unknown as DomainObject };
    }
    return { ...dto, id: dto.id ?? v4() };
  }
  convertDelete<DomainObject extends RaRecord = RaRecord>(
    dto: any
  ): DeleteResult<DomainObject> {
    return { data: dto };
  }
  convertDeleteMany(dto: any): DeleteManyResult {
    return { data: dto };
  }

  private _handleList<DomainObject>(payload: {
    [key: string]: UserPlanDto[];
  }): DomainObject[] {
    return (Object.entries(payload)
      .filter(([key]) => key !== 'page')[0][1]
      .map(UserPlan.dtoConverter) ?? []) as Array<any>;
  }
}
