import { useState } from 'react';
import {
  Create,
  CreateProps,
  useCreate,
  useEditContext,
  useNotify
} from 'react-admin';

import { ConfirmCreate } from 'shared/components/dialogs/ConfirmCreate.component';

import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { UnchangedDialog } from 'shared/components/dialogs/UnchangedDialog.component';
import { ResourceName } from 'shared/enums';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';
import { v4 } from 'uuid';

import { CollectionForm } from '../components/CollectionForm.component';
import { CollectionMutationPayload } from '../models/collection-mutation-payload.model';
import { Collection } from '../models/Collection.model';

export function CollectionCreate(props: CreateProps) {
  return (
    <Create {...props} title='Collection'>
      <>
        <CollectionCreateForm />
      </>
    </Create>
  );
}
function CollectionCreateForm() {
  const notify = useNotify();
  const [UID] = useState(v4());
  const [create, updateState] = useCreate();
  const createContext = useEditContext<Collection>();

  const {
    shouldShowConfirmSubmit,
    shouldShowUnchanged,
    shouldShowUnsaved,
    goBack,
    handleSave,
    handleCancelEdit,
    handleCancelSubmit,
    handleConfirmSubmit,
    handleUnchangedContinue,
    handleUnchangedQuit,
    handleUnsavedCancel,
    handleUnsavedConfirm
  } = useMutationVM<CollectionMutationPayload, Collection>({
    PayloadClass: CollectionMutationPayload,
    record: createContext.record,
    resource: ResourceName.COLLECTIONS,
    mutator: (resource, id, data, prev, options) => {
      create(`${resource}/${UID}`, data, options);
    },
    onMutateFailure: handleUpdateFailure,
    onMutateSuccess: handleUpdateSuccess
  });
  function handleUpdateSuccess() {
    notify('Collection has created.', { type: 'success' });
    goBack();
  }
  function handleUpdateFailure(e: Error) {
    notify(
      e.message || 'Something went wrong. Please try again in a few seconds.',
      { type: 'error' }
    );
  }
  return (
    <>
      <CollectionForm
        onCancel={handleCancelEdit}
        onSave={handleSave}
        title={'Create collection'}
      />
      <ConfirmCreate
        loading={createContext.loading || updateState.loading}
        isOpen={shouldShowConfirmSubmit}
        onConfirm={handleConfirmSubmit}
        onClose={handleCancelSubmit}
        recordName=' an collection'
      />
      <ConfirmUnsaved
        loading={createContext.loading}
        isOpen={shouldShowUnsaved}
        onClose={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
      />
      <UnchangedDialog
        name='collection'
        isOpen={shouldShowUnchanged}
        onContinue={handleUnchangedContinue}
        onQuit={handleUnchangedQuit}
      />
    </>
  );
}
