import {
  RichTextField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { ContentTitle } from 'resources/activities/pages/ActivityShow.component';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { SectionName } from 'shared/enums/section-name.enum';
import { vh, rem } from 'shared/utils/styles/css-unit';

export function TestShow(props: ShowProps) {
  return (
    <Show
      {...props}
      title='Tests'
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'create', 'edit']}
          sectionName={SectionName.Content}
        />
      }
    >
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <ContentTitle />
        </Typography>
        <TabbedShowLayout>
          <Tab label='Details'>
            <TextField source='id' label='Id' emptyText='-' />
            <TextField source='display_name' label='Name' emptyText='-' />
            <TextField source='engine' label='Engine' emptyText='-' />
            <RichTextField
              source='description'
              label='Description'
              emptyText='-'
            />
            <TextField
              source='engine_variation'
              label='Test Engine'
              emptyText='-'
            />
            <TextField source='share_link' label='Share link' emptyText='-' />
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
}
