import { Privilege } from 'shared/services/firebase-auth-provider/privilege.enum';

export class AdminsMutationPayload {
  content?: Privilege | null;
  entities?: Privilege | null;
  communication?: Privilege | null;
  config?: Privilege | null;
  monetization?: Privilege | null;
  user_id?: string | null;

  constructor(
    content: Privilege | null = null,
    entities: Privilege | null = null,
    communication: Privilege | null = null,
    config: Privilege | null = null,
    monetization: Privilege | null = null,
    user_id: string | null = null
  ) {
    this.content = content;
    this.entities = entities;
    this.communication = communication;
    this.config = config;
    this.monetization = monetization;
    this.user_id = user_id;
  }

  static factory(admins: Partial<AdminsMutationPayload> = {}) {
    return new AdminsMutationPayload(
      admins.content,
      admins.entities,
      admins.communication,
      admins.config,
      admins.monetization,
      admins.user_id
    );
  }
}
