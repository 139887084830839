import { useMemo } from 'react';
import {
  Datagrid,
  FunctionField,
  Loading,
  Record,
  useListContext
} from 'react-admin';

import { FieldObject } from 'resources/types';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { HasOmitItems } from 'shared/interfaces/react-admin';
import { shortenString } from 'shared/utils/helperFunctions/shortenString';

import { Segment } from '../models/segment.model';

export const SegmentTable = (props: Partial<HasOmitItems<keyof Segment>>) => {
  const listContext = useListContext<Segment>();
  const fields = useMemo(() => {
    const allFields: Array<FieldObject<keyof Segment>> = [
      {
        source: 'name',
        element: <LinkableField source={'name'} label='Name' emptyText='-' />
      },
      {
        source: 'description',
        element: (
          <FunctionField
            label='Description'
            render={(record?: Record) =>
              shortenString(record?.description ?? '', 50)
            }
            emptyText='-'
          />
        )
      }
    ];

    const output: JSX.Element[] = [];

    for (const field of allFields) {
      if (props.omitItems ? !props.omitItems.includes(field.source) : true) {
        output.push(field.element);
      }
    }
    return output;
  }, [props.omitItems]);

  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <Datagrid>{fields}</Datagrid>
      )}
    </>
  );
};
