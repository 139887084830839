import { useCallback, useEffect, useMemo } from 'react';
import {
  Confirm,
  ConfirmProps,
  HttpError,
  useGetMany,
  useGetOne,
  useUpdate
} from 'react-admin';
import { useParams } from 'react-router-dom';

import { HasId } from 'resources/types';

import { AppUser } from 'resources/users/models/AppUser.model';
import { ResourceName } from 'shared/enums/resource-name.enum';

import { UserPlanEditDto, UserPlan } from '../models';

export interface CancelRenewalDialogProps extends Pick<ConfirmProps, 'isOpen'> {
  onSuccess(): void;
  onCancel(): void;
  onError(e: HttpError): void;
}

export function CancelRenewalDialog(props: CancelRenewalDialogProps) {
  const { onSuccess, onCancel, onError, ...confirmProps } = props;
  const params = useParams<HasId>();
  const getUserState = useGetOne<AppUser>(ResourceName.USERS, params.id, {
    enabled: !!params.id
  });
  const getPlansState = useGetMany(ResourceName.USERS_PLANS, [params.id], {
    enabled: !!params.id
  });

  const [update, updateState] = useUpdate();

  const loading = useMemo(
    () => getUserState.loading || getPlansState.loading || updateState.loading,
    [getUserState.loading, getPlansState.loading, updateState.loading]
  );
  const userPlan = useMemo<UserPlan | null>(() => {
    if (getPlansState.data == null || getPlansState.data.length === 0) {
      return null;
    }
    const planById = getPlansState.data.find((plan) => plan?.id === params.id);

    if (planById == null) {
      return null;
    }
    return planById as UserPlan;
  }, [getPlansState.data, params.id]);

  const handleSuccess = useMemo(
    () => () => {
      onSuccess();
    },
    [onSuccess]
  );
  const handleError = useMemo(
    () => (e: HttpError) => {
      onError(e);
    },
    [onError]
  );
  const handleConfirm = useMemo(
    () => () => {
      if (userPlan == null) {
        handleError(new HttpError('User has no plan', 404));
      }
      update(
        ResourceName.USERS_PLANS,
        params.id,
        new UserPlanEditDto({
          is_renew: false
        }),
        userPlan,
        {
          onSuccess() {
            handleSuccess();
          },
          onFailure(e) {
            handleError(e);
          }
        }
      );
    },
    [handleError, handleSuccess, params.id, update, userPlan]
  );
  const handleCancel = useMemo(
    () => () => {
      onCancel();
    },
    [onCancel]
  );

  const handleInit = useCallback(() => {
    const notFoundError = () =>
      handleError(new HttpError('User has no plan', 404));

    if (params.id == null) {
      handleError(new HttpError('No user is selected', 403));
      return;
    }
    if (loading) {
      return;
    }
    if (getUserState.data == null) {
      handleError(new HttpError('User not found', 404));
    }
    if (getPlansState.data == null || getPlansState.data.length === 0) {
      notFoundError();
      return;
    }
    if (userPlan == null) {
      notFoundError();
      return;
    }
  }, [
    params.id,
    loading,
    getPlansState.data,
    getUserState.data,
    userPlan,
    handleError
  ]);
  useEffect(() => {
    handleInit();
  }, [handleInit]);

  return (
    <Confirm
      {...confirmProps}
      content={`
      You are about to cancel
      ${getUserState.data?.first_name} ${getUserState.data?.last_name}'s plan renewal.
      `}
      title='Are you sure?'
      onConfirm={handleConfirm}
      onClose={handleCancel}
      loading={loading}
    />
  );
}
