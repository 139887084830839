import {
  Create,
  CreateProps,
  useCreate,
  useEditContext,
  useNotify
} from 'react-admin';

import { ConfirmCreate } from 'shared/components/dialogs/ConfirmCreate.component';

import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { UnchangedDialog } from 'shared/components/dialogs/UnchangedDialog.component';
import { ResourceName } from 'shared/enums';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';

import { LeaderboardForm } from '../components/LeaderboardForm.component';

import {
  LeaderboardCreatePayload,
  LeaderboardMutationPayload
} from '../models/leaderboard-mutation-payload.model';
import { LeaderboardModel } from '../models/Leaderboard.model';

export function LeaderboardCreate(props: CreateProps) {
  return (
    <Create {...props} title='Leaderboards'>
      <>
        <LeaderboardCreateForm />
      </>
    </Create>
  );
}
function LeaderboardCreateForm() {
  const notify = useNotify();
  const [create, createState] = useCreate();
  const createContext = useEditContext<LeaderboardModel>();

  const {
    shouldShowConfirmSubmit,
    shouldShowUnchanged,
    shouldShowUnsaved,
    goBack,
    handleSave,
    handleCancelEdit,
    handleCancelSubmit,
    handleConfirmSubmit,
    handleUnchangedContinue,
    handleUnchangedQuit,
    handleUnsavedCancel,
    handleUnsavedConfirm
  } = useMutationVM<LeaderboardMutationPayload, LeaderboardModel>({
    PayloadClass: LeaderboardMutationPayload,
    record: createContext.record,
    resource: ResourceName.LEADERBOARD,
    mutator: (resource, id, data, prev, options) => {
      create(resource, data, options);
    },
    onMutateFailure: handleUpdateFailure,
    onMutateSuccess: handleUpdateSuccess
  });
  function handleUpdateSuccess() {
    notify('Leaderboard created successfully.', { type: 'success' });
    goBack();
  }
  function handleUpdateFailure(e: Error) {
    notify(
      e.message || 'Something went wrong. Please try again in a few seconds.',
      { type: 'error' }
    );
  }
  return (
    <>
      <LeaderboardForm
        onCancel={handleCancelEdit}
        onSave={(values) =>
          handleSave(LeaderboardCreatePayload.factory(values))
        }
      />
      <ConfirmCreate
        loading={createContext.loading || createState.loading}
        isOpen={shouldShowConfirmSubmit}
        onConfirm={handleConfirmSubmit}
        onClose={handleCancelSubmit}
        recordName=' a Leaderboard'
      />
      <ConfirmUnsaved
        loading={createContext.loading}
        isOpen={shouldShowUnsaved}
        onClose={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
      />
      <UnchangedDialog
        name='Leaderboard'
        isOpen={shouldShowUnchanged}
        onContinue={handleUnchangedContinue}
        onQuit={handleUnchangedQuit}
      />
    </>
  );
}
