import { useMemo } from 'react';

import {
  BooleanField,
  Datagrid,
  DateField,
  Loading,
  Record,
  ReferenceField,
  TextField,
  useListContext
} from 'react-admin';

import { CustomerLinkField } from 'resources/customers/components/fields/CustomerLinkField.component';

import { FieldObject } from 'resources/types';
import { CurrentPlanField } from 'resources/users_plans/components';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { ResourceName } from 'shared/enums';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';
import { shortenString } from 'shared/utils/helperFunctions/shortenString';

import { v4 } from 'uuid';

import { UsersListOmittableFields } from '../enums/users-list-omittable-fields.enum';
import { AppUser } from '../models/AppUser.model';

interface UsersDatagridProps {
  omitFields?: Array<UsersListOmittableFields>;
}
export function UsersDatagrid(props: UsersDatagridProps) {
  const listContext = useListContext<AppUser>();
  const fields: FieldObject<UsersListOmittableFields>[] = useMemo(() => {
    const {
      current_plan,
      customer,
      display_name,
      email,
      plan_last_update,
      plan_provider,
      plan_is_renew,
      registered_version,
      is_legacy
    } = UsersListOmittableFields;

    return [
      {
        source: display_name,
        element: (
          <LinkableField
            source={display_name}
            label='Name'
            render={(record?: Record) =>
              shortenString(renderFullName(record), 24)
            }
            emptyText='-'
            key={v4()}
          />
        )
      },
      {
        source: email,
        element: (
          <TextField source={email} label='Email' emptyText='-' key={v4()} />
        )
      },
      {
        source: registered_version,
        element: (
          <TextField
            label='Registered Version'
            source={registered_version}
            emptyText='-'
            sortable={false}
            key={v4()}
          />
        )
      },
      {
        source: is_legacy,
        element: (
          <BooleanField
            label='In Legacy List'
            source={is_legacy}
            emptyText='-'
            sortable={false}
            key={v4()}
          />
        )
      },
      {
        source: plan_last_update,
        element: (
          <DateField
            locales='en-GB'
            label='Latest Plan Update'
            source={plan_last_update}
            emptyText='-'
            sortable={false}
            key={v4()}
          />
        )
      },
      {
        source: current_plan,
        element: (
          <ReferenceField
            key={v4()}
            label='Current Plan'
            source='id'
            reference={ResourceName.USERS_PLANS}
            sortable={false}
            link={false}
            emptyText='NONE'
          >
            <CurrentPlanField />
          </ReferenceField>
        )
      },
      {
        source: plan_provider,
        element: (
          <ReferenceField
            key={v4()}
            label='Plan Provider'
            reference={ResourceName.USERS_PLANS}
            source='id'
            emptyText='-'
            link={false}
            sortBy='membership_payment_method'
          >
            <TextField source={plan_provider} emptyText='-' />
          </ReferenceField>
        )
      },
      {
        source: plan_is_renew,
        element: (
          <ReferenceField
            key={v4()}
            label='Plan Renews'
            reference={ResourceName.USERS_PLANS}
            source='id'
            emptyText='-'
            sortable={false}
            link={false}
          >
            <BooleanField source={plan_is_renew} emptyText='-' />
          </ReferenceField>
        )
      },
      {
        source: customer,
        element: (
          <ReferenceField
            key={v4()}
            label='Customer'
            reference={ResourceName.CUSTOMERS}
            source='customer_id'
            emptyText='-'
            sortable={false}
            link={false}
          >
            <CustomerLinkField label='Customer' />
          </ReferenceField>
        )
      }
    ];
  }, []);
  const fieldsToShow = useMemo(() => {
    if (!props.omitFields) {
      return fields;
    }
    const omitFieldsMap = Object.fromEntries(
      props.omitFields.map((field) => [field, true])
    );
    return fields.filter((field) => !omitFieldsMap[field.source]);
  }, [fields, props.omitFields]);
  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <Datagrid optimized>
          {fieldsToShow.map((field) => field.element)}
        </Datagrid>
      )}
    </>
  );
}
