export class MembershipDisplayPayload {
  id: string | undefined;
  button: string | undefined;
  headline: string | undefined;
  plans_title: string | undefined;
  benefits_text: string[];
  payload: any;
  name: string;

  constructor(
    id: string | undefined,
    button: string | undefined,
    headline: string | undefined,
    plans_title: string | undefined,
    benefits_text: Record<'benefit_text', string>[] = [],
    name = ''
  ) {
    this.id = id;
    this.button = button;
    this.headline = headline;
    this.plans_title = plans_title;
    this.benefits_text = benefits_text.map((element) => element.benefit_text);
    this.name = name;
  }

  static factory(membershipDisplay: Partial<MembershipDisplayPayload> = {}) {
    return new MembershipDisplayPayload(
      membershipDisplay.id,
      membershipDisplay.payload.button,
      membershipDisplay.payload.headline,
      membershipDisplay.payload.plans_title,
      membershipDisplay.payload.benefits_text,
      membershipDisplay.name
    );
  }
}
