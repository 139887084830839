import { useMemo, useState } from 'react';
import {
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate
} from 'react-admin';

import { Button, CircularProgress } from '@mui/material';

import { ConfirmUpdate } from 'shared/components/dialogs/ConfirmUpdate.component';
import {
  HasOnError,
  HasOnSuccess
} from 'shared/components/interfaces/MutationComponentProps';
import { ResourceName } from 'shared/enums';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';

import { AppUser } from '../models/AppUser.model';
import { UserMutationPayload } from '../models/user-mutation-payload.dto';

export function LegacyButton(props: Partial<HasOnError & HasOnSuccess>) {
  const record = useRecordContext<AppUser>();
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();

  const [update, updateState] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();
  const handleMutateSuccess = useMemo(
    () => () => {
      notify(
        `User ${
          record?.is_legacy ? 'removed from' : 'added to'
        } 'Legacy List'.`,
        { type: 'success' }
      );
      props.onSuccess != null && props.onSuccess();
      refresh();
      setShouldShowConfirm(false);
    },
    [notify, props, record?.is_legacy, refresh]
  );
  const handleMutateFailure = useMemo(
    () => (e?: Error) => {
      notify(e?.message ?? 'Something went wrong', { type: 'error' });
      props.onError != null && props.onError();
      refresh();
      setShouldShowConfirm(false);
    },
    [notify, props, refresh]
  );

  const { handleSave, handleConfirmSubmit, handleCancelSubmit } = useMutationVM<
    UserMutationPayload,
    AppUser
  >({
    record: record,
    PayloadClass: UserMutationPayload,
    mutator: update,
    resource: ResourceName.USERS,
    onMutateSuccess: handleMutateSuccess,
    onMutateFailure: handleMutateFailure
  });

  const buttonLabel = useMemo(() => {
    if (record == null) {
      return 'Loading User...';
    }
    return record.is_legacy ? 'Remove from list' : 'Add to list';
  }, [record]);

  const handleClick = () => {
    handleSave(new UserMutationPayload(!record.is_legacy));
    setShouldShowConfirm(true);
  };

  const isDisabled = useMemo(
    () => record == null || updateState.loading,
    [record, updateState.loading]
  );

  if (record == null) {
    return <CircularProgress size='sm' />;
  }
  return (
    <>
      <Button onClick={handleClick} disabled={isDisabled}>
        {buttonLabel}
      </Button>
      <ConfirmUpdate
        onClose={handleCancelSubmit}
        onConfirm={handleConfirmSubmit}
        isOpen={shouldShowConfirm}
        recordName='User'
      />
    </>
  );
}
