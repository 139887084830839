import {
  DateField,
  FunctionField,
  ImageField,
  NumberField,
  ReferenceField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import UrlFieldNewWindow from 'shared/components/fields/UrlFieldNewWindow.component';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { ResourceName } from 'shared/enums';
import { SectionName } from 'shared/enums/section-name.enum';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';
import { vh, rem } from 'shared/utils/styles/css-unit';

import { LeaderboardModel } from '../models/Leaderboard.model';

export function LeaderboardShow(props: ShowProps) {
  return (
    <Show
      {...props}
      title='Leaderboards'
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'create']}
          sectionName={SectionName.Content}
        />
      }
    >
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <LeaderboardTitle />
        </Typography>
        <TabbedShowLayout>
          <Tab label='Details'>
            <UrlFieldNewWindow
              source='leaderboard_url'
              label='Leaderboard Link'
              emptyText='-'
            />
            <TextField source='title' label='Title' emptyText='-' />
            <TextField source='subtitle' label='Subtitle' emptyText='-' />
            <TextField source='activity_id' label='Activity ID' emptyText='-' />
            <NumberField source='number_of_items' label='Size' emptyText='-' />
            <DateField source='start_date' label='Start Date' emptyText='-' />
            <DateField source='end_date' label='End Date' emptyText='-' />
            <ImageField source='right_logo' title='Right Logo' emptyText='-' />
          </Tab>
          <Tab label='User Data'>
            <TextField source='user_id' label='App user id' emptyText='-' />
            <ReferenceField
              reference={ResourceName.USERS}
              label='User email'
              source='user_id'
              link={'show'}
              sortable={false}
            >
              <TextField source='email' />
            </ReferenceField>
            <ReferenceField
              reference={ResourceName.USERS}
              label='Full Name'
              source='user_id'
              link={false}
              sortable={false}
            >
              <FunctionField render={renderFullName} />
            </ReferenceField>
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
}

export function LeaderboardTitle() {
  const leaderboard = useRecordContext<LeaderboardModel>();
  return <>{leaderboard.id ?? 'Create Leaderboard'}</>;
}
