import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

interface UnchangedDialogProps {
  isOpen: boolean;
  onContinue(): void;
  onQuit(): void;
  name: string;
  itemPageName?: string;
}
export function UnchangedDialog(props: UnchangedDialogProps) {
  return (
    <Dialog disableScrollLock={true} open={props.isOpen}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        No Changes Detected
      </DialogTitle>
      <DialogContent>
        <DialogContentText textAlign='center'>
          What shall we do?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonGroup
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(2, 1fr)`,
            px: 'auto'
          }}
        >
          <Button
            variant='contained'
            sx={(theme) => ({ background: theme.palette.primary.main })}
            onClick={props.onContinue}
          >
            Continue Editing
          </Button>
          <Button
            variant='contained'
            sx={(theme) => ({
              'background': theme.palette.grey[400],
              '&:hover': {
                background: theme.palette.grey[500]
              }
            })}
            onClick={props.onQuit}
          >
            Quit
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
