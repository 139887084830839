import { useState } from 'react';
import {
  Create,
  CreateProps,
  useCreate,
  useEditContext,
  useNotify
} from 'react-admin';

import { ConfirmCreate } from 'shared/components/dialogs/ConfirmCreate.component';

import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { UnchangedDialog } from 'shared/components/dialogs/UnchangedDialog.component';
import { ResourceName } from 'shared/enums';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';

import { v4 } from 'uuid';

import { ReferralsForm } from '../components/ReferralsForm.component';
import { Referral } from '../models/Referral.model';
import { ReferralsMutationPayload } from '../models/referrals-mutation-payload.model';

export function ReferralCreate(props: CreateProps) {
  return (
    <Create {...props} title='Referral'>
      <>
        <ReferralCreateForm />
      </>
    </Create>
  );
}
function ReferralCreateForm() {
  const notify = useNotify();
  const [UID] = useState(v4());
  const [create, updateState] = useCreate();
  const createContext = useEditContext<Referral>();

  const {
    shouldShowConfirmSubmit,
    shouldShowUnchanged,
    shouldShowUnsaved,
    goBack,
    handleSave,
    handleCancelEdit,
    handleCancelSubmit,
    handleConfirmSubmit,
    handleUnchangedContinue,
    handleUnchangedQuit,
    handleUnsavedCancel,
    handleUnsavedConfirm
  } = useMutationVM<ReferralsMutationPayload, Referral>({
    PayloadClass: ReferralsMutationPayload,
    record: createContext.record,
    resource: ResourceName.REFERRALS,
    mutator: (resource, id, data, prev, options) => {
      create(`${resource}/${UID}`, data, options);
    },
    onMutateFailure: handleUpdateFailure,
    onMutateSuccess: handleUpdateSuccess
  });
  function handleUpdateSuccess() {
    notify('Referral created successfully.', { type: 'success' });
    goBack();
  }
  function handleUpdateFailure(e: Error) {
    notify(
      e.message || 'Something went wrong. Please try again in a few seconds.',
      { type: 'error' }
    );
  }
  return (
    <>
      <ReferralsForm
        onCancel={handleCancelEdit}
        onSave={handleSave}
        uid={UID}
        title={'New Referral'}
      />
      <ConfirmCreate
        loading={createContext.loading || updateState.loading}
        isOpen={shouldShowConfirmSubmit}
        onConfirm={handleConfirmSubmit}
        onClose={handleCancelSubmit}
        recordName=' a referral'
      />
      <ConfirmUnsaved
        loading={createContext.loading}
        isOpen={shouldShowUnsaved}
        onClose={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
      />
      <UnchangedDialog
        name='Referral'
        itemPageName='Customer'
        isOpen={shouldShowUnchanged}
        onContinue={handleUnchangedContinue}
        onQuit={handleUnchangedQuit}
      />
    </>
  );
}
