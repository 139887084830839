import { isNodeEnv } from 'shared/utils/helperFunctions/dotenv.utils';

export class LeaderboardModel {
  id: string;
  title: string;
  subtitle: string;
  user_id: string;
  activity_id: string;
  number_of_items: number;
  start_date: string;
  end_date: string;
  right_logo: string;
  leaderboard_url: string;

  constructor(
    id: string,
    title: string,
    subtitle: string,
    user_id: string,
    activity_id: string,
    number_of_items: number,
    start_date: string,
    end_date: string,
    right_logo: string
  ) {
    this.id = id;
    this.title = title;
    this.subtitle = subtitle;
    this.user_id = user_id;
    this.activity_id = activity_id;
    this.number_of_items = number_of_items;
    this.start_date = start_date;
    this.end_date = end_date;
    this.right_logo = right_logo;
    this.leaderboard_url = `${
      isNodeEnv('prod')
        ? 'https://leaderboards.blazepod.live'
        : 'https://leaderboards.blazepod.dev'
    }/${id}`;
  }

  static fromDto(leaderboard: any) {
    return new LeaderboardModel(
      leaderboard.label,
      leaderboard.title,
      leaderboard.subtitle,
      leaderboard.user_id,
      leaderboard.activity_id,
      leaderboard.number_of_items,
      leaderboard.start_date,
      leaderboard.end_date,
      leaderboard.rightLogo
    );
  }
}
