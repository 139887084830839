import {
  BooleanField,
  BooleanFieldProps,
  DateField,
  DateFieldProps,
  FieldProps,
  FunctionField,
  FunctionFieldProps,
  NumberField,
  NumberFieldProps,
  TextField,
  TextFieldProps
} from 'react-admin';

import { TypedField } from 'shared/interfaces/react-admin/typed-field';

import { AppUser } from '../models/AppUser.model';

type AppUserSource = keyof AppUser;
type UserFieldProps<T extends FieldProps> = T & { source: AppUserSource };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AppUserRender = (record?: AppUser, source?: AppUserSource) => any; // typed version of render in FunctionFieldProps
export type AppUserField<P extends FieldProps> = TypedField<
  P & { source: AppUserSource }
>;

export const UserFunctionField = FunctionField as TypedField<
  FunctionFieldProps & { render: AppUserRender; source?: AppUserSource }
>;
export const UserTextField = TextField as AppUserField<TextFieldProps>;
export const UserNumberField = NumberField as AppUserField<NumberFieldProps>;
export const UserDateField = DateField as AppUserField<DateFieldProps>;

export type UserBooleanFieldProps = UserFieldProps<BooleanFieldProps>;
export const UserBooleanField =
  BooleanField as TypedField<UserBooleanFieldProps>;
