import {
  BackOfficeDataProvider,
  BackOfficeDtoConverter,
  DataProviderMethod,
  defaultUrlRenderer,
  DtoConverter,
  UrlArray
} from 'shared/services/backoffice-data-provider';

export class UsersDataProvider extends BackOfficeDataProvider {
  protected readonly updateMethod: 'put' | 'patch' = 'patch';
  protected dtoConverter: DtoConverter = new BackOfficeDtoConverter();
  protected urlRenderer(
    url: string | UrlArray,
    method?: DataProviderMethod | undefined
  ): string {
    return defaultUrlRenderer(url, method);
  }
}
