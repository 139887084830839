import { ElementType } from 'react';

import { Theme, Typography, TypographyProps } from '@mui/material';

export interface WrapperWithLabelProps extends TypographyProps {
  component?: ElementType;
}

export function LabelWrapper(props: WrapperWithLabelProps) {
  const {
    component = 'small',
    variant = 'body1',
    color = (theme: Theme) => theme.palette.grey[600],
    ...rest
  } = props;
  return (
    <Typography
      {...rest}
      variant={variant}
      component={component}
      color={color}
    />
  );
}
