import { GetOneResult, Record as RaRecord } from 'react-admin';

import { uniqueId } from 'lodash';

import { HasId } from 'resources/types';

import { BackOfficeDtoConverter } from 'shared/services/backoffice-data-provider';

import { MembershipCode } from './models/MembershipCode.model';

export class CodesDtoConverter extends BackOfficeDtoConverter {
  override convertCreate<DomainObject extends RaRecord = RaRecord>(
    dto: HasId
  ): GetOneResult<DomainObject> {
    return { data: { id: uniqueId(), codes: dto as DomainObject } as any };
  }
  override convert(dto: any) {
    return MembershipCode.fromDto(dto) as any;
  }
}
