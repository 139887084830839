import TimerIcon from '@mui/icons-material/Timer';
import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { TestShow } from './pages/TestShow.component';
import { TestsList } from './pages/TestsList.component';

export const TestsModule = RaModule.fromResourceProps({
  name: ResourceName.TESTS,
  options: { label: 'Tests' },
  list: TestsList,
  show: TestShow,
  intent: ResourceIntent.ROUTE,
  icon: TimerIcon
});
