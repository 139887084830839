import { ListProps, SelectInput } from 'react-admin';

import { CustomersSortableFieldsEnum } from 'resources/customers/customers-sortable-fields.enum';
import { offlinePlanProviders } from 'resources/customers/enums/MembershipPlanProvider.enum';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { SectionName } from 'shared/enums/section-name.enum';
import { CustomerTypesEnum } from 'shared/utils/constants/blazepod';

import { CustomersList } from '../customers/components/CustomersList.component';

export function OfflineCustomersList(props: ListProps) {
  return (
    <CustomersList
      {...props}
      extendedfilter={[
        <SelectInput
          source='origin'
          optionText='name'
          choices={offlinePlanProviders()}
          resettable
        />
      ]}
      editable
      typesToSum={Object.values(CustomerTypesEnum).filter(
        (type) => type !== CustomerTypesEnum.INDIVIDUAL
      )}
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'edit', 'list']}
          sectionName={SectionName.Entities}
        />
      }
      sortBy={CustomersSortableFieldsEnum.alias}
      omitFields={['full_name']}
    />
  );
}
