import {
  BackOfficeDataProvider,
  defaultUrlRenderer
} from 'shared/services/backoffice-data-provider';

import { SegmentDtoConverter } from './SegmentDtoConverter';

export class SegmentDataProvider extends BackOfficeDataProvider {
  protected dtoConverter = new SegmentDtoConverter();
  protected urlRenderer = defaultUrlRenderer;
}
