import { useMemo, useState } from 'react';

import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  ReduxState
} from 'react-admin';
import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';

import { styled } from '@mui/material';

import { modules } from 'resources';
import { ResourceIntent } from 'shared/enums';
import { SectionIcons } from 'shared/enums/section-icons.const';
import { SectionName } from 'shared/enums/section-name.enum';

import { useAppPermissions } from 'shared/hooks/useAppPermissions';

import { isViewer } from 'shared/services/firebase-auth-provider/checkPermissions';

import { NavbarClassNames } from './enums';

import { SubMenu } from './SubMenu.component';
export const Navbar = styled(NostylesNavbar)(({ theme }) => ({
  [`& ${NavbarClassNames.root}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  [`& ${NavbarClassNames.open}`]: {
    width: 200
  },
  [`& ${NavbarClassNames.closed}`]: {
    width: 55
  }
}));

function NostylesNavbar(props: MenuProps) {
  const location = useLocation();
  const [openSubMenus, setOpenSubMenus] = useState(
    Object.fromEntries(
      Object.values(SectionName).map((sectionName) => [
        sectionName,
        location.pathname.includes(sectionName) ?? false
      ])
    )
  );
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);

  const { permissions } = useAppPermissions();

  const handleToggle = (menu: SectionName) => {
    setOpenSubMenus((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const getMenuLink = useMemo(
    () => (value: SectionName) => {
      const resources = [];
      for (const module of modules) {
        if (
          module.intent === ResourceIntent.ROUTE &&
          module.name.includes(value)
        ) {
          resources.push(
            <MenuItemLink
              to={{
                pathname: `/${module.name}`,
                state: { _scrollToTop: true }
              }}
              primaryText={module.options?.label ?? module.name}
              leftIcon={module.icon ? <module.icon /> : <></>}
              dense={true}
              key={module.name}
            />
          );
        }
      }

      return resources;
    },
    []
  );

  const menu = useMemo(() => {
    const subMenus = [];
    for (const [section, value] of Object.entries(SectionName)) {
      const links = getMenuLink(value);

      if (
        !isViewer(permissions ? permissions[value] : undefined) ||
        links.length === 0
      ) {
        continue;
      }

      subMenus.push(
        <SubMenu
          handleToggle={() => handleToggle(value)}
          isOpen={openSubMenus[value]}
          name={section}
          icon={SectionIcons[value]}
          dense={false}
          key={`${section}-${value}`}
        >
          {links}
        </SubMenu>
      );
    }
    return subMenus;
  }, [getMenuLink, permissions, openSubMenus]);

  return (
    <div
      className={`${NavbarClassNames.root} ${
        open ? NavbarClassNames.open : NavbarClassNames.closed
      }`}
    >
      <DashboardMenuItem />
      {menu}
    </div>
  );
}
