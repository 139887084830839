import { List, ListProps, SearchInput } from 'react-admin';

import { AppResponsive } from 'shared/components/common/AppResponsive.component';
import { RaListOrder } from 'shared/services/backoffice-data-provider';

import { AdminTable } from '../components/AdminTable.component';

export function AdminsList(props: ListProps) {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'user_id', order: RaListOrder.ASC }}
      filters={[<SearchInput source='search' alwaysOn />]}
      exporter={false}
    >
      <AppResponsive
        xs={<AdminTable omitItems={['entities', 'communication', 'config']} />}
        sm={<AdminTable omitItems={['communication', 'config']} />}
        md={<AdminTable omitItems={['config']} />}
        lg={<AdminTable />}
        xl={<AdminTable />}
      />
    </List>
  );
}
