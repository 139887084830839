import {
  Edit,
  EditProps,
  useEditContext,
  useNotify,
  useUpdate
} from 'react-admin';

import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { ConfirmUpdate } from 'shared/components/dialogs/ConfirmUpdate.component';
import { UnchangedDialog } from 'shared/components/dialogs/UnchangedDialog.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';

import {
  LeaderboardForm,
  LeaderboardFormSource
} from './LeaderboardForm.component';
import { LeaderboardMutationPayload } from '../models/leaderboard-mutation-payload.model';
import { LeaderboardModel } from '../models/Leaderboard.model';

export function LeaderboardEdit(props: EditProps) {
  return (
    <Edit {...props} title='Leaderboards'>
      <LeaderboardEditForm />
    </Edit>
  );
}

function LeaderboardEditForm() {
  const notify = useNotify();
  const [update, updateState] = useUpdate();
  const editContext = useEditContext<LeaderboardModel>();

  const {
    shouldShowConfirmSubmit,
    shouldShowUnchanged,
    shouldShowUnsaved,
    goBack,
    handleSave,
    handleCancelEdit,
    handleCancelSubmit,
    handleConfirmSubmit,
    handleUnchangedContinue,
    handleUnchangedQuit,
    handleUnsavedCancel,
    handleUnsavedConfirm
  } = useMutationVM<LeaderboardMutationPayload, LeaderboardModel>({
    PayloadClass: LeaderboardMutationPayload,
    record: editContext.record,
    resource: ResourceName.LEADERBOARD,
    mutator: update,
    onMutateFailure: handleUpdateFailure,
    onMutateSuccess: handleUpdateSuccess
  });
  function handleUpdateSuccess() {
    notify('Leaderboard updated successfully.', { type: 'success' });
    goBack();
  }
  function handleUpdateFailure(e: Error) {
    notify(
      e.message || 'Something went wrong. Please try again in a few seconds.',
      { type: 'error' }
    );
  }

  return (
    <>
      <LeaderboardForm
        onCancel={handleCancelEdit}
        onSave={(values) =>
          handleSave(LeaderboardMutationPayload.factory(values))
        }
        fieldsToOmit={[LeaderboardFormSource.id]}
      />
      <ConfirmUpdate
        loading={editContext.loading || updateState.loading}
        isOpen={shouldShowConfirmSubmit}
        onConfirm={handleConfirmSubmit}
        onClose={handleCancelSubmit}
        recordName='Leaderboard'
      />
      <ConfirmUnsaved
        loading={editContext.loading}
        isOpen={shouldShowUnsaved}
        onClose={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
      />
      <UnchangedDialog
        name='Leaderboard'
        itemPageName='Leaderboard'
        isOpen={shouldShowUnchanged}
        onContinue={handleUnchangedContinue}
        onQuit={handleUnchangedQuit}
      />
    </>
  );
}
