import { useCallback } from 'react';
import { useListContext } from 'react-admin';

import { Tab, Tabs } from '@mui/material';
import { getSummaryLabel } from 'shared/components/summary/getSummaryLabel';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useBPSummary } from 'shared/hooks/useBPSummary';
import { Stat } from 'shared/models/summary/Stat.model';

export function Summary(props: { summaryUrl: string }) {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;
  const summary = useBPSummary(ResourceName.CUSTOMERS, props.summaryUrl);
  const tabs: Stat[] = [
    ...summary.data,
    {
      id: 'all',
      display_name: 'All',
      count: summary.data?.reduce(
        (count, stat) => +(stat?.count ?? '0') + count,
        0
      ),
      filter_key: 'customer_state',
      filter_value: 'All'
    }
  ];

  const handleTabChange = useCallback(
    (value: string) => {
      setFilters &&
        setFilters(
          { ...filterValues, customer_state: value },
          displayedFilters,
          false // no debounce, we want the filter to fire immediately
        );
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <Tabs
      variant='fullWidth'
      centered
      value={filterValues.customer_state}
      indicatorColor='primary'
      onChange={(e, value) => handleTabChange(value)}
    >
      {tabs.map((choice) => (
        <Tab
          key={choice.id}
          label={getSummaryLabel(choice.display_name, choice.count)}
          value={choice.filter_value}
        />
      ))}
    </Tabs>
  );
}
