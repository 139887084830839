export class ReferralsMutationPayload {
  name: string | undefined;
  description: string | undefined;
  media: string | undefined;
  display_text: string | undefined;
  payload: string | undefined;

  constructor(
    name: string | undefined,
    description: string | undefined,
    display_text: string | undefined,
    media: string | undefined,
    payload: string | undefined
  ) {
    this.name = name;
    this.description = description;
    this.display_text = display_text;
    this.media = media;
    this.payload = payload;
  }

  static factory(referral: Partial<any> = {}) {
    return new ReferralsMutationPayload(
      referral.name,
      referral.description,
      referral.display_text,
      referral.media,
      referral.payload
    );
  }
}
