import { useMemo } from 'react';
import {
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useGetMany,
  useRecordContext
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { ResourceName } from 'shared/enums';
import { SectionName } from 'shared/enums/section-name.enum';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';
import { vh, rem } from 'shared/utils/styles/css-unit';

import { AppAdmin } from '../models/Admin.model';

export function AdminsShow(props: ShowProps) {
  return (
    <Show
      {...props}
      title='Admins'
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'create']}
          sectionName={SectionName.Config}
        />
      }
    >
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <AdminTitle />
        </Typography>
        <TabbedShowLayout>
          <Tab label='Admin info'>
            <TextField source='user_id' label='App user id' emptyText='-' />
            <ReferenceField
              reference={ResourceName.USERS}
              label='User email'
              source='user_id'
              link={'show'}
              sortable={false}
            >
              <TextField source='email' />
            </ReferenceField>
            <ReferenceField
              reference={ResourceName.USERS}
              label='Full Name'
              source='user_id'
              link={false}
              sortable={false}
            >
              <FunctionField render={renderFullName} />
            </ReferenceField>
          </Tab>
          <Tab label='Permissions'>
            <TextField
              source='communication'
              label='Communication'
              emptyText='-'
            />
            <TextField source='content' label='Content' emptyText='-' />
            <TextField source='entities' label='Entities' emptyText='-' />
            <TextField
              source='monetization'
              label='Monetization'
              emptyText='-'
            />
            <TextField source='config' label='Config' emptyText='-' />
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
}

export function AdminTitle() {
  const admin = useRecordContext<AppAdmin>();
  const users = useGetMany(ResourceName.USERS, [admin.user_id ?? ''], {
    enabled: !!admin.user_id
  });
  const label = useMemo(() => {
    const currentUser = users.data?.find((user) => user?.id === admin.user_id);
    return currentUser == null ? 'Create Admin' : renderFullName(currentUser);
  }, [admin.user_id, users.data]);

  return <>{label}</>;
}
