import {
  ArrayField,
  ChipField,
  Datagrid,
  FunctionField,
  ImageField,
  NumberField,
  ReferenceField,
  RichTextField,
  Show,
  ShowProps,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import UrlFieldNewWindow from 'shared/components/fields/UrlFieldNewWindow.component';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { ResourceName } from 'shared/enums';
import { SectionName } from 'shared/enums/section-name.enum';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';
import { vh, rem } from 'shared/utils/styles/css-unit';

import { ContentItem } from '../../activities/models/ContentItem.model';

export function UserActivityShow(props: ShowProps) {
  return (
    <Show
      {...props}
      title='Activities'
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'create', 'edit']}
          sectionName={SectionName.Content}
        />
      }
    >
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <ContentTitle />
        </Typography>
        <TabbedShowLayout>
          <Tab label='Details'>
            <ReferenceField
              reference={ResourceName.USERS}
              label='User Name'
              source='creator_id'
              link={false}
              sortable={false}
            >
              <FunctionField render={renderFullName} />
            </ReferenceField>
            <ReferenceField
              reference={ResourceName.USERS}
              label='User id'
              source='creator_id'
              link={false}
              sortable={false}
            >
              <TextField source='id' />
            </ReferenceField>
            <ReferenceField
              reference={ResourceName.USERS}
              label='User email'
              source='creator_id'
              link={'show'}
              sortable={false}
            >
              <TextField source='email' />
            </ReferenceField>
            <TextField source='id' label='Activity Id' emptyText='-' />
            <TextField
              source='display_name'
              label='Activity Name'
              emptyText='-'
            />
            <RichTextField
              source='description'
              label='Description'
              emptyText='-'
            />
            <NumberField source='plays' label='Times played' emptyText='-' />
            <TextField source='share_link' label='Share link' emptyText='-' />
          </Tab>
          <Tab label='Media'>
            <ImageField source='cover_box' label='Cover' emptyText='-' />
            <ArrayField source='media'>
              <Datagrid>
                <TextField source='type'></TextField>
                <UrlFieldNewWindow shortString={60} source='url' />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label='Settings'>
            <NumberField
              source='settings.fixed.pods'
              label='Pods'
              emptyText='-'
            />
            <NumberField
              source='settings.fixed.duration'
              label='Duration'
              emptyText='-'
            />
            <NumberField
              source='settings.fixed.players'
              label='Players'
              emptyText='-'
            />
          </Tab>
          <Tab label='Filters'>
            <ArrayField source='accessories'>
              <SingleFieldList>
                <ChipField source='value'></ChipField>
              </SingleFieldList>
            </ArrayField>
            <ArrayField source='goals'>
              <SingleFieldList>
                <ChipField source='value'></ChipField>
              </SingleFieldList>
            </ArrayField>
            <ArrayField source='fields'>
              <SingleFieldList>
                <ChipField source='value'></ChipField>
              </SingleFieldList>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
}

export function ContentTitle() {
  const content = useRecordContext<ContentItem>();
  return <>{content.display_name}</>;
}
