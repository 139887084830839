import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { LeaderboardEdit } from './components/LeaderboardEdit.component';

import { LeaderboardCreate } from './pages/LeaderboardCreate.component';

import { LeaderboardShow } from './pages/LeaderboardShow.component';

import { LeaderboardsList } from './pages/LeaderboardsList.component';

export const leaderboardsModule = RaModule.fromResourceProps({
  name: ResourceName.LEADERBOARD,
  options: { label: 'Leaderboards' },
  list: LeaderboardsList,
  show: LeaderboardShow,
  edit: LeaderboardEdit,
  create: LeaderboardCreate,
  intent: ResourceIntent.ROUTE,
  icon: LeaderboardIcon
});
