import { useMemo } from 'react';

import Divider from '@mui/material/Divider';

import { LabelWrapper } from '../fields/LabelWrapper.component';

export interface FormDividerProps {
  title?: string;
  removeDivider?: boolean;
}

export function FormDivider(props: FormDividerProps) {
  const divider = useMemo(() => <Divider sx={{ mb: 2.4 }} />, []);
  return (
    <>
      {props.removeDivider ?? divider}
      <LabelWrapper component='h4'>{props?.title}</LabelWrapper>
    </>
  );
}
