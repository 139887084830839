import {
  Button,
  ConfirmProps,
  email,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar
} from 'react-admin';

import SendIcon from '@mui/icons-material/Send';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

export interface DialogProps extends Omit<ConfirmProps, 'onConfirm'> {
  defaultValue: string;
  onSend(email: string): void;
}

export function ResendCodeFormDialog(props: DialogProps) {
  return (
    <Dialog disableScrollLock={true} open={props.isOpen ?? false}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
        <SimpleForm
          submitOnEnter={false}
          save={(values: any) => props.onSend(values.email)}
          toolbar={
            <Toolbar>
              <SaveButton label='Send' icon={<SendIcon fontSize='small' />} />
              <Button onClick={props.onClose} label='Abort'></Button>{' '}
            </Toolbar>
          }
        >
          <TextInput
            label='email'
            source='email'
            validate={email()}
            defaultValue={props.defaultValue}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
}
