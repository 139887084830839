import { useMemo, useState } from 'react';

import {
  HttpError,
  LinearProgress,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';

import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { Box, Button, Tooltip } from '@mui/material';

import { CodeStatus } from 'resources/codes/enums/code-status.enum';
import { MembershipCode } from 'resources/codes/models/MembershipCode.model';
import { ConfirmCancel } from 'shared/components/dialogs/ConfirmCancel.component';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';
import { px } from 'shared/utils/styles/css-unit';

import { useCancelCode } from '../hooks/useCancelCode';

export function CodeCancelButton(props: { label?: string | boolean }) {
  const { permissions } = useAppPermissions();
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const [shouldDisable, setShouldDisable] = useState<boolean>();
  const record = useRecordContext<MembershipCode>();
  const refresh = useRefresh();
  const notify = useNotify();

  const isPending = useMemo(() => {
    const pendingStatus = record?.status === CodeStatus.Pending;
    setShouldDisable(!pendingStatus);
    return pendingStatus;
  }, [record?.status]);

  const handleCancelSuccess = () => {
    setShouldShowConfirm(false);
    refresh();
    notify('Canceled Code', { type: 'success' });
  };

  const handleCancelFail = (e: HttpError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const { cancelCode, ...cancelState } = useCancelCode({
    onSuccess: handleCancelSuccess,
    onFailure: handleCancelFail
  });
  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  const handleConfirm = () => {
    cancelCode(record.id);
  };

  if (record == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='left'
        title={
          isPending
            ? 'Cancel membership-code.'
            : `You can cancel only pending code`
        }
        describeChild
        enterDelay={1000}
      >
        <div>
          {isPublisher(permissions?.entities) && (
            <Button
              disabled={shouldDisable}
              onClick={handleClick}
              sx={(theme) => ({
                px: px(5),
                py: px(4),
                color: theme.palette.warning.main,
                lineHeight: 1.5
              })}
            >
              <CancelSharpIcon fontSize='small' />
              {props.label != null ? (
                <Box component='span' sx={{ pl: px(6) }}>
                  {props.label === true ? 'Cancel Code' : props.label}
                </Box>
              ) : (
                ''
              )}
            </Button>
          )}
        </div>
      </Tooltip>
      <ConfirmCancel
        onClose={handleAbort}
        onConfirm={handleConfirm}
        loading={cancelState.loading}
        recordName='Code'
        isOpen={shouldShowConfirm}
      />
    </>
  );
}
