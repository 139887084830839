import {
  DateField,
  DateFieldProps,
  EmailField,
  EmailFieldProps,
  FieldProps,
  FunctionField,
  FunctionFieldProps,
  NumberField,
  NumberFieldProps,
  TextField,
  TextFieldProps
} from 'react-admin';

import { Customer } from 'resources/customers/models/Customer.model';
import { TypedField } from 'shared/interfaces/react-admin/typed-field';

import { HasCustomerSource } from '../../interfaces/HasCustomerSource';
import { CustomerSource } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CustomerRender = (record?: Customer, source?: CustomerSource) => any; // as type-safe as can be for an implementation for the render function of the FunctionField.

type CustomerField<P extends FieldProps> = TypedField<P & HasCustomerSource>;
export const CustomerTextField = TextField as CustomerField<TextFieldProps>;
export const CustomerNumberField =
  NumberField as CustomerField<NumberFieldProps>;

export const CustomerEmailField = EmailField as CustomerField<EmailFieldProps>;

export const CustomerDateField = DateField as CustomerField<DateFieldProps>;

export const CustomerFunctionField = FunctionField as CustomerField<
  FunctionFieldProps & { render: CustomerRender }
>;
