import { useCallback } from 'react';

import { useListContext } from 'react-admin';

import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { getSummaryLabel } from 'shared/components/summary/getSummaryLabel';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useBPSummary } from 'shared/hooks/useBPSummary';
import { Stat } from 'shared/models/summary/Stat.model';

import { CollectionTable } from './CollectionTable.component';
import { Collection } from '../models/Collection.model';

export function CollectionsTabbedDatagrid() {
  const listContext = useListContext<Collection>();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const summary = useBPSummary(ResourceName.COLLECTIONS);
  const tabs: Stat[] = [
    ...summary.data,
    new Stat(
      'all',
      'status',
      null,
      summary.data.reduce((prev, { count }) => +prev + Number(count ?? 0), 0),
      'All'
    )
  ];

  const handleTabChange = useCallback(
    (value: string) => {
      setFilters &&
        setFilters(
          { ...filterValues, status: value },
          displayedFilters,
          false // no debounce, we want the filter to fire immediately
        );
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <>
      {tabs && (
        <Tabs
          variant='fullWidth'
          centered
          value={filterValues.status ?? null}
          indicatorColor='primary'
          onChange={(e, value) => handleTabChange(value)}
        >
          {tabs.map((choice) => (
            <Tab
              key={choice.id}
              label={getSummaryLabel(choice.display_name, choice.count)}
              value={choice.filter_value}
            />
          ))}
        </Tabs>
      )}
      <Divider />
      <CollectionTable />
    </>
  );
}
