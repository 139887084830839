import AssessmentIcon from '@mui/icons-material/Assessment';
import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { UserActivitiesList } from './pages/UserActivitiesList.component';
import { UserActivityShow } from './pages/UserActivityShow.component';

export const UserActivitiesModule = RaModule.fromResourceProps({
  name: ResourceName.USER_ACTIVITIES,
  options: { label: 'Community content' },
  list: UserActivitiesList,
  show: UserActivityShow,
  intent: ResourceIntent.ROUTE,
  icon: AssessmentIcon
});
