import {
  BackOfficeDataProvider,
  defaultUrlRenderer
} from 'shared/services/backoffice-data-provider';

import { CodesDtoConverter } from './CodesDtoConverter';

export class CodesDataProvider extends BackOfficeDataProvider {
  protected dtoConverter = new CodesDtoConverter();
  protected urlRenderer = defaultUrlRenderer;
}
