import { useMemo } from 'react';
import { EditButton, useGetOne, useRecordContext } from 'react-admin';

import { Tooltip } from '@mui/material';
import { ContentItem } from 'resources/activities/models/ContentItem.model';
import { ResourceName } from 'shared/enums';
import { ContentVisibility } from 'shared/enums/content-enums';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';

export interface ContentItemEditButtonProps {
  label?: '';
  disable?: boolean;
  resource: ResourceName;
}

export function ContentItemEditButton(props: ContentItemEditButtonProps) {
  const record = useRecordContext<ContentItem>();

  const draft_id = useMemo(() => {
    return record?.my_draft;
  }, [record.my_draft]);

  const draftContent = useGetOne<ContentItem>(props.resource, draft_id ?? '');

  const { permissions } = useAppPermissions();

  const shouldDisable = useMemo(() => {
    return !isPublisher(permissions?.content);
  }, [permissions?.content]);

  const title = useMemo(() => {
    if (record?.visibility === ContentVisibility.PUBLIC) {
      if (record.my_draft == null) {
        return 'Create draft';
      }
      return 'Edit draft';
    } else {
      return `Edit ${record.type.toLocaleLowerCase()}`;
    }
  }, [record?.my_draft, record.type, record?.visibility]);

  return (
    <Tooltip
      arrow
      placement='left'
      title={title}
      describeChild
      disableHoverListener={props.disable}
      enterDelay={1000}
    >
      <div>
        <EditButton
          disabled={shouldDisable}
          label={props.label}
          record={draftContent?.data ?? record}
        />
      </div>
    </Tooltip>
  );
}
