import {
  BPMedia,
  ContentItem
} from 'resources/activities/models/ContentItem.model';
import {
  BPContentEngineEnum,
  ContentCreatorType,
  ContentType,
  ContentVisibility
} from 'shared/enums/content-enums';

export class TestContent extends ContentItem {
  goal: string;
  test_type: string;
  engine_variation: string;
  order: string;

  constructor(
    id: string,
    old_activity_key: string,
    type: ContentType,
    engine: BPContentEngineEnum,
    display_name: string,
    description: string,
    cover_big: string,
    cover_small: string,
    media: BPMedia[],
    tags: string[],
    creator_type: ContentCreatorType,
    creator_id: string,
    visibility: ContentVisibility,
    min_content_version: number,
    created_at: string,
    updated_at: string,
    published_at: string,
    new_until: string,
    origin_custom_key: string,
    cover_box: string,
    cover_rect: string,
    draft_of: string,
    replaced_by: string,
    random_order: number,
    share_link: string,
    my_draft: string,
    labels: Record<string, string>,
    is_popular: boolean,
    has_benchmark: boolean,
    community_valid: boolean,
    goal: string,
    test_type: string,
    engine_variation: string,
    order: string,
    consent = false,
    published = false,
    plays: string
  ) {
    super(
      id,
      old_activity_key,
      type,
      engine,
      display_name,
      description,
      cover_big,
      cover_small,
      media,
      tags,
      creator_type,
      creator_id,
      visibility,
      min_content_version,
      created_at,
      updated_at,
      published_at,
      new_until,
      origin_custom_key,
      cover_box,
      cover_rect,
      draft_of,
      replaced_by,
      random_order,
      share_link,
      my_draft,
      labels,
      is_popular,
      has_benchmark,
      community_valid,
      consent,
      published,
      plays
    );
    this.goal = goal;
    this.test_type = test_type;
    this.engine_variation = engine_variation;
    this.order = order;
  }

  static fromDto(content: any) {
    return new TestContent(
      content.id,
      content.old_activity_key,
      content.type,
      content.engine,
      content.display_name,
      content.description,
      content.cover_big,
      content.cover_small,
      content.media,
      content.tags,
      content.creator_type,
      content.creator_id,
      content.visibility,
      content.min_content_version,
      content.created_at,
      content.updated_at,
      content.published_at,
      content.new_until,
      content.origin_custom_key,
      content.cover_box,
      content.cover_rect,
      content.draft_of,
      content.replaced_by,
      content.random_order,
      content.share_link,
      content.my_draft,
      content.labels,
      content.is_popular,
      content.has_benchmark,
      content.community_valid,
      content.goal,
      content.test_type,
      content.engine_variation,
      content.order,
      content.consent,
      content.published,
      content.plays
    );
  }
}
