import { FieldProps, Record, useRecordContext } from 'react-admin';

import { get } from 'lodash';
import { MembershipCode } from 'resources/codes/models/MembershipCode.model';
import { FieldFiller } from 'shared/components/fields/FieldFiller.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import {
  CustomerType,
  CustomerTypesEnum
} from 'shared/utils/constants/blazepod';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';

/** @description this field will determine weather to reference the online/offline customers resource by checking its type.*/
export function CustomerLinkField(props: FieldProps) {
  const record = useRecordContext<MembershipCode>();

  const type = get(record, 'type') as CustomerType | undefined;

  if (!record || !type) {
    return <FieldFiller />;
  }
  return (
    <LinkableField
      page='show'
      viewResource={
        type === CustomerTypesEnum.INDIVIDUAL
          ? ResourceName.ONLINE_CUSTOMERS
          : ResourceName.OFFLINE_CUSTOMERS
      }
      render={(record?: Record) =>
        record && (record.alias ? record.alias : renderFullName(record))
      }
    />
  );
}
