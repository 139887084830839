export enum ResourceName {
  USERS = 'entities/users',
  USERS_PLANS = 'entities/users/plans',
  MEMBERSHIP_PLANS = 'entities/membership_plans',
  CODES = 'entities/codes',
  BATCHES = 'entities/batches',
  CUSTOMERS = 'entities/customers',
  ONLINE_CUSTOMERS = 'entities/online-customers',
  OFFLINE_CUSTOMERS = 'entities/offline-customers',
  ADMINS = 'config/admins',
  LOGS = 'logs',
  CONTENT_PACKAGES = 'content/packages',
  BASIC_CONTENT = 'content',
  COLLECTIONS = 'content/collections',
  ACTIVITIES = 'content/activities',
  USER_ACTIVITIES = 'content/user_activities',
  TESTS = 'content/tests',
  LEADERBOARD = 'content/leaderboard',
  MEMBERSHIP_DISPLAY = 'monetization/membership_display',
  PROMOTIONS = 'monetization/promotions',
  STORE_KEYS = 'monetization/store_keys',
  CAMPAIGNS = 'monetization/campaigns',
  REFERRALS = 'monetization/referrals',
  SEGMENTS = 'monetization/segments'
}
