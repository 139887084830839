import React, { useRef, useEffect, useCallback, ComponentProps } from 'react';

import {
  FormHelperText,
  FormControl,
  InputLabel,
  PropTypes as MuiPropTypes
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import Quill, { QuillOptionsStatic } from 'quill';
import { useInput, FieldTitle } from 'ra-core';
import { InputHelperText } from 'ra-ui-materialui';

import styles from './styles';

const useStyles = makeStyles(styles, { name: 'RaRichTextInput' });

export interface RichTextInputProps {
  label?: string | false;
  options?: QuillOptionsStatic;
  source: string;
  toolbar?:
    | boolean
    | string[]
    | Array<any>[]
    | string
    | {
        container: string | string[] | Array<any>[];
        handlers?: Record<string, Function>;
      };
  fullWidth?: boolean;
  configureQuill?: (instance: Quill) => void;
  helperText?: ComponentProps<typeof InputHelperText>['helperText'];
  record?: Record<any, any>;
  resource?: string;
  variant?: string;
  margin?: MuiPropTypes.Margin;
  [key: string]: any;
}

const OriginalRichTextInput = (props: RichTextInputProps) => {
  const {
    options = {}, // Quill editor options
    toolbar = true,
    fullWidth = true,
    classes: classesOverride,
    configureQuill,
    helperText,
    label,
    source,
    resource,
    variant,
    margin = 'dense',
    ...rest
  } = props;
  const classes = useStyles(props);
  const quillInstance = useRef<Quill>();
  const divRef = useRef<HTMLDivElement>(null);
  const editor = useRef<HTMLElement>();

  const {
    id,
    isRequired,
    input: { value, onChange },
    meta: { touched, error }
  } = useInput({ source, ...rest });

  const lastValueChange = useRef(value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTextChange = useCallback(
    debounce(() => {
      const value =
        editor.current?.innerHTML === '<p><br></p>'
          ? ''
          : editor.current?.innerHTML;

      if (lastValueChange.current !== value) {
        lastValueChange.current = value;
        onChange(value);
      }
    }, 500),
    [onChange]
  );
  const Break = Quill.import('blots/break');
  const Embed = Quill.import('blots/embed');

  class SmartBreak extends Break {
    length() {
      return 1;
    }
    value() {
      return '\n';
    }

    insertInto(parent: any, ref: any) {
      Embed.prototype.insertInto.call(this, parent, ref);
    }
  }

  SmartBreak.blotName = 'break';
  SmartBreak.tagName = 'BR';
  Quill.register(SmartBreak);

  useEffect(() => {
    if (!divRef.current) return;
    quillInstance.current = new Quill(divRef.current, {
      modules: {
        toolbar,
        clipboard: { matchVisual: false },
        keyboard: {
          bindings: {
            linebreak: {
              key: 13,
              shiftKey: true,
              handler: function (range: any) {
                const currentLeaf = quillInstance.current?.getLeaf(
                  range.index
                )[0];
                const nextLeaf = quillInstance.current?.getLeaf(
                  range.index + 1
                )[0];
                quillInstance.current?.insertEmbed(
                  range.index,
                  'break',
                  true,
                  'user'
                );
                // Insert a second break if:
                // At the end of the editor, OR next leaf has a different parent (<p>)
                if (
                  nextLeaf === null ||
                  currentLeaf.parent !== nextLeaf.parent
                ) {
                  quillInstance.current?.insertEmbed(
                    range.index,
                    'break',
                    true,
                    'user'
                  );
                }
                // Now that we've inserted a line break, move the cursor forward
                quillInstance.current?.setSelection(range.index + 1, 'silent');
              }
            }
          }
        }
      },
      theme: 'snow',
      ...options
    });

    if (configureQuill) {
      configureQuill(quillInstance?.current);
    }

    quillInstance.current.setContents(
      quillInstance.current.clipboard.convert(value)
    );

    editor.current =
      divRef.current?.querySelector<HTMLElement>('.ql-editor') ?? undefined;
    quillInstance.current.on('text-change', onTextChange);

    return () => {
      quillInstance.current?.off('text-change', onTextChange);
      if (onTextChange.cancel) {
        onTextChange.cancel();
      }
      quillInstance.current = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastValueChange.current !== value) {
      const selection = quillInstance.current?.getSelection();
      quillInstance.current?.setContents(
        quillInstance.current.clipboard.convert(value)
      );
      if (selection && quillInstance.current?.hasFocus()) {
        quillInstance.current.setSelection(selection);
      }
    }
  }, [value]);

  return (
    <FormControl
      error={!!(touched && error)}
      fullWidth={fullWidth}
      className='ra-rich-text-input'
      margin={margin}
    >
      <InputLabel shrink htmlFor={id} className={classes.label}>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </InputLabel>
      <div data-testid='quill' ref={divRef} className={variant} />
      <FormHelperText
        error={!!error}
        className={error ? 'ra-rich-text-input-error' : ''}
      >
        <InputHelperText
          error={error}
          helperText={helperText}
          touched={touched ?? false}
        />
      </FormHelperText>
    </FormControl>
  );
};

OriginalRichTextInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  source: PropTypes.string,
  fullWidth: PropTypes.bool,
  configureQuill: PropTypes.func
};

export default OriginalRichTextInput;
