import * as React from 'react';

import {
  Error as ErrorComponent,
  Loading,
  ReferenceField,
  Tab,
  TabbedShowLayout,
  useGetList,
  useGetOne,
  useShowContext
} from 'react-admin';

import { useParams } from 'react-router-dom';

import { Box, Stack } from '@mui/material';
import { CustomerTextField } from 'resources/customers/components/fields/customer-fields';
import { CustomerLinkField } from 'resources/customers/components/fields/CustomerLinkField.component';

import { HasId } from 'resources/types';
import { UserHistoryLog } from 'resources/users/models/UserHistoryLog.model';
import { UserPlanTextField } from 'resources/users_plans/components/users-plans-fields';
import { UserPlan } from 'resources/users_plans/models/user-plan.model';
import { Divider } from 'shared/components/common/Divider.component';
import { LabelWrapper } from 'shared/components/fields/LabelWrapper.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';

import { em } from 'shared/utils/styles/css-unit';

import { IsLegacyField } from './IsLegacyField.component';
import { PlanExpirationDate } from './PlanExpirationDate.component';
import { PlanIsRenew } from './PlanIsRenew.component';
import { UserCurrentPlan } from './UserCurrentPlan.component';
import { HasOnEditPlan } from '../../interfaces/HasOnEditPlan';
import { AppUser } from '../../models/AppUser.model';
import HistoryLogList from '../HistoryLogList.component';
import { ResetPasswordButton } from '../ResetPasswordButton.component';
import { ResetSeenItemsButton } from '../ResetSeenItemsButton.component';
import { SetPasswordButton } from '../SetPasswordButton.component';
import { UserDateField, UserTextField } from '../user-fields';

type UserCardProps = HasOnEditPlan;
export function UserTabbedView(props: UserCardProps) {
  const params = useParams<HasId>();
  const { loading, error, record } = useShowContext<AppUser>();
  const userPlan = useGetOne<UserPlan>(
    ResourceName.USERS_PLANS,
    record?.id ?? '',
    {
      enabled: !!record?.id
    }
  );
  const historyLog = useGetList<UserHistoryLog>(
    ResourceName.LOGS,
    undefined,
    undefined,
    { apiResource: `${ResourceName.USERS}/${params.id}/membership_history` }
  );
  const bpPermissions = useAppPermissions();

  const publisher = React.useMemo(() => {
    const { permissions } = bpPermissions;
    return isPublisher(permissions?.entities);
  }, [bpPermissions]);

  const historyData = React.useMemo(() => {
    const data: UserHistoryLog[] = [];
    for (const id of historyLog.ids) {
      const currentLog = historyLog.data[id];
      if (currentLog != null) {
        data.push(currentLog);
      }
    }
    return data;
  }, [historyLog.data, historyLog.ids]);

  const planTab = React.useMemo(() => {
    if (userPlan.data == null) {
      return publisher ? (
        <Box
          sx={(theme) => ({
            alignSelf: 'center',
            width: { sm: 136 },
            px: em(1.5),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          })}
        >
          <UserCurrentPlan
            onChangePlan={() => props.onEditPlan('membership_plan')}
            permission={publisher}
            userPlan={userPlan.data}
          />
        </Box>
      ) : (
        []
      );
    }

    return (
      <Tab label='Plan'>
        <Box>
          <LabelWrapper component='div' variant='caption'>
            Current Plan
          </LabelWrapper>
          <ReferenceField
            source='id'
            reference={ResourceName.USERS_PLANS}
            label='Current Plan'
            link={false}
          >
            <UserCurrentPlan
              onChangePlan={() => props.onEditPlan('membership_plan')}
              permission={publisher}
              userPlan={userPlan.data}
            />
          </ReferenceField>
        </Box>
        <ReferenceField
          source='id'
          reference={ResourceName.USERS_PLANS}
          label='Provider'
          link={false}
        >
          <UserPlanTextField source='provider' />
        </ReferenceField>
        <ReferenceField
          source='id'
          reference={ResourceName.USERS_PLANS}
          label='Status'
          link={false}
        >
          <UserPlanTextField source='plan_status' emptyText='-' />
        </ReferenceField>
        <ReferenceField
          source='id'
          reference={ResourceName.USERS_PLANS}
          label='Expiration Date'
          link={false}
        >
          <PlanExpirationDate
            permission={publisher}
            onEditExpireDate={() => props.onEditPlan('expire_date')}
          />
        </ReferenceField>
        <ReferenceField
          source='id'
          reference={ResourceName.USERS_PLANS}
          label='Plan Renews'
          link={false}
        >
          <PlanIsRenew
            onEditIsRenew={() => props.onEditPlan('is_renew')}
            permission={publisher}
          />
        </ReferenceField>
        <ReferenceField
          source='id'
          reference={ResourceName.USERS_PLANS}
          label='Origin'
          link={false}
        >
          <UserPlanTextField source='plan_origin' emptyText='-' />
        </ReferenceField>
        <UserDateField
          label='Last Update'
          source='plan_last_update'
          locales='en-GB'
        />
      </Tab>
    );
  }, [props, publisher, userPlan.data]);
  const customerTab = React.useMemo(() => {
    if (record == null || record.customer_id == null) {
      return [];
    }

    return (
      <Tab label='Customer'>
        <ReferenceField
          label='Name/Alias'
          source='customer_id'
          reference={ResourceName.CUSTOMERS}
          link={false}
        >
          <CustomerLinkField />
        </ReferenceField>
        <ReferenceField
          label='Email'
          source='customer_id'
          reference={ResourceName.CUSTOMERS}
          link={false}
        >
          <CustomerTextField source='email' />
        </ReferenceField>
        <ReferenceField
          label='Type'
          source='customer_id'
          reference={ResourceName.CUSTOMERS}
          link={false}
        >
          <CustomerTextField source='type' />
        </ReferenceField>
        <ReferenceField
          label='Provider'
          source='customer_id'
          reference={ResourceName.CUSTOMERS}
          link={false}
        >
          <CustomerTextField source='origin' />
        </ReferenceField>
      </Tab>
    );
  }, [record]);

  const historyTab = React.useMemo(() => {
    return (
      <Tab label='History Log'>
        <HistoryLogList history={historyData} loading={historyLog.loading} />
      </Tab>
    );
  }, [historyData, historyLog.loading]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorComponent error={error} />;
  }
  return (
    <TabbedShowLayout>
      <Tab label='Details'>
        <UserTextField emptyText='-' source='first_name' />
        <UserTextField emptyText='-' source='last_name' />
        <UserTextField emptyText='-' source='email' />
        <UserTextField emptyText='-' source='registered_version' />
        <LabelWrapper component='div' variant='caption'>
          In Legacy List
        </LabelWrapper>
        <IsLegacyField emptyText='-' source='is_legacy' />
        <UserTextField emptyText='-' label='User ID' source='id' />
      </Tab>
      {!Array.isArray(planTab) && <Divider vairant='diagonal' />}
      {planTab}
      <Divider vairant='diagonal' />
      {historyTab}
      {!Array.isArray(customerTab) && <Divider vairant='diagonal' />}
      {customerTab}
      <Divider vairant='diagonal' />
      {publisher && (
        <Tab label='Manage'>
          <br></br>
          <LabelWrapper variant='h6'>User authentication actions:</LabelWrapper>
          <br></br>
          <Stack sx={{ alignItems: 'center' }} direction='row' spacing={4}>
            <SetPasswordButton></SetPasswordButton>
            <Divider margin={{ left: em(2) }} vairant='diagonal' />
            <ResetPasswordButton></ResetPasswordButton>
          </Stack>
          <Divider vairant='horizontal' />
          <br></br>
          <LabelWrapper variant='h6'>User On-Boarding actions:</LabelWrapper>
          <br></br>
          <Stack sx={{ alignItems: 'center' }} direction='row' spacing={4}>
            <ResetSeenItemsButton
              property_name={'highlights'}
            ></ResetSeenItemsButton>
            <Divider margin={{ left: em(2) }} vairant='diagonal' />
            <ResetSeenItemsButton
              property_name={'walkthroughs'}
            ></ResetSeenItemsButton>
            <Divider margin={{ left: em(2) }} vairant='diagonal' />
            <ResetSeenItemsButton
              property_name={'campaigns'}
            ></ResetSeenItemsButton>
            <Divider margin={{ left: em(2) }} vairant='diagonal' />
            <ResetSeenItemsButton
              property_name={'newFeatureIndication'}
            ></ResetSeenItemsButton>
          </Stack>
          <br></br>
        </Tab>
      )}
    </TabbedShowLayout>
  );
}
