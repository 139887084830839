export class Referral {
  id: string;
  name: string;
  description: string;
  media: string;
  display_text: string;
  is_deleted: boolean;
  payload: any;
  is_active: boolean;
  segment_id: string;

  constructor(
    id: string,
    name: string,
    description: string,
    media: string,
    display_text: string,
    is_deleted: boolean,
    payload: string,
    is_active: boolean,
    segment_id: string
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.media = media;
    this.display_text = display_text;
    this.is_deleted = is_deleted;
    this.payload = payload;
    this.is_active = is_active;
    this.segment_id = segment_id;
  }

  static fromDto(referral: any) {
    return new Referral(
      referral.id,
      referral.name,
      referral.description,
      referral.media,
      referral.display_text,
      referral.is_deleted,
      referral.payload,
      referral.is_active,
      referral.segment_id
    );
  }
}
