import { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchEnd, fetchStart, useAuthProvider } from 'react-admin';
import { useDispatch } from 'react-redux';

import { BlazePodApiError } from 'shared/models/errors/BlazepodApiError';
import { StatDto } from 'shared/models/summary/Stat.dto';
import { Stat } from 'shared/models/summary/Stat.model';
import { getApiRoot } from 'shared/utils/helperFunctions/dotenv.utils';

export function useBPSummary(
  resource: string,
  summaryUrl = 'summary'
): UseBPSummary {
  const authProvider = useAuthProvider();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<Stat[]>([]);
  const [error, setError] = useState<BlazePodApiError | undefined>();

  const handleFetch = useCallback(async () => {
    setLoading(true);
    dispatch(fetchStart());
    const token = await authProvider.getToken();
    fetch(`${getApiRoot()}/${resource}/${summaryUrl}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((raw) => raw.json())
      .then((response) => {
        const { payload: rawSummary } = response;

        const summaryWithIds = (rawSummary as StatDto[]).map((stat) => ({
          ...stat,
          id: `${stat.filter_key}-${stat.filter_value}-${stat.count}`
        }));

        setSummary(summaryWithIds as Stat[]);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        dispatch(fetchEnd());
        setLoading(false);
      });
  }, [dispatch, authProvider, resource, summaryUrl]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);
  return useMemo(
    () => ({ loading, data: summary, error }),
    [loading, summary, error]
  );
}

export interface UseBPSummary {
  loading: boolean;
  data: Stat[];

  error?: BlazePodApiError;
}
