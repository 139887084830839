import { ValueOfObject } from 'shared/utils/types';

export const CustomersSortableFieldsEnum = Object.freeze({
  full_name: 'full_name',
  email: 'email',
  creation_date: 'creation_date',
  creator: 'creator',
  total_codes: 'code_summary.total',
  activated_codes: 'code_summary.activated',
  pending_codes: 'code_summary.pending',
  canceled_codes: 'code_summary.canceled',
  expired_codes: 'code_summary.expired',
  alias: 'alias'
} as const);

export type CustomersSortableFields = ValueOfObject<
  typeof CustomersSortableFieldsEnum
>;
