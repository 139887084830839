import { useMemo, useState } from 'react';
import { useAuthProvider } from 'react-admin';
import Chart from 'react-apexcharts';
import { useQuery } from 'react-query';

import { Stack, Typography } from '@mui/material';

import './assets/style.css';

import LargeLogoWithoutText from 'shared/components/logos/LargeLogoWithoutText.component';
import {
  simplePieChartParams,
  StackedColumnsParams
} from 'shared/models/dashoboard/chart-helper';
import {
  CustomerCodesDashboard,
  PlanProviderStat,
  PlanProviderSummary
} from 'shared/models/dashoboard/PlanProvider.model';
import { Stat } from 'shared/models/summary/Stat.model';
import { getApiRoot } from 'shared/utils/helperFunctions/dotenv.utils';
import { buildBlazepodHeaders } from 'shared/utils/helperFunctions/fetchHelper';
import { getTitleCase } from 'shared/utils/helperFunctions/strings';
import { vw } from 'shared/utils/styles/css-unit/helper.util';

export function Dashboard() {
  const authProvider = useAuthProvider();
  const [planLoading, setPlanLoading] = useState<boolean>(false);
  const [providerLoading, setProviderLoading] = useState<boolean>(false);
  const [codesLoading, setCodesLoading] = useState<boolean>(false);

  const [usersPlanPie, setUsersPlanPie] = useState<any>();
  const [providerPie, setproviderPie] = useState<any>();
  const [renewPie, setRenewPie] = useState<any>();
  const [codesPie, setCodesPie] = useState<any>();

  const [providerBar, setproviderBar] = useState<any>();

  const [totalUsers, setTotalUsers] = useState<string>();
  const [totalProUsers, setTotalProUsers] = useState<string>();
  const [onlineCustomerPendingCode, setOnlineCustomerPendingCode] =
    useState<string>();

  const fetchPlansDashboard = async () => {
    setPlanLoading(false);
    setUsersPlanPie(null);
    const res = await fetch(
      `${getApiRoot()}/dashboard/users_plans`,
      await buildBlazepodHeaders(authProvider)
    );
    return res.json();
  };

  const fetchPlanProviderDashboard = async () => {
    setProviderLoading(false);
    setproviderPie(null);
    setRenewPie(null);
    setproviderBar(null);
    const res = await fetch(
      `${getApiRoot()}/dashboard/plan_providers?plan=Pro`,
      await buildBlazepodHeaders(authProvider)
    );
    return res.json();
  };

  const fetchCustomerCodesDashboard = async () => {
    setCodesLoading(false);
    const res = await fetch(
      `${getApiRoot()}/dashboard/customers_codes?customer_type=Individual`,
      await buildBlazepodHeaders(authProvider)
    );
    return res.json();
  };

  const renderPlanData = () => {
    if (planStatus === 'success') {
      const plans: Stat[] = planData.payload.plans;
      setUsersPlanPie(
        simplePieChartParams(
          plans.map((plan) => plan.count ?? 0),
          plans.map((plan) => getTitleCase(plan.display_name)),
          `User's plan`
        )
      );
      setTotalUsers(planData.payload.total.toLocaleString());
      setPlanLoading(true);
    }
  };

  const renderProviderData = () => {
    if (providersStatus === 'success') {
      const providers: PlanProviderStat[] = providersData.payload.providers;
      providers.sort((a, b) => b.total - a.total);
      setproviderPie(
        simplePieChartParams(
          providers.map((plan) => plan.total),
          providers.map((plan) => getTitleCase(plan.provider)),
          `Paid by provider`
        )
      );
      const series = new Array<{ name: string; data: number[] }>(
        { name: 'Renew', data: providers.map((plan) => plan.count_is_renew) },
        {
          name: 'Not-renew',
          data: providers.map((plan) => plan.count_not_renew)
        }
      );
      setproviderBar(
        StackedColumnsParams(
          providers.map((plan) => getTitleCase(plan.provider)),
          series,
          'Renew by provider'
        )
      );
      const summary: PlanProviderSummary = providersData.payload.summary;
      setTotalProUsers(summary.total.toLocaleString());
      setRenewPie(
        simplePieChartParams(
          [summary.renew, summary.not_renew],
          ['Renew', 'Not Renew'],
          `Pro - Renew status`
        )
      );
      setProviderLoading(true);
    }
  };

  const renderCodesData = () => {
    if (codesStatus === 'success') {
      const customerCodesDashboard: CustomerCodesDashboard = codesData.payload;
      const codes = customerCodesDashboard.codes;
      const totalPending = Number.parseInt(
        codes.find((code) => code.value === 'PENDING')?.display_name ?? '0'
      );
      setOnlineCustomerPendingCode(totalPending.toLocaleString());
      setCodesPie(
        simplePieChartParams(
          codes.map((code) => Number.parseInt(code.display_name)),
          codes.map((code) => getTitleCase(code.value)),
          `Online Customers Codes`
        )
      );
      setCodesLoading(true);
    }
  };

  const { data: planData, status: planStatus } = useQuery(
    'users_plan',
    fetchPlansDashboard,
    { onSuccess: renderPlanData }
  );
  const { data: providersData, status: providersStatus } = useQuery(
    'providers',
    fetchPlanProviderDashboard,
    { onSuccess: renderProviderData }
  );
  const { data: codesData, status: codesStatus } = useQuery(
    'codes',
    fetchCustomerCodesDashboard,
    { onSuccess: renderCodesData }
  );

  const renderPlanDataMemo = useMemo(() => {
    renderPlanData();
    renderProviderData();
    renderCodesData();
  }, [
    providersData,
    planData,
    codesData,
    codesLoading,
    providerLoading,
    planLoading
  ]);

  return (
    <div id='main'>
      <br />
      <Typography variant='h2' align='center'>
        Welcome to BlazePod Back-office!
      </Typography>
      <br />
      <div id='mainContent' className='mainContent'>
        <Stack direction='row' spacing='10px' style={{ padding: 20 }}>
          <div className='smallBox' style={{ width: '10%' }}>
            <Stack direction='row' style={{ padding: 'auto' }}>
              <LargeLogoWithoutText
                style={{ margin: 'auto' }}
                sx={{
                  fontSize: vw(6)
                }}
              />
            </Stack>
          </div>
          <div className='smallBox' style={{ width: '30%' }}>
            <Typography variant='h6' align='center' fontWeight='bold'>
              Total Users
            </Typography>
            <br></br>
            <Typography variant='h2' align='center'>
              {totalUsers}
            </Typography>
          </div>
          <div className='smallBox' style={{ width: '30%' }}>
            <Typography variant='h6' align='center' fontWeight='bold'>
              Total Pro Users
            </Typography>
            <br></br>
            <Typography variant='h2' align='center'>
              {totalProUsers}
            </Typography>
          </div>
          <div className='smallBox' style={{ width: '30%' }}>
            <Typography variant='h6' align='center' fontWeight='bold'>
              Online Pending Codes
            </Typography>
            <br></br>
            <Typography variant='h2' align='center'>
              {onlineCustomerPendingCode}
            </Typography>
          </div>
        </Stack>
        <Stack direction='row' spacing='30px' style={{ padding: 20 }}>
          <div className='box' style={{ width: '33%' }}>
            {!providerLoading && (
              <div className='loading'>Loading users data...</div>
            )}
            {planLoading && usersPlanPie && (
              <Chart
                options={usersPlanPie.options}
                series={usersPlanPie.series}
                type='pie'
                height='100%'
              />
            )}
          </div>
          <div className='box' style={{ width: '33%' }}>
            {!planLoading && (
              <div className='loading'>Loading renew data...</div>
            )}
            {planLoading && renewPie && (
              <Chart
                options={renewPie.options}
                series={renewPie.series}
                type='donut'
                height='100%'
              />
            )}
          </div>
          <div className='box' style={{ width: '33%' }}>
            {!providerLoading && (
              <div className='loading'>Loading providers data...</div>
            )}
            {providerLoading && providerPie && (
              <Chart
                options={providerPie.options}
                series={providerPie.series}
                type='pie'
                height='100%'
              />
            )}
          </div>
        </Stack>
        <Stack direction='row' spacing='30px' style={{ padding: 20 }}>
          <div className='mediumBox' style={{ width: '50%' }}>
            {!planLoading && (
              <div className='loading'>Loading renew data...</div>
            )}
            {planLoading && providerBar && (
              <Chart
                options={providerBar.options}
                series={providerBar.series}
                type='bar'
                height='100%'
              />
            )}
          </div>
          <div className='mediumBox' style={{ width: '50%' }}>
            {!codesLoading && (
              <div className='loading'>Loading codes data...</div>
            )}
            {codesLoading && codesPie && (
              <Chart
                options={codesPie.options}
                series={codesPie.series}
                type='donut'
                height='100%'
              />
            )}
          </div>
        </Stack>
      </div>
    </div>
  );
}
