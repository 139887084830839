export class ContentArrayItem {
  id: string;
  constructor(id: string) {
    this.id = id;
  }
}
export class CollectionMutationPayload {
  display_name?: string | null;
  description?: string | null;
  contentsArray?: ContentArrayItem[] | null;
  contents: string[] | null | undefined;

  constructor(
    display_name?: string | null,
    description?: string | null,
    contentsArray?: ContentArrayItem[] | null
  ) {
    this.display_name = display_name;
    this.description = description;
    this.contents = contentsArray?.map((v) => v.id);
  }

  static factory(collection: Partial<CollectionMutationPayload> = {}) {
    return new CollectionMutationPayload(
      collection.display_name,
      collection.description,
      collection.contentsArray
    );
  }
}
