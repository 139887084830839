import { useMemo } from 'react';

import {
  ArrayInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useRecordContext
} from 'react-admin';

import { Box, Typography } from '@mui/material';

import RichTextInput from 'ra-input-rich-text';
import { ContentItem } from 'resources/activities/models/ContentItem.model';
import { HasCustomerId } from 'resources/customers/interfaces';
import { LabelWrapper } from 'shared/components/fields/LabelWrapper.component';

import { AutocompleteReferenceInput } from 'shared/components/inputs/AutocompleteReferenceInput.component';
import { SaveCancelFormToolbar } from 'shared/components/toolbars/SaveCancelFormToolbar.component';

import { ResourceName } from 'shared/enums';
import { ContentVisibility } from 'shared/enums/content-enums';
import { RaListOrder } from 'shared/services/backoffice-data-provider';
import { rem, vh } from 'shared/utils/styles/css-unit';

import { CollectionMutationPayload } from '../models/collection-mutation-payload.model';

export enum CollectionFormSource {
  name = 'name',
  description = 'description',
  contents = 'contents'
}

export interface CollectionFormProps extends Partial<HasCustomerId> {
  onSave(values: Partial<CollectionMutationPayload>): void;
  onCancel(): void;
  fieldsToOmit?: CollectionFormSource[];
  title: string;
}

export function CollectionForm(props: CollectionFormProps) {
  const { onCancel, onSave } = props;

  const record = useRecordContext();

  record.contentsArray = record.contents;

  const handleSave = useMemo(
    () => (values: Partial<CollectionMutationPayload>) => {
      values.contents = values.contentsArray?.map((v) => v.id);
      onSave(
        CollectionMutationPayload.factory({
          ...values
        })
      );
    },
    [onSave]
  );

  return (
    <>
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <ContentEditTitle />
        </Typography>
        <SimpleForm
          submitOnEnter={false}
          save={handleSave}
          toolbar={<SaveCancelFormToolbar onCancel={onCancel} />}
        >
          <LabelWrapper component='h4'>{props.title}</LabelWrapper>
          <TextInput
            label='Name'
            source='display_name'
            resettable={true}
            validate={[required()]}
          />
          <RichTextInput source='description'></RichTextInput>
          <ArrayInput label='Contents' source='contentsArray'>
            <SimpleFormIterator>
              <AutocompleteReferenceInput
                filter={{ status: 'PUBLIC' }}
                source='id'
                filterKey='search'
                label={'Activity Name'}
                sort={{ field: 'display_name', order: RaListOrder.ASC }}
                reference={ResourceName.ACTIVITIES}
                optionText='display_name'
                resettable
              ></AutocompleteReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Box>
    </>
  );
}

export function ContentEditTitle() {
  const content = useRecordContext<ContentItem>();
  return (
    <>
      {`${content.display_name ?? ''}` +
        (content.visibility === ContentVisibility.DRAFT ? ' - draft' : '')}
    </>
  );
}
