import { useMemo } from 'react';

import { SelectInput, SimpleForm } from 'react-admin';

import { Box, Typography } from '@mui/material';
import { uniqueId } from 'lodash';

import { HasCustomerId } from 'resources/customers/interfaces';
import { FieldObject } from 'resources/types';
import { LabelWrapper } from 'shared/components/fields/LabelWrapper.component';

import { SaveCancelFormToolbar } from 'shared/components/toolbars/SaveCancelFormToolbar.component';

import { Privilege } from 'shared/services/firebase-auth-provider/privilege.enum';

import { rem, vh } from 'shared/utils/styles/css-unit';

import { UserIdInputFromEmail } from '../../../shared/components/inputs/UserIdInputFromEmail.component';
import { AdminsMutationPayload } from '../models/admins-mutation-payload.model';

import { AdminTitle } from '../pages/AdminsShow.component';

export enum AdminsFormSource {
  content = 'content',
  entities = 'entities',
  communication = 'communication',
  monetization = 'monetization',
  user_id = 'user_id'
}

export interface AdminsFormProps extends Partial<HasCustomerId> {
  onSave(values: Partial<AdminsMutationPayload>): void;
  onCancel(): void;
  fieldsToOmit?: AdminsFormSource[];
}

export function AdminsForm(props: AdminsFormProps) {
  const { onCancel, onSave, fieldsToOmit } = props;

  const shouldShowField = useMemo(
    () => (field: AdminsFormSource) => {
      return fieldsToOmit ? !fieldsToOmit.includes(field) : true;
    },
    [fieldsToOmit]
  );

  const handleSave = useMemo(
    () => (values: Partial<AdminsMutationPayload>) => {
      onSave(
        AdminsMutationPayload.factory({
          ...values
        })
      );
    },
    [onSave]
  );
  const adminCreationFields = useMemo(() => {
    const fields: FieldObject<AdminsFormSource>[] = [
      {
        source: AdminsFormSource.user_id,
        element: <UserIdInputFromEmail key={uniqueId()}></UserIdInputFromEmail>
      }
    ];
    const filteredFields = fields.filter((field) =>
      shouldShowField(field.source)
    );
    if (filteredFields.length === 0) {
      return [];
    }
    return filteredFields.map((field) => field.element);
  }, [shouldShowField]);

  return (
    <>
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <AdminTitle />
        </Typography>
        <SimpleForm
          submitOnEnter={false}
          save={handleSave}
          toolbar={<SaveCancelFormToolbar onCancel={onCancel} />}
        >
          <LabelWrapper component='h4'>
            {adminCreationFields.length > 0 ? 'Add Admin' : 'Edit Permission'}{' '}
          </LabelWrapper>
          {adminCreationFields}
          <SelectInput
            label='Content'
            source='content'
            choices={getTypeOptions()}
            resettable={true}
          />
          <SelectInput
            label='Entities'
            source='entities'
            choices={getTypeOptions()}
            resettable={true}
          />
          <SelectInput
            label='Communication'
            source='communication'
            choices={getTypeOptions()}
            resettable={true}
          />
          <SelectInput
            label='Monetization'
            source='monetization'
            choices={getTypeOptions()}
            resettable={true}
          />
          <SelectInput
            label='Config'
            source='config'
            choices={getTypeOptions()}
            resettable={true}
          />
        </SimpleForm>
      </Box>
    </>
  );
}
function getTypeOptions() {
  const privTypesValues = Object.values(Privilege);
  return privTypesValues.map((type) => ({ id: type, name: type }));
}
