export class UserMutationPayload {
  is_legacy: boolean | null;
  set_password: boolean | null;

  constructor(
    is_legacy: boolean | null = null,
    set_password: boolean | null = null
  ) {
    this.is_legacy = is_legacy;
    this.set_password = set_password;
  }
}
