import { Confirm, ConfirmProps } from 'react-admin';

import kebabCase from 'lodash/kebabCase';

export type ConfirmUpdateProps = Omit<ConfirmProps, 'title' | 'content'> & {
  recordName: string;
  title?: string;
  content?: string;
};
export function ConfirmUpdate(props: ConfirmUpdateProps) {
  return (
    <Confirm
      {...props}
      title={props.title ?? 'Are you sure?'}
      content={
        props.content ??
        `You are about to update this ${kebabCase(
          props.recordName.toLowerCase()
        )} data.`
      }
    />
  );
}
