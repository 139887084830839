import { useMemo, useState } from 'react';
import {
  Confirm,
  Create,
  CreateProps,
  downloadCSV,
  Exporter,
  fetchRelatedRecords,
  useCreate,
  useDataProvider,
  useNotify
} from 'react-admin';
import { useHistory } from 'react-router-dom';

import DownloadIcon from '@mui/icons-material/Download';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { MembershipCode } from 'resources/codes/models/MembershipCode.model';
import { HasCustomerId } from 'resources/customers/interfaces/has-customer-id';
import { LoaderDialog } from 'shared/components/dialogs/LoaderDialog.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { csvMaker } from 'shared/utils/helperFunctions/csvMaker';

import { CodesFormSource } from '../components/codes-form';
import { CodesForm } from '../components/codes-form/CodesForm.component';
import { CodesCreationPayload } from '../models/codes-creation-payload.model';

export interface CodesCreateProps extends CreateProps, Partial<HasCustomerId> {
  onCreateCodesSuccessDoneClick?(): void;
  onCreateCodesFailure?(): void;
  onCancel?(): void;
}

export function CodesCreate(props: CodesCreateProps) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isShowSuccessDialog, setIsShowSuccessDialog] = useState(false);
  const history = useHistory();

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [create, createState] = useCreate(ResourceName.CODES);

  const [formValues, setFormValues] = useState<
    CodesCreationPayload & { description: string }
  >();

  const onSave = useMemo(
    () => (values: CodesCreationPayload) => {
      if (props.customer_id) {
        values.customer_id = props.customer_id;
      }
      setFormValues(
        CodesCreationPayload.factory({
          ...values
        })
      );
      setIsConfirmOpen(true);
    },
    []
  );
  const onConfirm = useMemo(
    () => () => {
      if (!formValues) {
        notify('No form Values were set!', { type: 'error' });
      }
      create(ResourceName.CODES, formValues, {
        onFailure(error) {
          notify(error.message, { type: 'error' });
          if (props.onCreateCodesFailure) {
            props.onCreateCodesFailure();
          }
          setIsConfirmOpen(false);
        },
        onSuccess() {
          setIsConfirmOpen(false);
          setIsShowSuccessDialog(true);
        }
      });
    },
    [formValues, create, notify, props]
  );

  const goBack = useMemo(
    () => () => {
      history.goBack();
    },
    [history]
  );
  const handleDone = useMemo(
    () => () => {
      setIsShowSuccessDialog(false);
      if (props.onCreateCodesSuccessDoneClick != null) {
        props.onCreateCodesSuccessDoneClick();
      } else {
        goBack();
      }
    },
    [goBack, props]
  );
  const handleCancel = useMemo(
    () => () => {
      setIsShowSuccessDialog(false);
      if (props.onCancel != null) {
        props.onCancel();
      } else {
        goBack();
      }
    },
    [goBack, props]
  );

  const handleDownload: Exporter = async (
    data,
    _fetchRelatedRecords,
    dataProvider
  ) => {
    try {
      const _customers = await _fetchRelatedRecords(
        data,
        'customer_id',
        ResourceName.CUSTOMERS
      );
      const _users = await _fetchRelatedRecords(
        data,
        'app_user_id',
        ResourceName.USERS
      );
      const codesForExport: Array<MembershipCode> = data.map(
        ({ customer_id, app_user_id, ...rest }: MembershipCode) => {
          return {
            ...rest,
            app_user: app_user_id != null ? _users[app_user_id].email : null,
            customer: customer_id != null ? _customers[customer_id].alias : null
          };
        }
      );

      const headers = [
        'code',
        'id',
        'customer',
        'app_user',
        'creation_date',
        'code_status',
        'plan_duration'
      ];

      const csv = csvMaker(codesForExport, headers);
      downloadCSV(csv, `code_batch-${codesForExport[0].creation_date}`);
    } catch (error) {
      console.log(error);
      notify((error as any)?.message ?? 'Something went wrong', {
        type: 'error'
      });
    }
  };

  const handleDownloadCodes = () => {
    if (createState.data != null) {
      try {
        handleDownload(
          createState.data.codes,
          fetchRelatedRecords(dataProvider),
          dataProvider
        );
      } catch (e) {
        if (e instanceof Error) {
          notify(e.message, { type: 'error' });
        } else {
          notify('Something went wrong', { type: 'error' });
        }
      }
    }
  };

  const handleSave = useMemo(
    () => (values: CodesCreationPayload) =>
      onSave(CodesCreationPayload.factory(values)),
    [onSave]
  );

  return (
    <>
      <Create {...props}>
        <CodesForm
          onSave={handleSave}
          onCancel={handleCancel}
          fieldsToOmit={props.customer_id ? [CodesFormSource.customer_id] : []}
          customer_id={props.customer_id}
        />
      </Create>
      <Confirm
        isOpen={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        onConfirm={onConfirm}
        title={`Are you sure?`}
        content={`You are going to create ${formValues?.number_of_codes} codes.`}
      />
      <LoaderDialog loading={createState.loading} title='Creating codes...' />
      <Dialog disableScrollLock={true} open={isShowSuccessDialog}>
        <DialogTitle>Codes Created!</DialogTitle>
        <DialogActions>
          <Button onClick={handleDownloadCodes}>
            <DownloadIcon /> Download Codes
          </Button>
          <Button onClick={handleDone}>
            {props.onCreateCodesSuccessDoneClick ? 'Done' : 'Go Back'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
