import TagIcon from '@mui/icons-material/Tag';

import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { SegmentList } from './pages/SegmentList.component';
import { SegmentShow } from './pages/SegmentShow.component';

export const SegmentsModule = RaModule.fromResourceProps({
  name: ResourceName.SEGMENTS,
  intent: ResourceIntent.ROUTE,
  options: { label: 'Segments' },
  list: SegmentList,
  show: SegmentShow,
  icon: TagIcon
});
