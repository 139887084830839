export const AUTH_TOKEN_KEY = 'firbase_token';
export const USER_KEY = 'firebase_user';

export const CONFIG = {
  development: {
    apiKey: 'AIzaSyBV79MqtNteP2D__xIxR5ZOZUpIYb5TcsA',
    authDomain: 'blazepod-development.firebaseapp.com',
    databaseURL: 'https://blazepod-development.firebaseio.com',
    projectId: 'blazepod-development',
    storageBucket: 'blazepod-development.appspot.com',
    messagingSenderId: '82230290514',
    appId: '1:82230290514:web:3cebae24045fb51e34978b',
    measurementId: 'G-WNK1FCNGQB'
  },
  production: {
    apiKey: 'AIzaSyA9711E8keMQU7akb8Ws7K_LeICHMtBcvk',
    authDomain: 'blazepod-apps-85c6f.firebaseapp.com',
    databaseURL: 'https://blazepod-apps-85c6f.firebaseio.com',
    projectId: 'blazepod-apps-85c6f',
    storageBucket: 'blazepod-apps-85c6f.appspot.com',
    messagingSenderId: '505971713713',
    appId: '1:505971713713:web:d7c3c34d34e2c0ff1f6490',
    measurementId: 'G-EL63DXWY62'
  }
};
