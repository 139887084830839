import { Stack } from '@mui/material';

import { HasUserPlan } from 'resources/users/interfaces/HasUserPlan';
import { CurrentPlanField } from 'resources/users_plans/components';

import { ChangePlanButton } from './ChangePlanButton.component';
import { HasOnChangePlan } from '../../interfaces/HasOnChangePlan';
import { HasPermission } from '../../interfaces/HasPermission';

interface UserCurrentPlanProps
  extends HasOnChangePlan,
    HasPermission,
    Partial<HasUserPlan> {}
export function UserCurrentPlan(props: UserCurrentPlanProps) {
  return (
    <Stack sx={{ alignItems: 'center' }} direction='row' spacing={4}>
      {props.userPlan != null && (
        <>
          <CurrentPlanField />
        </>
      )}
      <ChangePlanButton
        userPlan={props.userPlan}
        permission={props.permission}
        onChangePlan={props.onChangePlan}
      />
    </Stack>
  );
}
