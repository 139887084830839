import { ValueOfObject } from 'shared/utils/types';

export const MembershipPlanNamesEnum = Object.freeze({
  PRO: 'Pro',
  NEW: 'New',
  LEGACY: 'Legacy',
  NO_PLAN: 'No plan'
} as const);
export const MembershipPlansEnum = Object.freeze({
  PRO: 'pro',
  NEW: 'new',
  LEGACY: 'legacy',
  NO_PLAN: 'no_plan'
} as const);

export type MembershipPlanName = ValueOfObject<typeof MembershipPlanNamesEnum>;
export type MembershipPlan = ValueOfObject<typeof MembershipPlansEnum>;
