import { useMemo } from 'react';
import { EditButton, useRecordContext } from 'react-admin';

import { Tooltip } from '@mui/material';
import { CodeStatus } from 'resources/codes/enums/code-status.enum';
import { MembershipCode } from 'resources/codes/models/MembershipCode.model';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';

export function CodeEditButton(props: { label?: string }) {
  const { permissions } = useAppPermissions();
  const record = useRecordContext<MembershipCode>();

  const isPending = useMemo(
    () => record?.status === CodeStatus.Pending,
    [record?.status]
  );

  const shouldDisable = useMemo(() => {
    return record.creator_id === null || !isPending;
  }, [record.creator_id, isPending]);
  return (
    <Tooltip
      arrow
      placement='left'
      title={isPending ? 'Edit code' : 'Only pending code is editable'}
      describeChild
      enterDelay={1000}
    >
      <div>
        {isPublisher(permissions?.entities) && (
          <EditButton
            disabled={shouldDisable}
            basePath={`/${ResourceName.CODES}`}
            label={props.label ?? ''}
            record={record}
          />
        )}
      </div>
    </Tooltip>
  );
}
