import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { p, vh, vw } from 'shared/utils/styles/css-unit';

export const LoginContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  background: '#FFF',
  width: vw(100),
  maxWidth: vw(96),
  height: vh(100),
  maxHeight: vh(96),
  padding: 0,
  [theme.breakpoints.up('sm')]:
    (window?.visualViewport?.height ?? 0) < (window?.visualViewport?.width ?? 0)
      ? {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)'
      }
      : {}
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  minHeight: '50vw',
  height: p(100),
  maxWidth: vw(100),
  paddingTop: vw(16),
  paddingRight: vw(3),
  margin: 0,
  [theme.breakpoints.up('sm')]:
    (window?.visualViewport?.height ?? 0) < (window?.visualViewport?.width ?? 0)
      ? {
        paddingRight: 0,
        paddingTop: 0,
        height: vh(100),
        width: p(100)
      }
      : {}
}));
