import { useState } from 'react';

import {
  HttpError,
  LinearProgress,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { Button, Tooltip } from '@mui/material';

import { ConfirmUpdate } from 'shared/components/dialogs/ConfirmUpdate.component';
import { ResourceName } from 'shared/enums';
import { useAxiosCall } from 'shared/hooks/useAxiosCall';
import { px } from 'shared/utils/styles/css-unit';

import { PackageItem } from '../package-item.model';

export function RemoveFromPackageButton() {
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const record = useRecordContext<PackageItem>();

  const refresh = useRefresh();
  const notify = useNotify();

  const fetchRemoveContent = useAxiosCall<boolean>({
    resource: `${ResourceName.CONTENT_PACKAGES}/${record.package_id}`,
    autoFetch: false,
    init: { method: 'PATCH' }
  });

  const handleSuccess = () => {
    setShouldShowConfirm(false);
    refresh();
    notify('Content removed.', { type: 'success' });
  };

  const handelFail = (e: HttpError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  const handleConfirm = () => {
    fetchRemoveContent
      .fetch({ content_id: record.content_id, action: 'remove' })
      .then(handleSuccess)
      .catch(handelFail);
  };

  if (record == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='right'
        title={'Remove content from package'}
        describeChild
        enterDelay={1000}
      >
        <div>
          <Button
            onClick={handleClick}
            sx={(theme) => ({
              px: px(5),
              py: px(4),
              color: theme.palette.warning.main,
              lineHeight: 1.5
            })}
          >
            <RemoveCircleIcon />
          </Button>
        </div>
      </Tooltip>
      <ConfirmUpdate
        onClose={handleAbort}
        onConfirm={handleConfirm}
        isOpen={shouldShowConfirm}
        recordName='package'
      />
    </>
  );
}
