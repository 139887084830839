import { kebabCase } from 'lodash';
import { MembershipPlanView } from 'resources/users/enums/MembershipPlanView.enum';
import { getTitleCase } from 'shared/utils/helperFunctions/strings';

import { MembershipPlan, MembershipPlanName } from './membership-plans.enum';

export class MembershipPlanVM {
  model: MembershipPlan;
  view: MembershipPlanView;

  constructor(plan: MembershipPlan | MembershipPlanName) {
    this.model = MembershipPlanVM.planFromName(plan as MembershipPlanName);
    this.view = MembershipPlanVM.assertPlanView(plan);
  }
  static planFromName(planName: MembershipPlanName): MembershipPlan {
    return kebabCase(planName) as MembershipPlan;
  }
  static planNameFromPlan(plan: MembershipPlan): MembershipPlanName {
    return getTitleCase(plan) as MembershipPlanName;
  }
  static assertPlanView(
    planName: MembershipPlan | MembershipPlanName
  ): MembershipPlanView {
    const plan = planName.toLowerCase() as MembershipPlan;

    if (plan === MembershipPlanView.PRO) return MembershipPlanView.PRO;
    return MembershipPlanView.FREE;
  }
}
