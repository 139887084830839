import { Confirm, ConfirmProps } from 'react-admin';

export function ConfirmUnsaved(props: Omit<ConfirmProps, 'title' | 'content'>) {
  return (
    <Confirm
      {...props}
      title='Are you sure?'
      content='Unsaved changes will be lost.'
    />
  );
}
