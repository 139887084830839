export class StoreKeys {
  public id: string;
  public name: string;
  public store_keys: string;

  constructor(id = '', name = '', store_keys = '') {
    this.id = id;
    this.name = name;
    this.store_keys = store_keys;
  }

  static fromDto(store_keys: any): any {
    console.log(store_keys);
    return new StoreKeys(store_keys.id, store_keys.name, store_keys.store_keys);
  }
}
