import { List, ListProps, SearchInput } from 'react-admin';

import { AppResponsive } from 'shared/components/common/AppResponsive.component';

import { MembershipDisplayTable } from '../components/MembershipDisplayTable.component';

export function MembershipDisplayList(props: ListProps) {
  return (
    <List
      {...props}
      perPage={25}
      filters={[<SearchInput source='search' alwaysOn />]}
      exporter={false}
    >
      <AppResponsive
        xs={<MembershipDisplayTable />}
        sm={<MembershipDisplayTable />}
        md={<MembershipDisplayTable />}
        lg={<MembershipDisplayTable />}
        xl={<MembershipDisplayTable />}
      />
    </List>
  );
}
