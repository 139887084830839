import {
  Edit,
  EditProps,
  useEditContext,
  useNotify,
  useUpdate
} from 'react-admin';

import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { ConfirmUpdate } from 'shared/components/dialogs/ConfirmUpdate.component';
import { UnchangedDialog } from 'shared/components/dialogs/UnchangedDialog.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';

import {
  AdminsForm,
  AdminsFormSource
} from '../components/AdminsForm.component';
import { AppAdmin } from '../models/Admin.model';
import { AdminsMutationPayload } from '../models/admins-mutation-payload.model';

export type AdminsEditProps = EditProps;

export function AdminsEdit(props: AdminsEditProps) {
  return (
    <Edit {...props} title='Admins'>
      <AdminsEditForm />
    </Edit>
  );
}

function AdminsEditForm() {
  const notify = useNotify();
  const [update, updateState] = useUpdate();
  const editContext = useEditContext<AppAdmin>();

  const {
    shouldShowConfirmSubmit,
    shouldShowUnchanged,
    shouldShowUnsaved,
    goBack,
    handleSave,
    handleCancelEdit,
    handleCancelSubmit,
    handleConfirmSubmit,
    handleUnchangedContinue,
    handleUnchangedQuit,
    handleUnsavedCancel,
    handleUnsavedConfirm
  } = useMutationVM<AdminsMutationPayload, AppAdmin>({
    PayloadClass: AdminsMutationPayload,
    record: editContext.record,
    resource: ResourceName.ADMINS,
    mutator: update,
    onMutateFailure: handleUpdateFailure,
    onMutateSuccess: handleUpdateSuccess
  });
  function handleUpdateSuccess() {
    notify('Admin permissions updated successfully.', { type: 'success' });
    goBack();
  }
  function handleUpdateFailure(e: Error) {
    notify(
      e.message || 'Something went wrong. Please try again in a few seconds.',
      { type: 'error' }
    );
  }
  return (
    <>
      <AdminsForm
        onCancel={handleCancelEdit}
        onSave={handleSave}
        fieldsToOmit={[AdminsFormSource.user_id]}
      />
      <ConfirmUpdate
        loading={editContext.loading || updateState.loading}
        isOpen={shouldShowConfirmSubmit}
        onConfirm={handleConfirmSubmit}
        onClose={handleCancelSubmit}
        recordName='Admin'
      />
      <ConfirmUnsaved
        loading={editContext.loading}
        isOpen={shouldShowUnsaved}
        onClose={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
      />
      <UnchangedDialog
        name='Admin'
        itemPageName='Admin'
        isOpen={shouldShowUnchanged}
        onContinue={handleUnchangedContinue}
        onQuit={handleUnchangedQuit}
      />
    </>
  );
}
