import { Datagrid, Loading, useListContext } from 'react-admin';

import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';

import { getTitleCase } from 'shared/utils/helperFunctions/strings';

import { CodeEditButton } from './CodeEditButton.component';
import { CodesFunctionField, CodesTextField } from './codes-fields';
import { MembershipCode } from '../models/MembershipCode.model';

export function CodesXSmallTable() {
  const listContext = useListContext<MembershipCode>();
  const { permissions } = useAppPermissions();
  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <Datagrid rowClick='show'>
          <CodesTextField source='code' />
          <CodesFunctionField
            source='status'
            sortable={false}
            render={(record?: MembershipCode) =>
              getTitleCase(record?.status?.toString() ?? '')
            }
          ></CodesFunctionField>
          {isPublisher(permissions?.entities) && <CodeEditButton />}
        </Datagrid>
      )}
    </>
  );
}
