import {
  CreateResult,
  DeleteManyResult,
  DeleteResult,
  GetListResult,
  GetManyReferenceResult,
  GetManyResult,
  GetOneResult,
  Record as RaRecord,
  UpdateManyResult,
  UpdateResult
} from 'react-admin';

import { HasId } from 'resources/types';
import { v4 } from 'uuid';

import { DtoConverter } from './interfaces';
import { BPApiListPayload } from './models/server-responses/bp-api-list-payload.model';

export class BackOfficeDtoConverter implements DtoConverter {
  protected convert?<Dto, DomainModel>(dto: Dto): DomainModel;
  convertList<DomainObject extends RaRecord = RaRecord>(
    dto: BPApiListPayload
  ): GetListResult<DomainObject> {
    if (dto == null) {
      return { data: [], total: 0 };
    }
    if (Array.isArray(dto)) {
      let mapper = (item: DomainObject) => ({
        ...item,
        id: item.id || v4(),
        hasId: false
      });
      if (this.convert != null) {
        mapper = this.convert.bind(this);
      }

      return {
        data: dto.map(mapper),
        total: dto.length
      };
    }
    const list = this._handleListResponse(dto) as DomainObject[];
    const { page } = dto;

    return { data: list, total: page?.total || list.length };
  }
  convertOne<DomainObject extends RaRecord = RaRecord>(
    dto: HasId
  ): GetOneResult<DomainObject> {
    if (this.convert == null) {
      return { data: dto as DomainObject };
    }
    return { data: this.convert(dto) };
  }

  convertMany<DomainObject extends RaRecord = RaRecord>(
    dto: BPApiListPayload
  ): GetManyResult<DomainObject> {
    let list: DomainObject[] = [];
    if (Array.isArray(dto)) {
      list = this.convert == null ? dto : dto.map<any>(this.convert);
    } else {
      list = this._handleListResponse<DomainObject>(dto);
    }

    return { data: list };
  }
  convertManyReference<DomainObject extends RaRecord = RaRecord>(
    dto: BPApiListPayload
  ): GetManyReferenceResult<DomainObject> {
    if (dto == null) {
      return { data: [], total: 0 };
    }
    if (Array.isArray(dto)) {
      let mapper = (item: DomainObject) => ({
        ...item,
        id: item.id || v4(),
        hasId: false
      });
      if (this.convert != null) {
        mapper = this.convert.bind(this);
      }

      return {
        data: dto.map(mapper),
        total: dto.length
      };
    }
    const list = this._handleListResponse(dto) as DomainObject[];
    const { page } = dto;
    return { data: list, total: page?.total || list.length };
  }
  convertUpdate<DomainObject extends RaRecord = RaRecord>(
    dto: any
  ): UpdateResult<DomainObject> {
    return { data: this.convert == null ? dto : this.convert(dto) };
  }
  convertUpdateMany(dto: any[]): UpdateManyResult {
    if (Array.isArray(dto)) {
      return { data: dto };
    }
    const data = dto['ids'];
    if (data != null) {
      return { data };
    }
    return { data: dto };
  }
  convertCreate<DomainObject extends RaRecord = RaRecord>(
    dto: any
  ): CreateResult<DomainObject> {
    if (dto == null) {
      return { data: { success: true, id: v4() } as unknown as DomainObject };
    }
    return {
      data:
        this.convert == null
          ? { ...dto, id: dto.id ?? v4() }
          : this.convert(dto)
    };
  }
  convertDelete<DomainObject extends RaRecord = RaRecord>(
    dto: any
  ): DeleteResult<DomainObject> {
    return { data: this.convert == null ? dto : this.convert(dto) };
  }
  convertDeleteMany(dto: any): DeleteManyResult {
    return { data: this.convert == null ? dto : this.convert(dto) };
  }

  private _handleListResponse<DomainObject>(payload: {
    [key: string]: DomainObject[];
  }): DomainObject[] {
    const filtered = (Object.entries(payload).filter(
      ([key]) => key !== 'page'
    )[0][1] ?? []) as Array<DomainObject>;
    if (this.convert == null) {
      return filtered;
    }
    return (filtered.map(this.convert.bind(this)) ?? []) as Array<DomainObject>;
  }
}
