import { useMemo, useState } from 'react';

import {
  HttpError,
  LinearProgress,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';

import SportsScoreIcon from '@mui/icons-material/SportsScore';

import { Box, Button, Tooltip } from '@mui/material';

import { ContentItem } from 'resources/activities/models/ContentItem.model';
import { ResourceName } from 'shared/enums';
import { ContentUpdateAction } from 'shared/enums/content-enums';
import { useAxiosCall } from 'shared/hooks/useAxiosCall';

import { getTitleCase } from 'shared/utils/helperFunctions/strings';

import { px } from 'shared/utils/styles/css-unit';

import { EditDateFormDialog } from './EditDateFormDialog.component';

export function EditChallengeButton(props: { label?: boolean }) {
  const showLabel = props.label ?? false;
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const record = useRecordContext<ContentItem>();
  const refresh = useRefresh();
  const notify = useNotify();
  const tooltip = `Update challenge`;

  const isChallengeEnabled = useMemo<boolean>(() => {
    if (record.challenge_until) {
      const endDate = new Date(record.challenge_until);
      const now = new Date();
      return endDate > now;
    } else {
      return false;
    }
  }, [record?.trending_until]);

  const fetchChallengeUntil = useAxiosCall<boolean>({
    resource: `${ResourceName.BASIC_CONTENT}/${record.id}`,
    autoFetch: false,
    init: { method: 'PATCH' }
  });

  const handleConfirm = (challenge_until: string) => {
    fetchChallengeUntil
      .fetch({
        challenge_until,
        action: ContentUpdateAction.Challenge
      })
      .then(() => handleSuccess())
      .catch((err) => handleFail(err));
  };

  const handleSuccess = () => {
    setShouldShowConfirm(false);
    refresh();
    notify(`Mark activity as challenge successfully`, { type: 'success' });
  };

  const handleFail = (e: HttpError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  if (record == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='left'
        title={tooltip}
        describeChild
        enterDelay={1000}
      >
        <div>
          <Button
            onClick={handleClick}
            sx={(theme) => ({
              px: px(5),
              py: px(4),
              color: theme.palette.primary.main,
              lineHeight: 1.5
            })}
          >
            <SportsScoreIcon
              fontSize='small'
              color={isChallengeEnabled ? undefined : 'disabled'}
            />
            {showLabel ? (
              <Box component='span' sx={{ pl: px(6) }}>
                {'Mark as challenge'}
              </Box>
            ) : (
              ''
            )}
          </Button>
        </div>
      </Tooltip>
      <EditDateFormDialog
        onClose={handleAbort}
        onSend={handleConfirm}
        loading={fetchChallengeUntil.loading}
        isOpen={shouldShowConfirm}
        defaultValue={record?.challenge_until ?? ''}
        content={`Confirm to mark ${getTitleCase(record.type)} as challenge`}
        title={`Update challenge`}
        label={'Challenge until'}
      />
    </>
  );
}
