import {
  BackOfficeDataProvider,
  defaultUrlRenderer
} from 'shared/services/backoffice-data-provider';

import { PromotionsDtoConverter } from './PromotionsDtoConverter';

export class PromotionsDataProvider extends BackOfficeDataProvider {
  protected dtoConverter = new PromotionsDtoConverter();
  protected urlRenderer = defaultUrlRenderer;
}
