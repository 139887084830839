import SocialDistanceIcon from '@mui/icons-material/SocialDistance';

import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { ReferralCreate } from './pages/ReferralCreate.component';
import { ReferralEdit } from './pages/ReferralEdit.component';
import { ReferralList } from './pages/ReferralList.component';
import { ReferralShow } from './pages/ReferralShow.component';

export const ReferralsModule = RaModule.fromResourceProps({
  name: ResourceName.REFERRALS,
  intent: ResourceIntent.ROUTE,
  options: { label: 'Referrals' },
  create: ReferralCreate,
  list: ReferralList,
  show: ReferralShow,
  edit: ReferralEdit,
  icon: SocialDistanceIcon
});
