import { ShowProps } from 'react-admin';

import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { SectionName } from 'shared/enums/section-name.enum';

import { CustomerCodes } from '../customers/components/CustomerCodes.component';
import { CustomersShow } from '../customers/components/CustomersShow.component';

export type OnlineCustomersShowProps = ShowProps;

export function OnlineCustomersShow(props: OnlineCustomersShowProps) {
  return (
    <CustomersShow
      {...props}
      title={'Online Customers'}
      CodesComponent={CustomerCodes}
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'edit', 'create', 'list']}
          sectionName={SectionName.Entities}
        />
      }
    />
  );
}
