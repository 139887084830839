import { useState } from 'react';
import {
  Button,
  ShowProps,
  ToolbarProps,
  TopToolbar,
  useCreateController,
  useRedirect,
  useRefresh
} from 'react-admin';
import { useParams } from 'react-router-dom';

import { Add, Edit } from '@mui/icons-material';
import { Drawer } from '@mui/material';
import { CodesFilterableFieldsEnum } from 'resources/codes/enums/codes-filterable-fields.enum';
import { CodesCreate } from 'resources/codes/pages/CodesCreate.component';
import { HasCustomerId } from 'resources/customers/interfaces/has-customer-id';
import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';

import { BatchesList } from '../code_batches/BatchesList.component';
import { CustomersShow } from '../customers/components/CustomersShow.component';

export function OfflineCustomersShow(props: ShowProps) {
  const [isNewCodesOpen, setIsNewCodesOpen] = useState(false);
  const [isUnsavedOpen, setIsUnsavedOpen] = useState(false);
  const params = useParams<{ id: string }>();
  const refresh = useRefresh();

  const handleCreateCodesClick = () => {
    setIsNewCodesOpen(true);
  };
  const handleCreateCodesSuccess = () => {
    setIsNewCodesOpen(false);
    refresh();
  };
  const handleCreateCodesCancel = () => {
    setIsUnsavedOpen(true);
  };
  const handleUnsavedConfirm = () => {
    setIsUnsavedOpen(() => {
      setIsNewCodesOpen(false);
      return false;
    });
  };
  const handleUnsavedCancel = () => {
    setIsUnsavedOpen(false);
  };

  return (
    <>
      <CustomersShow
        {...props}
        title={'Offline Customers'}
        codesFilters={[
          CodesFilterableFieldsEnum.creator_id,
          CodesFilterableFieldsEnum.search
        ]}
        CodesComponent={BatchesList}
        actions={
          <OfflineCustomerShowToolbar
            id={params.id}
            onCreateCodes={handleCreateCodesClick}
          />
        }
      />
      <Drawer disableScrollLock={true} open={isNewCodesOpen} anchor='right'>
        <CreateCustomerCodes
          customer_id={params.id}
          onSuccess={handleCreateCodesSuccess}
          onCancel={handleCreateCodesCancel}
        />
      </Drawer>
      <ConfirmUnsaved
        onClose={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
        isOpen={isUnsavedOpen}
      />
    </>
  );
}

interface CreateCustomerCodesProps extends HasCustomerId {
  onSuccess?(): void;
  onCancel?(): void;
}

function CreateCustomerCodes(props: CreateCustomerCodesProps) {
  const createController = useCreateController({
    resource: ResourceName.CODES,
    basePath: `/${ResourceName.CODES}`
  });

  return (
    <CodesCreate
      {...createController}
      onCancel={props.onCancel}
      customer_id={props.customer_id}
      onCreateCodesSuccessDoneClick={props.onSuccess}
    />
  );
}

function OfflineCustomerShowToolbar({
  onCreateCodes,
  id,
  ...props
}: ToolbarProps & { onCreateCodes(): void; id: string }) {
  const { permissions } = useAppPermissions();
  const redirect = useRedirect();
  return (
    <TopToolbar {...props}>
      {isPublisher(permissions?.entities) && (
        <Button
          label='Edit'
          onClick={() =>
            redirect('edit', `/${ResourceName.OFFLINE_CUSTOMERS}`, id)
          }
        >
          <Edit />
        </Button>
      )}
      {isPublisher(permissions?.entities) && (
        <Button label='Create Codes' onClick={onCreateCodes}>
          <Add />
        </Button>
      )}
    </TopToolbar>
  );
}
