import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Confirm,
  Edit,
  required,
  SelectInput,
  SimpleForm,
  useCreate,
  useNotify,
  useRecordContext,
  useGetOne,
  useGetMany
} from 'react-admin';
import { useParams } from 'react-router-dom';

import { pick } from 'lodash';
import { PlanDuration } from 'resources/plans/plan-duration.model';
import { FieldObject, HasId } from 'resources/types';
import { AppUser } from 'resources/users/models/AppUser.model';
import { UserPlan } from 'resources/users_plans/models/user-plan.model';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { MembershipPlan } from 'shared/utils/constants/blazepod';
import { MembershipPlanVM } from 'shared/utils/constants/blazepod/MembershipPlanVM.model';

import { ChangePlanFormToolbar } from './ChagnePlanFormToolbar.component';
import { getPlanOptions } from './getPlanOptions.util';
import { PlanDurationInputGroup } from './PlanDurationInputGroup.component';
import { UpdateMembershipPlanPayload } from './update-membership-plan-payload.model';
import { MembershipPlanView } from '../../enums/MembershipPlanView.enum';

interface ChangeMembershipPlanDialogProp {
  onCancel: () => void;
  onUpdateSuccess: () => void;
  omitInputs?: ChangePlanOmittableInput[];
}
export function ChangeMembershipPlanDialog(
  props: ChangeMembershipPlanDialogProp
) {
  const params = useParams<HasId>();

  return (
    <Edit
      resource={ResourceName.USERS}
      basePath={'/' + ResourceName.USERS}
      id={params.id}
      title=' '
    >
      <ChangeMembershipPlanForm
        {...pick(props, ['onUpdateSuccess', 'onCancel', 'omitInputs'])}
        id={params.id}
      />
    </Edit>
  );
}

interface ChangeMembershipPlanFormProps extends ChangeMembershipPlanDialogProp {
  id: string;
}

export function ChangeMembershipPlanForm({
  onCancel,
  onUpdateSuccess,
  omitInputs,
  id
}: ChangeMembershipPlanFormProps) {
  // const createContext = useCreateContext<AppUser>();
  const record = useRecordContext<AppUser>();
  const userPlan = useGetOne<UserPlan>(ResourceName.USERS_PLANS, id);
  const plans = useGetMany(
    ResourceName.MEMBERSHIP_PLANS,
    [userPlan.data?.plan_id ?? ''],
    { enabled: !!userPlan.data?.plan_id }
  );

  const [shouldShowSaveDialog, setShouldShowSaveDialog] =
    useState<boolean>(false);
  const [formData, setFormData] = useState<UpdateMembershipPlanPayload>(
    new UpdateMembershipPlanPayload(
      MembershipPlanView.FREE,
      new PlanDuration(0, 0, false)
    )
  );

  const [doUpdate, updateState] = useCreate();
  const notify = useNotify();

  const changeUserMembershipPlan = useCallback(
    (values: UpdateMembershipPlanPayload) => {
      doUpdate(
        `${ResourceName.USERS}/${id}/plan`,
        UpdateMembershipPlanPayload.getPurged(values),
        {
          onSuccess() {
            notify('User data updated successfully.', { type: 'success' });
            onUpdateSuccess();
          }
        }
      );
    },
    [doUpdate, id, notify, onUpdateSuccess]
  );

  const onSave = (
    values: Partial<AppUser> & {
      membership_plan: MembershipPlan;
      plan_duration: PlanDuration;
    }
  ) => {
    const { membership_plan, plan_duration } = values;

    setShouldShowSaveDialog(true);
    setFormData({
      ...new UpdateMembershipPlanPayload(
        new MembershipPlanVM(membership_plan).view,
        plan_duration
      )
    });
  };

  const handleUpdateChange = useCallback(() => {
    try {
      if (updateState.error) {
        throw updateState.error;
      }
      if (updateState.loading) {
        notify('Updating Plan...', { type: 'info' });
      }
    } catch (e) {
      notify((e as Error).message, { type: 'error' });
    }

    setShouldShowSaveDialog(false);
  }, [updateState.error, updateState.loading, notify]);

  useEffect(() => {
    handleUpdateChange();
  }, [handleUpdateChange]);

  const inputs = useMemo(() => {
    const omitMap = omitInputs
      ? Object.fromEntries(omitInputs.map((input) => [input, true]))
      : {};
    const all: Array<FieldObject<ChangePlanOmittableInput>> = [
      {
        source: 'membership_plan',
        element: (
          <SelectInput
            source='membership_plan'
            choices={getPlanOptions(
              record,
              plans.data?.find((plan) => plan?.id === userPlan.data?.plan_id)
            )}
            validate={required()}
            key='membership_plan-1234'
          />
        )
      },
      {
        source: 'plan_duration',
        element: <PlanDurationInputGroup key='plan_duration-1234' />
      }
    ];
    return all.filter((input) => !omitMap[input.source]);
  }, [omitInputs, plans.data, record, userPlan.data?.plan_id]);

  return (
    <>
      <SimpleForm
        save={onSave}
        toolbar={<ChangePlanFormToolbar onCancel={onCancel} />}
      >
        {inputs.map(({ element }) => element)}
      </SimpleForm>
      <Confirm
        isOpen={shouldShowSaveDialog}
        loading={updateState.loading}
        title='Are You Sure?'
        content="This will update the user's Membership Status!"
        onConfirm={() => changeUserMembershipPlan(formData)}
        onClose={() => setShouldShowSaveDialog(false)}
      />
    </>
  );
}

export type ChangePlanOmittableInput = 'plan_duration' | 'membership_plan';
