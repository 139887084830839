import {
  AuthProvider as RA_AuthProvider,
  HttpError,
  UserIdentity
} from 'react-admin';

import {
  Auth,
  AuthProvider,
  browserLocalPersistence,
  getIdToken,
  setPersistence,
  signInWithPopup,
  signOut,
  User as FirebaseUser
} from 'firebase/auth';
import { BackOfficePrivileges } from 'shared/services/firebase-auth-provider/BackOfficePrivileges.model';

import { getApiRoot } from 'shared/utils/helperFunctions/dotenv.utils';

import { AuthService } from '../backoffice-data-provider';
import { FirebaseWrapper } from '../firebase-wrapper/FirebaseWrapper';

export class FirebaseAuthProvider implements RA_AuthProvider, AuthService {
  private auth: Auth;
  private provider: AuthProvider;
  private readonly permissionsKey: string = 'app-permissions';
  constructor(wrapper: FirebaseWrapper) {
    this.auth = wrapper.auth;
    this.provider = wrapper.authProvider;
    setPersistence(this.auth, browserLocalPersistence);
  }

  getUser(): Promise<FirebaseUser | null> {
    return new Promise<FirebaseUser | null>((resolve, reject) => {
      const unsubscribe = this.auth.onIdTokenChanged((user) => {
        unsubscribe();
        if (user == null) {
          if (localStorage.getItem(this.permissionsKey) != null) {
            this.logout();
            return;
          }
        }

        resolve(user);
      });
    });
  }

  login(): Promise<void> {
    return new Promise((resolve, reject) => {
      signInWithPopup(this.auth, this.provider)
        .then(() => {
          return this.getPermissions();
        })
        .then((permissions) => {
          if (!permissions?.isAdmin) {
            reject(this.noPermissionError());
            return;
          }
          resolve();
        });
    });
  }
  logout(): Promise<string | false | void> {
    localStorage.removeItem(this.permissionsKey);
    return signOut(this.auth);
  }
  async checkAuth(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getPermissions()
        .then((permissions) => {
          if (permissions == null) {
            reject();
            return;
          }
          return this.getUser();
        })
        .then((user) => {
          if (user == null) {
            reject();
          } else {
            resolve();
          }
        });
    });
  }
  checkError(httpError: HttpError): Promise<void> {
    return new Promise((resolve, reject) => {
      const status = httpError && httpError.status;
      if (status !== 401) {
        resolve();
      }
      this.getToken()
        .then((token) => {
          if (token == null) {
            console.warn('Received authentication error from API');
            reject();
          }
        })
        .catch((e) => {
          console.log(e);
          if (localStorage.getItem(this.permissionsKey)) {
            this.logout();
          }
        });
    });
  }
  async getPermissions(): Promise<BackOfficePrivileges | null> {
    return new Promise((resolve, reject) => {
      const localPermissions = localStorage.getItem(this.permissionsKey);
      if (localPermissions != null) {
        const parsedLocal: BackOfficePrivileges = JSON.parse(localPermissions);

        if (
          parsedLocal.validUntil != null &&
          parsedLocal.validUntil > Date.now()
        ) {
          resolve(parsedLocal);
          return;
        }
      }
      this.getToken()
        .then((token) => {
          if (token == null) {
            resolve(null);
            throw this.noPermissionError();
          }
          return fetch(`${getApiRoot()}/privileges`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
        })
        .then((resp) => resp.json())
        .then((parsed) => {
          if (!parsed.isAdmin) {
            reject(this.noPermissionError());
            return;
          }

          const privileges = new BackOfficePrivileges(parsed);
          localStorage.setItem(this.permissionsKey, JSON.stringify(privileges));
          resolve(privileges);
        })
        .catch((e) => {
          console.log('Authorization Error: ', e);
          reject(e);
        });
    });
  }
  async getIdentity(): Promise<UserIdentity> {
    return new Promise<UserIdentity>((resolve, reject) => {
      this.getUser()
        .then((user) => {
          if (user == null) {
            reject(this.noUserError());
            return;
          }
          resolve({
            id: user.uid,
            fullName: user.displayName ?? '',
            avatar: user.photoURL ?? ''
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  getToken(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      this.getUser()
        .then((user) => {
          if (user == null) {
            this.logout();
            return;
          }
          return getIdToken(user);
        })
        .then((token) => {
          if (token == null) {
            resolve(null);
            return;
          }
          resolve(token);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  }
  private noUserError() {
    return new HttpError('There is no user logged In', 401);
  }
  private noPermissionError() {
    return new HttpError(
      'Sorry, but you are not registered as an admin :(',
      401
    );
  }
}
