import { RaListOrder, ApiListOrder } from '../../enums';

export class SortPayload {
  sort_by: string;
  order: ApiListOrder;

  constructor(sort_by: string, order: RaListOrder) {
    this.sort_by = sort_by;
    this.order = order.toLowerCase() as ApiListOrder;
  }
}
