import AssessmentIcon from '@mui/icons-material/Assessment';
import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { ActivitiesList } from './pages/ActivitiesList.component';
import { ActivityShow } from './pages/ActivityShow.component';

export const ActivitiesModule = RaModule.fromResourceProps({
  name: ResourceName.ACTIVITIES,
  options: { label: 'Activities' },
  list: ActivitiesList,
  show: ActivityShow,
  intent: ResourceIntent.ROUTE,
  icon: AssessmentIcon
});
