import { useMemo, useState } from 'react';

import {
  HttpError,
  LinearProgress,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

import { Box, Button, Tooltip, useTheme } from '@mui/material';

import { ContentItem } from 'resources/activities/models/ContentItem.model';
import {
  ConfirmDialog,
  DialogButtonConfig
} from 'shared/components/dialogs/BaseConfirmDialog.component';
import { ResourceName } from 'shared/enums';
import { ContentUpdateAction } from 'shared/enums/content-enums';
import { useAxiosCall } from 'shared/hooks/useAxiosCall';

import { getTitleCase } from 'shared/utils/helperFunctions/strings';

import { px } from 'shared/utils/styles/css-unit';

export function EditConsentTagButton(props: { label?: boolean }) {
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const record = useRecordContext<ContentItem>();
  const refresh = useRefresh();
  const theme = useTheme();
  const notify = useNotify();
  const tooltip = `Update consent tag`;

  const isConsent = useMemo<boolean>(() => {
    return record?.consent;
  }, [record?.consent]);

  const fetchUpdateConsent = useAxiosCall<boolean>({
    resource: `${ResourceName.BASIC_CONTENT}/${record.id}`,
    autoFetch: false,
    init: { method: 'PATCH' }
  });

  const handleConfirm = () => {
    fetchUpdateConsent
      .fetch({ consent: !isConsent, action: ContentUpdateAction.Consent })
      .then(() => handleSuccess())
      .catch((err) => handleFail(err));
  };

  const handleSuccess = () => {
    setShouldShowConfirm(false);
    refresh();
    notify(`${getTitleCase(record.type)} consent updated successfully`, {
      type: 'success'
    });
  };

  const handleFail = (e: HttpError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  if (record == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='left'
        title={tooltip}
        describeChild
        enterDelay={1000}
      >
        <div>
          <Button
            onClick={handleClick}
            sx={(theme) => ({
              px: px(5),
              py: px(4),
              color: theme.palette.primary.main,
              lineHeight: 1.5
            })}
          >
            <ThumbUpAltIcon
              fontSize='small'
              color={isConsent ? undefined : 'disabled'}
            />
            {props.label != null ? (
              <Box component='span' sx={{ pl: px(6) }}>
                {'Consent'}
              </Box>
            ) : (
              ''
            )}
          </Button>
        </div>
      </Tooltip>
      <ConfirmDialog
        onClose={handleAbort}
        onConfirm={handleConfirm}
        isOpen={shouldShowConfirm}
        loading={fetchUpdateConsent.loading}
        confirmButton={
          new DialogButtonConfig('Confirm', theme.palette.primary.main)
        }
        closeButton={new DialogButtonConfig('Abort', theme.palette.grey[500])}
        content={`Confirm to change ${record.type.toLowerCase()} consent`}
        title={tooltip}
      />
    </>
  );
}
