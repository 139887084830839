import { useMemo } from 'react';
import {
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate
} from 'react-admin';

import LockResetIcon from '@mui/icons-material/LockReset';
import { Button, CircularProgress } from '@mui/material';

import { ConfirmUpdate } from 'shared/components/dialogs/ConfirmUpdate.component';
import {
  HasOnError,
  HasOnSuccess
} from 'shared/components/interfaces/MutationComponentProps';
import { ResourceName } from 'shared/enums';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';

import { AppUser } from '../models/AppUser.model';
import { UserMutationPayload } from '../models/user-mutation-payload.dto';

export function SetPasswordButton(props: Partial<HasOnError & HasOnSuccess>) {
  const record = useRecordContext<AppUser>();
  const refresh = useRefresh();

  const [update, updateState] = useUpdate();
  const notify = useNotify();
  const handleMutateSuccess = useMemo(
    () => () => {
      notify(`Password has updated.`, { type: 'success' });
      props.onSuccess != null && props.onSuccess();
      refresh();
    },
    [notify, props, refresh]
  );
  const handleMutateFailure = useMemo(
    () => (e?: Error) => {
      notify(e?.message ?? 'Something went wrong', { type: 'error' });
      props.onError != null && props.onError();
      refresh();
    },
    [notify, props, refresh]
  );

  const {
    handleSave,
    shouldShowConfirmSubmit,
    handleConfirmSubmit,
    handleCancelSubmit
  } = useMutationVM<UserMutationPayload, AppUser>({
    record: record,
    PayloadClass: UserMutationPayload,
    mutator: update,
    resource: ResourceName.USERS,
    onMutateSuccess: handleMutateSuccess,
    onMutateFailure: handleMutateFailure
  });

  const buttonLabel = useMemo(() => {
    if (record == null) {
      return 'Loading User...';
    }
    return 'Set Password';
  }, [record]);

  const handleClick = () => {
    handleSave(new UserMutationPayload(null, true));
  };

  const isDisabled = useMemo(
    () => record == null || updateState.loading,
    [record, updateState.loading]
  );

  if (record == null) {
    return <CircularProgress size='sm' />;
  }
  return (
    <>
      <Button
        onClick={handleClick}
        disabled={isDisabled}
        size='small'
        variant='contained'
      >
        <LockResetIcon />
        {buttonLabel}
      </Button>
      <ConfirmUpdate
        onClose={handleCancelSubmit}
        onConfirm={handleConfirmSubmit}
        isOpen={shouldShowConfirmSubmit}
        recordName='User'
        content={'You are about to change user password'}
        title={'Update User Password'}
      />
    </>
  );
}
