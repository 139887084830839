import {
  BooleanInput,
  Button,
  ConfirmProps,
  SaveButton,
  SimpleForm,
  Toolbar
} from 'react-admin';

import CancelSharpIcon from '@mui/icons-material/CancelSharp';

import SendIcon from '@mui/icons-material/Send';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

export interface DialogProps extends Omit<ConfirmProps, 'onConfirm'> {
  label: string;
  defaultValue: boolean;
  onSend(date: boolean | null): void;
}

export function EditCommunityStatusDialog(props: DialogProps) {
  return (
    <Dialog disableScrollLock={true} open={props.isOpen ?? false}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
        <SimpleForm
          save={(values: any) => props.onSend(values.override)}
          toolbar={
            <Toolbar
              style={{
                display: 'flex',
                gap: '16px' // Adds a gap between all children
              }}
            >
              <SaveButton
                label='Confirm'
                icon={<SendIcon fontSize='small' />}
              />
              <Button
                onClick={() => props.onSend(null)}
                color='secondary'
                label='Reset'
              ></Button>
              <Button onClick={props.onClose} label='Abort'></Button>{' '}
            </Toolbar>
          }
        >
          <BooleanInput
            label={props.label}
            source='override'
            isRequired={false}
            defaultValue={props.defaultValue}
          ></BooleanInput>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
}
