import {
  Button,
  ConfirmProps,
  SaveButton,
  SimpleForm,
  Toolbar
} from 'react-admin';

import SendIcon from '@mui/icons-material/Send';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { AutocompleteReferenceInput } from 'shared/components/inputs/AutocompleteReferenceInput.component';
import { ResourceName } from 'shared/enums';
import { RaListOrder } from 'shared/services/backoffice-data-provider';

export interface DialogProps extends Omit<ConfirmProps, 'onConfirm'> {
  onSend(content_id: string): void;
}

export function AddToPackageFormDialog(props: DialogProps) {
  return (
    <Dialog disableScrollLock={true} open={props.isOpen ?? false}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
        <SimpleForm
          submitOnEnter={false}
          save={(values: any) => props.onSend(values.id)}
          toolbar={
            <Toolbar>
              <SaveButton
                label='Confirm'
                icon={<SendIcon fontSize='small' />}
              />
              <Button onClick={props.onClose} label='Abort'></Button>{' '}
            </Toolbar>
          }
        >
          <AutocompleteReferenceInput
            filter={{ status: 'PUBLIC' }}
            source='id'
            filterKey='search'
            label={'Activity Name'}
            sort={{ field: 'display_name', order: RaListOrder.ASC }}
            reference={ResourceName.ACTIVITIES}
            optionText='display_name'
            resettable
          ></AutocompleteReferenceInput>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
}
