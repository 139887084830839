import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

export const PlansModule = RaModule.fromResourceProps({
  name: ResourceName.MEMBERSHIP_PLANS,
  intent: ResourceIntent.REGISTRATION,
  // icon: AltRouteIcon,
  options: { label: 'Membership Plans' }
  // list: ListGuesser,
  // show: ShowGuesser
});
