import {
  BackOfficeDataProvider,
  defaultUrlRenderer
} from 'shared/services/backoffice-data-provider';

import { StoreKeyDtoConverter } from './StoreKeyDtoConverter';

export class StoreKeysDataProvider extends BackOfficeDataProvider {
  protected dtoConverter = new StoreKeyDtoConverter();
  protected urlRenderer = defaultUrlRenderer;
}
