import {
  Edit,
  EditProps,
  useEditContext,
  useNotify,
  useUpdate
} from 'react-admin';

import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { ConfirmUpdate } from 'shared/components/dialogs/ConfirmUpdate.component';
import { UnchangedDialog } from 'shared/components/dialogs/UnchangedDialog.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';

import {
  MembershipDisplayForm,
  MembershipDisplayFormSource
} from './MembershipDisplayForm.component';
import { MembershipDisplayModel } from '../models/MembershipDisplay.model';

export function MemberhsipDisplayEdit(props: EditProps) {
  return (
    <Edit {...props} title='Offers'>
      <MembershpiDisplayEditForm />
    </Edit>
  );
}

function MembershpiDisplayEditForm() {
  const notify = useNotify();
  const [update, updateState] = useUpdate();
  const editContext = useEditContext<MembershipDisplayModel>();

  const {
    shouldShowConfirmSubmit,
    shouldShowUnchanged,
    shouldShowUnsaved,
    goBack,
    handleSave,
    handleCancelEdit,
    handleCancelSubmit,
    handleConfirmSubmit,
    handleUnchangedContinue,
    handleUnchangedQuit,
    handleUnsavedCancel,
    handleUnsavedConfirm
  } = useMutationVM<MembershipDisplayModel, MembershipDisplayModel>({
    PayloadClass: MembershipDisplayModel,
    record: editContext.record,
    resource: ResourceName.MEMBERSHIP_DISPLAY,
    mutator: update,
    onMutateFailure: handleUpdateFailure,
    onMutateSuccess: handleUpdateSuccess
  });
  function handleUpdateSuccess() {
    notify('Plan content updated successfully.', { type: 'success' });
    goBack();
  }
  function handleUpdateFailure(e: Error) {
    notify(
      e.message || 'Something went wrong. Please try again in a few seconds.',
      { type: 'error' }
    );
  }
  return (
    <>
      <MembershipDisplayForm
        onCancel={handleCancelEdit}
        onSave={handleSave}
        fieldsToOmit={[
          MembershipDisplayFormSource.source_plan,
          MembershipDisplayFormSource.target_plan
        ]}
      />
      <ConfirmUpdate
        loading={editContext.loading || updateState.loading}
        isOpen={shouldShowConfirmSubmit}
        onConfirm={handleConfirmSubmit}
        onClose={handleCancelSubmit}
        recordName="Plan's Content"
      />
      <ConfirmUnsaved
        loading={editContext.loading}
        isOpen={shouldShowUnsaved}
        onClose={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
      />
      <UnchangedDialog
        name="Plan's Content"
        itemPageName="Plan's Content"
        isOpen={shouldShowUnchanged}
        onContinue={handleUnchangedContinue}
        onQuit={handleUnchangedQuit}
      />
    </>
  );
}
