import { useMemo } from 'react';

import {
  DateInput,
  Edit,
  HttpError,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useEditContext,
  useUpdate
} from 'react-admin';

import { useParams } from 'react-router-dom';

import { Button } from '@mui/material';
import { HasId } from 'resources/types';
import { ConfirmUpdate } from 'shared/components/dialogs/ConfirmUpdate.component';
import { HasOnCancel } from 'shared/components/interfaces/MutationComponentProps';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';

import { UserPlanEditDto, UserPlan } from '../models';

export interface EditExpireDateFormProps {
  onSuccess(): void;
  onCancel(): void;
  onError(e: HttpError): void;
}

function EditExpireDateForm(props: EditExpireDateFormProps) {
  const { onSuccess, onCancel, onError } = props;

  const editContext = useEditContext<UserPlan>();

  const [update, updateState] = useUpdate();

  const handleSuccess = useMemo(
    () => () => {
      onSuccess();
    },
    [onSuccess]
  );
  const handleError = useMemo(
    () => (e: HttpError) => {
      onError(e);
    },
    [onError]
  );

  const {
    handleSave,
    handleConfirmSubmit,
    handleCancelSubmit,
    shouldShowConfirmSubmit
  } = useMutationVM<UserPlanEditDto, UserPlan>({
    record: editContext.record,
    resource: ResourceName.USERS_PLANS,
    PayloadClass: UserPlanEditDto,
    mutator: update,
    onMutateFailure: handleError,
    onMutateSuccess: handleSuccess
  });

  const handleSubmit = useMemo(
    () => (values: Record<'expire_date', Date>) => {
      console.log('rawVals: ', values);

      const dateObj = new Date(values.expire_date);

      // console.log('dateVal: ', dateObj.toISOString());
      handleSave({ expiration_date: dateObj.toISOString() });
    },
    [handleSave]
  );
  const handleCancel = useMemo(
    () => () => {
      onCancel();
    },
    [onCancel]
  );

  return (
    <>
      <SimpleForm
        toolbar={<EditExpirationToolbar onCancel={handleCancel} />}
        save={handleSubmit}
        submitOnEnter={false}
      >
        <DateInput source='expire_date' locale='iso' />
      </SimpleForm>

      <ConfirmUpdate
        onConfirm={handleConfirmSubmit}
        onClose={handleCancelSubmit}
        isOpen={shouldShowConfirmSubmit}
        recordName='User'
        loading={editContext.loading || updateState.loading}
      />
    </>
  );
}

function EditExpirationToolbar({
  onCancel,
  ...toolbarProps
}: HasOnCancel & ToolbarProps) {
  return (
    <Toolbar {...toolbarProps}>
      <SaveButton redirect={false} />
      <Button onClick={onCancel}>Cancel</Button>
    </Toolbar>
  );
}

export function EditExpireDate(props: EditExpireDateFormProps) {
  const params = useParams<HasId>();
  return (
    <Edit
      resource={ResourceName.USERS_PLANS}
      basePath={`/${ResourceName.USERS_PLANS}`}
      id={params.id}
      title=' '
    >
      <EditExpireDateForm {...props} />
    </Edit>
  );
}
