import { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Report';

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import { BPError } from 'shared/services/backoffice-data-provider';
import { isNodeEnv } from 'shared/utils/helperFunctions/dotenv.utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1em'
    },
    fontFamily: 'Roboto, sans-serif',
    opacity: 0.5
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: '3em',
    height: '3em',
    marginRight: '0.5em'
  },
  panel: {
    marginTop: '1em',
    maxWidth: '60em'
  },
  panelSummary: {
    userSelect: 'all'
  },
  panelDetails: {
    whiteSpace: 'pre-wrap'
  }
}));

interface BPErrorViewProps {
  error: BPError;
}

export function BPErrorView(props: BPErrorViewProps): JSX.Element {
  const { error } = props;
  const classes = useStyles(props);
  return (
    <Fragment>
      <div className={classes.container}>
        <h1 className={classes.title} role='alert'>
          <ErrorIcon className={classes.icon} />
          {error.message}
        </h1>
        <div>{`A client error occurred and your request couldn't be completed.`}</div>
        {!isNodeEnv('prod') && (
          <Accordion className={classes.panel}>
            <AccordionSummary className={classes.panelSummary}>
              {error?.cause?.message}
            </AccordionSummary>
          </Accordion>
        )}
      </div>
    </Fragment>
  );
}
