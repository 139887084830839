import {
  ArrayField,
  BooleanField,
  Datagrid,
  ImageField,
  NumberField,
  RichTextField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import UrlFieldNewWindow from 'shared/components/fields/UrlFieldNewWindow.component';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { SectionName } from 'shared/enums/section-name.enum';
import { vh, rem } from 'shared/utils/styles/css-unit';

import { ContentItem } from '../models/ContentItem.model';

export function ActivityShow(props: ShowProps) {
  return (
    <Show
      {...props}
      title='Activities'
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'create', 'edit']}
          sectionName={SectionName.Content}
        />
      }
    >
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <ContentTitle />
        </Typography>
        <TabbedShowLayout>
          <Tab label='Details'>
            <TextField source='id' label='Id' emptyText='-' />
            <TextField source='display_name' label='Name' emptyText='-' />
            <BooleanField
              source='is_popular'
              label='Is Popular'
              emptyText='-'
            />
            <TextField source='engine' label='Engine' emptyText='-' />
            <RichTextField
              source='description'
              label='Description'
              emptyText='-'
            />
            <NumberField source='plays' label='Times played' emptyText='-' />
            <TextField source='share_link' label='Share link' emptyText='-' />
          </Tab>
          <Tab label='Covers'>
            <ImageField
              source='cover_box'
              label='Cover Box (690x690)'
              emptyText='-'
            />
            <ImageField
              source='cover_rect'
              label='Cover Rect (690x585)'
              emptyText='-'
            />
            <ImageField
              source='cover_small'
              label='Cover Small (567x444)'
              emptyText='-'
            />
          </Tab>
          <Tab label='Media'>
            <ArrayField source='media'>
              <Datagrid>
                <TextField source='type'></TextField>
                <UrlFieldNewWindow shortString={60} source='url' />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
}

export function ContentTitle() {
  const content = useRecordContext<ContentItem>();
  return <>{content.display_name}</>;
}
