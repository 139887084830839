import {
  AutocompleteInput,
  OnCreateHandler,
  ReferenceInput,
  required
} from 'react-admin';

import omit from 'lodash/omit';
import { CustomersSortableFieldsEnum } from 'resources/customers/customers-sortable-fields.enum';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { RaListOrder } from 'shared/services/backoffice-data-provider/enums/ra-list-order.enum';
import { CustomerTypesEnum } from 'shared/utils/constants/blazepod';

import { CodesFormSource } from './CodesFormSource.enum';

export function CustomerInput(props: { onCreateNewClick: OnCreateHandler }) {
  const customerIdFilterToQuery = (filter: string) => {
    const getSearch = filter.length > 0 ? { search: filter } : {};
    return {
      ...getSearch,
      customer_type: Object.values(omit(CustomerTypesEnum, ['INDIVIDUAL']))
    };
  };
  return (
    <ReferenceInput
      source={CodesFormSource.customer_id}
      reference={ResourceName.CUSTOMERS}
      sort={{
        field: CustomersSortableFieldsEnum.full_name,
        order: RaListOrder.ASC
      }}
      filterToQuery={customerIdFilterToQuery}
      validate={required()}
    >
      <AutocompleteInput
        optionText='alias'
        onCreate={props.onCreateNewClick}
        resettable
        createLabel='+ Create New Customer'
        createkItemLabel='+ Create New Customer'
      />
    </ReferenceInput>
  );
}
