import { CALC_OPERATION } from './calc-operations.const';

/**
 * except the calc method, in any of these functions enter a number and get it stringified with css size unit
 */
export const cssUnit = {
  /** rem
   * @param amount number
   * @return the number stringed with the 'rem' css size unit
   */
  rem: (amount: number) => `${amount}rem`,
  /** em
   * @param amount number
   * @return the number stringed with the 'em' css size unit
   */
  em: (amount: number) => `${amount}em`,
  /** vw
   * @param amount number
   * @return the number stringed with the 'vw' css size unit
   * */
  vw: (amount: number) => `${amount}vw`,
  /** vh
   * @param amount number
   * @return the number stringed with the 'vh' css size unit
   */
  vh: (amount: number) => `${amount}vh`,
  /** fr(grid)
   * @param amount number
   * @return the number stringed with the 'fr' css grid size unit
   */
  fr: (amount: number) => `${amount}fr`,
  /** px
   * @param amount number
   * @return the number stringed with the 'px' css size unit
   */
  px: (amount: number) => `${amount}px`,
  /** %(percent)
   * @param amount number
   * @return the number stringed with the '%' css size unit
   */
  p: (amount: number) => `${amount}%`,
  /**
   *
   * @param args write the operation for the css calc function as an array (example: calc(vh(100), "-" ,rem(3)))
   * @returns a css calc function as a string
   */
  calc(...args: (string | number | CALC_OPERATION)[]) {
    return `calc(${args.join(' ')})`;
  }
};

export const { calc, em, fr, p, px, rem, vh, vw } = cssUnit;
