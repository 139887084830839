import * as React from 'react';
import { useMemo } from 'react';
import { FunctionField, Record, useRecordContext } from 'react-admin';

import { Button, Stack, useTheme } from '@mui/material';
import { MembershipPlanProvider } from 'resources/customers/enums/MembershipPlanProvider.enum';
import { UserPlan } from 'resources/users_plans/models/user-plan.model';
import { em } from 'shared/utils/styles/css-unit';

import { HasOnEditExpireDate } from '../../interfaces/HasOnEditExpireDate';
import { HasPermission } from '../../interfaces/HasPermission';

export function PlanExpirationDate(props: HasOnEditExpireDate & HasPermission) {
  const record = useRecordContext<UserPlan>();
  const theme = useTheme();

  const disableExpiredDate = useMemo(() => {
    const allowProvider = new Array<MembershipPlanProvider>(
      MembershipPlanProvider.BlazePod,
      MembershipPlanProvider.Shopify,
      MembershipPlanProvider.ShopifyEU
    );
    if (
      allowProvider.includes(record.provider) &&
      record.expire_date !== null &&
      props.permission
    ) {
      return false;
    }
    return true;
  }, [props.permission, record]);

  return (
    <Stack sx={{ alignItems: 'center' }} direction='row' spacing={4}>
      <FunctionField
        source='expire_date'
        render={(record?: Record) => {
          const date = record?.expire_date
            ? new Date(record.expire_date).toLocaleDateString('en-GB')
            : null;
          return record != null &&
            record.provider === MembershipPlanProvider.None
            ? date ?? '-'
            : date ?? 'Forever';
        }}
      />
      <div
        style={{
          height: em(1.7),
          borderLeft: `1px solid ${theme.palette.grey[400]}`
        }}
      />
      <Button onClick={props.onEditExpireDate} disabled={disableExpiredDate}>
        Edit Expire Date
      </Button>
    </Stack>
  );
}
