import { useMemo, useState } from 'react';

import {
  minValue,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  useRecordContext,
  useRefresh
} from 'react-admin';

import { Box, Divider, Drawer, Typography } from '@mui/material';

import { uniqueId } from 'lodash';

import { CodesCreationPayload } from 'resources/codes/models/codes-creation-payload.model';
import { CodesMutationPayload } from 'resources/codes/models/codes-mutation-payload.model';
import { CodesTitle } from 'resources/codes/pages/CodesShow.component';
import { CustomersCreate } from 'resources/customers/components/CustomersCreate.component';
import { HasCustomerId } from 'resources/customers/interfaces';
import { FieldObject } from 'resources/types';
import { LabelWrapper } from 'shared/components/fields/LabelWrapper.component';
import { ResourceName } from 'shared/enums/resource-name.enum';

import { vh, rem } from 'shared/utils/styles/css-unit';

import { CodesFormSource } from './CodesFormSource.enum';
import { CodesFormToolbar } from './CodesFormToolbar.component';
import { ContentPackagesFormGroup } from './ContentPackagesFormGroup.component';
import { CustomerInput } from './CustomerInput.component';
import { ExpiredDateInput } from './ExpiredDateInput.component';
import { PlanDurationFormGroup } from './PlanDurationFormGroup.component';

export interface CodesFormProps extends Partial<HasCustomerId> {
  onSave(values: Partial<CodesMutationPayload>): void;
  onCancel(): void;
  fieldsToOmit?: CodesFormSource[];
}

export function CodesForm(props: CodesFormProps) {
  const { onCancel, onSave, fieldsToOmit } = props;
  const [isCreateCustomerOpen, setIsCreateCustomerOpen] = useState(false);
  const [defaultCustomerCreateAlias, setDefaultCustomerCreateAlias] =
    useState<string>();

  const record = useRecordContext();

  const refresh = useRefresh();

  const shouldShowField = useMemo(
    () => (field: CodesFormSource) => {
      return fieldsToOmit ? !fieldsToOmit.includes(field) : true;
    },
    [fieldsToOmit]
  );
  const handleCustomerCreateSuccess = () => {
    refresh();
    setIsCreateCustomerOpen(false);
  };
  const handleCreateCustomerClick = (filter?: string) => {
    setIsCreateCustomerOpen(true);
    setDefaultCustomerCreateAlias(filter);
  };

  const handleSave = useMemo(
    () => (values: Partial<CodesCreationPayload>) => {
      const expired_date = values.expired_date
        ? new Date(values.expired_date).toISOString()
        : null;
      values = { ...values, expired_date };
      onSave({ ...values });
    },
    [onSave, props.customer_id]
  );
  const codesCreationFields = useMemo(() => {
    const fields: FieldObject<CodesFormSource>[] = [
      {
        source: CodesFormSource.number_of_codes,
        element: (
          <NumberInput
            key={uniqueId()}
            min={0}
            source={CodesFormSource.number_of_codes}
            validate={[required(), minValue(1)]}
          />
        )
      },
      {
        source: CodesFormSource.customer_id,
        element: (
          <CustomerInput
            key={uniqueId()}
            onCreateNewClick={handleCreateCustomerClick}
          />
        )
      },
      {
        source: CodesFormSource.description,
        element: (
          <TextInput
            label='Batch Description'
            key={uniqueId()}
            source={CodesFormSource.description}
          />
        )
      }
    ];
    const filteredFields = fields.filter((field) =>
      shouldShowField(field.source)
    );
    if (filteredFields.length === 0) {
      return [];
    }
    return filteredFields.map((field) => field.element);
  }, [shouldShowField]);

  const divider = useMemo(() => <Divider sx={{ mb: 2.4 }} />, []);
  return (
    <>
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <CodesTitle />
        </Typography>
        <SimpleForm
          submitOnEnter={false}
          save={handleSave}
          toolbar={<CodesFormToolbar onCancel={onCancel} />}
        >
          {codesCreationFields.length > 0 && (
            <LabelWrapper component='h4'>Codes Creation</LabelWrapper>
          )}
          {codesCreationFields}
          {codesCreationFields.length > 0 && divider}
          {shouldShowField(CodesFormSource.plan_duration) && (
            <PlanDurationFormGroup />
          )}
          {shouldShowField(CodesFormSource.expired_date) && (
            <>
              {divider}
              <LabelWrapper component='h4'>
                {codesCreationFields.length > 0 ? 'Codes' : 'Code'} Expiration
              </LabelWrapper>
              <ExpiredDateInput
                id={record.code}
                source={CodesFormSource.expired_date}
              />
            </>
          )}
          {shouldShowField(CodesFormSource.packages) && (
            <>
              {divider}
              <ContentPackagesFormGroup />
            </>
          )}
        </SimpleForm>
      </Box>
      {shouldShowField(CodesFormSource.customer_id) && (
        <Drawer
          disableScrollLock={true}
          open={isCreateCustomerOpen}
          anchor='right'
        >
          <CustomersCreate
            onCancel={() => setIsCreateCustomerOpen(false)}
            handleSuccess={handleCustomerCreateSuccess}
            basePath={`/${ResourceName.CUSTOMERS}`}
            resource={ResourceName.CUSTOMERS}
            imperativeResource={ResourceName.CUSTOMERS}
            aliasDefaultValue={defaultCustomerCreateAlias}
          />
        </Drawer>
      )}
    </>
  );
}
