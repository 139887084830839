import { useMemo } from 'react';

import {
  TextInput,
  Record,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  useRecordContext,
  ImageField,
  required,
  maxLength,
  ImageInput,
  SelectInput,
  BooleanInput
} from 'react-admin';

import { Box, Typography } from '@mui/material';

import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

import RichTextInput from 'ra-input-rich-text';
import { FormDivider } from 'shared/components/common/FormDivider.componen';

import { SaveCancelFormToolbar } from 'shared/components/toolbars/SaveCancelFormToolbar.component';

import { Storage } from 'shared/services/firebase-wrapper/FirebaseWrapper';
import { rem, vh } from 'shared/utils/styles/css-unit';

import { v4 } from 'uuid';

import { storeOptionValues } from '../models/referral-types';
import { ReferralsMutationPayload } from '../models/referrals-mutation-payload.model';
import { ReferralTitle } from '../pages/ReferralShow.component';

export interface ReferralsFormProps {
  onSave(values: Partial<ReferralsMutationPayload>): void;
  onCancel(): void;
  uid?: string;
  title: string;
}

export function ReferralsForm(props: ReferralsFormProps) {
  const { onCancel, onSave, title, uid } = props;
  const record = useRecordContext();
  const UploadImageToFirebase = async (upload: any) => {
    const file = upload?.rawFile;
    const referral_id = record.id ?? uid;
    const fileRef = ref(
      Storage,
      `monetization/referral/${referral_id}/${file.name + v4()}`
    );
    let url;
    try {
      await uploadBytes(fileRef, file).then(async (data) => {
        await getDownloadURL(data.ref).then((firebaseUrl) => {
          url = firebaseUrl;
        });
      });
      return url;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = useMemo(
    () => async (data: Partial<Record>) => {
      const need_upload = data.media && typeof data.media != 'string';
      if (need_upload) {
        const url = await UploadImageToFirebase(data.media);
        data.media = url;
      }
      onSave(
        ReferralsMutationPayload.factory({
          ...data
        })
      );
    },
    [onSave]
  );

  return (
    <>
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          {title}
          <ReferralTitle />
        </Typography>
        <SimpleForm
          submitOnEnter={false}
          save={handleSave}
          toolbar={<SaveCancelFormToolbar onCancel={onCancel} />}
        >
          <FormDivider title='Details' removeDivider></FormDivider>
          <TextInput label={'Name'} source='name' validate={[required()]} />
          <TextInput
            label={'Description'}
            source='description'
            fullWidth={true}
          ></TextInput>
          <FormDivider title='Configuration'></FormDivider>
          <SelectInput
            validate={[required()]}
            source='payload.store'
            choices={storeOptionValues}
            label={'Store'}
          ></SelectInput>
          <TextInput
            label={'Referal program id'}
            source='payload.program_id'
            validate={[required()]}
          />
          <BooleanInput label={'With PayPal'} source='payload.show_paypal' />
          <FormDivider title='Referral'></FormDivider>
          <ImageInput source='media' accept='image/*' validate={[required()]}>
            <ImageField source='media'></ImageField>
          </ImageInput>
          <TextInput
            label={'Headline'}
            source='display_text.headline'
            validate={[required()]}
          />
          <RichTextInput
            label={'Body'}
            source='display_text.body'
            validate={[required()]}
          ></RichTextInput>

          <FormDivider title="How it work's"></FormDivider>
          <ArrayInput
            source='display_text.how_it_works'
            label=''
            validate={[maxLength(5, 'Only 5 section is allowed'), required()]}
          >
            <SimpleFormIterator>
              <TextInput label={'Title'} source='title' />
              <RichTextInput label={'Text'} source='text'></RichTextInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Box>
    </>
  );
}
