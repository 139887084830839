import {
  BooleanField,
  BooleanFieldProps,
  DateField,
  DateFieldProps,
  FieldProps,
  TextField,
  TextFieldProps
} from 'react-admin';

import { TypedField } from 'shared/interfaces/react-admin/typed-field';

import { UserPlan } from '../models';

type UserPlanField<P extends FieldProps> = TypedField<
  P & { source: keyof UserPlan }
>;
export const UserPlanTextField = TextField as UserPlanField<TextFieldProps>;
export const UserPlanDateField = DateField as UserPlanField<DateFieldProps>;
export const UserPlanBooleanField =
  BooleanField as UserPlanField<BooleanFieldProps>;
