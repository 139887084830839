import InventoryIcon from '@mui/icons-material/Inventory';
import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { PackagesShow } from './pages/PackageShow.component';

import { PackagesList } from './pages/PackagesList.component';

export const ContentPackagesModule = RaModule.fromResourceProps({
  name: ResourceName.CONTENT_PACKAGES,
  intent: ResourceIntent.ROUTE,
  options: { label: 'Content Packages' },
  list: PackagesList,
  show: PackagesShow,
  icon: InventoryIcon
});
