export const enum NavbarClassNames {
  open = 'navbar_open-0x352af4c',
  closed = 'navbar_closed-0x2bb2326',
  root = 'navbar_root-0x43c44ee'
}
export const enum SubMenuClassNames {
  icon = 'submenu_icon-0x334f6f1',
  sidebarIsOpen = 'submenu_closed-0x2144093',
  sidebarIsClosed = 'submenu_open-0x25c4f43'
}
