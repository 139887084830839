import { AuthProvider } from 'react-admin';

export async function buildBlazepodHeaders(
  authProvider: AuthProvider,
  apiMethod?: string,
  apiBody?: any
) {
  const token = await authProvider.getToken();
  const method = apiMethod ?? 'GET';
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': `application/json`
  };
  const body = JSON.stringify(apiBody);
  if (apiBody) {
    return { method, headers, body };
  }
  return { method, headers };
}
