import { ListProps, SelectInput } from 'react-admin';

import { onlinePlanProviders } from 'resources/customers/enums/MembershipPlanProvider.enum';
import { CustomerTypesEnum } from 'shared/utils/constants/blazepod';

import { CustomersList } from '../customers/components/CustomersList.component';

export function OnlineCustomersList(props: ListProps) {
  return (
    <CustomersList
      {...props}
      extendedfilter={[
        <SelectInput
          source='origin'
          optionText='name'
          choices={onlinePlanProviders()}
          resettable
        />
      ]}
      typesToSum={[CustomerTypesEnum.INDIVIDUAL]}
      omitFields={['creator', 'creator_id', 'alias']}
    />
  );
}
