import {
  ContentType,
  BPContentEngineEnum,
  ContentVisibility,
  ContentCreatorType,
  BPMediaType
} from 'shared/enums/content-enums';

export class BPMedia {
  url: string;
  type: BPMediaType;
  text?: string;

  constructor(url: string, type: BPMediaType, text?: string) {
    this.url = url;
    this.type = type;
    this.text = text;
  }
}

class fieldItem {
  value: string;
  constructor(value: string) {
    this.value = value;
  }
}
export class ContentItem {
  id: string;
  old_activity_key: string;
  type: ContentType;
  engine: BPContentEngineEnum;
  display_name: string;
  description: string;
  cover_big: string;
  cover_small: string;
  media: BPMedia[];
  tags: string[];
  creator_type: ContentCreatorType;
  creator_id: string;
  visibility: ContentVisibility;
  min_content_version: number;
  created_at: string;
  updated_at: string;
  published_at: string;
  new_until: string;
  origin_custom_key: string;
  cover_box: string;
  cover_rect: string;
  draft_of: string;
  replaced_by: string;
  random_order: number;
  share_link: string;
  my_draft: string;
  trending_until: any;
  is_popular: boolean;
  has_benchmark: boolean;
  community_valid: boolean;
  consent: boolean;
  published: boolean;
  plays: number;
  has_video: boolean;
  settings: any;
  accessories: fieldItem[];
  fields: fieldItem[];
  goals: fieldItem[];
  challenge_until: string | null;

  constructor(
    id: string,
    old_activity_key: string,
    type: ContentType,
    engine: BPContentEngineEnum,
    display_name: string,
    description: string,
    cover_big: string,
    cover_small: string,
    media: BPMedia[],
    tags: string[],
    creator_type: ContentCreatorType,
    creator_id: string,
    visibility: ContentVisibility,
    min_content_version: number,
    created_at: string,
    updated_at: string,
    published_at: string,
    new_until: string,
    origin_custom_key: string,
    cover_box: string,
    cover_rect: string,
    draft_of: string,
    replaced_by: string,
    random_order: number,
    share_link: string,
    my_draft: string,
    labels: any,
    is_popular: boolean,
    has_benchmark: boolean,
    community_valid: boolean,
    consent = false,
    published = false,
    plays: string,
    challenge_until: string | null = null,
    settings: any = null,
    accessories: string[] = new Array<string>(),
    fields: string[] = new Array<string>(),
    goals: string[] = new Array<string>()
  ) {
    this.id = id;
    this.old_activity_key = old_activity_key;
    this.type = type;
    this.engine = engine;
    this.display_name = display_name;
    this.description = description;
    this.cover_big = cover_big;
    this.cover_small = cover_small;
    this.media = media;
    this.tags = tags;
    this.creator_type = creator_type;
    this.creator_id = creator_id;
    this.visibility = visibility;
    this.min_content_version = min_content_version;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.published_at = published_at;
    this.new_until = labels?.new_until ?? null;
    this.origin_custom_key = origin_custom_key;
    this.cover_box = cover_box;
    this.cover_rect = cover_rect;
    this.draft_of = draft_of;
    this.replaced_by = replaced_by;
    this.random_order = random_order;
    this.share_link = share_link;
    this.my_draft = my_draft;
    this.trending_until = labels?.trending_until ?? null;
    this.is_popular = is_popular;
    this.has_benchmark = has_benchmark;
    this.community_valid = community_valid;
    this.consent = consent;
    this.published = published;
    this.plays = Number.parseInt(plays);
    this.has_video =
      media.filter((m) => m.type === BPMediaType.VIDEO).length > 0;
    this.settings = settings;
    this.accessories = accessories?.map((v) => {
      return { value: v };
    });
    this.fields = fields?.map((v) => {
      return { value: v };
    });
    this.goals = goals?.map((v) => {
      return { value: v };
    });
    this.challenge_until = challenge_until;
  }

  static fromDto(content: any) {
    return new ContentItem(
      content.id,
      content.old_activity_key,
      content.type,
      content.engine,
      content.display_name,
      content.description,
      content.cover_big,
      content.cover_small,
      content.media,
      content.tags,
      content.creator_type,
      content.creator_id,
      content.visibility,
      content.min_content_version,
      content.created_at,
      content.updated_at,
      content.published_at,
      content.new_until,
      content.origin_custom_key,
      content.cover_box,
      content.cover_rect,
      content.draft_of,
      content.replaced_by,
      content.random_order,
      content.share_link,
      content.my_draft,
      content.labels,
      content.is_popular,
      content.has_benchmark,
      content.community_valid,
      content.consent,
      content.published,
      content.plays,
      content.challenge_until,
      content.settings,
      content.accessories,
      content.fields,
      content.goals
    );
  }
}
