export class MembershipDisplayPayload {
  benefits_text: Record<'benefit_text', string>[];
  button: string;
  headline: string;
  plans_title: string;

  constructor(
    headline: string,
    plans_title: string,
    button: string,
    benefits_text: Record<'benefit_text', string>[]
  ) {
    this.benefits_text = benefits_text;
    this.button = button;
    this.headline = headline;
    this.plans_title = plans_title;
  }
}

export class MembershipDisplayModel {
  id: string;
  source_plan: string;
  target_plan: string;
  payload: MembershipDisplayPayload;
  name: string;

  constructor(
    id: string,
    source_plan: string,
    target_plan: string,
    payload: MembershipDisplayPayload,
    name: string
  ) {
    this.id = id;
    this.source_plan = source_plan;
    this.target_plan = target_plan;
    this.payload = payload;
    this.name = name;
  }

  static fromDto(membershipDisplay: any) {
    const benefits_text = membershipDisplay.payload.benefits_text as string[];
    membershipDisplay.payload.benefits_text = benefits_text.map((item) => {
      return { benefit_text: item };
    });
    return new MembershipDisplayModel(
      membershipDisplay.id,
      membershipDisplay.source_plan,
      membershipDisplay.target_plan,
      membershipDisplay.payload,
      membershipDisplay.name
    );
  }
}
