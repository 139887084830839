import { useMemo, useState } from 'react';

import {
  HttpError,
  LinearProgress,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';

import FiberNewIcon from '@mui/icons-material/FiberNew';

import { Box, Button, Tooltip } from '@mui/material';

import { ContentItem } from 'resources/activities/models/ContentItem.model';
import { ResourceName } from 'shared/enums';
import { ContentUpdateAction } from 'shared/enums/content-enums';
import { useAxiosCall } from 'shared/hooks/useAxiosCall';

import { getTitleCase } from 'shared/utils/helperFunctions/strings';

import { px } from 'shared/utils/styles/css-unit';

import { EditDateFormDialog } from './EditDateFormDialog.component';

export function EditNewTagButton(props: { label?: boolean }) {
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const record = useRecordContext<ContentItem>();
  const refresh = useRefresh();
  const notify = useNotify();
  const tooltip = `Update new tag`;

  const isNewTagEnabled = useMemo<boolean>(() => {
    const newDate = new Date(record.new_until);
    const today = new Date();
    return newDate > today;
  }, [record?.new_until]);

  const fetchNewUntil = useAxiosCall<boolean>({
    resource: `${ResourceName.BASIC_CONTENT}/${record.id}`,
    autoFetch: false,
    init: { method: 'PATCH' }
  });

  const handleConfirm = (new_until: string) => {
    fetchNewUntil
      .fetch({
        new_until,
        action: ContentUpdateAction.Tags
      })
      .then(() => handleSuccess())
      .catch((err) => handleFail(err));
  };

  const handleSuccess = () => {
    setShouldShowConfirm(false);
    refresh();
    notify(`New tag updated successfully`, { type: 'success' });
  };

  const handleFail = (e: HttpError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  if (record == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='left'
        title={tooltip}
        describeChild
        enterDelay={1000}
      >
        <div>
          <Button
            onClick={handleClick}
            sx={(theme) => ({
              px: px(5),
              py: px(4),
              color: theme.palette.primary.main,
              lineHeight: 1.5
            })}
          >
            <FiberNewIcon
              fontSize='small'
              color={isNewTagEnabled ? undefined : 'disabled'}
            />
            {props.label != null ? (
              <Box component='span' sx={{ pl: px(6) }}>
                {'New Tag'}
              </Box>
            ) : (
              ''
            )}
          </Button>
        </div>
      </Tooltip>
      <EditDateFormDialog
        onClose={handleAbort}
        onSend={handleConfirm}
        loading={fetchNewUntil.loading}
        isOpen={shouldShowConfirm}
        defaultValue={record?.new_until}
        content={`Confirm to change ${getTitleCase(record.type)} new tag`}
        title={`Update New tag`}
        label={'New Until'}
      />
    </>
  );
}
