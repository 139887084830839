import {
  ArrayField,
  Datagrid,
  DateField,
  ReferenceField,
  SimpleShowLayoutProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { CustomerLinkField } from 'resources/customers/components/fields/CustomerLinkField.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';

import { rem, vh } from 'shared/utils/styles/css-unit';

import {
  CodesDateField,
  CodesFunctionField,
  CodesReferenceField,
  CodesTextField
} from './codes-fields';
import { MembershipCode } from '../models/MembershipCode.model';
import { CodesTitle } from '../pages/CodesShow.component';
import { renderPlanDurationJSX } from '../utils/renderPlanDuration';

export type CodesShowLayoutProps = Omit<SimpleShowLayoutProps, 'children'>;

export function CodesShowLayout(props: CodesShowLayoutProps) {
  const record = useRecordContext<MembershipCode>();

  return (
    <Box sx={{ minHeight: vh(30) }}>
      <Typography
        sx={{ p: rem(2), fontSize: rem(2) }}
        variant='h2'
        component='h2'
      >
        <CodesTitle />
      </Typography>
      <TabbedShowLayout>
        <Tab label='Code Info'>
          <CodesTextField source='code' />
          <CodesTextField source='provider' />
          <CodesReferenceField
            emptyText='-'
            reference={ResourceName.USERS}
            label='Creator'
            source='creator_id'
            link={false}
          >
            <LinkableField render={renderFullName} />
          </CodesReferenceField>
          <CodesTextField label='Code ID' source='id' />
          <CodesFunctionField
            label='Plan Duration'
            source='plan_duration'
            render={renderPlanDurationJSX}
          />
        </Tab>
        <Tab label='Member'>
          <CodesReferenceField
            emptyText='-'
            reference={ResourceName.CUSTOMERS}
            source='customer_id'
            link={false}
          >
            <CustomerLinkField />
          </CodesReferenceField>
          <CodesReferenceField
            emptyText='-'
            reference={ResourceName.USERS}
            source='app_user_id'
            link={false}
          >
            <LinkableField render={renderFullName} />
          </CodesReferenceField>
        </Tab>
        <Tab label='Life Cycle'>
          <CodesTextField source='status' />
          <CodesDateField locales='en-GB' source='creation_date' />
          <CodesDateField
            locales='en-GB'
            label='Expiration Date'
            source='expired_date'
            emptyText='Never'
          />
          <CodesDateField
            locales='en-GB'
            label='Activation Date'
            source='activation_date'
            emptyText='Not Activated'
          />
        </Tab>
        {record.hasPackages && (
          <Tab label='Content Packages'>
            <ArrayField source='packages' sortable={false} label={''}>
              <Datagrid>
                <ReferenceField
                  source={'package_id'}
                  link={false}
                  reference={ResourceName.CONTENT_PACKAGES}
                >
                  <TextField source='name'></TextField>
                </ReferenceField>
                <DateField
                  locales='en-GB'
                  source='expiration_date'
                  emptyText='-'
                ></DateField>
              </Datagrid>
            </ArrayField>
          </Tab>
        )}
      </TabbedShowLayout>
    </Box>
  );
}
