import { AppResponsive } from 'shared/components/common/AppResponsive.component';

import { CodesTable } from './CodesTable.component';
import { CodesXSmallTable } from './CodesXSmallTable.component';

export function CodesResponsiveTable() {
  return (
    <AppResponsive
      xs={<CodesXSmallTable />}
      sm={
        <CodesTable
          omitItems={[
            'creator_id',
            'limitation',
            'activation_date',
            'plan_duration',
            'app_user_id',
            'expired_date',
            'creation_date',
            'customer_id',
            'provider'
          ]}
        />
      }
      md={
        <CodesTable
          omitItems={[
            'creator_id',
            'limitation',
            'activation_date',
            'plan_duration',
            'app_user_id',
            'expired_date'
          ]}
        />
      }
      lg={
        <CodesTable
          omitItems={[
            'creator_id',
            'limitation',
            'activation_date',
            'plan_duration'
          ]}
        />
      }
      xl={<CodesTable />}
    />
  );
}
