import {
  CustomerType,
  CustomerTypesEnum
} from 'shared/utils/constants/blazepod';

export class CustomerMutationPayload {
  [key: string]: CustomerMutationPayload[keyof CustomerMutationPayload];

  alias: string;
  email: string;
  first_name: string;
  last_name: string;

  constructor(email = '', first_name = '', last_name = '', alias = '') {
    this.email = email;
    this.first_name = first_name;
    this.last_name = last_name;
    this.alias = alias;
  }
}

export class CustomerCreatedPayload extends CustomerMutationPayload {
  [key: string]: CustomerCreatedPayload[keyof CustomerCreatedPayload];
  type: CustomerType;

  constructor(
    email = '',
    type: CustomerType = CustomerTypesEnum.PROMOTER,
    first_name = '',
    last_name = '',
    alias = ''
  ) {
    super(email, first_name, last_name, alias);
    this.type = type;
  }
}
