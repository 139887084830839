import {
  BackOfficeDataProvider,
  defaultUrlRenderer
} from 'shared/services/backoffice-data-provider';

import { CollectionsDtoConverter } from './CollectionsDtoConverter';

export class CollectionDataProvider extends BackOfficeDataProvider {
  protected dtoConverter = new CollectionsDtoConverter();
  protected urlRenderer = defaultUrlRenderer;
}
