import isEqual from 'lodash/isEqual';

export function deepValidateChanged(input: object, record: object): boolean {
  for (const key in input) {
    const inputProp = (input as Record<string, unknown>)[key];
    const recordProp = (record as Record<string, unknown>)[key];
    if (inputProp == null && recordProp == null) {
      continue;
    }
    if (isEqual(inputProp, recordProp)) {
      continue;
    }
    return true;
  }
  return false;
}
