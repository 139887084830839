import React from 'react';
import {
  InputProps,
  NumberInput,
  NumberInputProps,
  SelectInput,
  SelectInputProps,
  TextInput,
  TextInputProps
} from 'react-admin';

import { HasCustomerSource } from '../../interfaces/HasCustomerSource';

type CustomerInput<P extends Omit<InputProps, 'source'>> = React.ComponentType<
  P & HasCustomerSource
>;
export const CustomerTextInput = TextInput as CustomerInput<TextInputProps>;
export const CustomerSelectInput =
  SelectInput as CustomerInput<SelectInputProps>;
export const CustomerNumberInput =
  NumberInput as CustomerInput<NumberInputProps>;
