import { List, ListProps, SearchInput } from 'react-admin';

import { AppResponsive } from 'shared/components/common/AppResponsive.component';
import { Pagination } from 'shared/components/pagination/Pagination.component';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { SectionName } from 'shared/enums/section-name.enum';
import { RaListOrder } from 'shared/services/backoffice-data-provider';

import { SegmentTable } from '../components/SegmentTable.component';

export function SegmentList(props: ListProps) {
  return (
    <List
      {...props}
      perPage={100}
      sort={{ field: 'name', order: RaListOrder.ASC }}
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'edit', 'list', 'create']}
          sectionName={SectionName.Monetization}
        />
      }
      pagination={<Pagination />}
      filters={[<SearchInput source='search' alwaysOn />]}
      exporter={false}
    >
      <AppResponsive
        xs={<SegmentTable omitItems={[]} />}
        sm={<SegmentTable omitItems={[]} />}
        md={<SegmentTable omitItems={[]} />}
        lg={<SegmentTable />}
        xl={<SegmentTable />}
      />
    </List>
  );
}
