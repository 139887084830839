export class LeaderboardMutationPayload {
  title?: string | undefined;
  subtitle?: string | undefined;
  user_id?: string | undefined;
  activity_id?: string | undefined;
  number_of_items?: number | undefined;
  start_date?: string | undefined;
  end_date?: string | undefined;
  rightLogo?: string | undefined;
  right_logo?: string | undefined;

  constructor(
    title?: string,
    subtitle?: string,
    user_id?: string,
    activity_id?: string,
    number_of_items?: number,
    start_date?: string,
    end_date?: string,
    rightLogo?: string
  ) {
    this.title = title;
    this.subtitle = subtitle;
    this.user_id = user_id;
    this.activity_id = activity_id;
    this.number_of_items = number_of_items;
    this.start_date = start_date;
    this.end_date = end_date;
    this.rightLogo = rightLogo;
  }

  static factory(leaderboard: Partial<LeaderboardMutationPayload> = {}) {
    return new LeaderboardMutationPayload(
      leaderboard.title,
      leaderboard.subtitle,
      leaderboard.user_id,
      leaderboard.activity_id,
      leaderboard.number_of_items,
      leaderboard.start_date,
      leaderboard.end_date,
      leaderboard.right_logo
    );
  }
}

export class LeaderboardCreatePayload extends LeaderboardMutationPayload {
  label: string;
  constructor(
    label: string,
    title?: string,
    subtitle?: string,
    user_id?: string,
    activity_id?: string,
    number_of_items?: number,
    start_date?: string,
    end_date?: string,
    rightLogo?: string
  ) {
    super(
      title,
      subtitle,
      user_id,
      activity_id,
      number_of_items,
      start_date,
      end_date,
      rightLogo
    );
    this.label = label;
  }

  static factory(leaderboard: Partial<LeaderboardCreatePayload> = {}) {
    return new LeaderboardCreatePayload(
      leaderboard.label ?? 'default',
      leaderboard.title,
      leaderboard.subtitle,
      leaderboard.user_id,
      leaderboard.activity_id,
      leaderboard.number_of_items,
      leaderboard.start_date,
      leaderboard.end_date,
      leaderboard.right_logo
    );
  }
}
