import {
  ArrayField,
  BooleanField,
  Datagrid,
  NumberField,
  ReferenceField,
  RichTextField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { ContentTitle } from 'resources/activities/pages/ActivityShow.component';
import { PublishUnpublishedButton } from 'resources/contents/PublishUnpublishedButton.component';
import { ContentItemEditButton } from 'shared/components/buttons/ContentItemEditButton.component';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { ResourceName } from 'shared/enums';
import { SectionName } from 'shared/enums/section-name.enum';
import { vh, rem } from 'shared/utils/styles/css-unit';

export function CollectionShow(props: ShowProps) {
  return (
    <Show
      {...props}
      title='Collections'
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'create', 'edit']}
          extraActions={[
            {
              type: 'edit',
              element: (
                <ContentItemEditButton
                  disable={true}
                  resource={ResourceName.COLLECTIONS}
                />
              )
            },
            {
              type: 'publish',
              element: <PublishUnpublishedButton label={true} />
            }
          ]}
          sectionName={SectionName.Content}
        />
      }
    >
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <ContentTitle />
        </Typography>
        <TabbedShowLayout>
          <Tab label='Details'>
            <TextField source='id' label='Id' emptyText='-' />
            <TextField source='display_name' label='Name' emptyText='-' />
            <TextField source='visibility' label='Visibility' emptyText='-' />
            <BooleanField
              source='is_popular'
              label='Is Popular'
              emptyText='-'
            />
            <RichTextField
              source='description'
              label='Description'
              emptyText='-'
            />
            <NumberField
              source='contents_count'
              label='Number of Contents'
              emptyText='-'
            />
            <ReferenceField
              source='my_draft'
              label={'Draft Name'}
              link={'show'}
              sortBy={'display_name'}
              emptyText='-'
              reference={ResourceName.COLLECTIONS}
            >
              <TextField source='display_name' />
            </ReferenceField>
            <ArrayField source='contents' sortable={false} label={'Contents'}>
              <Datagrid>
                <ReferenceField
                  source={'id'}
                  label={'Activity Name'}
                  link={'show'}
                  sortBy={'display_name'}
                  reference={ResourceName.ACTIVITIES}
                >
                  <TextField source='display_name' />
                </ReferenceField>
                <ReferenceField
                  source={'id'}
                  label={'Engine'}
                  link={false}
                  sortBy={'display_name'}
                  reference={ResourceName.ACTIVITIES}
                >
                  <TextField source='engine' />
                </ReferenceField>
                <ReferenceField
                  source={'id'}
                  label={'Visibility'}
                  link={false}
                  sortBy={'display_name'}
                  reference={ResourceName.ACTIVITIES}
                >
                  <TextField source='visibility' />
                </ReferenceField>
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
}
