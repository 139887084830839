import { SaveButton, Toolbar, ToolbarProps } from 'react-admin';

import Button from '@mui/material/Button';

export function ChangePlanFormToolbar({
  onCancel,
  ...props
}: ToolbarProps & { onCancel: () => void }) {
  return (
    <Toolbar {...props}>
      <SaveButton />
      <Button onClick={onCancel}>Cancel</Button>
    </Toolbar>
  );
}
