import { useMemo } from 'react';

import {
  TextInput,
  Record,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  useRecordContext,
  ImageField,
  BooleanInput,
  DateInput,
  required,
  maxLength,
  NumberInput,
  minValue
} from 'react-admin';

import { Box, Typography } from '@mui/material';

import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

import RichTextInput from 'ra-input-rich-text';
import { FormDivider } from 'shared/components/common/FormDivider.componen';
import ImageInputBO from 'shared/components/inputs/ImageInputBO.component';

import OriginalRichTextInput from 'shared/components/inputs/OriginalRickTextInput.components';
import { SaveCancelFormToolbar } from 'shared/components/toolbars/SaveCancelFormToolbar.component';

import { Storage } from 'shared/services/firebase-wrapper/FirebaseWrapper';
import { rem, vh } from 'shared/utils/styles/css-unit';

import { v4 } from 'uuid';

import { CampaignsMutationPayload } from '../models/campaigns-mutation-payload.model';
import { CampaignTitle } from '../pages/CampaignShow.component';

export enum CampaignsFormSource {}

export interface CampaignsFormProps {
  onSave(values: Partial<CampaignsMutationPayload>): void;
  onCancel(): void;
  fieldsToOmit?: CampaignsFormSource[];
  initialValues?: any;
  uid?: string;
  title: string;
}

export function CampaignsForm(props: CampaignsFormProps) {
  const { onCancel, onSave, initialValues, title } = props;

  const record = useRecordContext();

  const UploadImageToFirebase = async (upload: any) => {
    const file = upload?.rawFile;
    const campaign_id = record.id ?? props.uid;
    const fileRef = ref(
      Storage,
      `monetization/campaigns/${campaign_id}/${file.name + v4()}`
    );
    let url;
    try {
      await uploadBytes(fileRef, file).then(async (data) => {
        await getDownloadURL(data.ref).then((firebaseUrl) => {
          url = firebaseUrl;
        });
      });
      return url;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = useMemo(
    () => async (data: Partial<Record>) => {
      for (let i = 0; i < data.media.length; i++) {
        const need_upload = data.media[i].upload;
        if (need_upload) {
          const url = await UploadImageToFirebase(data.media[i].upload);
          data.media[i].url = url;
          data.media[i].type = 'image';
          delete data.media[i].upload;
        }
      }
      onSave(
        CampaignsMutationPayload.factory({
          ...data
        })
      );
    },
    [onSave]
  );

  return (
    <>
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          {title}
          <CampaignTitle />
        </Typography>
        <SimpleForm
          initialValues={initialValues}
          submitOnEnter={false}
          save={handleSave}
          toolbar={<SaveCancelFormToolbar onCancel={onCancel} />}
        >
          <FormDivider title='Details' removeDivider></FormDivider>
          <TextInput label={'Name'} source='name' validate={[required()]} />
          <TextInput
            label={'Description'}
            source='description'
            fullWidth={true}
          ></TextInput>
          <FormDivider title='Campaign'></FormDivider>
          <DateInput
            validate={[required()]}
            label={'Start date'}
            source='start_date'
          ></DateInput>
          <DateInput label={'End date'} source='end_date'></DateInput>
          <BooleanInput label={'Status'} source='is_active'></BooleanInput>

          <FormDivider title='Display Frequency'></FormDivider>
          <NumberInput
            label={'Gap days'}
            source='display_frequency.gap_days'
            validate={[required(), minValue(0)]}
          >
            {' '}
          </NumberInput>
          <NumberInput
            label={'Display amount'}
            source='display_frequency.display_amount'
            validate={[required(), minValue(0)]}
          >
            {' '}
          </NumberInput>

          <FormDivider title='Media'></FormDivider>
          <ArrayInput
            source='media'
            validate={[maxLength(5, 'Only 5 pictures is allowed'), required()]}
          >
            <SimpleFormIterator>
              <ImageInputBO
                label='Campaign picture'
                source='upload'
                accept='image/*'
                placeholder='Click and select from your device'
                initial='url'
                multiple={false}
                options={{
                  noDrag: true
                }}
              >
                <ImageField source='url'></ImageField>
              </ImageInputBO>
              <RichTextInput label={'Text'} source='text'></RichTextInput>
            </SimpleFormIterator>
          </ArrayInput>
          <FormDivider title='Texts'></FormDivider>
          <TextInput label={'Headline'} source='display_text.headline' />
          <OriginalRichTextInput
            label={'Text - Verify line spaces before saving the campaign !!'}
            validate={[required()]}
            source='display_text.text'
          ></OriginalRichTextInput>
          <TextInput
            label={'Price action text'}
            validate={[required()]}
            source='display_text.price_action_text'
          />
          <TextInput
            label={'CTA'}
            validate={[required()]}
            source='display_text.button'
          />
          <TextInput
            label={'Below button'}
            source='display_text.below_button'
          />
        </SimpleForm>
      </Box>
    </>
  );
}
