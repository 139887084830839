import { ValueOfObject } from 'shared/utils/types';

export const CodesFilterableFieldsEnum = Object.freeze({
  search: 'serach',
  customer_id: 'customer_id',
  creator_id: 'creator_id'
} as const);

export type CodesFilterableField = ValueOfObject<
  typeof CodesFilterableFieldsEnum
>;
