import { useMemo } from 'react';

import {
  BooleanInput,
  List,
  ListProps,
  Responsive,
  SearchInput,
  SelectArrayInput,
  SelectInput,
  TextInput
} from 'react-admin';

import { Theme, useMediaQuery } from '@mui/material';
import { MembershipPlanProvider } from 'resources/customers/enums/MembershipPlanProvider.enum';
import { AutocompleteReferenceInput } from 'shared/components/inputs/AutocompleteReferenceInput.component';
import { Pagination } from 'shared/components/pagination/Pagination.component';

import { ResourceName } from 'shared/enums';
import { RaListOrder } from 'shared/services/backoffice-data-provider/enums/ra-list-order.enum';
import {
  CustomerTypesEnum,
  MembershipPlanNamesEnum
} from 'shared/utils/constants/blazepod';

import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';
import { getTitleCase } from 'shared/utils/helperFunctions/strings';

import { UsersDatagrid } from '../components/UsersDatagrid.component';
import { UsersSimpleList } from '../components/UsersSimpleList.component';
import { UsersTabbedDatagrid } from '../components/UsersTabbedDatagrid.component';
import { UsersListOmittableFields } from '../enums/users-list-omittable-fields.enum';
import { UsersSortableFieldsEnum } from '../enums/users-sortable-fields.enum';

export const UsersList = (props: ListProps) => {
  const filterChoices = useMemo(
    () =>
      [['all', 'All']]
        .concat(Object.entries(MembershipPlanNamesEnum))
        .map(([, val]) => ({
          id: val,
          name: val
        })),
    []
  );
  const providerChoices = useMemo(
    () =>
      Object.entries(MembershipPlanProvider).map(([name, val]) => ({
        id: val,
        name
      })),
    []
  );

  const customerTypeChoices = useMemo(
    () =>
      Object.entries(CustomerTypesEnum).map(([, val]) => ({
        id: val,
        name: getTitleCase(val)
      })),
    []
  );
  const smUpBreakpoint = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('sm')
  );
  const lgUpBreakpoint = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('lg')
  );

  const orderFilters = [
    <SearchInput source='search' alwaysOn />,
    <SelectArrayInput
      source='plan_provider'
      label='Plan Provider'
      optionText='name'
      choices={providerChoices}
    ></SelectArrayInput>,
    <BooleanInput label='In Legacy List' source='is_legacy' />,
    <BooleanInput label='Plan Renew' source='renew' />,
    <TextInput
      label='Registered Version'
      source='registered_version'
      resettable
    />,
    <AutocompleteReferenceInput
      label='Customer'
      source='customer_id'
      reference={ResourceName.CUSTOMERS}
      filterKey='search'
      sort={{ field: 'alias', order: RaListOrder.ASC }}
      optionText={(option) =>
        option?.alias ?? option?.email ?? renderFullName(option)
      }
    />,
    <SelectArrayInput
      source='customer_type'
      label='Customer Type'
      choices={customerTypeChoices}
    ></SelectArrayInput>
  ];
  const notLgFilters = orderFilters.concat([
    <SelectInput
      source='membership_status'
      optionText='name'
      choices={filterChoices}
      alwaysOn={smUpBreakpoint}
      allowEmpty={false}
    />
  ]);
  const {
    customer,
    plan_is_renew,
    plan_last_update,
    plan_provider,
    registered_version
  } = UsersListOmittableFields;
  return (
    <List
      {...props}
      exporter={false}
      filterDefaultValues={{ membership_status: 'All' }}
      sort={{
        field: UsersSortableFieldsEnum.display_name,
        order: RaListOrder.ASC
      }}
      filters={lgUpBreakpoint ? orderFilters : notLgFilters}
      perPage={100}
      pagination={<Pagination />}
    >
      <Responsive
        xsmall={<UsersSimpleList />}
        small={
          <UsersDatagrid
            omitFields={[
              registered_version,
              plan_last_update,
              plan_provider,
              plan_is_renew,
              customer
            ]}
          />
        }
        medium={
          <UsersDatagrid
            omitFields={[plan_last_update, plan_provider, customer]}
          />
        }
        large={<UsersTabbedDatagrid />}
      />
    </List>
  );
};
