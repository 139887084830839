import { useCallback, useMemo } from 'react';
import { Loading, useListContext } from 'react-admin';

import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { getSummaryLabel } from 'shared/components/summary/getSummaryLabel';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useBPSummary } from 'shared/hooks/useBPSummary';
import { Stat } from 'shared/models/summary/Stat.model';

import { CodesTable } from './CodesTable.component';
import { MembershipCode } from '../models/MembershipCode.model';

export function CodesTabbedDatagrid() {
  const listContext = useListContext<MembershipCode>();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const summary = useBPSummary(ResourceName.CODES);
  const tabs: Stat[] = useMemo(
    () => [
      ...summary.data,
      new Stat(
        'all',
        'code_status',
        'All',
        summary.data?.reduce((count, stat) => +(stat?.count ?? '0') + count, 0),
        'All'
      )
    ],
    [summary.data]
  );

  const handleTabChange = useCallback(
    (value: string | string[]) => {
      setFilters &&
        setFilters(
          { ...filterValues, code_status: value },
          displayedFilters,
          false // no debounce, we want the filter to fire immediately
        );
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <>
      <Tabs
        variant='fullWidth'
        centered
        value={filterValues.code_status}
        indicatorColor='primary'
        onChange={(_, value) => handleTabChange(value)}
      >
        {tabs &&
          tabs.map((choice) => (
            <Tab
              key={choice.id}
              label={getSummaryLabel(choice.display_name, choice.count)}
              value={choice.filter_value}
            />
          ))}
      </Tabs>
      <Divider />
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <CodesTable />
      )}
    </>
  );
}
