/**
 * generate the app's default linear-gradient
 * @param _degrees the degrees you want the gradient to be offset by
 * @returns the linear-gradient() css function with the predefined parameters
 */
export function createBPBlueGradient(_degrees: number): string {
  return `
    linear-gradient(
      ${_degrees}deg,
      #0fd5e7 -15.99%,
      #2e7edb 42.96%,
      #4d33cf 107.54%,
      #2e28a0 144.03%,
      #0f1c70 264.75%
    );
  `;
}
