import { Record as RaRecord } from 'react-admin';

import { AppUser } from 'resources/users/models/AppUser.model';
import { MembershipPlanNamesEnum } from 'shared/utils/constants/blazepod';
import { MembershipPlanName } from 'shared/utils/constants/blazepod';
import { MembershipPlanVM } from 'shared/utils/constants/blazepod/MembershipPlanVM.model';

export function getPlanOptions(
  user: AppUser | undefined,
  currentPlan?: RaRecord
) {
  const planNames = Object.values(MembershipPlanNamesEnum);
  const generateOption = (plan: MembershipPlanName) => {
    const vm = new MembershipPlanVM(plan);
    return {
      id: vm.model,
      name: MembershipPlanVM.planNameFromPlan(vm.model)
    };
  };

  if (!user) {
    return planNames.map(generateOption);
  }

  const { membership_plan } = user;

  const filterPlan = (plan: MembershipPlanName) => {
    const baseFilter =
      plan !== membership_plan && plan !== MembershipPlanNamesEnum.NO_PLAN;

    if (!user.is_legacy) {
      return baseFilter && plan !== MembershipPlanNamesEnum.LEGACY;
    }

    return baseFilter && plan !== MembershipPlanNamesEnum.NEW;
  };

  return planNames.filter(filterPlan).map(generateOption);
}
