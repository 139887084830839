import { ReactElement } from 'react';

import { Typography } from '@mui/material';

export interface FieldFillerProps {
  align?: 'center' | 'left' | 'right';
  emptyText?: ReactElement | string;
}

export const FieldFiller = (props: FieldFillerProps) => {
  return (
    <Typography
      component='div'
      variant='body2'
      align={props.align ? props.align : 'center'}
    >
      {props.emptyText ? props.emptyText : '-'}
    </Typography>
  );
};
