import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

export interface ListItemWithIconProps {
  approve?: boolean;
  label?: string;
}

export function ListItemWithIcon(props: ListItemWithIconProps) {
  return (
    <ListItem>
      <ListItemIcon>
        {props.approve ? (
          <CheckCircleOutlineIcon
            sx={(theme) => ({ color: theme.palette.success.main })}
          />
        ) : (
          <HighlightOffIcon
            sx={(theme) => ({ color: theme.palette.warning.main })}
          />
        )}
      </ListItemIcon>
      <ListItemText primary={props.label} />
    </ListItem>
  );
}
