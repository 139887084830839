import { useMemo } from 'react';
import {
  Datagrid,
  Loading,
  NumberField,
  TextField,
  useListContext
} from 'react-admin';

import { uniqueId } from 'lodash';
import { FieldObject } from 'resources/types';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { HasOmitItems } from 'shared/interfaces/react-admin';

import { ContentPackages } from '../content-packages.dto';

export const PackagesTable = (
  props: Partial<HasOmitItems<keyof ContentPackages>>
) => {
  const listContext = useListContext<ContentPackages>();
  const fields = useMemo(() => {
    const allFields: Array<FieldObject<keyof ContentPackages>> = [
      {
        source: 'id',
        element: (
          <LinkableField
            source='id'
            label='Package id'
            emptyText='-'
            key={uniqueId()}
          />
        )
      },
      {
        source: 'name',
        element: (
          <TextField
            sortable={false}
            source='name'
            label='Name'
            emptyText='-'
            key={uniqueId()}
          />
        )
      },
      {
        source: 'description',
        element: (
          <TextField
            sortable={false}
            source='description'
            label='Description'
            emptyText='-'
            key={uniqueId()}
          />
        )
      },
      {
        source: 'allow',
        element: (
          <NumberField
            sortable={false}
            source='allow'
            label='Open Contents'
            emptyText='-'
            key={uniqueId()}
          />
        )
      },
      {
        source: 'override',
        element: (
          <NumberField
            sortable={false}
            source='override'
            label='By Override'
            emptyText='-'
            key={uniqueId()}
          />
        )
      }
    ];

    const output: JSX.Element[] = [];

    for (const field of allFields) {
      if (props.omitItems ? !props.omitItems.includes(field.source) : true) {
        output.push(field.element);
      }
    }
    return output;
  }, [props.omitItems]);

  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <Datagrid>{fields}</Datagrid>
      )}
    </>
  );
};
