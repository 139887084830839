import { RaModule } from 'resources/RaModule';

import { ActivitiesModule } from './activities';
import { AdminsModule } from './admins';
import { CampaignsModule } from './campaigns';
import { BatchesModule } from './code_batches';
import { CodesModule } from './codes';
import { CollectionsModule } from './collections';
import { ContentPackagesModule } from './content_packages';
import { CustomersModule } from './customers';
import { leaderboardsModule } from './leaderboard';
import { LogsModule } from './logs';
import { membershipDisplayModule } from './membership_display';
import { OfflineCustomersRouteModule } from './offline_customers';
import { OnlineCustomerRouteModule } from './online_customers';
import { PlansModule } from './plans';
import { ReferralsModule } from './referrals';
import { SegmentsModule } from './segments';
import { TestsModule } from './tests';
import { UserActivitiesModule } from './user_activities';
import { UsersModule } from './users';
import { UserPlansModule } from './users_plans';

export const modules: RaModule[] = [
  UsersModule,
  BatchesModule,
  CodesModule,
  OfflineCustomersRouteModule,
  OnlineCustomerRouteModule,
  CustomersModule,
  PlansModule,
  UserPlansModule,
  AdminsModule,
  LogsModule,
  ActivitiesModule,
  UserActivitiesModule,
  TestsModule,
  CollectionsModule,
  leaderboardsModule,
  membershipDisplayModule,
  ContentPackagesModule,
  CampaignsModule,
  ReferralsModule,
  SegmentsModule
];
