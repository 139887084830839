export class Campaign {
  id: string;
  name: string;
  description: string;
  is_active: boolean;
  segment_id: string;
  media: string;
  start_date: string;
  end_date: string;
  display_frequency: string;
  display_text: string;
  is_deleted: boolean;

  constructor(
    id: string,
    name: string,
    description: string,
    is_active: boolean,
    segment_id: string,
    media: string,
    start_date: string,
    end_date: string,
    display_frequency: string,
    display_text: string,
    is_deleted: boolean
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.is_active = is_active;
    this.segment_id = segment_id;
    this.media = media;
    this.start_date = start_date;
    this.end_date = end_date;
    this.display_frequency = display_frequency;
    this.display_text = display_text;
    this.is_deleted = is_deleted;
  }

  static fromDto(campaign: any) {
    return new Campaign(
      campaign.id,
      campaign.name,
      campaign.description,
      campaign.is_active,
      campaign.segment_id,
      campaign.media,
      campaign.start_date,
      campaign.end_date,
      campaign.display_frequency,
      campaign.display_text,
      campaign.is_deleted
    );
  }
}
