import {
  BackOfficeDataProvider,
  defaultUrlRenderer
} from 'shared/services/backoffice-data-provider';

import { ContentPackagesDtoConverter } from './ContentPackagesDtoConverter';

export class ContentPackagesDataProvider extends BackOfficeDataProvider {
  protected dtoConverter = new ContentPackagesDtoConverter();
  protected urlRenderer = defaultUrlRenderer;
}
