import { EditButton, useRecordContext, useResourceContext } from 'react-admin';

import { Tooltip } from '@mui/material';

export interface EditButtonProps {
  label?: string | false;
  disabled?: boolean;
  successMessage?: string;
  tooltip: string;
}

export function BaseEditButton(props: EditButtonProps) {
  const record = useRecordContext();
  const resource = useResourceContext();
  return (
    <Tooltip
      arrow
      placement='left'
      title={props.tooltip}
      describeChild
      enterDelay={1000}
    >
      <div>
        <EditButton
          disabled={props.disabled}
          basePath={`/${resource}`}
          label={props.label === false ? '' : props.label ?? 'Edit'}
          record={record}
        />
      </div>
    </Tooltip>
  );
}
