import { useMemo } from 'react';
import { ReferenceField, TextField, useRecordContext } from 'react-admin';

import { Typography } from '@mui/material';
import { ResourceName } from 'shared/enums';

export const SegmentFeatureField = () => {
  const record = useRecordContext<SegmentFeatureItem>();

  const isReferral = useMemo<boolean>(() => {
    return record?.key === 'refer_a_friend';
  }, [record?.key]);

  const isCampaign = useMemo<boolean>(() => {
    return record?.key === 'campaign';
  }, [record?.key]);

  if (isReferral) {
    return (
      <ReferenceField
        label='Referrals'
        source='feature_id'
        link='show'
        emptyText='-'
        reference={ResourceName.REFERRALS}
      >
        <TextField source='name' />
      </ReferenceField>
    );
  }
  if (isCampaign) {
    return (
      <ReferenceField
        label='Campaign'
        source='feature_id'
        link='show'
        emptyText='-'
        reference={ResourceName.CAMPAIGNS}
      >
        <TextField source='name' />
      </ReferenceField>
    );
  }
  return (
    <Typography component='span' variant='body2'>
      {'-'}
    </Typography>
  );
};

export class SegmentFeatureItem {
  public key: string;
  public feature_id: string;
  public segment_id: string;

  constructor(key = '', feature_id = '', segment_id = '') {
    this.key = key;
    this.feature_id = feature_id;
    this.segment_id = segment_id;
  }

  static fromDto(feature: any, id: string): SegmentFeatureItem {
    if (!feature) return new SegmentFeatureItem();
    return new SegmentFeatureItem(feature.key, feature.feature_id, id);
  }
}
