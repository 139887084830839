import { ConfirmProps } from 'react-admin';

import { CheckCircle, InfoOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

export class DialogButtonConfig {
  label: string;
  color: any;
  constructor(label: string, color: any) {
    this.label = label;
    this.color = color;
  }
}

export interface DialogProps extends ConfirmProps {
  confirmButton: DialogButtonConfig;
  closeButton: DialogButtonConfig;
}
export function ConfirmDialog(props: DialogProps) {
  return (
    <Dialog disableScrollLock={true} open={props.isOpen ?? false}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          disabled={props.loading ?? false}
          sx={(theme) => ({ color: props.closeButton.color })}
        >
          <InfoOutlined /> {props.closeButton.label}
        </Button>
        <Button
          onClick={props.onConfirm}
          disabled={props.loading ?? false}
          sx={(theme) => ({ color: props.confirmButton.color })}
        >
          <CheckCircle /> {props.confirmButton.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
