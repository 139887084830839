import { useMemo, useState } from 'react';

import {
  HttpError,
  LinearProgress,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';

import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import { Box, Button, Tooltip, useTheme } from '@mui/material';

import { ContentItem } from 'resources/activities/models/ContentItem.model';
import {
  ConfirmDialog,
  DialogButtonConfig
} from 'shared/components/dialogs/BaseConfirmDialog.component';
import { ResourceName } from 'shared/enums';
import {
  ContentUpdateAction,
  ContentVisibility
} from 'shared/enums/content-enums';

import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { useAxiosCall } from 'shared/hooks/useAxiosCall';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';
import { px } from 'shared/utils/styles/css-unit';

export function PublishUnpublishedButton(props: { label?: boolean }) {
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const record = useRecordContext<ContentItem>();
  const refresh = useRefresh();
  const notify = useNotify();
  const theme = useTheme();
  const isPublic = useMemo<boolean>(() => {
    return record?.visibility === ContentVisibility.PUBLIC;
  }, [record?.visibility]);

  const { permissions } = useAppPermissions();

  const shouldDisable = useMemo(() => {
    return !isPublisher(permissions?.content);
  }, [permissions?.content]);

  const typeString = `${
    record.visibility === ContentVisibility.DRAFT ? ' draft ' : ''
  } ${record.type.toLocaleLowerCase()}`;
  const tooltip = `${isPublic ? 'Unpublish' : 'Publish'} a ${typeString}`;
  const messageContent = `Please confirm ${
    isPublic ? 'removing' : 'publishing'
  } the ${typeString} in the app.`;
  const action = isPublic ? 'unpublish' : 'publish';

  const fetchPublishUnpublished = useAxiosCall<boolean>({
    resource: `${ResourceName.BASIC_CONTENT}/${record.id}`,
    autoFetch: false,
    init: { method: 'PATCH' }
  });

  const handleConfirm = () => {
    fetchPublishUnpublished
      .fetch({
        published: !isPublic,
        action: ContentUpdateAction.PublishedPredefined
      })
      .then(() => handleCancelSuccess())
      .catch((err) => handleCancelFail(err));
  };

  const handleCancelSuccess = () => {
    setShouldShowConfirm(false);
    refresh();
    notify(`${tooltip} updated successfully`, { type: 'success' });
  };

  const handleCancelFail = (e: HttpError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  if (record == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='left'
        title={tooltip}
        describeChild
        enterDelay={1000}
      >
        <div>
          <Button
            disabled={shouldDisable}
            onClick={handleClick}
            sx={(theme) => ({
              px: px(5),
              py: px(4),
              color: theme.palette.primary.main,
              lineHeight: 1.5
            })}
          >
            <RocketLaunchIcon
              fontSize='small'
              color={isPublic ? undefined : 'disabled'}
            />
            {props.label != null ? (
              <Box component='span' sx={{ pl: px(6) }}>
                {action.toUpperCase()}
              </Box>
            ) : (
              ''
            )}
          </Button>
        </div>
      </Tooltip>
      <ConfirmDialog
        onClose={handleAbort}
        onConfirm={handleConfirm}
        isOpen={shouldShowConfirm}
        loading={fetchPublishUnpublished.loading}
        confirmButton={
          new DialogButtonConfig('Confirm', theme.palette.primary.main)
        }
        closeButton={new DialogButtonConfig('Abort', theme.palette.grey[500])}
        content={messageContent}
        title={tooltip}
      />
    </>
  );
}
