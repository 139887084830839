import { useMemo } from 'react';
import {
  BooleanField,
  Datagrid,
  FunctionField,
  Record,
  ReferenceField,
  TextField,
  useListContext,
  Loading
} from 'react-admin';

import { FieldObject } from 'resources/types';
import { BaseDeleteButton } from 'shared/components/buttons/BaseDeleteButton.component';
import { BaseEditButton } from 'shared/components/buttons/BaseEditButton.component';
import { ChangeStatusButton } from 'shared/components/buttons/ChangeStatusButton.component';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { ResourceName } from 'shared/enums';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { HasOmitItems } from 'shared/interfaces/react-admin';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';
import { shortenString } from 'shared/utils/helperFunctions/shortenString';

import { getTitleCase } from 'shared/utils/helperFunctions/strings';

import { Referral } from '../models/Referral.model';

export const ReferralTable = (props: Partial<HasOmitItems<keyof Referral>>) => {
  const listContext = useListContext<Referral>();
  const { permissions } = useAppPermissions();
  const fields = useMemo(() => {
    const allFields: Array<FieldObject<keyof Referral>> = [
      {
        source: 'name',
        element: <LinkableField source={'name'} label='Name' emptyText='-' />
      },
      {
        source: 'description',
        element: (
          <FunctionField
            label='Description'
            render={(record?: Record) =>
              shortenString(record?.description ?? '', 50)
            }
            emptyText='-'
          />
        )
      },
      {
        source: 'payload',
        element: (
          <FunctionField
            render={(record?: Record) =>
              getTitleCase(record?.payload?.store ?? '-')
            }
            label='Store'
          />
        )
      },
      {
        source: 'is_active',
        element: <BooleanField source='is_active' label={'Active'} />
      },
      {
        source: 'segment_id',
        element: (
          <ReferenceField
            label='Segment'
            source='segment_id'
            emptyText='-'
            link='show'
            reference={ResourceName.SEGMENTS}
          >
            <TextField source='name' />
          </ReferenceField>
        )
      }
    ];

    const output: JSX.Element[] = [];

    for (const field of allFields) {
      if (props.omitItems ? !props.omitItems.includes(field.source) : true) {
        output.push(field.element);
      }
    }
    return output;
  }, [props.omitItems]);

  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <Datagrid>
          {fields}
          {isPublisher(permissions?.monetization) && (
            <ChangeStatusButton
              recordType={'referral'}
              resource={ResourceName.REFERRALS}
            />
          )}
          {isPublisher(permissions?.monetization) && (
            <BaseEditButton label={false} tooltip='Edit Referral' />
          )}
          {isPublisher(permissions?.monetization) && (
            <BaseDeleteButton
              label={false}
              tooltip='Delete referral'
              messageContent={'Confirm to delete this referral'}
              messageTitle={'Delete referral'}
            />
          )}
        </Datagrid>
      )}
    </>
  );
};
