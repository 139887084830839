import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { OfflineCustomersCreate } from './OfflineCustomersCreate.component';
import { OfflineCustomersEdit } from './OfflineCustomersEdit.component';
import { OfflineCustomersList } from './OfflineCustomersList.component';
import { OfflineCustomersShow } from './OfflineCustomersShow.component';

export const OfflineCustomersRouteModule = RaModule.fromResourceProps({
  name: ResourceName.OFFLINE_CUSTOMERS,
  options: { label: 'Offline Customers' },
  list: OfflineCustomersList,
  show: OfflineCustomersShow,
  create: OfflineCustomersCreate,
  edit: OfflineCustomersEdit,
  intent: ResourceIntent.ROUTE,
  icon: CorporateFareIcon
});
