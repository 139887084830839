import {
  Edit,
  EditProps,
  useEditContext,
  useNotify,
  useUpdate
} from 'react-admin';

import { Typography } from '@mui/material';
import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { ConfirmUpdate } from 'shared/components/dialogs/ConfirmUpdate.component';
import { UnchangedDialog } from 'shared/components/dialogs/UnchangedDialog.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';
import { PageType } from 'shared/utils/constants/react-admin';
import { getTitleCase } from 'shared/utils/helperFunctions/strings';

import { CodesForm, CodesFormSource } from '../components/codes-form';
import { CodesMutationPayload } from '../models/codes-mutation-payload.model';
import { MembershipCode } from '../models/MembershipCode.model';

export type CodesEditProps = EditProps;

export function CodesEdit(props: CodesEditProps) {
  return (
    <Edit {...props} title={<CodesTitle page='edit' />}>
      <CodesEditForm />
    </Edit>
  );
}

function CodesEditForm() {
  const notify = useNotify();
  const [update, updateState] = useUpdate();
  const editContext = useEditContext<MembershipCode>();

  const {
    shouldShowConfirmSubmit,
    shouldShowUnchanged,
    shouldShowUnsaved,
    goBack,
    handleSave,
    handleCancelEdit,
    handleCancelSubmit,
    handleConfirmSubmit,
    handleUnchangedContinue,
    handleUnchangedQuit,
    handleUnsavedCancel,
    handleUnsavedConfirm
  } = useMutationVM<CodesMutationPayload, MembershipCode>({
    PayloadClass: CodesMutationPayload,
    record: editContext.record,
    resource: ResourceName.CODES,
    mutator: update,
    onMutateFailure: handleUpdateFailure,
    onMutateSuccess: handleUpdateSuccess
  });
  function handleUpdateSuccess() {
    notify('Code data updated successfully.', { type: 'success' });
    goBack();
  }
  function handleUpdateFailure(e: Error) {
    notify(
      e.message || 'Something went wrong. Please try again in a few seconds.',
      { type: 'error' }
    );
  }
  return (
    <>
      <CodesForm
        onCancel={handleCancelEdit}
        onSave={(values) => handleSave(CodesMutationPayload.factory(values))}
        fieldsToOmit={[
          CodesFormSource.customer_id,
          CodesFormSource.number_of_codes,
          CodesFormSource.description
        ]}
      />
      <ConfirmUpdate
        loading={editContext.loading || updateState.loading}
        isOpen={shouldShowConfirmSubmit}
        onConfirm={handleConfirmSubmit}
        onClose={handleCancelSubmit}
        recordName='Code'
      />
      <ConfirmUnsaved
        loading={editContext.loading}
        isOpen={shouldShowUnsaved}
        onClose={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
      />
      <UnchangedDialog
        name='Code'
        itemPageName='Customer'
        isOpen={shouldShowUnchanged}
        onContinue={handleUnchangedContinue}
        onQuit={handleUnchangedQuit}
      />
    </>
  );
}

function CodesTitle(props: { page: PageType }) {
  return <Typography>Codes - {getTitleCase(props.page)}</Typography>;
}
