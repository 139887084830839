import { Confirm, ConfirmProps } from 'react-admin';

export type ConfirmUpdateProps = Omit<ConfirmProps, 'title' | 'content'> & {
  recordName: string;
};
export function ConfirmCreate(props: ConfirmUpdateProps) {
  return (
    <Confirm
      {...props}
      title='Are you sure?'
      content={`You are about to create ${props.recordName.toLowerCase()}.`}
    />
  );
}
