import {
  BackOfficeDataProvider,
  defaultUrlRenderer
} from 'shared/services/backoffice-data-provider';

import { CampaignDtoConverter } from './CampaignDtoConverter';

export class CampaignDataProvider extends BackOfficeDataProvider {
  protected dtoConverter = new CampaignDtoConverter();
  protected urlRenderer = defaultUrlRenderer;
}
