import { useMemo } from 'react';

import { Theme, useMediaQuery } from '@mui/material';

export interface AppResponsiveProps {
  xs?: JSX.Element;
  sm?: JSX.Element;
  md?: JSX.Element;
  lg?: JSX.Element;
  xl?: JSX.Element;
}

export function AppResponsive({ lg, md, sm, xl, xs }: AppResponsiveProps) {
  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const isMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const isLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
  const isXl = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  return useMemo(() => {
    if (isXl) {
      return xl ?? lg ?? md ?? sm ?? xs ?? <>No Elements Passed</>;
    }
    if (isLg) {
      return lg ?? xl ?? md ?? sm ?? xs ?? <>No Elements Passed</>;
    }
    if (isMd) {
      return md ?? sm ?? xs ?? lg ?? xl ?? <>No Elements Passed</>;
    }
    if (isSm) {
      return sm ?? xs ?? md ?? lg ?? xl ?? <>No Elements Passed</>;
    }

    return xs ?? sm ?? md ?? lg ?? xl ?? <>No Elements Passed</>;
  }, [isLg, isMd, isSm, isXl, lg, md, sm, xl, xs]);
}
