import GoogleIcon from '@mui/icons-material/Google';
import { Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import { BPFab } from 'shared/components/buttons/BPFab.component';
import { LoaderDialog } from 'shared/components/dialogs/LoaderDialog.component';
import LargeLogo from 'shared/components/logos/LargeLogo.component';

import { p, rem, vw } from 'shared/utils/styles/css-unit';

import { LoginContainer, LogoBox } from './customMuiComponents';

interface LoginComponentProps {
  signIn(): void;
  loading: boolean;
  isAuth: boolean;
}
export function LoginComponent(props: LoginComponentProps) {
  const { signIn, isAuth, loading } = props;

  const smMediaQ = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  const getScreenIsHorizontal = () =>
    (window?.visualViewport?.height ?? 0) <
    (window?.visualViewport?.width ?? 0);

  const getFontSize = () =>
    smMediaQ ? (getScreenIsHorizontal() ? vw(40) : vw(60)) : vw(100);

  const onLoginClick = () => signIn();
  return (
    <>
      <LoginContainer>
        <Box
          sx={{
            minHeight: '45vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent'
          }}
        >
          {getScreenIsHorizontal() && (
            <Box sx={{ width: p(100), height: p(25) }}>
              <Typography
                variant='h1'
                sx={{ fontWeight: 100, textAlign: 'center' }}
              >
                Hello!
              </Typography>
            </Box>
          )}
          <BPFab
            disabled={loading || isAuth}
            onClick={onLoginClick}
            width={rem(18)}
          >
            <GoogleIcon />
            Log In
          </BPFab>
        </Box>
        <LogoBox>
          <LargeLogo
            sx={{
              fontSize: getFontSize()
            }}
          />
        </LogoBox>
      </LoginContainer>
      <LoaderDialog
        loading={loading}
        title='Logging In'
        content='Just a moment please...'
      />
    </>
  );
}
