import { useMemo, useState } from 'react';

import {
  HttpError,
  LinearProgress,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';

import LanguageIcon from '@mui/icons-material/Language';

import { Box, Button, Tooltip, useTheme } from '@mui/material';

import { ContentItem } from 'resources/activities/models/ContentItem.model';
import { PublishWebsiteConfirmDialog } from 'resources/user_activities/components/PublishWebsiteConfirmDialog.component';
import {
  ConfirmDialog,
  DialogButtonConfig
} from 'shared/components/dialogs/BaseConfirmDialog.component';
import { ResourceName } from 'shared/enums';
import { ContentUpdateAction } from 'shared/enums/content-enums';
import { useAxiosCall } from 'shared/hooks/useAxiosCall';

import { getTitleCase } from 'shared/utils/helperFunctions/strings';

import { px } from 'shared/utils/styles/css-unit';

export function EditMarketplacePublicButton(props: { label?: boolean }) {
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const record = useRecordContext<ContentItem>();
  const refresh = useRefresh();
  const theme = useTheme();
  const notify = useNotify();

  const isPublished = useMemo<boolean>(() => {
    return record?.published;
  }, [record?.published]);

  const isDisabled = useMemo<boolean>(() => {
    return !record?.consent;
  }, [record?.consent]);

  const tooltip = isDisabled
    ? 'Required consent'
    : (isPublished ? `Unpublished from` : `Publish to`) + ` website`;

  const fetchWebsiteVisibility = useAxiosCall<boolean>({
    resource: `${ResourceName.BASIC_CONTENT}/${record.id}`,
    autoFetch: false,
    init: { method: 'PATCH' }
  });

  const handleConfirm = () => {
    fetchWebsiteVisibility
      .fetch({
        visibility: !isPublished,
        action: ContentUpdateAction.PublishedWebsite
      })
      .then(() => handleSuccess())
      .catch((err) => handleFail(err));
  };

  const handleSuccess = () => {
    setShouldShowConfirm(false);
    refresh();
    notify(`${getTitleCase(record.type)} published successfully`, {
      type: 'success'
    });
  };

  const handleFail = (e: HttpError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  if (record == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='left'
        title={tooltip}
        describeChild
        enterDelay={1000}
      >
        <div>
          <Button
            onClick={handleClick}
            disabled={isDisabled}
            sx={(theme) => ({
              px: px(5),
              py: px(4),
              color: theme.palette.primary.main,
              lineHeight: 1.5
            })}
          >
            <LanguageIcon
              fontSize='small'
              color={isPublished ? undefined : 'disabled'}
            />
            {props.label != null ? (
              <Box component='span' sx={{ pl: px(6) }}>
                {'Website'}
              </Box>
            ) : (
              ''
            )}
          </Button>
        </div>
      </Tooltip>
      {isPublished ? (
        <ConfirmDialog
          onClose={handleAbort}
          onConfirm={handleConfirm}
          isOpen={shouldShowConfirm}
          loading={fetchWebsiteVisibility.loading}
          confirmButton={
            new DialogButtonConfig('Confirm', theme.palette.primary.main)
          }
          closeButton={new DialogButtonConfig('Abort', theme.palette.grey[500])}
          content={`Confirm to unpublish ${record.type.toLowerCase()} from website`}
          title={tooltip}
        />
      ) : (
        <PublishWebsiteConfirmDialog
          onClose={handleAbort}
          onConfirm={handleConfirm}
          isOpen={shouldShowConfirm}
          loading={fetchWebsiteVisibility.loading}
          title={tooltip}
        />
      )}
    </>
  );
}
