import { PackageItem } from './package-item.model';

export class ContentPackages {
  id: string;
  name: string | null;
  description: string | null;
  created: string | null;
  updated: string | null;
  payload: string | null;
  allow: number | null;
  override: number | null;
  contents: PackageItem[];

  constructor(
    id = '',
    name = null,
    description = null,
    created = null,
    updated = null,
    payload = null,
    allow = null,
    override = null,
    contents = new Array<PackageItem>()
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.created = created;
    this.updated = updated;
    this.payload = payload;
    this.allow = allow;
    this.override = override;
    this.contents = contents;
    this.contents = contents.map((content) => PackageItem.fromDto(content, id));
  }

  static fromDto(content_package: any) {
    return new ContentPackages(
      content_package.id,
      content_package.name,
      content_package.description,
      content_package.created,
      content_package.updated,
      content_package.payload,
      content_package.allow,
      content_package.override,
      content_package.contents
    );
  }
}
