import {
  ArrayField,
  Record,
  Datagrid,
  FunctionField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  sanitizeFieldRestProps,
  useRecordContext
} from 'react-admin';

import { Box, Stack, Typography } from '@mui/material';
import { rest } from 'lodash';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';

import { SectionName } from 'shared/enums/section-name.enum';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';
import { snakeCaseToTitle } from 'shared/utils/helperFunctions/strings';
import { vh, rem } from 'shared/utils/styles/css-unit';

import { AddToSegmentButton } from '../components/AddToSegmentButton.component';
import { RemoveFromSegmentButton } from '../components/RemoveFromSegmentButton.component';
import { SegmentFeatureField } from '../components/SegmentFeatureField.component';
import { Segment } from '../models/segment.model';

export function SegmentShow(props: ShowProps) {
  const { permissions } = useAppPermissions();
  return (
    <Show
      {...props}
      title='Referrals'
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'create', 'edit']}
          extraActions={[]}
          sectionName={SectionName.Monetization}
        />
      }
    >
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <ReferralTitle />
        </Typography>
        <TabbedShowLayout>
          <Tab label='Details'>
            <TextField source='id' label='Id' emptyText='-' />
            <TextField source='name' label='Name' emptyText='-' />
            <TextField source='description' label='Description' emptyText='-' />
          </Tab>
          <Tab label='Features'>
            {isPublisher(permissions?.monetization) && (
              <>
                <br />
                <Stack
                  sx={{ alignItems: 'center' }}
                  direction='row'
                  spacing={4}
                >
                  <AddToSegmentButton />
                </Stack>
                <br />
              </>
            )}
            <ArrayField source='features' label={''}>
              <Datagrid>
                <FunctionField
                  label={'Feature'}
                  render={(record?: Record) =>
                    snakeCaseToTitle(record?.key ?? '-')
                  }
                ></FunctionField>
                <Typography
                  component='span'
                  variant='body2'
                  label='Name'
                  {...sanitizeFieldRestProps(rest)}
                >
                  <SegmentFeatureField></SegmentFeatureField>
                </Typography>
                {isPublisher(permissions?.monetization) && (
                  <RemoveFromSegmentButton />
                )}
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
}

export function ReferralTitle() {
  const context = useRecordContext<Segment>();
  return <>{context.name}</>;
}
