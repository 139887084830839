import { useCallback, useEffect, useMemo } from 'react';
import { HttpError, useUpdate } from 'react-admin';

import { ResourceName } from 'shared/enums/resource-name.enum';

import { CodeStatus } from '../enums/code-status.enum';
import { MembershipCode } from '../models/MembershipCode.model';

interface UseCancelCode {
  cancelCode(id: string): void;
  loading: boolean;
  data?: MembershipCode;
  error?: HttpError;
}

interface UseCancelCodeProps {
  onSuccess(): void;
  onFailure(e: HttpError): void;
  onLoading?(): void;
}

export function useCancelCode({
  onFailure,
  onSuccess,
  onLoading
}: UseCancelCodeProps): UseCancelCode {
  const [update, updateState] = useUpdate<MembershipCode>();
  const handleLoading = useCallback(() => {
    if (onLoading != null && updateState.loading) {
      onLoading();
    }
  }, [onLoading, updateState.loading]);
  useEffect(() => {
    handleLoading();
  }, [handleLoading]);

  return useMemo(
    () => ({
      ...updateState,
      cancelCode(id: string) {
        update(
          ResourceName.CODES,
          id,
          { status: CodeStatus.Canceled },
          {},
          { onSuccess, onFailure }
        );
      }
    }),
    [onFailure, onSuccess, update, updateState]
  );
}
