import { useMemo } from 'react';
import {
  CreateButton,
  EditButton,
  FilterButton,
  ListButton,
  ShowButton,
  ToolbarProps,
  TopToolbar,
  useRecordContext
} from 'react-admin';

import { uniqueId } from 'lodash';
import { SectionName } from 'shared/enums/section-name.enum';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import {
  ActionButtonObject,
  ActionType,
  ExternalActionButtonObject,
  HasOmitItems
} from 'shared/interfaces/react-admin';
import {
  isPublisher,
  isViewer
} from 'shared/services/firebase-auth-provider/checkPermissions';

export interface TopToolbarProps
  extends ToolbarProps,
    Partial<HasOmitItems<ActionType>> {
  sectionName: SectionName;
  extraActions?: Array<ExternalActionButtonObject>;
}

export function AppGenericTopToolbar(props: TopToolbarProps) {
  const { permissions } = useAppPermissions();
  const context = useRecordContext();
  const actions = useMemo(() => {
    const all: Array<ActionButtonObject> = [
      {
        type: 'filter',
        element: isViewer(permissions?.[props.sectionName]) ? (
          <FilterButton key={uniqueId()} />
        ) : null
      },
      {
        type: 'list',
        element: isViewer(permissions?.[props.sectionName]) ? (
          <ListButton key={uniqueId()} />
        ) : null
      },
      {
        type: 'show',
        element: isViewer(permissions?.[props.sectionName]) ? (
          <ShowButton key={uniqueId()} />
        ) : null
      },
      {
        type: 'edit',
        element: isPublisher(permissions?.[props.sectionName]) ? (
          <EditButton key={uniqueId()} record={context} />
        ) : null
      },
      {
        type: 'create',
        element: isPublisher(permissions?.[props.sectionName]) ? (
          <CreateButton key={uniqueId()} />
        ) : null
      }
    ];

    const output = [];

    for (const item of all) {
      if (item.element == null || props.omitItems?.includes(item.type)) {
        continue;
      }
      output.push(item.element);
    }
    return output.concat(
      props.extraActions?.map((action) => action.element) ?? []
    );
  }, [
    context,
    permissions,
    props.extraActions,
    props.omitItems,
    props.sectionName
  ]);
  return <TopToolbar {...props}>{actions}</TopToolbar>;
}
