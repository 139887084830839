import {
  DateField,
  DateFieldProps,
  FieldProps,
  FunctionField,
  FunctionFieldProps,
  NumberField,
  NumberFieldProps,
  ReferenceField,
  ReferenceFieldProps,
  TextField,
  TextFieldProps
} from 'react-admin';

import { TypedField } from 'shared/interfaces/react-admin';

import { Batch } from './batch.model';

export type BatchesFieldProps<P extends FieldProps> = P & {
  source: keyof Batch;
};
export type BatchesField<P extends FieldProps> = TypedField<
  BatchesFieldProps<P>
>;

export const BatchesTextField = TextField as BatchesField<TextFieldProps>;
export const CodeBatchesDateField = DateField as BatchesField<DateFieldProps>;
export const CodeBatchesNumberField =
  NumberField as BatchesField<NumberFieldProps>;
export const CodeBatchesReferenceField =
  ReferenceField as BatchesField<ReferenceFieldProps>;
export const BatchesFunctionField = FunctionField as BatchesField<
  FunctionFieldProps & { render: (codesBatch?: Batch) => any }
>;
