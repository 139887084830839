import { SvgIcon, SvgIconProps } from '@mui/material';

function LargeLogoWithoutText(
  props: Omit<SvgIconProps, 'width' | 'height' | 'viewBox' | 'xmlns'>
) {
  return (
    <SvgIcon
      width='306'
      height='100%'
      viewBox='0 0 306 380'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <mask
        id='mask0_6_3'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='292'
        height='354'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M155.602 130.221C129.079 130.221 104.176 140.006 85.4783 157.775C66.7641 175.544 56.4578 199.211 56.4578 224.417C56.4578 249.622 66.7641 273.289 85.4783 291.058C104.177 308.828 129.08 318.614 155.602 318.614C182.123 318.614 207.026 308.828 225.725 291.058C244.428 273.289 254.729 249.622 254.729 224.417C254.729 199.211 244.428 175.544 225.725 157.775C207.027 140.006 182.124 130.221 155.602 130.221ZM155.587 354C119.113 354 84.8588 340.534 59.1337 316.083C38.1225 296.106 24.8139 270.767 20.6468 242.803C16.6178 215.766 21.7622 187.681 35.1325 163.72C35.1026 163.749 35.0791 163.773 35.0543 163.8L34.9802 163.875C43.3934 150.986 34.023 140.674 32.0588 138.737L18.4451 125.8C12.232 119.886 12.232 110.263 18.4451 104.35C21.4602 101.489 25.4685 99.9135 29.7317 99.9135C33.995 99.9135 38.0033 101.489 41.0184 104.35L47.0241 110.067L53.0408 115.785L57.4333 119.958C60.0177 122.4 63.5449 123.745 67.3655 123.745C71.6777 123.745 75.9673 122.01 79.1346 118.985C82.1603 116.11 83.933 112.376 84.1267 108.471C84.3204 104.568 82.9113 100.978 80.1592 98.3628L67.788 86.5964L30.1731 50.862L4.66783 26.6141C1.65784 23.7533 0.00012207 19.9467 0.00012207 15.8937C0.00012207 11.8406 1.65784 8.03358 4.66783 5.17414C7.68304 2.31377 11.6914 0.738037 15.9545 0.738037C20.2176 0.738037 24.2259 2.31377 27.2411 5.17414L92.7048 67.3826L120.172 93.443C123.32 96.4445 127.497 98.0973 131.936 98.0973C136.212 98.0973 140.202 96.5434 143.17 93.7225C149.413 87.7896 149.281 77.9851 142.876 71.8679L133.655 63.1252L98.4163 29.6179C92.2035 23.7041 92.2035 14.0816 98.4163 8.16681C101.416 5.30644 105.415 3.73118 109.678 3.73118C113.942 3.73118 117.947 5.30645 120.957 8.16681L255.383 135.919C255.605 136.13 255.793 136.37 255.974 136.601C256.11 136.775 256.241 136.942 256.386 137.101C280.038 161.725 292.649 193.73 291.898 227.217C291.145 260.809 276.992 292.368 252.048 316.083C226.317 340.534 192.06 354 155.587 354H155.587Z'
          fill='#1DB7E9'
        />
      </mask>
      <g mask='url(#mask0_6_3)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M31.2915 -272.973C220.835 -272.973 374.046 -126.929 374.046 53.7471V71.4078C374.046 252.083 220.835 398.126 31.2915 398.126C-158.25 398.126 -311.462 252.083 -311.462 71.4078V53.7471C-311.462 -126.929 -158.25 -272.973 31.2915 -272.973Z'
          fill='url(#paint0_linear_6_3)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_6_3'
          x1='672.754'
          y1='152.665'
          x2='138.178'
          y2='-576.305'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0FC9E7' />
          <stop offset='0.21' stopColor='#2E7EDB' />
          <stop offset='0.56' stopColor='#4D33CF' />
          <stop offset='0.75' stopColor='#2E28A0' />
          <stop offset='1' stopColor='#0F1C70' />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}

export default LargeLogoWithoutText;
