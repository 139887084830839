import { useMemo } from 'react';
import { List, ListProps, SearchInput, SelectInput } from 'react-admin';

import { Theme, useMediaQuery } from '@mui/material';
import { codesOriginProvider } from 'resources/customers/enums/MembershipPlanProvider.enum';
import { AppResponsive } from 'shared/components/common/AppResponsive.component';
import { AutocompleteReferenceInput } from 'shared/components/inputs/AutocompleteReferenceInput.component';
import { Pagination } from 'shared/components/pagination/Pagination.component';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { SectionName } from 'shared/enums/section-name.enum';
import { RaListOrder } from 'shared/services/backoffice-data-provider/enums/ra-list-order.enum';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';

import { CodesSelectStatus } from '../components/CodesSelectStatus.component';
import { CodesTabbedDatagrid } from '../components/CodesTabbedDatagrid';
import { CodesTable } from '../components/CodesTable.component';
import { CodesXSmallTable } from '../components/CodesXSmallTable.component';

export const CodesList = (props: ListProps) => {
  const xl = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));
  const filterChoices = codesOriginProvider();
  const orderFilters = useMemo(
    () =>
      [
        <SearchInput source='search' alwaysOn />,
        <AutocompleteReferenceInput
          label='Customer'
          source='customer_id'
          reference={ResourceName.CUSTOMERS}
          filterKey='search'
          sort={{ field: 'alias', order: RaListOrder.ASC }}
          optionText={(option) =>
            option?.alias ?? option?.email ?? renderFullName(option)
          }
        />,
        <AutocompleteReferenceInput
          label='Creator'
          source='creator_id'
          reference={ResourceName.USERS}
          filterKey='search'
          sort={{ field: 'email', order: RaListOrder.ASC }}
          optionText='email'
        />,
        <SelectInput
          source='origin'
          optionText='name'
          choices={filterChoices}
          resettable
        />
      ].concat(
        !xl ? [<CodesSelectStatus label='Status' source='code_status' />] : []
      ),
    [filterChoices, xl]
  );

  return (
    <List
      {...props}
      exporter={false}
      filterDefaultValues={{ code_status: 'All' }}
      sort={{ field: 'code', order: RaListOrder.ASC }}
      filters={orderFilters}
      perPage={100}
      pagination={<Pagination />}
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'edit', 'list']}
          sectionName={SectionName.Entities}
        />
      }
    >
      <AppResponsive
        xs={<CodesXSmallTable />}
        sm={
          <CodesTable
            omitItems={[
              'creator_id',
              'limitation',
              'activation_date',
              'plan_duration',
              'app_user_id',
              'expired_date',
              'creation_date',
              'customer_id',
              'provider',
              'packages'
            ]}
          />
        }
        md={
          <CodesTable
            omitItems={[
              'creator_id',
              'limitation',
              'activation_date',
              'plan_duration',
              'app_user_id',
              'expired_date',
              'packages'
            ]}
          />
        }
        lg={
          <CodesTable
            omitItems={[
              'creator_id',
              'limitation',
              'activation_date',
              'plan_duration',
              'packages'
            ]}
          />
        }
        xl={<CodesTabbedDatagrid />}
      />
    </List>
  );
};
