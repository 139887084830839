import {
  Button,
  ConfirmProps,
  DateInput,
  SaveButton,
  SimpleForm,
  Toolbar
} from 'react-admin';

import SendIcon from '@mui/icons-material/Send';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

export interface DialogProps extends Omit<ConfirmProps, 'onConfirm'> {
  label: string;
  defaultValue: string;
  onSend(date: string): void;
}

export function EditDateFormDialog(props: DialogProps) {
  return (
    <Dialog disableScrollLock={true} open={props.isOpen ?? false}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
        <SimpleForm
          save={(values: any) => props.onSend(values.date)}
          toolbar={
            <Toolbar>
              <SaveButton
                label='Confirm'
                icon={<SendIcon fontSize='small' />}
              />
              <Button onClick={props.onClose} label='Abort'></Button>{' '}
            </Toolbar>
          }
        >
          <DateInput
            label={props.label}
            source='date'
            isRequired={true}
            defaultValue={props.defaultValue}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
}
