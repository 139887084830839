export enum UsersListOmittableFields {
  display_name = 'display_name',
  email = 'email',
  registered_version = 'registered_version',
  plan_last_update = 'plan_last_update',
  current_plan = 'current_plan',
  plan_provider = 'provider',
  plan_is_renew = 'is_renew',
  customer = 'customer',
  is_legacy = 'is_legacy'
}
