import {
  BackOfficeDataProvider,
  defaultUrlRenderer
} from 'shared/services/backoffice-data-provider';

import { ActivitiesDtoConverter } from './ActivitiesDtoConverter';

export class ActivityDataProvider extends BackOfficeDataProvider {
  protected dtoConverter = new ActivitiesDtoConverter();
  protected urlRenderer = defaultUrlRenderer;
}
