import { useRef } from 'react';
import {
  email,
  maxLength,
  minLength,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRecordContext
} from 'react-admin';

import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { memoize } from 'lodash';
import {
  CustomerCreatedPayload,
  CustomerMutationPayload
} from 'resources/customers/models/customer-mutation-payload.model';
import { Customer } from 'resources/customers/models/Customer.model';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useAxiosCall } from 'shared/hooks/useAxiosCall';
import { CustomerTypesEnum } from 'shared/utils/constants/blazepod';
import { rem, vh } from 'shared/utils/styles/css-unit';

import { CustomerTitle } from './CustomerTitle.component';
import {
  CustomerSelectInput,
  CustomerTextInput
} from './inputs/customer-inputs';

interface CustomerFormProps {
  onSave: (values: Partial<CustomerMutationPayload>) => void;
  onCancel: () => void;
  fieldsToHide?: OmittableField[];
  loading?: boolean;
  defaultValues?: Partial<Customer>;
}
export function CustomerForm(props: CustomerFormProps) {
  const customer = useRecordContext<Customer>();

  const aliasServerValidation = useAxiosCall<boolean>({
    resource: `${ResourceName.CUSTOMERS}/verify_alias`,
    autoFetch: false
  });

  const prevAliasRef = useRef(customer?.alias ?? null);
  const prevAliasErrorRef = useRef<string | undefined>(undefined);

  const validateAlias = memoize(async (value: string) => {
    const getIsAliasValid = async (alias: string) => {
      const isUnchanged =
        value === prevAliasRef.current || value === customer?.alias;
      const isInvalidFormat =
        value.split('').filter((char) => /[A-Za-z]/gm.test(char)).length < 3;
      prevAliasRef.current = value;
      if (isUnchanged) return prevAliasErrorRef.current;
      if (isInvalidFormat) {
        prevAliasErrorRef.current =
          'Please write a valid name, with at least 3 letters (A-Z/a-z).';
        return prevAliasErrorRef.current;
      }
      return aliasServerValidation.fetch({ alias }).then((isInputValid) => {
        if (isInputValid) {
          prevAliasErrorRef.current = undefined;
        } else {
          prevAliasErrorRef.current = `
          The customer-name(alias) you entered already exists.
             A customer-name must be unique.`;
        }
        return prevAliasErrorRef.current;
      });
    };

    return getIsAliasValid(value);
  });

  const handleSave = (values: CustomerCreatedPayload) =>
    props.onSave({ ...values });
  const shouldRenderField = (field: OmittableField) => {
    const { fieldsToHide } = props;
    return fieldsToHide ? !fieldsToHide.includes(field) : true;
  };
  return (
    <Box sx={{ minHeight: vh(30) }}>
      <Typography
        sx={{ p: rem(2), fontSize: rem(2) }}
        variant='h2'
        component='h2'
      >
        <CustomerTitle />
      </Typography>
      <SimpleForm
        save={handleSave}
        toolbar={
          <Toolbar>
            <SaveButton />
            <Button onClick={props.onCancel}>Cancel</Button>
          </Toolbar>
        }
      >
        {shouldRenderField('alias') && (
          <CustomerTextInput
            disabled={props.loading}
            label='Customer Name (alias)'
            source='alias'
            defaultValue={props.defaultValues?.alias}
            validate={[required(), minLength(3), maxLength(64), validateAlias]}
          />
        )}
        {aliasServerValidation.loading && (
          <LinearProgress sx={{ mb: rem(2) }} />
        )}

        <Typography>Contact Details:</Typography>

        {shouldRenderField('first_name') && (
          <CustomerTextInput
            disabled={props.loading}
            label='First Name'
            source='first_name'
          />
        )}
        {shouldRenderField('last_name') && (
          <CustomerTextInput
            disabled={props.loading}
            label='Last Name'
            source='last_name'
          />
        )}
        {shouldRenderField('email') && (
          <CustomerTextInput
            disabled={props.loading}
            source='email'
            type='email'
            validate={[email()]}
          />
        )}
        {shouldRenderField('type') && (
          <CustomerSelectInput
            disabled={props.loading}
            label='Customer Type'
            source='type'
            choices={getTypeOptions()}
            validate={shouldRenderField('type') ? required() : undefined}
          />
        )}
      </SimpleForm>
    </Box>
  );
}

function getTypeOptions() {
  const customerTypesValues = Object.values(CustomerTypesEnum);
  const offlineCustomerTypes = customerTypesValues.filter(
    (type) => type !== CustomerTypesEnum.INDIVIDUAL
  );
  return offlineCustomerTypes.map((type) => ({ id: type, name: type }));
}
type OmittableField = 'first_name' | 'last_name' | 'type' | 'email' | 'alias';
