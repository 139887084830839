import { useMemo } from 'react';
import { useRecordContext, useRefresh } from 'react-admin';

import { Stack } from '@mui/material';

import { AppUser } from 'resources/users/models/AppUser.model';

import { Divider } from 'shared/components/common/Divider.component';

import { useAppPermissions } from 'shared/hooks/useAppPermissions';

import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';

import { em } from 'shared/utils/styles/css-unit';

import { LegacyButton } from '../IsLegacyButton.component';
import { UserBooleanField, UserBooleanFieldProps } from '../user-fields';

export function IsLegacyField(props: UserBooleanFieldProps) {
  const { permissions } = useAppPermissions();
  const record = useRecordContext<AppUser>();

  const refresh = useRefresh();
  const isEditable = useMemo(() => {
    return isPublisher(permissions?.entities) && record != null;
  }, [permissions?.entities, record]);

  const handleSuccess = () => {
    refresh();
  };

  return (
    <Stack sx={{ alignItems: 'center' }} direction='row' spacing={4}>
      <UserBooleanField source='is_legacy' />
      {isEditable && <Divider margin={{ left: em(2) }} vairant='diagonal' />}
      {isEditable && <LegacyButton onSuccess={handleSuccess} />}
    </Stack>
  );
}
