import { CodeLimitation } from 'resources/codes/models/MembershipCode.model';
import { ContentPackage } from 'resources/content_packages/content-package.model';
import { PlanDuration } from 'resources/plans/plan-duration.model';

import { CodesMutationPayload } from './codes-mutation-payload.model';

export class CodesCreationPayload extends CodesMutationPayload {
  number_of_codes: number;
  customer_id: string;
  expired_date: string | null;
  plan_duration: PlanDuration;
  description: string;
  limitations?: CodeLimitation;
  packages?: ContentPackage[];

  constructor(
    number_of_codes = 0,
    customer_id = '',
    expired_date: string | null = null,
    plan_duration = new PlanDuration(),
    description = '',
    limitations?: CodeLimitation,
    packages?: ContentPackage[]
  ) {
    super(expired_date, plan_duration, undefined, limitations);
    this.number_of_codes = number_of_codes;
    this.customer_id = customer_id;
    this.expired_date = expired_date;
    this.plan_duration = plan_duration;
    this.description = description;
    this.limitations = limitations;
    this.packages = packages;
  }
  static factory({
    number_of_codes,
    customer_id,
    expired_date,
    plan_duration,
    limitations,
    description,
    packages
  }: Partial<CodesCreationPayload>) {
    return new CodesCreationPayload(
      number_of_codes,
      customer_id,
      expired_date,
      plan_duration,
      description,
      limitations,
      packages
    );
  }
}
