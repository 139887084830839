import {
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
  ArrayField,
  Datagrid,
  ImageField,
  RichTextField,
  FunctionField,
  Record,
  BooleanField,
  ReferenceField
} from 'react-admin';

import { Box, Typography } from '@mui/material';

import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { ResourceName } from 'shared/enums';
import { SectionName } from 'shared/enums/section-name.enum';
import { getTitleCase } from 'shared/utils/helperFunctions/strings';
import { vh, rem } from 'shared/utils/styles/css-unit';

import { Referral } from '../models/Referral.model';

export function ReferralShow(props: ShowProps) {
  return (
    <Show
      {...props}
      title='Referrals'
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'create']}
          extraActions={[]}
          sectionName={SectionName.Monetization}
        />
      }
    >
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <ReferralTitle />
        </Typography>
        <TabbedShowLayout>
          <Tab label='Details'>
            <TextField source='id' label='Id' emptyText='-' />
            <TextField source='name' label='Name' emptyText='-' />
            <TextField source='description' label='Description' emptyText='-' />
            <BooleanField source='is_active' label={'Active'} />
            <ReferenceField
              label='Segment'
              source='segment_id'
              emptyText='-'
              link='show'
              reference={ResourceName.SEGMENTS}
            >
              <TextField source='name' />
            </ReferenceField>
          </Tab>
          <Tab label='Configuration'>
            <FunctionField
              render={(record?: Record) =>
                getTitleCase(record?.payload?.store ?? '-')
              }
              label='Store'
            />
            <TextField
              source='payload.program_id'
              label='Program id'
              emptyText='-'
            />
            <BooleanField
              label='Show PayPal popup'
              source='payload.show_paypal'
              emptyText='-'
            ></BooleanField>
          </Tab>
          <Tab label='Referral'>
            <ImageField label='Media' source='media' emptyText='-' />
            <TextField
              source='display_text.headline'
              label='Headline'
              emptyText='-'
            />
            <RichTextField
              source='display_text.body'
              label='Body'
              emptyText='-'
            ></RichTextField>
          </Tab>
          <Tab label="How It work's">
            <ArrayField source='display_text.how_it_works' label={''}>
              <Datagrid>
                <TextField source='title'></TextField>
                <RichTextField source='text'></RichTextField>
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
}

export function ReferralTitle() {
  const context = useRecordContext<Referral>();
  return <>{context.name}</>;
}
