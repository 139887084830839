import { useMemo } from 'react';

import {
  ArrayInput,
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { uniqueId } from 'lodash';

import { FieldObject } from 'resources/types';

import { SaveCancelFormToolbar } from 'shared/components/toolbars/SaveCancelFormToolbar.component';

import { ResourceName } from 'shared/enums';
import { rem, vh } from 'shared/utils/styles/css-unit';

import { MembershipDisplayPayload } from '../models/membership-display-mutation-payload.model';
import { MembershipDisplayTitle } from '../pages/MembershipDisplayShow.component';

export enum MembershipDisplayFormSource {
  id = 'id',
  name = 'name',
  button = 'payload.button',
  headline = 'payload.headline',
  plans_title = 'payload.plans_title',
  benefits_text = 'payload.benefits_text',
  source_plan = 'source_plan',
  target_plan = 'target_plan'
}

export interface MembershipDisplayFormProps {
  onSave(values: Partial<MembershipDisplayPayload>): void;
  onCancel(): void;
  fieldsToOmit?: MembershipDisplayFormSource[];
}

export function MembershipDisplayForm(props: MembershipDisplayFormProps) {
  const { onCancel, onSave, fieldsToOmit } = props;

  const shouldShowField = useMemo(
    () => (field: MembershipDisplayFormSource) => {
      return fieldsToOmit ? !fieldsToOmit.includes(field) : true;
    },
    [fieldsToOmit]
  );

  const handleSave = useMemo(
    () => (values: Partial<MembershipDisplayPayload>) => {
      onSave(
        MembershipDisplayPayload.factory({
          ...values
        })
      );
    },
    [onSave]
  );
  const offerCreationFields = useMemo(() => {
    const fields: FieldObject<MembershipDisplayFormSource>[] = [
      {
        source: MembershipDisplayFormSource.source_plan,
        element: (
          <ReferenceInput
            label={'From Plan'}
            source={'id'}
            reference={ResourceName.MEMBERSHIP_PLANS}
          >
            <AutocompleteInput optionText='short_name' resettable />
          </ReferenceInput>
        )
      },
      {
        source: MembershipDisplayFormSource.target_plan,
        element: (
          <ReferenceInput
            label={'To Plan'}
            source={'id'}
            reference={ResourceName.MEMBERSHIP_PLANS}
          >
            <AutocompleteInput optionText='short_name' resettable />
          </ReferenceInput>
        )
      }
    ];
    const filteredFields = fields.filter((field) =>
      shouldShowField(field.source)
    );
    if (filteredFields.length === 0) {
      return [];
    }
    return filteredFields.map((field) => field.element);
  }, [shouldShowField]);

  return (
    <>
      <Box sx={{ minHeight: vh(30) }}>
        <Typography
          sx={{ p: rem(2), fontSize: rem(2) }}
          variant='h2'
          component='h2'
        >
          <MembershipDisplayTitle />
        </Typography>
        <SimpleForm
          submitOnEnter={false}
          save={handleSave}
          toolbar={<SaveCancelFormToolbar onCancel={onCancel} />}
        >
          <TextInput
            label={'Name'}
            key={uniqueId()}
            source={MembershipDisplayFormSource.name}
          />
          <TextInput
            label={'Headline'}
            key={uniqueId()}
            source={MembershipDisplayFormSource.headline}
          />
          <TextInput
            label={'Plans Title'}
            key={uniqueId()}
            source={MembershipDisplayFormSource.plans_title}
          />
          <ArrayInput
            label={'Benefits'}
            source={MembershipDisplayFormSource.benefits_text}
          >
            <SimpleFormIterator>
              <TextInput
                fullWidth
                label={false}
                key={uniqueId()}
                source={'benefit_text'}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput
            label={'CTA'}
            key={uniqueId()}
            source={MembershipDisplayFormSource.button}
          />
        </SimpleForm>
      </Box>
    </>
  );
}
