export const NodeEnv = {
  dev: 'development',
  prod: 'production'
} as const;
export type NodeEnvId = keyof typeof NodeEnv;

export const isNodeEnv = (id: NodeEnvId) =>
  process.env.REACT_APP_DEPLOY_ENV === NodeEnv[id];

export const getApiRoot = () => {
  return `https://api.blazepod.${
    isNodeEnv('prod') ? 'live' : 'dev'
  }/v1/backoffice`;
};
