import { MembershipPlanProvider } from 'resources/customers/enums/MembershipPlanProvider.enum';

import { UserPlanDto } from './user-plan.dto';

export class UserPlan {
  id: string;
  user_id: string;
  plan_id: string;
  plan_origin: string;
  plan_status: string;
  provider: MembershipPlanProvider;
  is_renew: boolean;
  expire_date: string | null;

  constructor(
    id: string,
    user_id: string,
    plan_id: string,
    plan_origin: string,
    plan_status: string,
    provider: MembershipPlanProvider,
    is_renew = false,
    expire_date: string | null = null
  ) {
    this.id = id;
    this.user_id = user_id;
    this.plan_id = plan_id;
    this.plan_origin = plan_origin;
    this.plan_status = plan_status;
    this.provider = provider;
    this.is_renew = is_renew;
    this.expire_date = expire_date;
  }

  static dtoConverter(dto: UserPlanDto) {
    const {
      expire_date,
      is_renew,
      plan_id,
      plan_origin,
      plan_status,
      provider,
      user_id
    } = dto;

    return new UserPlan(
      user_id,
      user_id,
      plan_id,
      plan_origin,
      plan_status,
      provider,
      is_renew,
      expire_date
    );
  }
}
