import { Loading } from 'react-admin';

import { Box, Step, StepContent, Stepper, Typography } from '@mui/material';
import { uniqueId } from 'lodash';

import { formatStringToDate } from 'shared/utils/dates/dates-helper';

import { UserHistoryLog } from '../models/UserHistoryLog.model';

interface HistoryLogListProps {
  title?: string;

  history: UserHistoryLog[];

  loading?: boolean;
}

function HistoryLogList({ title, history, loading }: HistoryLogListProps) {
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Box ml={2}>
        <Typography variant='h3'>
          {title || 'History Log'}
          {history.length === 0 && ' - Empty'}
        </Typography>

        <Stepper orientation='vertical' sx={{ mt: 1 }}>
          {history.length > 0 &&
            history.map((log) => (
              <Step
                key={`${log?.date_of_change}-${uniqueId()}`}
                expanded
                active
                completed
              >
                <Typography
                  variant='caption'
                  color={(theme) => theme.palette.grey[600]}
                >
                  {formatStringToDate(log?.date_of_change)}
                </Typography>
                <StepContent>
                  <Typography variant='subtitle2'>
                    {log?.description}
                  </Typography>
                </StepContent>
              </Step>
            ))}
        </Stepper>
      </Box>
    </>
  );
}

export default HistoryLogList;
