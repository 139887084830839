import { useState } from 'react';
import {
  Create,
  CreateProps,
  useCreate,
  useEditContext,
  useNotify
} from 'react-admin';

import { ConfirmCreate } from 'shared/components/dialogs/ConfirmCreate.component';

import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { UnchangedDialog } from 'shared/components/dialogs/UnchangedDialog.component';
import { ResourceName } from 'shared/enums';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';

import { v4 } from 'uuid';

import { CampaignsForm } from '../components/CampaignForm.component';
import { Campaign } from '../models/campaign.model';
import { CampaignsMutationPayload } from '../models/campaigns-mutation-payload.model';

export function CampaignCreate(props: CreateProps) {
  return (
    <Create {...props} title='Campaigns'>
      <>
        <CampaignCreateForm />
      </>
    </Create>
  );
}
function CampaignCreateForm() {
  const notify = useNotify();
  const [UID] = useState(v4());
  const [create, updateState] = useCreate();
  const createContext = useEditContext<Campaign>();

  const initialValues = {
    display_frequency: { gap_days: 'value1', display_amount: 'value2' }
  };

  const {
    shouldShowConfirmSubmit,
    shouldShowUnchanged,
    shouldShowUnsaved,
    goBack,
    handleSave,
    handleCancelEdit,
    handleCancelSubmit,
    handleConfirmSubmit,
    handleUnchangedContinue,
    handleUnchangedQuit,
    handleUnsavedCancel,
    handleUnsavedConfirm
  } = useMutationVM<CampaignsMutationPayload, Campaign>({
    PayloadClass: CampaignsMutationPayload,
    record: createContext.record,
    resource: ResourceName.CAMPAIGNS,
    mutator: (resource, id, data, prev, options) => {
      create(`${resource}/${UID}`, data, options);
    },
    onMutateFailure: handleUpdateFailure,
    onMutateSuccess: handleUpdateSuccess
  });
  function handleUpdateSuccess() {
    notify('Campaign created successfully.', { type: 'success' });
    goBack();
  }
  function handleUpdateFailure(e: Error) {
    notify(
      e.message || 'Something went wrong. Please try again in a few seconds.',
      { type: 'error' }
    );
  }
  return (
    <>
      <CampaignsForm
        initialValues={initialValues}
        onCancel={handleCancelEdit}
        onSave={handleSave}
        uid={UID}
        title={'New Campaign'}
      />
      <ConfirmCreate
        loading={createContext.loading || updateState.loading}
        isOpen={shouldShowConfirmSubmit}
        onConfirm={handleConfirmSubmit}
        onClose={handleCancelSubmit}
        recordName=' a campaign'
      />
      <ConfirmUnsaved
        loading={createContext.loading}
        isOpen={shouldShowUnsaved}
        onClose={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
      />
      <UnchangedDialog
        name='Campaign'
        itemPageName='Customer'
        isOpen={shouldShowUnchanged}
        onContinue={handleUnchangedContinue}
        onQuit={handleUnchangedQuit}
      />
    </>
  );
}
