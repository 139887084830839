import { ResourceName } from 'shared/enums';
import {
  BackOfficeDataProvider,
  BackOfficeDtoConverter,
  DataProviderMethod,
  UrlArray
} from 'shared/services/backoffice-data-provider';
import { CustomerTypesEnum } from 'shared/utils/constants/blazepod';
import { createSearchParams } from 'shared/utils/navigation';

export class CustomerDataProvider extends BackOfficeDataProvider {
  private getManyBaseUrl(searchParams: Record<string, string[] | string>) {
    return `entities/customers?${createSearchParams(searchParams)}`;
  }

  protected customerUrlNeeded = ['getList', 'getManyReference', 'getMany'];

  protected dtoConverter = new BackOfficeDtoConverter();

  protected override urlRenderer(
    url: string | UrlArray,
    method: DataProviderMethod | undefined
  ) {
    if (!this.customerUrlNeeded.includes(method ?? '')) {
      return Array.isArray(url)
        ? `${ResourceName.CUSTOMERS}/${(url as UrlArray)[1]}`
        : ResourceName.CUSTOMERS;
    }

    let searchParams = {};
    if (url === ResourceName.ONLINE_CUSTOMERS) {
      searchParams = { customer_type: [CustomerTypesEnum.INDIVIDUAL] };
    } else if (url === ResourceName.OFFLINE_CUSTOMERS) {
      searchParams = {
        customer_type: [
          ...Object.values(CustomerTypesEnum).filter(
            (type) => type !== CustomerTypesEnum.INDIVIDUAL
          )
        ]
      };
    }
    return this.getManyBaseUrl(searchParams);
  }
}
