import { useMemo, useState } from 'react';
import { Record as RaRecord, useRedirect } from 'react-admin';
import { useHistory } from 'react-router-dom';

import { ResourceName } from 'shared/enums/resource-name.enum';
import { deepValidateChanged } from 'shared/utils/forms/deepValidateChanged';
import { Constructor } from 'shared/utils/types/constructor';

import { Mutator } from './types';

interface UseMutationVM<Payload> {
  payload: Payload;
  shouldShowConfirmSubmit: boolean;
  shouldShowUnchanged: boolean;
  shouldShowUnsaved: boolean;
  navigateToList(): void;
  navigateToItem(): void;
  goBack(): void;
  handleSave(payload: Payload): void;
  handleCancelEdit(): void;
  handleConfirmSubmit(): void;
  handleCancelSubmit(): void;
  handleUnsavedCancel(): void;
  handleUnsavedConfirm(): void;
  handleUnchangedContinue(): void;
  handleUnchangedQuit(): void;
  /**@deprecated*/ setFormValues?(payload: Payload | (() => Payload)): void;
  /**@deprecated*/ setShouldShowConfirmSubmit?(
    should: boolean | (() => boolean)
  ): void;
  /**@deprecated*/ setShouldShowUnchanged?(
    should: boolean | (() => boolean)
  ): void;
  /**@deprecated*/ setShouldShowUnsaved?(
    should: boolean | (() => boolean)
  ): void;
}
interface MutationVMConfig<Payload, Model extends RaRecord> {
  PayloadClass: Constructor<Payload>;
  resource: ResourceName;
  mutator: Mutator<Payload, Model>;
  onMutateSuccess(): void;
  onMutateFailure(e?: Error): void;
  onAbort?(): void;
  record?: Model;
}

export function useMutationVM<Payload extends object, Model extends RaRecord>(
  config: MutationVMConfig<Payload, Model>
): UseMutationVM<Payload> {
  const {
    PayloadClass,
    resource,
    record,
    mutator,
    onMutateFailure,
    onMutateSuccess,
    onAbort
  } = config;

  const [payload, setPayload] = useState<Payload>(new PayloadClass());
  const [shouldShowConfirmSubmit, setShouldShowConfirmSubmit] =
    useState<boolean>(false);
  const [shouldShowUnchanged, setShouldShowUnchanged] =
    useState<boolean>(false);
  const [shouldShowUnsaved, setShouldShowUnsaved] = useState<boolean>(false);

  const redirect = useRedirect();
  const history = useHistory();

  return useMemo(() => {
    const navigateToList = () => redirect(`/${resource}`);
    const goBack = () => history.goBack();
    const navigateToItem = () => redirect('show', `/${resource}`, record?.id);

    const handleCancelEdit = () => {
      setShouldShowUnsaved(true);
    };
    const handleSave = (payload: Payload) => {
      const changed = deepValidateChanged(payload as object, record ?? {});
      if (changed) {
        setPayload(() => {
          setShouldShowConfirmSubmit(true);
          return payload;
        });
        return;
      }
      setShouldShowUnchanged(true);
    };

    const handleCancelSubmit = () => {
      setShouldShowConfirmSubmit(false);
    };
    const handleConfirmSubmit = () => {
      mutator(resource, record?.id, payload, record, {
        onFailure: onMutateFailure,
        onSuccess: onMutateSuccess
      });
    };

    const handleUnsavedCancel = () => {
      setShouldShowUnsaved(false);
    };
    const handleUnsavedConfirm = () => {
      setShouldShowUnsaved(false);
      onAbort != null ? onAbort() : goBack();
    };

    const handleUnchangedContinue = () => {
      setShouldShowUnchanged(false);
    };
    const handleUnchangedQuit = () => {
      goBack();
    };

    return {
      payload,
      shouldShowConfirmSubmit,
      shouldShowUnchanged,
      shouldShowUnsaved,
      navigateToItem,
      navigateToList,
      goBack,
      handleSave,
      handleCancelEdit,
      handleConfirmSubmit,
      handleCancelSubmit,
      handleUnsavedCancel,
      handleUnsavedConfirm,
      handleUnchangedContinue,
      handleUnchangedQuit
    };
  }, [
    payload,
    shouldShowConfirmSubmit,
    shouldShowUnchanged,
    shouldShowUnsaved,
    redirect,
    resource,
    history,
    record,
    mutator,
    onMutateFailure,
    onMutateSuccess,
    onAbort
  ]);
}
