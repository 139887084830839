import { SegmentFeatureItem } from '../components/SegmentFeatureField.component';

export class Segment {
  public id: string;
  public name: string;
  public description: string;
  public features: SegmentFeatureItem[];

  constructor(
    id = '',
    name = '',
    description = '',
    features = new Array<SegmentFeatureItem>()
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.features = features;
    if (features?.length > 0) {
      this.features = features.map((feature) =>
        SegmentFeatureItem.fromDto(feature, id)
      );
    }
  }

  static fromDto(segment: any): any {
    return new Segment(
      segment.id,
      segment.name,
      segment.description,
      segment.features
    );
  }
}
