import { createRoot } from 'react-dom/client';

import { isNodeEnv } from 'shared/utils/helperFunctions/dotenv.utils';

import { Root } from './Root';

if (!isNodeEnv('prod') && module.hot) {
  module.hot.accept();
}

// create a root element and append to document.body
const container = document.querySelector('#root') as HTMLElement;

// create app root in container element
const root = createRoot(container);

// render app in element

root.render(<Root />);
