export enum ContentType {
  ACTIVITY = 'ACTIVITY',
  TEST = 'TEST',
  COLLECTION = 'COLLECTION',
  WORKOUTS = 'WORKOUTS'
}

export enum BPContentEngineEnum {
  Random = 'random',
  AllAtOnce = 'allAtOnce',
  Focus = 'focus',
  Sequence = 'sequence',
  HomeBase = 'homeBase',
  Collection = 'collection'
}

export enum ContentVisibility {
  OWNER = 'OWNER',
  SPECIFIC = 'SPECIFIC',
  PUBLIC = 'PUBLIC',
  HIDDEN = 'HIDDEN',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  PRIVATE = 'PRIVATE'
}

export enum ContentCreatorType {
  BLAZEPOD = 'BLAZEPOD',
  USER = 'USER',
  TOP_LEVEL = 'TOP_LEVEL'
}

export enum BPMediaType {
  IMAGE = 'image',
  GIT = 'gif',
  VIDEO = 'video',
  SETUP = 'setup',
  SUMMARY = 'summary',
  ASSET = 'asset'
}

export enum ContentUpdateAction {
  Consent = 'consent',
  PublishedWebsite = 'publishedWebsite',
  PublishedPredefined = 'publishedPredefined',
  Tags = 'tags',
  Benchmark = 'benchmark',
  Challenge = 'challenge'
}
