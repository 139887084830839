import { ContentPackage } from 'resources/content_packages/content-package.model';
import { MembershipPlanProvider } from 'resources/customers/enums/MembershipPlanProvider.enum';
import { PlanDuration } from 'resources/plans/plan-duration.model';

import { MembershipCodeDto } from './MembershipCode.dto';
import { CodeStatus } from '../enums/code-status.enum';

export type CodeLimitation = RegExp[];

export class MembershipCode {
  id: string;
  code: string;
  status: CodeStatus;
  creation_date: string;
  expired_date: string | null;
  activation_date: string | null;
  plan_duration: PlanDuration | null;
  creator_id: string | null;
  customer_id: string | null;
  app_user_id: string | null;
  provider: MembershipPlanProvider | null;
  /**@description awaiting server support! */ limitation?: CodeLimitation;
  packages: ContentPackage[] | null;
  hasPackages: boolean | null;

  // [x: string]: MembershipCode[keyof MembershipCode];

  constructor(
    id: string,
    code: string,
    code_status: CodeStatus,
    creation_date: string,
    expired_date: string | null = null,
    activation_date: string | null = null,
    plan_duration: PlanDuration | null = null,
    creator_id: string | null = null,
    customer_id: string | null = null,
    app_user_id: string | null = null,
    provider: MembershipPlanProvider | null = null,
    code_limitation?: CodeLimitation,
    packages: ContentPackage[] | null = null
  ) {
    this.id = id;
    this.code = code;
    this.status = code_status;
    this.creation_date = creation_date;
    this.expired_date = expired_date;
    this.activation_date = activation_date;
    this.plan_duration = plan_duration;
    this.creator_id = creator_id;
    this.customer_id = customer_id;
    this.app_user_id = app_user_id;
    this.provider = provider;
    this.limitation = code_limitation;
    this.packages = packages;
    this.hasPackages = packages != null && packages.length > 0;
  }

  static fromDto({
    id,
    code,
    code_status,
    creation_date,
    expired_date = null,
    activation_date = null,
    plan_duration = null,
    creator_id = null,
    customer_id = null,
    app_user_id = null,
    provider = null,
    code_limitation,
    packages
  }: MembershipCodeDto) {
    return new MembershipCode(
      id,
      code,
      code_status,
      creation_date,
      expired_date,
      activation_date,
      plan_duration,
      creator_id,
      customer_id,
      app_user_id,
      provider,
      code_limitation,
      packages
    );
  }
}
