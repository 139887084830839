import { useMemo, useState } from 'react';

import {
  HttpError,
  LinearProgress,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';

import VisibilityIcon from '@mui/icons-material/Visibility';

import { Box, Button, Tooltip, useTheme } from '@mui/material';

import { CampaignsMutationPayload } from 'resources/campaigns/models/campaigns-mutation-payload.model';
import { ReferralsMutationPayload } from 'resources/referrals/models/referrals-mutation-payload.model';
import {
  ConfirmDialog,
  DialogButtonConfig
} from 'shared/components/dialogs/BaseConfirmDialog.component';
import { ResourceName } from 'shared/enums';

import { useAxiosCall } from 'shared/hooks/useAxiosCall';
import { getTitleCase } from 'shared/utils/helperFunctions/strings';
import { px } from 'shared/utils/styles/css-unit';

export interface changeStatusButtonProps {
  label?: boolean;
  recordType: string;
  resource: ResourceName;
}

export function ChangeStatusButton(props: changeStatusButtonProps) {
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const theme = useTheme();

  const isActive = useMemo<boolean>(() => {
    return record?.is_active;
  }, [record?.is_active]);

  const tooltip = `${isActive ? 'Deactivate' : 'Activate'} ${props.recordType}`;
  const messageContent = `Please confirm ${
    isActive ? 'deactivate' : 'activate'
  } this ${props.recordType}`;

  const fetchRemoveContent = useAxiosCall<boolean>({
    resource: `${props.resource}/${record.id}`,
    autoFetch: false,
    init: { method: 'PUT' }
  });

  const handleConfirm = () => {
    let values;
    switch (props.resource) {
      case ResourceName.CAMPAIGNS:
        values = CampaignsMutationPayload.factory(record);
        break;
      case ResourceName.REFERRALS:
        values = ReferralsMutationPayload.factory(record);
        break;
      default:
        break;
    }
    fetchRemoveContent
      .fetch({ ...values, is_active: !isActive })
      .then(() => handleCancelSuccess())
      .catch((err) => handleCancelFail(err));
  };

  const handleCancelSuccess = async () => {
    setShouldShowConfirm(false);
    refresh();
    notify(`${getTitleCase(props.recordType)} updated successfully`, {
      type: 'success'
    });
  };

  const handleCancelFail = (e: HttpError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  if (record == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='left'
        title={tooltip}
        describeChild
        enterDelay={1000}
      >
        <div>
          <Button
            onClick={handleClick}
            sx={(theme) => ({
              px: px(5),
              py: px(4),
              color: theme.palette.primary.main,
              lineHeight: 1.5
            })}
          >
            <VisibilityIcon
              fontSize='small'
              color={isActive ? undefined : 'disabled'}
            />
            {props.label != null ? (
              <Box component='span' sx={{ pl: px(6) }}>
                {tooltip.toUpperCase()}
              </Box>
            ) : (
              ''
            )}
          </Button>
        </div>
      </Tooltip>
      <ConfirmDialog
        onClose={handleAbort}
        onConfirm={handleConfirm}
        isOpen={shouldShowConfirm}
        confirmButton={
          new DialogButtonConfig('Confirm', theme.palette.primary.main)
        }
        closeButton={new DialogButtonConfig('Abort', theme.palette.grey[500])}
        content={messageContent}
        title={`Confirm ${tooltip.toLowerCase()}`}
      />
    </>
  );
}
