import { useMemo } from 'react';
import {
  FieldProps,
  Link,
  Record as RaRecord,
  useRecordContext,
  useResourceContext
} from 'react-admin';

import { Typography } from '@mui/material';
import get from 'lodash/get';
import { PageType } from 'shared/utils/constants/react-admin';

import { FieldFiller } from './FieldFiller.component';

export interface LinkableFieldProps extends FieldProps {
  source?: string;
  identifierPropertyName?: string;
  viewResource?: string;
  page?: PageType;
  render?<R extends RaRecord = RaRecord>(record: R): string | null | undefined;
}

export function LinkableField(props: LinkableFieldProps) {
  const {
    source,
    emptyText = '-',
    viewResource,
    page = 'show',
    render
  } = props;
  const resource = useResourceContext();
  const record = useRecordContext(props);

  const text = useMemo(() => {
    if (render) {
      return render(record);
    }
    if (source) {
      return get(record, source) as string;
    }
    return null;
  }, [source, record, render]);

  return !text || text.length === 0 ? (
    <FieldFiller emptyText={emptyText} />
  ) : (
    <Link
      to={`/${viewResource ?? resource}/${
        record[props.identifierPropertyName || 'id']
      }${page ? `/${page}` : ''}`}
    >
      <Typography component='span' variant='body2'>
        {text}
      </Typography>
    </Link>
  );
}
