import { ElementType } from 'react';

import { Theme, Typography, TypographyProps } from '@mui/material';

export interface LargeTextWrapperProps extends TypographyProps {
  component?: ElementType;
}

export function LargeTextWrapper(props: LargeTextWrapperProps) {
  const {
    component = 'data',
    variant = 'h4',
    color = (theme: Theme) => theme.palette.primary.main,
    ...rest
  } = props;
  return (
    <Typography
      {...rest}
      component={component}
      variant={variant}
      color={color}
    />
  );
}
