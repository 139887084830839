import { useState } from 'react';

import {
  HttpError,
  LinearProgress,
  useAuthProvider,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Button, Tooltip } from '@mui/material';

import { ResourceName } from 'shared/enums';
import { getApiRoot } from 'shared/utils/helperFunctions/dotenv.utils';
import { buildBlazepodHeaders } from 'shared/utils/helperFunctions/fetchHelper';
import { px } from 'shared/utils/styles/css-unit';

import { AddToSegmentFormDialog } from './AddToSegmentFormDialog.component';
import { Segment } from '../models/segment.model';

export function AddToSegmentButton() {
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const record = useRecordContext<Segment>();
  const authProvider = useAuthProvider();

  const refresh = useRefresh();
  const notify = useNotify();

  const handleSuccess = () => {
    setShouldShowConfirm(false);
    refresh();
    notify('Feature added.', { type: 'success' });
  };

  const handelFail = (e: HttpError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  const fetchUpdateStatus = async (values: any) => {
    const body = {
      payload: {
        feature_id: values.feature_id
      },
      segment_id: record.id,
      name: values.feature_name,
      description: values.feature_description,
      app_feature_key: values.feature_key
    };
    const res = await fetch(
      `${getApiRoot()}/${ResourceName.SEGMENTS}/${record.id}/feature`,
      await buildBlazepodHeaders(authProvider, 'PUT', { ...body })
    );
    return res.json();
  };

  const handleConfirm = async (values: any) => {
    fetchUpdateStatus(values)
      .then((response) => {
        if (response.payload) {
          handleSuccess();
        } else {
          handelFail(
            new HttpError(
              'This segment contains this feature already',
              response.status.code
            )
          );
        }
      })
      .catch(handelFail);
  };

  if (record == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='right'
        title={'Add feature to segment'}
        describeChild
        enterDelay={1000}
      >
        <div>
          <Button
            onClick={handleClick}
            sx={(theme) => ({
              px: px(5),
              py: px(4),
              lineHeight: 1.5
            })}
          >
            <AddCircleIcon />
            {' Add feature'}
          </Button>
        </div>
      </Tooltip>
      <AddToSegmentFormDialog
        onClose={handleAbort}
        onSend={handleConfirm}
        isOpen={shouldShowConfirm}
        title='Add to feature'
      />
    </>
  );
}
