import { useAuthState, useLogin } from 'ra-core';
import { LoginComponent } from 'shared/components/log-in/Login.component';

export const LogInPage = () => {
  const login = useLogin();
  const { authenticated, loading } = useAuthState();

  const handleSignIn = () => login({});
  return (
    <LoginComponent
      signIn={handleSignIn}
      loading={loading}
      isAuth={authenticated ?? false}
      // error={loaded && authenticated == null ? 'Not Authenticated' : undefined}
    />
  );
};

export default LogInPage;
