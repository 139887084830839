export class AppAdmin {
  id: string;
  user_id: string;
  content: string;
  entities: string;
  config: string;
  communication: string;
  monetization: string;

  constructor(
    user_id: string,
    content: string,
    entities: string,
    communication: string,
    monetization: string,
    config: string
  ) {
    this.id = user_id;
    this.user_id = user_id;
    this.content = content;
    this.entities = entities;
    this.communication = communication;
    this.monetization = monetization;
    this.config = config;
  }

  static fromDto(admin: any) {
    return new AppAdmin(
      admin.user_id,
      admin.content,
      admin.entities,
      admin.communication,
      admin.monetization,
      admin.config
    );
  }
}
