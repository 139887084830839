import { BooleanInput, List, ListProps, SearchInput } from 'react-admin';

import { AppResponsive } from 'shared/components/common/AppResponsive.component';
import { AutocompleteReferenceInput } from 'shared/components/inputs/AutocompleteReferenceInput.component';
import { Pagination } from 'shared/components/pagination/Pagination.component';

import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { ResourceName } from 'shared/enums';
import { SectionName } from 'shared/enums/section-name.enum';
import { RaListOrder } from 'shared/services/backoffice-data-provider';

import { UserActivitiesTable } from '../components/UserActivitiesTable.component';

export function UserActivitiesList(props: ListProps) {
  const orderFilters = [
    <SearchInput source='search' alwaysOn />,
    <BooleanInput label='Consent' source='consent' />,
    <BooleanInput label='Website published' source='published' />,
    <AutocompleteReferenceInput
      label='User id'
      source='creator_id'
      reference={ResourceName.USERS}
      sort={{ field: 'display_name', order: RaListOrder.ASC }}
      filterKey='search'
      optionText='id'
    />
  ];
  return (
    <List
      {...props}
      perPage={100}
      sort={{ field: 'display_name', order: RaListOrder.ASC }}
      pagination={<Pagination />}
      filters={orderFilters}
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'edit', 'list', 'create']}
          sectionName={SectionName.Content}
        />
      }
      exporter={false}
    >
      <AppResponsive
        xs={<UserActivitiesTable omitItems={[]} />}
        sm={<UserActivitiesTable omitItems={[]} />}
        md={<UserActivitiesTable omitItems={[]} />}
        lg={<UserActivitiesTable />}
        xl={<UserActivitiesTable />}
      />
    </List>
  );
}
