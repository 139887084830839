import { useMemo, useState } from 'react';

import {
  LinearProgress,
  useGetMany,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';

import MailIcon from '@mui/icons-material/Mail';
import { Button, Tooltip } from '@mui/material';

import { ResendCodeFormDialog } from 'resources/codes/components/ResendCodeFormDialog.component';
import { MembershipCode } from 'resources/codes/models/MembershipCode.model';
import { ResourceName } from 'shared/enums';
import { useAxiosCall } from 'shared/hooks/useAxiosCall';
import { BPError } from 'shared/services/backoffice-data-provider';
import { px } from 'shared/utils/styles/css-unit';

import { CodeStatus } from '../enums/code-status.enum';

export function CodeResendButton() {
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const code = useRecordContext<MembershipCode>();
  const customers = useGetMany(
    ResourceName.CUSTOMERS,
    [code.customer_id ?? ''],
    { enabled: !!code.customer_id }
  );
  const customer = useMemo(() => {
    return customers.data.find((c) => c?.id === code.customer_id);
  }, [code.customer_id, customers.data]);
  const refresh = useRefresh();
  const notify = useNotify();

  const fetchResendCode = useAxiosCall<boolean>({
    resource: `${ResourceName.CODES}/${code.id}/resend`,
    autoFetch: false,
    init: { method: 'POST' }
  });

  const isPending = useMemo(
    () => code?.status === CodeStatus.Pending,
    [code?.status]
  );

  const shouldDisable = useMemo(() => !isPending, [isPending]);

  const handleSuccess = () => {
    setShouldShowConfirm(false);
    refresh();
    notify('Code sent.', { type: 'success' });
  };

  const handelFail = (e: BPError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  const handleConfirm = async (email: string) => {
    await fetchResendCode
      .fetch({ email })
      .then(handleSuccess)
      .catch(handelFail);
  };

  if (code == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='left'
        title={'Resend code'}
        describeChild
        enterDelay={1000}
      >
        <div>
          <Button
            disabled={shouldDisable}
            onClick={handleClick}
            sx={(theme) => ({
              px: px(5),
              py: px(4),
              lineHeight: 1.5
            })}
          >
            <MailIcon />
          </Button>
        </div>
      </Tooltip>
      <ResendCodeFormDialog
        onClose={handleAbort}
        onSend={handleConfirm}
        loading={fetchResendCode.loading}
        isOpen={shouldShowConfirm}
        defaultValue={customer?.email}
        content='Confirm the email address'
        title='Resend code'
      />
    </>
  );
}
