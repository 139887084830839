import {
  Loading,
  ReferenceField,
  SimpleList,
  TextField,
  useListContext
} from 'react-admin';

import { CurrentPlanField } from 'resources/users_plans/components';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { ResourceName } from 'shared/enums/resource-name.enum';

import { AppUser } from '../models/AppUser.model';

export function UsersSimpleList() {
  const listContext = useListContext<AppUser>();
  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <SimpleList
          linkType='show'
          primaryText={<TextField source='email' label='Email' emptyText='-' />}
          secondaryText={
            <ReferenceField
              source='id'
              label='Current Plan'
              reference={ResourceName.USERS_PLANS}
              link={false}
            >
              <CurrentPlanField />
            </ReferenceField>
          }
        />
      )}
    </>
  );
}
