import { useState } from 'react';
import {
  Button,
  ConfirmProps,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar
} from 'react-admin';

import SendIcon from '@mui/icons-material/Send';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { AutocompleteReferenceInput } from 'shared/components/inputs/AutocompleteReferenceInput.component';
import { ResourceName } from 'shared/enums';
import { RaListOrder } from 'shared/services/backoffice-data-provider';

export const featureOptions = [
  { id: 'refer_a_friend', name: 'Refer a friend', label: 'Refer a friend' },
  { id: 'campaign', name: 'Campaign', label: 'Campaign' }
];

export interface DialogProps
  extends Omit<ConfirmProps, 'onConfirm' | 'content'> {
  onSend(values: any): void;
}

export function AddToSegmentFormDialog(props: DialogProps) {
  const [selectedFeature, SetSelectedFeature] = useState<string>();

  function onSelectInputChange(event: any): void {
    SetSelectedFeature(event.target.value);
  }

  return (
    <Dialog disableScrollLock={true} open={props.isOpen ?? false}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <SimpleForm
          submitOnEnter={false}
          save={(values: any) => {
            values.feature_key = selectedFeature;
            props.onSend(values);
          }}
          toolbar={
            <Toolbar>
              <SaveButton
                label='Confirm'
                icon={<SendIcon fontSize='small' />}
              />
              <Button onClick={props.onClose} label='Abort'></Button>{' '}
            </Toolbar>
          }
        >
          <TextInput source={'feature_name'} label={'Name'}></TextInput>
          <TextInput
            source={'feature_description'}
            label={'Description'}
          ></TextInput>
          <InputLabel>Select feature resource</InputLabel>
          <Select onChange={(event) => onSelectInputChange(event)}>
            <MenuItem value={'refer_a_friend'}>Refer a friend</MenuItem>
            <MenuItem value={'campaign'}>Campaign</MenuItem>
          </Select>
          {selectedFeature === 'refer_a_friend' && (
            <AutocompleteReferenceInput
              source='feature_id'
              filterKey='search'
              label={'Referrals Name'}
              sort={{ field: 'name', order: RaListOrder.ASC }}
              reference={ResourceName.REFERRALS}
              optionText='name'
              resettable
            ></AutocompleteReferenceInput>
          )}
          {selectedFeature === 'campaign' && (
            <AutocompleteReferenceInput
              source='feature_id'
              filterKey='search'
              label={'Campaign Name'}
              sort={{ field: 'name', order: RaListOrder.ASC }}
              reference={ResourceName.CAMPAIGNS}
              optionText='name'
              resettable
            ></AutocompleteReferenceInput>
          )}
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
}
