import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { MemberhsipDisplayEdit } from './components/MemberhsipDisplayEdit.component';
import { MembershipDisplayList } from './pages/MembershipDisplayList.component';
import { MembershipDisplayShow } from './pages/MembershipDisplayShow.component';

export const membershipDisplayModule = RaModule.fromResourceProps({
  name: ResourceName.MEMBERSHIP_DISPLAY,
  options: { label: 'Offers' },
  list: MembershipDisplayList,
  show: MembershipDisplayShow,
  edit: MemberhsipDisplayEdit,
  // create: MembershipDisplayCreate,
  intent: ResourceIntent.ROUTE,
  icon: ShoppingBagIcon
});
