export function csvMaker<Data extends object>(
  data: Data | Data[],
  headers: string[]
) {
  const flattenedObject: Record<string, unknown> = {};
  const valuesToPush: string[] = [];
  let headersToPush: string[] = [];
  function handleObject([key, value]: [string, unknown]): Record<
    string,
    unknown
  > {
    if (!value || typeof value !== 'object') {
      return { [key]: value };
    }
    const valueEntries = Object.entries(value);
    if (!valueEntries || valueEntries.length === 0) {
      return {};
    }
    const firstKey = valueEntries[0][0];
    const firstProp = handleObject([
      `${key}.${firstKey}`,
      (value as Record<string, unknown>)[firstKey]
    ]);
    return {
      ...firstProp,
      ...handleObject([key, Object.fromEntries(valueEntries.slice(1))])
    };
  }

  const handleKeys = (data: Data, outputObject = flattenedObject) => {
    for (const key of headers) {
      outputObject = {
        ...outputObject,
        ...handleObject([key as string, data[key as keyof Data]])
      };
    }
    if (headersToPush.length < Object.keys(outputObject).length) {
      headersToPush = Object.keys(outputObject);
    }
    return outputObject;
  };

  if (!Array.isArray(data)) {
    valuesToPush.push(Object.values(handleKeys(data)).join(','));
  } else {
    for (const item of data) {
      const row = handleKeys(item, {});
      valuesToPush.push(Object.values(row).join(','));
    }
  }

  return [headersToPush.join(',')].concat(valuesToPush).join('\n');
}
