import CreditCardIcon from '@mui/icons-material/CreditCard';
import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { OnlineCustomersList } from './OnlineCustomersList.component';
import { OnlineCustomersShow } from './OnlineCustomersShow.component';

export const OnlineCustomerRouteModule = RaModule.fromResourceProps({
  name: ResourceName.ONLINE_CUSTOMERS,
  options: { label: 'Online Customers' },
  list: OnlineCustomersList,
  show: OnlineCustomersShow,
  intent: ResourceIntent.ROUTE,
  icon: CreditCardIcon
});
