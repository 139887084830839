import { useMemo } from 'react';
import { useRecordContext } from 'react-admin';

import { Button, Stack, useTheme } from '@mui/material';
import { MembershipPlanProvider } from 'resources/customers/enums/MembershipPlanProvider.enum';
import { UserPlanBooleanField } from 'resources/users_plans/components/users-plans-fields';
import { UserPlan } from 'resources/users_plans/models/user-plan.model';
import { em } from 'shared/utils/styles/css-unit';

import { HasOnEditIsRenew } from '../../interfaces/HasOnEditIsRenew';
import { HasPermission } from '../../interfaces/HasPermission';

export function PlanIsRenew(props: HasOnEditIsRenew & HasPermission) {
  const record = useRecordContext<UserPlan>();
  const theme = useTheme();

  const disableRenew = useMemo(() => {
    if (record.is_renew && props.permission) {
      if (
        record.provider === MembershipPlanProvider.Recharge ||
        record.provider === MembershipPlanProvider.RechargeEu
      ) {
        return false;
      }
    }
    return true;
  }, [props.permission, record]);

  return (
    <Stack sx={{ alignItems: 'center' }} direction='row' spacing={4}>
      <UserPlanBooleanField source='is_renew' />
      <div
        style={{
          height: em(1.7),
          borderLeft: `1px solid ${theme.palette.grey[400]}`
        }}
      />
      <Button disabled={disableRenew} onClick={props.onEditIsRenew}>
        Cancel Renewal
      </Button>
    </Stack>
  );
}
