import { useMemo } from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';

import { ResourceName } from 'shared/enums/resource-name.enum';
import { useBPSummary } from 'shared/hooks/useBPSummary';
import { Stat } from 'shared/models/summary/Stat.model';

export function CodesSelectStatus(
  props: Omit<SelectInputProps, 'loading' | 'choices' | 'error'>
) {
  const { data, loading, error } = useBPSummary(ResourceName.CODES);
  const options = useMemo(() => {
    if (data == null) {
      return [];
    }
    return data
      .concat([
        new Stat(
          'all',
          'status',
          'All',
          data.reduce((count, stat) => +(stat?.count ?? '0') + count, 0),
          'All'
        )
      ])
      .map((stat) => {
        return {
          id: stat?.filter_value,
          name: stat?.display_name,
          count: stat?.count?.toLocaleString()
        };
      });
  }, [data]);
  return (
    <SelectInput
      {...props}
      loading={loading}
      choices={options}
      error={!!error}
      optionText={(choice) => `${choice.name} (${choice.count})`}
    />
  );
}
