import { ReactElement, ReactNode } from 'react';
import { ReduxState } from 'react-admin';
import { useSelector } from 'react-redux';

import {
  Collapse,
  List,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { styled } from '@mui/material';

import { SubMenuClassNames } from './enums';

interface Props {
  dense: boolean;
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  children: ReactNode;
}

export const SubMenu = styled(NostylesSubMenu)(({ theme }) => ({
  [`& ${SubMenuClassNames.icon}`]: { minWidth: theme.spacing(5) },
  [`& ${SubMenuClassNames.sidebarIsOpen}`]: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(4)
    }
  },
  [`& ${SubMenuClassNames.sidebarIsClosed}`]: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(2)
    }
  }
}));
function NostylesSubMenu(props: Props) {
  const { handleToggle, isOpen, name, icon, children, dense } = props;

  const sidebarIsOpen = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  );

  const header = (
    <MenuItem dense={dense} button onClick={handleToggle}>
      <ListItemIcon className={SubMenuClassNames.icon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography
        variant='inherit'
        color='textSecondary'
        style={{ fontWeight: 'bold' }}
      >
        {name}
      </Typography>
    </MenuItem>
  );

  return (
    <>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement='right'>
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <List
          dense={dense}
          component='div'
          disablePadding
          className={
            sidebarIsOpen
              ? SubMenuClassNames.sidebarIsOpen
              : SubMenuClassNames.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </>
  );
}
