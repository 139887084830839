import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  HttpError,
  Record as RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult
} from 'react-admin';

import axios, { AxiosError } from 'axios';

import { getApiRoot } from 'shared/utils/helperFunctions/dotenv.utils';
import { createSearchParams } from 'shared/utils/navigation';

import { RaListOrder } from './enums';
import { AuthService, DtoConverter } from './interfaces';
import { BPApiStatus, BPErrorResponse, SortPayload } from './models';

import { DataProviderMethod, UrlArray } from './types';

export abstract class BackOfficeDataProvider implements DataProvider {
  protected client = axios.create();
  protected updateMethod: 'put' | 'patch' = 'put';

  protected abstract dtoConverter: DtoConverter;
  protected abstract urlRenderer(
    url: string | UrlArray,
    method?: DataProviderMethod
  ): string;

  constructor(protected authService: AuthService) {
    this.initConfig();
  }

  private async initConfig() {
    this.client.interceptors.request.use(async (req) => {
      return new Promise((resolve, reject) => {
        this.authService.getToken().then((token) => {
          if (token == null) {
            reject(new HttpError('No logged in user', 401));
            return;
          }
          req.headers['Authorization'] = `Bearer ${token}`;
          req.headers['name'] = 'BlazePodApi';
          const config = {
            baseURL: getApiRoot(),
            paramsSerializer: function (
              params: Record<string, string | string[]>
            ) {
              return createSearchParams(params).toString();
            }
          };
          resolve({ ...req, ...config });
        });
      });
    });
  }

  async getList<RecordType extends RaRecord = RaRecord>(
    resource: string,
    viewParams: GetListParams
  ): Promise<GetListResult<RecordType>> {
    return new Promise(async (resolve, reject) => {
      const params = this.handleListParams(viewParams);
      await this.client
        .get(this.urlRenderer(resource, 'getList'), {
          params
        })
        .then((response) => {
          const {
            data: { payload }
          } = response;
          resolve(this.dtoConverter.convertList(payload));
          return;
        })
        .catch((res: AxiosError<BPErrorResponse>) => {
          reject(res.response?.data.error);
        });
    });
  }

  async getOne<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const { id } = params;

    const {
      data: { payload }
    } = await this.client.get(
      this.urlRenderer([resource, String(id)], 'getOne')
    );

    return this.dtoConverter.convertOne({ ...payload, id: payload.id ?? id });
  }

  async getMany<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    const { ids } = params;

    const {
      data: { payload }
    } = await this.client.get(this.urlRenderer(resource, 'getMany'), {
      params: { id: ids }
    });

    if (payload == null) {
      return { data: [] };
    }

    return this.dtoConverter.convertMany(payload);
  }

  async getManyReference<RecordType extends RaRecord = RaRecord>(
    resource: string,
    viewParams: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    const params = this.handleListParams(viewParams);

    const {
      data: { payload }
    } = await this.client.get(this.urlRenderer(resource, 'getManyReference'), {
      params
    });

    return this.dtoConverter.convertManyReference(payload);
  }

  async update<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: UpdateParams<Partial<RecordType>>
  ): Promise<UpdateResult<RecordType>> {
    return new Promise((resolve, reject) => {
      this.client[this.updateMethod](
        this.urlRenderer([resource, String(params.id)], 'update'),
        {
          ...params.data
        }
      )
        .then(({ data: { payload } }) => {
          if (!payload) {
            resolve(this.getOne(resource, params));
            return;
          }
          resolve(this.dtoConverter.convertUpdate<RecordType>(payload));
        })
        .catch((res: AxiosError<BPErrorResponse>) => {
          reject(res.response?.data.error);
        });
    });
  }

  async updateMany<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: UpdateManyParams<Partial<RecordType>>
  ): Promise<UpdateManyResult> {
    return new Promise((resolve, reject) => {
      const { ids } = params;
      this.client[this.updateMethod](
        this.urlRenderer(resource, 'updateMany'),
        { ...params.data },
        { params: { id: ids } }
      ).then(({ data: { payload, status } }) => {
        if (!payload) {
          if (status.code < 200 || status.code >= 300) {
            reject(this._getError(status));
          }
          return resolve({ data: [] });
        }

        return resolve(this.dtoConverter.convertUpdateMany(payload));
      });
    });
  }

  async create<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: CreateParams<Partial<RecordType>>
  ): Promise<CreateResult<RecordType>> {
    return new Promise((resolve, reject) => {
      this.client
        .post(this.urlRenderer(resource, 'create'), { ...params.data }, {})
        .then((resp) => {
          const { payload } = resp.data;
          resolve(this.dtoConverter.convertCreate(payload));
          return;
        })
        .catch((res: AxiosError<BPErrorResponse>) => {
          reject(res.response?.data.error);
        });
    });
  }

  async delete<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>> {
    return new Promise((resolve, reject) =>
      this.client
        .delete(this.urlRenderer([resource, String(params.id)], 'delete'))
        .then((response) => {
          const { payload, status } = response.data;
          if (payload == null) {
            if (status.codes < 200 || status.code >= 300) {
              reject(this._getError(status));
              return;
            }
            resolve(this.getOne(resource, { id: params.id }));
            return;
          }
          resolve(this.dtoConverter.convertDelete(payload));
        })
    );
  }

  async deleteMany(
    resource: string,
    params: DeleteManyParams
  ): Promise<DeleteManyResult> {
    return new Promise((resolve, reject) =>
      this.client
        .delete(this.urlRenderer(resource, 'deleteMany'), {
          params: { id: params.ids }
        })
        .then((response) => {
          const { payload, status } = response.data;
          if (!payload) {
            if (status.codes < 200 || status.code >= 300) {
              reject(this._getError(status));
              return;
            }
            resolve({ data: params.ids });
            return;
          }
          resolve(this.dtoConverter.convertDeleteMany(payload));
        })
    );
  }

  protected handleListParams(params: GetListParams | GetManyReferenceParams) {
    const { pagination, filter, sort } = params;

    const offset = (pagination.page - 1) * pagination.perPage,
      page_size = pagination.perPage;

    const purgedFilters = Object.fromEntries(
      Object.entries(filter).filter(
        ([, value]) =>
          value !== 'All' &&
          value !== 'all' &&
          value !== 'none' &&
          value !== null &&
          value !== undefined &&
          String(value).length !== 0
      )
    );
    return {
      ...purgedFilters,
      ...new SortPayload(sort.field, sort.order as RaListOrder),
      offset: offset,
      page_size: page_size || 25
    };
  }

  private _getError(status: BPApiStatus) {
    return new HttpError(status.name, status.code, status.message);
  }
  [key: string]: any;
}
