import { ResourceName } from 'shared/enums/resource-name.enum';
import {
  BackOfficeDataProvider,
  DataProviderMethod,
  DtoConverter,
  UrlArray
} from 'shared/services/backoffice-data-provider';

import { UserPlanDtoConverter } from './user_plan-dto-converter';

export class UserPlansDataProvider extends BackOfficeDataProvider {
  protected dtoConverter: DtoConverter = new UserPlanDtoConverter();
  protected updateMethod: 'put' | 'patch' = 'patch';
  protected override urlRenderer(
    url: string | UrlArray,
    method: DataProviderMethod | undefined
  ) {
    if (method === 'update' || method === 'create' || method === 'getOne') {
      return `${ResourceName.USERS}/${(url as UrlArray)[1]}/plan`;
    }
    return Array.isArray(url) ? url.join('/') : url;
  }
}
