import { useState } from 'react';

import {
  HttpError,
  LinearProgress,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Button, Tooltip } from '@mui/material';

import { ResourceName } from 'shared/enums';
import { useAxiosCall } from 'shared/hooks/useAxiosCall';
import { px } from 'shared/utils/styles/css-unit';

import { AddToPackageFormDialog } from './AddToPackageFormDialog.component';
import { ContentPackages } from '../content-packages.dto';

export function AddToPackageButton() {
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>();
  const record = useRecordContext<ContentPackages>();

  const refresh = useRefresh();
  const notify = useNotify();

  const fetchAddContent = useAxiosCall<boolean>({
    resource: `${ResourceName.CONTENT_PACKAGES}/${record.id}`,
    autoFetch: false,
    init: { method: 'PATCH' }
  });

  const handleSuccess = () => {
    setShouldShowConfirm(false);
    refresh();
    notify('Content added.', { type: 'success' });
  };

  const handelFail = (e: HttpError) => {
    setShouldShowConfirm(false);
    notify(e.message, { type: 'error' });
  };

  const handleClick = () => {
    setShouldShowConfirm(true);
  };

  const handleAbort = () => {
    setShouldShowConfirm(false);
  };

  const handleConfirm = (content_id: string) => {
    fetchAddContent
      .fetch({ content_id, action: 'add' })
      .then(handleSuccess)
      .catch(handelFail);
  };

  if (record == null) {
    return <LinearProgress />;
  }

  return (
    <>
      <Tooltip
        arrow
        placement='right'
        title={'Add content to package'}
        describeChild
        enterDelay={1000}
      >
        <div>
          <Button
            onClick={handleClick}
            sx={(theme) => ({
              px: px(5),
              py: px(4),
              lineHeight: 1.5
            })}
          >
            <AddCircleIcon />
            {' Add content'}
          </Button>
        </div>
      </Tooltip>
      <AddToPackageFormDialog
        onClose={handleAbort}
        onSend={handleConfirm}
        loading={fetchAddContent.loading}
        isOpen={shouldShowConfirm}
        content='Select activity name'
        title='Add to package'
      />
    </>
  );
}
