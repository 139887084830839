import { useRecordContext } from 'react-admin';

import { Customer } from 'resources/customers/models/Customer.model';
import { renderFullName } from 'shared/utils/helperFunctions/renderFullName';

export function CustomerTitle() {
  const record = useRecordContext<Customer>();
  let label;
  if (Object.keys(record).length === 0) {
    label = 'Create Customer';
  } else {
    label = record?.alias || renderFullName(record);
  }
  return <>{label}</>;
}
