import { Record as RaRecord } from 'react-admin';

export function renderFullName(record?: RaRecord) {
  if (!record) {
    return '-';
  }
  const { first_name, last_name } = record;
  if (!first_name && !last_name) {
    return '-';
  }
  const full_name = `${(first_name?.length || 0) > 0 ? first_name + ' ' : ''}${
    (last_name?.length || 0) > 0 ? last_name : ''
  }`;
  if (full_name.length === 0) {
    return '-';
  }
  return full_name;
}
