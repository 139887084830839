import { ContentPackage } from 'resources/content_packages/content-package.model';
import { PlanDuration } from 'resources/plans/plan-duration.model';

export class BatchEditPayloadDto {
  description?: string;
  plan_duration?: PlanDuration;
  expired_date?: string;
  packages?: ContentPackage[];

  constructor(
    description: string,
    plan_duration?: PlanDuration,
    expired_date?: string,
    packages?: ContentPackage[]
  ) {
    this.description = description;
    this.plan_duration = plan_duration;
    this.expired_date = expired_date;
    this.packages = packages;
  }

  static factory({
    expired_date,
    plan_duration,
    description,
    packages
  }: Partial<BatchEditPayloadDto>) {
    return new BatchEditPayloadDto(
      description ?? '',
      plan_duration,
      expired_date,
      packages
    );
  }
}
