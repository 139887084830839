import { CodeStatus } from 'resources/codes/enums/code-status.enum';
import { CodeLimitation } from 'resources/codes/models/MembershipCode.model';
import { ContentPackage } from 'resources/content_packages/content-package.model';
import { PlanDuration } from 'resources/plans/plan-duration.model';

export class CodesMutationPayload {
  ['description']?: string;
  ['expired_date']: string | null;
  ['plan_duration']: PlanDuration;
  ['status']?: CodeStatus | null;
  ['limitations']?: CodeLimitation;
  ['number_of_codes']?: number;
  ['packages']?: ContentPackage[];

  constructor(
    expired_date: string | null = null,
    plan_duration: PlanDuration = new PlanDuration(),
    status?: CodeStatus | null,
    limitations?: CodeLimitation,
    number_of_codes?: number,
    description?: string,
    packages?: ContentPackage[]
  ) {
    this.expired_date = expired_date;
    this.plan_duration = plan_duration;
    this.status = status;
    this.limitations = limitations;
    this.number_of_codes = number_of_codes;
    this.description = description;
    this.packages = packages;
  }

  static factory({
    expired_date = null,
    plan_duration = new PlanDuration(),
    status,
    limitations,
    description,
    number_of_codes,
    packages
  }: Partial<CodesMutationPayload> = {}) {
    return new CodesMutationPayload(
      expired_date,
      plan_duration,
      status,
      limitations,
      number_of_codes,
      description,
      packages
    );
  }
}
