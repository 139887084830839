import {
  Edit,
  EditProps,
  useEditContext,
  useNotify,
  useUpdate
} from 'react-admin';

import { ConfirmUnsaved } from 'shared/components/dialogs/ConfirmUnsaved.component';
import { ConfirmUpdate } from 'shared/components/dialogs/ConfirmUpdate.component';
import { UnchangedDialog } from 'shared/components/dialogs/UnchangedDialog.component';
import { ResourceName } from 'shared/enums/resource-name.enum';
import { useMutationVM } from 'shared/hooks/mutation-vm/useMutationVM';

import { CampaignsForm } from '../components/CampaignForm.component';

import { Campaign } from '../models/campaign.model';
import { CampaignsMutationPayload } from '../models/campaigns-mutation-payload.model';

export function CampaignEdit(props: EditProps) {
  return (
    <Edit {...props} title='Campaigns'>
      <CampaignsEditForm />
    </Edit>
  );
}

function CampaignsEditForm() {
  const notify = useNotify();
  const [update, updateState] = useUpdate();
  const editContext = useEditContext<Campaign>();

  const {
    shouldShowConfirmSubmit,
    shouldShowUnchanged,
    shouldShowUnsaved,
    goBack,
    handleSave,
    handleCancelEdit,
    handleCancelSubmit,
    handleConfirmSubmit,
    handleUnchangedContinue,
    handleUnchangedQuit,
    handleUnsavedCancel,
    handleUnsavedConfirm
  } = useMutationVM<CampaignsMutationPayload, Campaign>({
    PayloadClass: CampaignsMutationPayload,
    record: editContext.record,
    resource: ResourceName.CAMPAIGNS,
    mutator: update,
    onMutateFailure: handleUpdateFailure,
    onMutateSuccess: handleUpdateSuccess
  });
  function handleUpdateSuccess() {
    notify('Campaign updated successfully.', { type: 'success' });
    goBack();
  }
  function handleUpdateFailure(e: Error) {
    notify(
      e.message || 'Something went wrong. Please try again in a few seconds.',
      { type: 'error' }
    );
  }
  return (
    <>
      <CampaignsForm
        onCancel={handleCancelEdit}
        onSave={handleSave}
        title={'Edit campaign - '}
      />
      <ConfirmUpdate
        loading={editContext.loading || updateState.loading}
        isOpen={shouldShowConfirmSubmit}
        onConfirm={handleConfirmSubmit}
        onClose={handleCancelSubmit}
        recordName='Campaign'
      />
      <ConfirmUnsaved
        loading={editContext.loading}
        isOpen={shouldShowUnsaved}
        onClose={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
      />
      <UnchangedDialog
        name='Campaign'
        isOpen={shouldShowUnchanged}
        onContinue={handleUnchangedContinue}
        onQuit={handleUnchangedQuit}
      />
    </>
  );
}
