import { GetListParams, GetListResult, Record as RaRecord } from 'react-admin';

import { ResourceName } from 'shared/enums';

import {
  BackOfficeDataProvider,
  BackOfficeDtoConverter,
  DataProviderMethod,
  DtoConverter,
  UrlArray
} from '../backoffice-data-provider';

export class LogsDataProvider extends BackOfficeDataProvider {
  protected urlRenderer(
    url: string | UrlArray,
    method?: DataProviderMethod | undefined
  ): string {
    if (method === 'getList' && url[1].startsWith(ResourceName.USERS)) {
      return url[1];
    }
    return Array.isArray(url) ? url.join('/') : url;
  }

  protected dtoConverter: DtoConverter = new BackOfficeDtoConverter();

  override async getList<RecordType extends RaRecord = RaRecord>(
    resource: string,
    viewParams: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const params = this.handleListParams(viewParams);

    const {
      data: { payload }
    } = await this.client.get(
      this.urlRenderer(
        [resource, viewParams.filter['apiResource'] ?? ''],
        'getList'
      ),
      {
        params
      }
    );
    return this.dtoConverter.convertList(payload);
  }
}
