import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { RaModule } from 'resources/RaModule';
import { ResourceName, ResourceIntent } from 'shared/enums';

import { CollectionCreate } from './pages/CollectionCreate.component';
import { CollectionEdit } from './pages/CollectionEdit.component';

import { CollectionShow } from './pages/CollectionShow.component';
import { CollectionsList } from './pages/CollectionsList.component';

export const CollectionsModule = RaModule.fromResourceProps({
  name: ResourceName.COLLECTIONS,
  options: { label: 'Collections' },
  list: CollectionsList,
  show: CollectionShow,
  edit: CollectionEdit,
  create: CollectionCreate,
  intent: ResourceIntent.ROUTE,
  icon: AutoAwesomeMotionIcon
});
