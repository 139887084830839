import {
  BackOfficeDataProvider,
  defaultUrlRenderer
} from 'shared/services/backoffice-data-provider';

import { AdminDtoConverter } from './AdminsDtoConverter';

export class AdminDataProvider extends BackOfficeDataProvider {
  protected dtoConverter = new AdminDtoConverter();
  protected urlRenderer = defaultUrlRenderer;
}
