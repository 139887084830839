import {
  FileInput,
  FileInputProps,
  sanitizeFieldRestProps,
  useRecordContext
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';

import { InputProps } from 'ra-core';

const useStyles = makeStyles(
  (theme) => ({
    root: { width: '100%' },
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default)
    },
    preview: {
      display: 'inline-block'
    },
    image: {
      margin: '0.5rem',
      maxHeight: '10rem'
    },
    removeButton: {
      'display': 'inline-block',
      'position': 'relative',
      'float': 'left',
      '& button': {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        minWidth: theme.spacing(2),
        opacity: 0
      },
      '&:hover button': {
        opacity: 1
      }
    }
  }),
  { name: 'RaImageInput' }
);

const ImageInputBO = (props: ImageInputProps) => {
  const classes = useStyles(props);
  const {
    className,
    classes: classesOverride,
    emptyText,
    source,
    src,
    title,
    initial,
    originalRecord,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const sourceValue = get(record, initial);

  if (!sourceValue) {
    return (
      <FileInput
        labelMultiple='ra.input.image.upload_several'
        labelSingle='ra.input.image.upload_single'
        classes={classes}
        {...props}
      />
    );
  }

  const titleValue = get(record, title) || title;

  return (
    <div className={className} {...sanitizeFieldRestProps(rest)}>
      <img
        title={titleValue}
        alt={titleValue}
        src={sourceValue}
        className={classes.image}
      />
    </div>
  );
};

export type ImageInputProps = FileInputProps &
  InputProps & { initialValue?: string };

export default ImageInputBO;
