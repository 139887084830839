import { useMemo } from 'react';

import { useRecordContext } from 'react-admin';

import { Button } from '@mui/material';
import { MembershipPlanProvider } from 'resources/customers/enums/MembershipPlanProvider.enum';
import { HasPermission } from 'resources/users/interfaces/HasPermission';
import { HasUserPlan } from 'resources/users/interfaces/HasUserPlan';
import { UserPlan } from 'resources/users_plans/models/user-plan.model';

import { Divider } from 'shared/components/common/Divider.component';
import { em } from 'shared/utils/styles/css-unit';

import { HasOnChangePlan } from '../../interfaces/HasOnChangePlan';

export function ChangePlanButton(
  props: HasOnChangePlan & Partial<HasUserPlan> & HasPermission
) {
  const record = useRecordContext<UserPlan>();

  const shouldDisabled = useMemo(() => {
    if (props.permission) {
      if (props.userPlan == null) {
        return false;
      }
      if (
        record.provider === MembershipPlanProvider.BlazePod ||
        record.provider === MembershipPlanProvider.None
      ) {
        return false;
      }
      if (
        (record.provider === MembershipPlanProvider.Recharge ||
          record.provider === MembershipPlanProvider.RechargeEu) &&
        !record.is_renew
      ) {
        return false;
      }
    }
    return true;
  }, [props.permission, props.userPlan, record.is_renew, record.provider]);

  return (
    <>
      {props.userPlan != null && (
        <Divider margin={{ left: em(2) }} vairant='diagonal' />
      )}
      <Button
        onClick={props.onChangePlan}
        disabled={shouldDisabled}
        sx={{ px: 0 }}
      >
        {props.userPlan == null ? 'Add Plan' : 'Change Plan'}
      </Button>
    </>
  );
}
