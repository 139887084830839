import { List, ListProps, SearchInput } from 'react-admin';

import { AppResponsive } from 'shared/components/common/AppResponsive.component';
import { Pagination } from 'shared/components/pagination/Pagination.component';
import { AppGenericTopToolbar } from 'shared/components/toolbars/AppGenericTopToolbar.component';
import { SectionName } from 'shared/enums/section-name.enum';
import { RaListOrder } from 'shared/services/backoffice-data-provider';

import { PackagesTable } from '../components/PackagesTable.component';

export function PackagesList(props: ListProps) {
  return (
    <List
      {...props}
      perPage={100}
      sort={{ field: 'id', order: RaListOrder.ASC }}
      actions={
        <AppGenericTopToolbar
          omitItems={['show', 'filter', 'edit', 'list', 'create']}
          sectionName={SectionName.Content}
        />
      }
      pagination={<Pagination />}
      filters={[<SearchInput source='search' alwaysOn />]}
      exporter={false}
    >
      <AppResponsive
        xs={<PackagesTable omitItems={[]} />}
        sm={<PackagesTable omitItems={[]} />}
        md={<PackagesTable omitItems={[]} />}
        lg={<PackagesTable />}
        xl={<PackagesTable />}
      />
    </List>
  );
}
