import { ReactNode } from 'react';

import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { cssUnit } from 'shared/utils/styles/css-unit';

const { rem, vw } = cssUnit;

export interface LoaderDialogProps {
  loading: boolean;
  title?: ReactNode;
  content?: ReactNode;
  variant?: 'circular' | 'linear';
  width?: string | number;
}

export function LoaderDialog(props: LoaderDialogProps) {
  const { loading, title, content, variant } = props;

  const Loader = () => {
    switch (variant) {
      case 'circular':
        return <CircularProgress />;
      case 'linear':
        return <LinearProgress />;
      default:
        return <CircularProgress />;
    }
  };
  return (
    <Dialog disableScrollLock={true} open={loading}>
      <Box sx={{ px: rem(2), minWidth: vw(20), maxWidth: vw(50) }}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {title || 'Loading...'}
        </DialogTitle>
        <DialogContent>
          <Typography variant='body1'>{content || 'Please wait :)'}</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pt: rem(1)
            }}
          >
            <Loader />
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
